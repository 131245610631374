import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { SpotifyApiContext } from 'react-spotify-api'
import { SpotifyAuth, Scopes } from 'react-spotify-auth'
import 'react-spotify-auth/dist/index.css'
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const Spotify = () => {

    const [token, setToken] = useState();
    const [t, i18n] = useTranslation('common');

    var d_id ='';
    //var token = 'BQBLzGRI3QliGb5yiMkgcap8jLrpqC6vSR07LiZdlQUHYqrl5uj5ClMAAgK5vUBzBh2h0BdtaCvyR5FbGtHBm_VrZpfT1eL8VyXqXwcjci965WUpOhZEjm82PZ4KyI44MDN5h8QBwVSFrTkI4io1My1a_0mj_icFQlsdjaHW6MpCu_y-vw5ere7paGZIHZigtijFOD9OT8_g8J3zQyfN-YE';
  
    const clientId      = '3cb75e19b41244598fe3226ded37ffdc';
    const clientSecret  = 'b3e9167eaf9a45348bcbc055f5772a58';

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('language'));
        
        /* axios({
            method: 'get',
            url: "https://accounts.spotify.com/authorize",
            data: {
                response_type: 'code',
                client_id: '3cb75e19b41244598fe3226ded37ffdc',
                redirect_uri: 'http://localhost:3000/spotify',
                scope: 'streaming user-read-email user-modify-playback-state user-read-private',
                state: 'addtrtrtrtdgAERV',
                show_dialog: true
                //refresh_token: localStorage.getItem("player_access_token")
            }, 
        }).then(function(res) {
                console.log("res =", res);

            
        }).catch(function (error){
            console.log(error);
        }); */
    }, [])
    

    


  console.log('====================================');
  console.log("token", token);
  console.log('====================================');


    /* window.onSpotifyPlayerAPIReady = () => {
        const player = new Spotify.Player({
            name: 'Web Playback SDK Template',
            getOAuthToken: cb => { cb(token); }
        });

        // Error handling
        player.on('initialization_error', e => console.error(e));
        player.on('authentication_error', e => console.error(e));
        player.on('account_error', e => console.error(e));
        player.on('playback_error', e => console.error(e));

        

        // Playback status updates
        player.on('player_state_changed', state => {
            console.log(state)
            $('#current-track').attr('src', state.track_window.current_track.album.images[0].url);
            $('#current-track-name').text(state.track_window.current_track.name);
        });

        // Ready
        player.on('ready', data => {
            console.log('Ready with Device ID', data.device_id);


            var startPlayer = document.querySelectorAll(".playSong");
            startPlayer.forEach((e)=>{
                e.onclick= ()=>{
                    var trackId = e.getAttribute("data-track");
                    play(data.device_id,trackId);
                }
            })


    
            
            // Play a track using our new device ID
            //play(data.device_id);
        });

            // Connect to the player!
            player.connect();
        } */

        // Play a specified track on the Web Playback SDK's device ID
        /* function play(device_id,trackId) {
            $.ajax({
                url: "https://api.spotify.com/v1/me/player/play?device_id=" + device_id,
                type: "PUT",
                data: '{"uris": ["spotify:track:'+trackId+'"]}',
                beforeSend: function(xhr){xhr.setRequestHeader('Authorization', 'Bearer ' + token );},
                success: function(data) { 
                    console.log(data)
                }
            });
        } */


  return (
   <>
   <div>
    <h1>Spotify Web Playback SDK Quick Start</h1>
    <button id="togglePlay">Toggle Play</button>
    <button id="pause">Pause Play</button>
    <button id="resume">Resume Play</button>
    <img src="" id="current-track" alt="" />
    <p id="current-track-name" />
    <ul>
        <li><button className="playSong" data-track="2VqAEP1yY5T523K7bZtL8a">Akon</button></li>
        <li><button className="playSong" data-track="3BY50NcEe6KKbxGznGBIUP">Vishwa mohan bhatt</button></li>
    </ul>

    {/* <div className='app'>
      {token ? (
        <SpotifyApiContext.Provider value={token}>

          <p>You are authorized with token: {token}</p>
        </SpotifyApiContext.Provider>
      ) : (
        // Display the login page
        <SpotifyAuth
          redirectUri='http://localhost:3000/spotify'
          clientID='3cb75e19b41244598fe3226ded37ffdc'
          scopes={[Scopes.userReadPrivate, 'streaming user-read-email user-modify-playback-state user-read-private']} // either style will work
          onAccessToken={(token) => setToken(token)}
        />
      )}
    </div> */}
</div>

   </>
  )
}

export default Spotify