import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { $ } from 'react-jquery-plugin';

import Header from '../includes/Header'
import Footer from '../includes/Footer'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import parse from 'html-react-parser';
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const Cart = () => {

    var currency     = localStorage.getItem('currency');
    var currencyRate = localStorage.getItem('currencyRate');
    let getProducts  = []; //JSON.parse(localStorage.getItem('products'));
    let languages    = localStorage.getItem('language');

    if (localStorage.getItem('products') != '') {
        getProducts = JSON.parse(localStorage.getItem('products'));
    }
    const [t, i18n] = useTranslation('common');

    //console.log(getProducts);
    let totalPrice = 0;

    const [course, setCourses] = useState([]);
    const [product, setProduct] = useState([]);
    const [cartMessage, setCartMessage] = useState('');
    const [totalItem, setTotalItem] = useState(0);
    const [refresh, setRefresh] = useState(0);
    const [currencySymbol, setCurrencySymbol] = useState('$');

    useEffect(() => {
        // alert('totalItem');
    }, [totalItem, refresh]);

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('language'));
        increaseCartItem();
        if(currency == 2){
            setCurrencySymbol('£');
        }

        if (getProducts != null && getProducts.length > 0) {
            try {

                axios.get(process.env.REACT_APP_API_URL + "get-items?items=" + JSON.stringify(getProducts))
                    .then(async (res) => {
                        if (res.status === 200) {
                            //console.log(res.data.data);
                            setCourses(res.data.data.courses);
                            setProduct(res.data.data.products);
                            let totalCount = res.data.data.courses.length + res.data.data.products.length;
                            setTotalItem(totalCount);
                        } else {

                            return console.log("Error !");
                        }



                    }).catch(error => {
                        console.log("Error !");

                    }

                    );
            } catch (error) {
                console.error('error');
            }
        } else {

            setCartMessage(t('lang.common.noItemInYourCart'));
        }

    }, []);

    const removeAddToCart = (uniqueid, type) => {
        const getProducts = JSON.parse(localStorage.getItem('products'));

        //console.log(uniqueid);

        if (localStorage.getItem('products').length > 0) {

            getProducts.map((v, i) => {

                if (v.uniqueid === uniqueid) {
                    getProducts.splice(i, 1);
                }

            });

            localStorage.setItem('products', JSON.stringify(getProducts));
            setTotalItem(JSON.parse(localStorage.getItem('products')).length);

            //Update Cart
            if (getProducts.length > 0) {
                try {

                    axios.get(process.env.REACT_APP_API_URL + "get-items?items=" + JSON.stringify(getProducts))
                        .then(async (res) => {
                            if (res.status === 200) {
                                //console.log(res.data.data);
                                setCourses(res.data.data.courses);
                                setProduct(res.data.data.products);
                            } else {

                                return console.log("Error !");
                            }

                        }).catch(error => {
                            console.log("Error !");

                        }

                        );
                } catch (error) {
                    console.error('error');
                }
            } else {
                //setTotalItem(0);
                localStorage.setItem('products', []);
                setTotalItem(0);
                setCartMessage(t('lang.common.noItemInYourCart'));
            }
        } else {
            localStorage.setItem('products', []);
            setTotalItem(0);
            //setTotalItem(0);
        }
        //console.log("totalItem", totalItem);
        // alert(totalItem);
    }


    const increaseCartItem = (e) => {


        if (e != undefined && e.itemType == 'courses') {

            let course_copy = course;

            const index = course_copy.findIndex(item => item.uniqueid === e.item);
            // alert(index);
            let item_count = course_copy[index].item_count + 1;
            course_copy[index].item_count = item_count;
            setCourses(course_copy);

        }
        if (e != undefined && e.itemType == 'products') {
            // console.log('courses', course);
            let product_copy = product;

            const index = product_copy.findIndex(item => item.uniqueid === e.item);
            // alert(index);
            let item_count = product_copy[index].item_count + 1;
            product_copy[index].item_count = item_count;
            setProduct(product_copy);
        }
        if (e != undefined) {
            let localStorageCart = JSON.parse(localStorage.getItem('products'));
            let localStorageCartIndex = localStorageCart.findIndex(item => item.uniqueid === e.item);
            let localStorageCartItemCount = localStorageCart[localStorageCartIndex].total + 1;
            localStorageCart[localStorageCartIndex].total = localStorageCartItemCount;
            localStorage.setItem('products', JSON.stringify(localStorageCart));
            setRefresh(!refresh);
        }

        /* var increase = $(".qtyInput2").val();

        console.log("e =", e);

        $(".qtyInput2").val(parseInt(increase) + 1);
        if (increase >= 20) {
            return false;
        } */


        /*  var qty = 0,
         maxlim;
         $('.priceControl .controls2').on('click', function() {

             qty = $(this).siblings('.qtyInput2').val();
             //console.log('qty= ',qty);
             maxlim = $(this).siblings('.qtyInput2').attr('data-max-lim');
             //console.log(maxlim);
             if (($(this).val() === '+') && (parseInt(maxlim) > qty)) {
                 qty++;
             } else if ($(this).val() === '-' && qty > 1) {
                 qty--;
             }

             $(this).siblings('.qtyInput2').val(qty);

         }); */
    }

    const decreaseCartItem = (e) => {


        if (e != undefined && e.itemType == 'courses') {

            let course_copy = course;

            const index = course_copy.findIndex(item => item.uniqueid === e.item);
            // alert(index);
            if (course_copy[index].item_count > 1) {
                let item_count = course_copy[index].item_count - 1;
                course_copy[index].item_count = item_count;
                setCourses(course_copy);
                let localStorageCart = JSON.parse(localStorage.getItem('products'));
                let localStorageCartIndex = localStorageCart.findIndex(item => item.uniqueid === e.item);
                let localStorageCartItemCount = localStorageCart[localStorageCartIndex].total - 1;
                localStorageCart[localStorageCartIndex].total = localStorageCartItemCount;
                localStorage.setItem('products', JSON.stringify(localStorageCart));
                setRefresh(!refresh);
            }


        }
        if (e != undefined && e.itemType == 'products') {
            // console.log('courses', course);
            let product_copy = product;

            const index = product_copy.findIndex(item => item.uniqueid === e.item);
            // alert(index);
            if (product_copy[index].item_count > 1) {
                let item_count = product_copy[index].item_count - 1;
                product_copy[index].item_count = item_count;
                setProduct(product_copy);

                let localStorageCart = JSON.parse(localStorage.getItem('products'));
                let localStorageCartIndex = localStorageCart.findIndex(item => item.uniqueid === e.item);
                let localStorageCartItemCount = localStorageCart[localStorageCartIndex].total - 1;
                localStorageCart[localStorageCartIndex].total = localStorageCartItemCount;
                localStorage.setItem('products', JSON.stringify(localStorageCart));
                setRefresh(!refresh);
            }

        }
        // if (e != undefined) {
        //     let localStorageCart = JSON.parse(localStorage.getItem('products'));
        //     let localStorageCartIndex = localStorageCart.findIndex(item => item.uniqueid === e.item);
        //     let localStorageCartItemCount = localStorageCart[localStorageCartIndex].total - 1;
        //     localStorageCart[localStorageCartIndex].total = localStorageCartItemCount;
        //     localStorage.setItem('products', JSON.stringify(localStorageCart));
        //     setRefresh(!refresh);
        // }

        // var increase = $(".qtyInput2").val();

        // $(".qtyInput2").val(parseInt(increase) - 1);
        // if (increase >= 20) {
        //     return false;
        // }
    }

    return (
        <>
            <Header itemCount={totalItem} />
            <section className='p-v-40 relative'>
                <div className='container'>
                    <div className="row">
                        <div className="col-12">
                            <div className="cartMsg d-flex w-100 justify-content-between align-items-center">
                                {cartMessage ? <h4>{cartMessage}</h4> : parse(`<h4>${t('lang.common.yourCart')}</h4>`)}
                                {/* <h4>Product has been added to your cart.</h4> */}
                                <Link to='/'>{t('lang.common.continueShopping')}</Link>
                            </div>
                        </div>
                        {!cartMessage ?
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="cartpage bgWgitePad w-100">
                                    <form action="" method="get">
                                        <div className="table-responsive">
                                            <table className="cart-items clean">
                                                <thead>
                                                    <tr>
                                                        <th className="first">{t('lang.common.items')}</th>
                                                        <th>{t('lang.common.price')}</th>
                                                        <th>{t('lang.common.quantity')}</th>
                                                        <th className="last">{t('lang.common.total')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        course.map((val, indx) => {

                                                            var price = val.price;
                                                            var dPrice = val.discount_price;

                                                            currency = parseInt(currency);

                                                            if(val.currency == currency){
                                                                price = price;
                                                                dPrice = dPrice;
                                                            }else if(val.currency != currency){
                                                                price = price * Number(currencyRate);
                                                            }

                                                            let discountedPrice = price - (price * dPrice / 100);

                                                            discountedPrice = Math.round(discountedPrice);

                                                            //let discountedPrice = val.price - (val.price * val.discount_price / 100);

                                                            {
                                                                totalPrice += discountedPrice * val.item_count; //((val.discount_price !== '' ? val.discount_price : val.price) * val.item_count);
                                                            }
                                                            return (<tr className="cart-item first" key={indx}>
                                                                <td className="cart-item-product first">
                                                                    <div className='d-flex'>
                                                                        <Link className="cart-image" to={`/courses/${val.slug}`}>
                                                                            <img src={process.env.REACT_APP_UPLOAD_URL + 'courses/images/' + val.photo} alt={val.title} />
                                                                        </Link>
                                                                        <div className="cart-item-product-wrap">
                                                                            <div className="cart-title w-100"><Link to={`/courses/${val.slug}`}>{ languages == 'ar' ? val.title_ar : val.title }</Link></div>
                                                                            <div className="w-100">
                                                                                <span className="cart-vendor vendor">{val.teacher.first_name + ' ' + val.teacher.last_name}</span>
                                                                                {/* <span className="cart-variant">10 Regular</span> */}
                                                                            </div>
                                                                            <div className="cart-item-rmv">
                                                                                <button type='button' className="cart-item-remove" onClick={(e) => { removeAddToCart(val.uniqueid, 'courses') }}><FontAwesomeIcon icon={faTrashAlt} /> {t('lang.common.remove')}</button>
                                                                                {/* <Link className="cart-item-remove" to='/'>
                                                                            <FontAwesomeIcon icon={faTrashAlt} /> Remove
                                                                        </Link> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="cart-item-price">
                                                                    <span className="money">{ discountedPrice != 0 ? currencySymbol+ '' + Math.round(discountedPrice) : t('lang.common.free') }</span>
                                                                </td>
                                                                <td className="cart-item-quantity">
                                                                    <div className="priceControl d-flex justify-content-center">
                                                                        <button className="controls2" type="button" onClick={(e) => { decreaseCartItem({ 'item': val.uniqueid, 'itemType': 'courses' }) }}>-</button>
                                                                        <input type="text" className="qtyInput2" value={val.item_count} data-max-lim={20} />
                                                                        <button className="controls2" type="button" onClick={(e) => { increaseCartItem({ 'item': val.uniqueid, 'itemType': 'courses' }) }}> +</button>
                                                                    </div>
                                                                </td>
                                                                <td className="cart-item-total last">
                                                                    <span className="money a">
                                                                        { discountedPrice != 0 ? currencySymbol+ '' + Math.round(discountedPrice) : t('lang.common.free') }
                                                                    </span>
                                                                </td>
                                                            </tr>)
                                                        })
                                                    }
                                                    {
                                                        product.map((val, indx) => {



                                                            {
                                                                /* var discountedPrices = val.price - (val.price * val.discount_price / 100);
                                                                totalPrice += discountedPrices * val.item_count;  */

                                                                var price = val.price;
                                                                var dPrice = val.discount_price;

                                                                if(currency == 2){
                                                                    price = val.price * Number(currencyRate);

                                                                }else{
                                                                    price = val.price;
                                                                }

                                                                var discountedPrices = price - (price * dPrice / 100);

                                                                discountedPrices = Math.round(discountedPrices);

                                                                totalPrice += discountedPrices * val.item_count;

                                                                //((val.discount_price !== '' ? val.discount_price : val.price) * val.item_count);
                                                            }
                                                            return (<tr className="cart-item first" key={indx}>
                                                                <td className="cart-item-product first">
                                                                    <div className='d-flex'>
                                                                        <Link className="cart-image" to={`/products/${val.slug}`}>
                                                                            <img src={val.gallery[0]} alt={val.product_name} />
                                                                        </Link>
                                                                        <div className="cart-item-product-wrap">
                                                                            <div className="cart-title w-100"><Link to={`/products/${val.slug}`}>{languages == 'ar' ? val.product_name_ar : val.product_name }</Link></div>
                                                                            <div className="w-100">
                                                                                <span className="cart-vendor vendor">{ languages == 'ar' ? val.product_category.category_name_ar : val.product_category.category_name}</span>

                                                                            </div>
                                                                            <div className="cart-item-rmv">
                                                                                <button type='button' className="cart-item-remove" onClick={(e) => { removeAddToCart(val.uniqueid, 'product') }}><FontAwesomeIcon icon={faTrashAlt} /> {t('lang.common.remove')}</button>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="cart-item-price">
                                                                    <span className="money v">
                                                                    { discountedPrices != 0 && discountedPrices !== '' ? currencySymbol+ '' + Math.round(discountedPrices) :
                                                                        discountedPrices == 0 ? t('lang.common.free') : Math.round(val.price)
                                                                    }
                                                                    </span>
                                                                </td>
                                                                <td className="cart-item-quantity">
                                                                    <div className="priceControl d-flex justify-content-center">
                                                                        <button className="controls2" type="button" onClick={(e) => { decreaseCartItem({ 'item': val.uniqueid, 'itemType': 'products' }) }}>-</button>
                                                                        <input type="text" className="qtyInput2" value={val.item_count} data-max-lim={20} />
                                                                        <button className="controls2" type="button" onClick={(e) => { increaseCartItem({ 'item': val.uniqueid, 'itemType': 'products' }) }}> +</button>
                                                                    </div>
                                                                </td>
                                                                <td className="cart-item-total last">
                                                                    <span className="money c">

                                                                        { discountedPrices != 0 && discountedPrices !== '' ? currencySymbol+ '' + Math.round(discountedPrices) * val.item_count :
                                                                            discountedPrices == 0 ? t('lang.common.free') : Math.round(val.price) * val.item_count
                                                                        }
                                                                    </span>
                                                                </td>
                                                            </tr>)
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="giftcardMsg">
                                            {t('lang.common.cod')}
                                        </div>
                                        {/* <div className="expected-days" style={{ width: "100%" }}>
                                        Generally takes around <b style={{ color: "#000000" }}>3-4 days</b> for
                                        delivery
                                    </div> */}
                                        <div className="cart-tools d-flex justify-content-end">
                                            {/* <div className="cart-instructions col-lg col-md col-sm-12 col-12">
                                            <p>Special instructions</p>
                                            <textarea rows={6} name="" placeholder="Add a note" defaultValue={""}/>
                                        </div> */}
                                            <div className="cart-totals">
                                                <p className="c-order-total">
                                                    <strong>{t('lang.common.orderTotal')}</strong>
                                                    <span className="cart-price">{currencySymbol}{Math.round(totalPrice)}</span>
                                                </p>
                                                {/* <p className="cart-message meta">
                                                Free shipping on orders above $ 400
                                            </p> */}

                                                {/* <input type="submit" name="checkout" defaultValue="Checkout" data-omnisend-checked="true" /> */}
                                                <Link to="/checkout" className="addCartBtn">{t('lang.common.checkout')}</Link>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            : ''
                        }
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Cart