import React, { useState, useEffect, useMemo } from 'react'
import Header from '../includes/Header'
import Footer from '../includes/Footer'
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faThLarge, faThList, faCartShopping } from '@fortawesome/free-solid-svg-icons'
import { Loader } from './Loader';
import axios from 'axios';
import parse from 'html-react-parser';
import InfiniteScroll from "react-infinite-scroll-component";
import MultiRangeSlider from '../includes/MultiRangeSlider';
import debounce from 'lodash.debounce';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";
import i18next from "i18next";

let page = 1;
let array = [];
const MainStore = () => {

    var currency     = localStorage.getItem('currency');
    var currencyRate = localStorage.getItem('currencyRate');
    let languages    = localStorage.getItem('language');
    const [t, i18n]  = useTranslation('common');

    const params                              = useParams();
    const [message, setMessage]               = useState('');
    const [brandMessage, setBrandMessage]     = useState('');
    const [category, setCategory]             = useState([]);
    const [loading, setLoading]               = useState(false);
    const [brand, setBrand]                   = useState([]);
    const [items, setItem]                    = useState([]);
    const [totalItem, setTotalItem]           = useState(0);
    const [filterCategory, setFilterCategory] = useState([]);
    const [filterRating, setFilterRating]     = useState([]);
    const [filterBrand, setFilterBrand]       = useState([]);
    const [activeMinPrice, setActiveMinPrice] = useState(0);
    const [activeMaxPrice, setActiveMaxPrice] = useState(0);
    const [categoryType, setCategoryType]     = useState('');
    const [currencySymbol, setCurrencySymbol] = useState('$');
    const [direction, setDirection]           = useState('ltr');
    const [alreadyAdded, setAlreadyAdded]     = useState('');

    const fetchData = () => {

        page =1;
        axios
          .get(process.env.REACT_APP_API_URL+`store/store-all?page=${page}&limit=20`)
          .then((res) => {

           if(res.data.data.product.length > 0){
                //console.log("res.data.data.product =", res.data.data.product);
                setItem(res.data.data.product);
                setCategory(res.data.data.category);
                setCategoryType(res.data.data.type);

                page = page + 1;

           }else{
            setMessage('');
            page = 1;
           }

           setLoading(false);

        });
    };

    const refresh = (setItems) => {};

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('language'));
        setLoading(true);
        window.scrollTo(0,0);
        setLoading(true);

        if(currency == 2){
            setCurrencySymbol('£');
        }

        if(localStorage.getItem('language') == 'ar'){
            setAlreadyAdded('تمت إضافة عربة الانتقال بالفعل');
        }else{
            setAlreadyAdded('Already added goto cart');
        }

        //setItems([]);
        page =1;
        axios
          .get(process.env.REACT_APP_API_URL+`store/store-all?page=${page}&limit=20`)
          .then((res) => {

           if(res.data.data.product.length > 0){
                //console.log("res.data.data.product =", res.data.data.product);
                setTimeout(() => {
                    setItem(res.data.data.product);
                    setLoading(false);
                }, 1000);

                setCategory(res.data.data.category);
                setCategoryType(res.data.data.type);

                page = page + 1;

                setLoading(false);

           }else{
            setMessage('');
            page = 1;
           }

           //setLoading(false);

        });
        // console.log(items);
        //console.log("InfiniteScroll =", items);
        var products = localStorage.getItem('products');
        if(products !== '' && products !==null){
            setTotalItem(JSON.parse(localStorage.getItem('products')).length);
        }

        if(localStorage.getItem('language') == 'ar'){
            setDirection('rtl');
        }

    }, []);

    const handleAddToCart = (id,type,uniqueid,total) =>{
        let getProducts = [];
        let selectedProduct = localStorage.getItem('products');
        let check = 1;
        let data = {
          id:id,
          type:type,
          uniqueid:uniqueid,
          total:total
        };
        //console.log("selectedProduct =", typeof selectedProduct);

        if(selectedProduct != '' && selectedProduct != null){
            selectedProduct = JSON.parse(selectedProduct);
            selectedProduct.map((v,i) => {
                if(v.uniqueid == uniqueid && v.type == type){
                    toast.success(alreadyAdded, {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    check = 0;

                }
            })
        }


        if(check == 0){
            return false;
        }

        if(localStorage.getItem('products') !== '' && localStorage.getItem('products')!=null){
            getProducts = JSON.parse(localStorage.getItem('products'));

          getProducts.push(data);
          localStorage.setItem('products', JSON.stringify(getProducts));
          setTotalItem(JSON.parse(localStorage.getItem('products')).length);


        }else{

          localStorage.setItem('products', JSON.stringify([data]));
          setTotalItem(JSON.parse(localStorage.getItem('products')).length);
        }
    }

    const filterData =(e) =>{
        page = 1;
        if(e != ''){

            array.map((v,i) => {
                //console.log("va", v);
                if(v.type == e.type){
                    array.splice(i, v.id);
                }

            });


        array.push(e);
        //array = [...array,e];
        //console.log("e =",array);
        //Filter Brand
        axios
            .get(process.env.REACT_APP_API_URL+`store/brand?data=${JSON.stringify(array)}`)
            .then((res) => {
            if(res.data.data.brand.length > 0){
                setBrand(res.data.data.brand);
            }else{
                setBrandMessage('No Brand Found!');
            }

            setLoading(false);

            });

          setLoading(true);
          setItem([]);
          axios
            .get(process.env.REACT_APP_API_URL+`store/search-all?data=${JSON.stringify(array)}&page=${page}&limit=20`)
            .then((res) => {
            if(res.data.data.product.length > 0){
                    setItem(res.data.data.product);
                    setCategory(res.data.data.category);
                    setLoading(false);
                    page = page + 1;

            }else{
                setMessage('');
                page = 1;
            }

            setLoading(false);

            });
        }
    }

    useMemo(()=>{
        filterData({'type':'price','id':1,'min':activeMinPrice,'max':activeMaxPrice});
    },[activeMinPrice,activeMaxPrice])

  return (
    <>
    {
        loading === true ? (<Loader />) : ''
    }

    <ToastContainer autoClose={2000} />
    <Header itemCount={totalItem} />

    <section className='storeSection'>
                <div className='container-fluid ph-40'>
                    <div className='row'>
                    <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                            <div className='storeAcordian'>
                                <h3>{t('lang.common.filterBy')}</h3>
                                <div className="accordion" id="accordionPanelsStayOpenExample">
                                <div className="accordion-item">
                                        <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree" >
                                                {t('lang.common.productType')}
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingThree" >
                                            <div className="accordion-body">
                                                <div className='accorBox radioBox'>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="cat_type" value="1" id="rbInstruments" onChange={()=>{ filterData({'type':'category','id':1}) }} />
                                                        <label className="form-check-label" htmlFor="rbInstruments">{t('lang.common.instruments')}</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="cat_type" value="2" id="rbAccessories" onChange={()=>{ filterData({'type':'category','id':2}) }} />
                                                        <label className="form-check-label" htmlFor="rbAccessories">{t('lang.common.accessories')}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo" >
                                                {t('lang.common.rating')}
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                                            <div className="accordion-body">
                                                <div className='accorBox radioBox'>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="rbs1" onChange={()=>{ filterData({'type':'rating','id':5}) }} />
                                                        <label className="form-check-label" htmlFor="rbs1"><FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /></label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="rbs2" defaultChecked="" onChange={()=>{ filterData({'type':'rating','id':4}) }} />
                                                        <label className="form-check-label" htmlFor="rbs2"><FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /></label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="rbs3" defaultChecked="" onChange={()=>{ filterData({'type':'rating','id':3}) }} />
                                                        <label className="form-check-label" htmlFor="rbs3"><FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /></label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="rbs4" defaultChecked="" onChange={()=>{ filterData({'type':'rating','id':2}) }} />
                                                        <label className="form-check-label" htmlFor="rbs4"> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /></label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="rbs5" defaultChecked="" onChange={()=>{ filterData({'type':'rating','id':1}) }} />
                                                        <label className="form-check-label" htmlFor="rbs5"><FontAwesomeIcon icon={faStar} /></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="panelsStayOpen-headingFour">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour" >
                                                {t('lang.common.price')}
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseFour" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
                                            <div className="accordion-body">
                                                <div className='accorBox rangeSlider'>
                                                {/* <Slider
                                                    range
                                                    allowCross={false}
                                                    draggableTrack={true}

                                                    min={100}
                                                    max={500}
                                                    defaultValue={[200, 300]}
                                                    tipFormatter={value => `$ ${value}`}
                                                    tipProps={{visible:true}}
                                                /> */}

                                                <MultiRangeSlider
                                                    min={1}
                                                    max={10000}
                                                    direction={direction}
                                                    onChange={
                                                        /* (min,max) => { //filterData({type:'price','id':1})
                                                        } */
                                                        debounce(({ min, max }) => {

                                                            // console.log("aaa");
                                                            //filterData({type:'price','min':min})
                                                            setActiveMinPrice(min);
                                                            setActiveMaxPrice(max);
                                                        }, 300)
                                                    }
                                                />
                                                {/* <ReactDualRangeSlider /> */}
                                                {/* <RangeSlider
                                                    style={{width: 160, height: 80}}
                                                    gravity={'center'}
                                                    min={200}
                                                    max={1000}
                                                    step={20}
                                                    selectionColor="#3df"
                                                    blankColor="#f618"
                                                    // onValueChanged={(low, high, fromUser) => {
                                                    //     this.setState({rangeLow: low, rangeHigh: high})
                                                    // }}
                                                /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="accordion-item">
                                        <h2 className="accordion-header" id="panelsStayOpen-headingFive">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive" >
                                                Brand
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseFive" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFive" >
                                            <div className="accordion-body">
                                                <div className='accorBox radioBox'>
                                                    {
                                                        brand.length >0 ?
                                                        brand.map((val,indx) => {
                                                            return(<div className="form-check" key={indx}>
                                                            <input className="form-check-input" type="radio" value={val.id} name="brand" id={indx} defaultChecked="" onChange={()=>{ filterData({'type':'brand','id':val.id, 'category_type':categoryType}) }} />
                                                            <label className="form-check-label" htmlFor={indx}>{val.brand_name}</label>
                                                        </div>)
                                                        })

                                                         : 'Pleaes select category'
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-9 col-md-9 col-sm-12 col-12'>
                            <div className='listGridTop row align-items-center justify-content-between'>
                                <div className='listGridTopLeft col-auto'>
                                    <h2>{t('lang.common.listOfItems')}</h2>
                                </div>
                                <div className='listGridTopRight col-auto'>
                                    <ul className='d-flex align-items-center'>
                                        <li className='mbHide'><Link to={`/store`} className="active"><FontAwesomeIcon icon={faThLarge} /></Link></li>
                                        <li className='mbHide'><Link to={`/store-list`}><FontAwesomeIcon icon={faThList} /></Link></li>
                                        <li>
                                            <div className='selectOptionBox'>
                                                <label>{t('lang.common.shortBy')}</label>
                                                <select onChange={(e)=>{ filterData({'type':'short_by','id':e.target.value}) }}>
                                                    <option value="">{t('lang.common.select')}</option>
                                                    <option value="1">{t('lang.common.newToOld')}</option>
                                                    <option value="2">{t('lang.common.oldToNew')}</option>
                                                </select>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='storeBoxArea'>

                            <InfiniteScroll
                                dataLength={20}
                                pageStart={0} //This is important field to render the next data
                                /* next={() => {
                                    fetchData();
                                }} */
                                hasMore={true}
                                loader={ parse(message) }
                                endMessage={
                                {/* <p style={{ textAlign: "center" }}>
                                    <b>Yay! You have seen it all</b>
                                </p> */}
                                }>
                                    <div className='row g-4'>
                                {

                                    items.length > 0 ? items.map((val,indx) => {

                                        var price = val.price;
                                        var dPrice = val.discount_price;

                                        if(currency == 2){
                                            price = val.price * Number(currencyRate);
                                            //dPrice = val.discount_price * Number(currencyRate);
                                        }else{
                                            price = val.price;
                                            //dPrice = val.discount_price;
                                        }

                                        let discountedPrice = price - (price * dPrice / 100);

                                        //let discountedPrices = val.price - (val.price * val.discount_price / 100);
                                        return(<div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12' key={indx}>
                                            <div className="projectInner w-100 projectInnerFixFooter">
                                                <div className="projectInnerTop w-100 relative">
                                                    <Link to={`/products/${val.slug}`}><img src={val.gallery[0]} alt={val.product_name} /></Link>
                                                    <span className='bestSeller'>{t('lang.common.bset_seller')}</span>
                                                    { dPrice != 0 ? <span className="discountPercent">{dPrice}% {t('lang.common.off')}</span> : '' }
                                                </div>
                                                <div className="projectInnerMid w-100">
                                                    <h3><Link to={`/products/${val.slug}`} className="black">{ languages == 'ar' ? val.product_name_ar : val.product_name }</Link></h3>
                                                    {
                                                       languages == 'ar' ? parse(`${val.product_description_ar.substring(0, process.env.REACT_APP_CHARACTER_MAX_LENGTH)}...`) : parse(`${val.product_description.substring(0, process.env.REACT_APP_CHARACTER_MAX_LENGTH)}...`)
                                                    }

                                                    <div className='d-flex align-items-center starArea w-100'>

                                                        <ul className='d-flex'>

                                                        {
                                                            val.rating != '' &&
                                                            (() => {
                                                                let td = [];
                                                                for (let i = 1; i <= val.rating; i++) {
                                                                td.push(<li><FontAwesomeIcon icon={faStar} /></li>);
                                                                }
                                                                return td;
                                                            })()
                                                        }


                                                        </ul>
                                                        {
                                                            val.totalRating > 0 && <span className='rvs'>({val.totalRating})</span>
                                                        }

                                                    </div>
                                                    <div className='totalUpdate'>
                                                        <ul className='d-flex justify-content-between'>
                                                            <li><span className="currentPrice">{ discountedPrice != 0 ? currencySymbol+ '' + Math.round(discountedPrice) : t('lang.common.free') }</span></li>
                                                            <li><span className='line-through'>{ price != 0 && dPrice != 0 ? currencySymbol+ '' +Math.round(price) : '' }</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="addCartArea w-100 justify-content-center d-flex">
                                                <button type='button' onClick={ (e) => {handleAddToCart(val.id,'product', 'product'+val.id, 1)}} className='addCartBtn'>{t('lang.common.add_to_cart')} <FontAwesomeIcon icon={faCartShopping} /></button>
                                                    {/* <Link to='/' className='buyNowBtn'>Buy Now <FontAwesomeIcon icon={faCartShopping} /></Link> */}
                                                </div>
                                            </div>
                                        </div>)
                                    }) : parse(`<h3>${t('lang.common.pleaseWait')}...</h3>`)
                                }
                               </div>
                            </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

    </>
  )
}

export default MainStore