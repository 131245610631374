import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

import common_ar from "./translations/ar.json";
import common_en from "./translations/en.json";

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  ns: ['common'],
  lng: 'en',                              // language to use
  resources: {
      en: {
          common: common_en               // 'common' is our custom namespace
      },
      ar: {
          common: common_ar
      },
  },
});

let language = localStorage.getItem('language');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>
    
  // </React.StrictMode>
);

if(language == 'ar'){
  document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
  document.body.classList.add('rtl');
}else{
  document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
  document.body.classList.add('ltr');
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
