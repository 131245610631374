import React, { useEffect, useState } from 'react'
import Header from '../includes/Header'
import Footer from '../includes/Footer'
import { Loader } from './Loader';
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faCalendarAlt, faClock, faHeart } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import parse from 'html-react-parser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";
import i18next from "i18next";

let languages = localStorage.getItem('language');
const CourseCategory = () => {

    const params                    = useParams();
    const [courses, setCourses]     = useState([]);
    const [category, setCategory]   = useState('Category');
    const [loading, setLoading]     = useState(false);
    const [totalItem, setTotalItem] = useState(0);
    const [currencySymbol, setCurrencySymbol] = useState('$');
    const [t, i18n] = useTranslation('common');

    const [alreadyAdded, setAlreadyAdded] = useState('');

    var currency     = localStorage.getItem('currency');
    var currencyRate = localStorage.getItem('currencyRate');

    let courseCategorySlug = localStorage.getItem('courseCategorySlug');

    if(courseCategorySlug !== null & courseCategorySlug !== params.slug){


        try {
            axios.get(process.env.REACT_APP_API_URL+"courses/category/"+params.slug)
              .then(async (res) => {
                 if(res.status === 200){
                    //console.log(res.data.data);
                    setCourses(res.data.data.courses);

                    if(languages == 'ar'){
                        setCategory(res.data.data.category_ar);
                    }else{
                        setCategory(res.data.data.category);
                    }

                    //setCategory(res.data.data.category);
                    localStorage.setItem('courseCategorySlug', params.slug);
                    setLoading(false);
                }else{
                  return console.log("Error !");
                }

            }).catch(error =>
                console.log("Error !")
            );
        } catch (error) {
        //console.error('error');
        }
    }

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('language'));
        window.scrollTo(0,0);
        setLoading(true);

        var products = localStorage.getItem('products');
        if(products !== '' && products !==null){
            setTotalItem(JSON.parse(localStorage.getItem('products')).length);
        }

        if(currency == 2){
            setCurrencySymbol('£');
        }

        if(localStorage.getItem('language') == 'ar'){
            setAlreadyAdded('تمت إضافة عربة الانتقال بالفعل');
        }else{
            setAlreadyAdded('Already added goto cart');
        }

        //setTotalItem(JSON.parse(localStorage.getItem('products')).length);

          try {
            axios.get(process.env.REACT_APP_API_URL+"courses/category/"+params.slug)
              .then(async (res) => {
                 if(res.status === 200){
                    setCourses(res.data.data.courses);

                    if(languages == 'ar'){
                        setCategory(res.data.data.category_ar);
                    }else{
                        setCategory(res.data.data.category);
                    }

                    localStorage.setItem('courseCategorySlug', params.slug);
                    setLoading(false);
                }else{
                  return console.log("Error !");
                }

              }).catch(error =>
                console.log("Error !")
              );
          } catch (error) {
            //console.error('error');
          }

    },[]);

    const handleAddToCart = (id,type,uniqueid,total) =>{
        let getProducts = [];
        let selectedProduct = localStorage.getItem('products');
        let check = 1;

        let data = {
            id:id,
            type:type,
            uniqueid:uniqueid,
            total:total
        };

        if(selectedProduct != '' && selectedProduct != null){
            selectedProduct = JSON.parse(selectedProduct);
            selectedProduct.map((v,i) => {
                if(v.uniqueid == uniqueid && v.type == type){
                    toast.success(alreadyAdded, {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    check = 0;

                }
            })
        }

        if(check == 0){
            return false;
        }

        if(localStorage.getItem('products') !== '' && localStorage.getItem('products')!==null){
            getProducts = JSON.parse(localStorage.getItem('products'));

          getProducts.push(data);
          localStorage.setItem('products', JSON.stringify(getProducts));

          setTotalItem(JSON.parse(localStorage.getItem('products')).length);

        }else{

          localStorage.setItem('products', JSON.stringify([data]));
          setTotalItem(JSON.parse(localStorage.getItem('products')).length);

        }
    }

    function formatDate(date) {
        var monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        var d = new Date(date),
            month = '' + monthShortNames[d.getMonth()], //(d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join(' ');
    }


  return (
    <>
        {
            loading === true ? (<Loader />) : ''
        }
        <ToastContainer autoClose={2000} />
        <Header itemCount={totalItem} />
        <section className='coursesBlock'>
            <div className='container-fluid ph-40'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='sectionTitle d-flex align-items-center justify-content-between'>
                            <h2 className='p-0'>{category}</h2>

                        </div>
                    </div>
                    {
                        courses.length > 0 ?
                        courses.map((val, indx) => {

                            var price = val.price;
                            var dPrice = val.discount_price;

                           /*  if(currency == 2){
                                price = val.price * Number(currencyRate);
                                //dPrice = val.discount_price * Number(currencyRate);
                            }else{
                                price = val.price;
                                //dPrice = val.discount_price;
                            } */

                            if(val.currency == currency){
                                price = val.price;
                                dPrice = val.discount_price;
                            }else if(val.currency != currency){
                                price = val.price * Number(currencyRate);
                            }

                            let discountedPrice = price - (price * dPrice / 100);


                            //let discountedPrices = val.price - (val.price * val.discount_price / 100);
                            return(<div className='col-lg-3 col-md-3 col-sm-6 col-12' key={indx}>
                            <div className="projectInner w-100">
                                <div className="projectInnerTop relative w-100">
                                    <Link to="/"><img src={process.env.REACT_APP_UPLOAD_URL+'courses/images/'+val.photo} alt={val.title} /></Link>
                                    <span className="bestSeller bestSellerFloting">{t('lang.common.bset_seller')}</span>
                                    { dPrice != 0 ? <span className="discountPercent">{dPrice}% {t('lang.common.off')}</span> : '' }
                                </div>
                                <div className="projectInnerMid w-100">
                                    <h4><Link to={`/courses/${val.slug}`}>{ languages == 'ar' ? val.title_ar : val.title }</Link></h4>
                                    {
                                        languages == 'ar' ?

                                        val.short_description_ar != null ?
                                        <p>{`${val.short_description_ar.substring(0, process.env.REACT_APP_CHARACTER_MAX_LENGTH)}...`}</p>
                                        : ''
                                        : <p>{`${val.short_description.substring(0, process.env.REACT_APP_CHARACTER_MAX_LENGTH)}...`}</p>
                                    }

                                    <div className='d-flex align-items-center starArea w-100'>
                                        <ul className='d-flex'>
                                            <li>
                                                {
                                                    val.rating != '' &&
                                                    (() => {
                                                        let td = [];
                                                        for (let i = 1; i <= val.rating; i++) {
                                                        td.push(<FontAwesomeIcon icon={faStar} />);
                                                        }
                                                        return td;
                                                    })()
                                                }
                                            </li>
                                        </ul>
                                        {
                                            val.totalRating > 0 && <span className='rvs'>({val.totalRating})</span>
                                        }
                                    </div>

                                </div>
                                <div className="price-sec w-100">
                                    <div className="row justify-content-between align-items-center">
                                        <div className="col-auto">
                                            <div className="price-sec-text">
                                            <p>{
                                                discountedPrice != 0 ? currencySymbol+''+Math.round(discountedPrice) : t('lang.common.free')
                                                } <span className="line-through">{ price != 0 && dPrice != 0 ? currencySymbol+''+Math.round(price) : ''}
                                                </span>
                                            </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='popHoverBox'>
                                    <div className='phTop w-100 d-flex'>
                                        <div className='phtLeft'>
                                            <h4><Link to={`/courses/${val.slug}`}>{ languages == 'ar' ? val.title_ar : val.title }</Link></h4>
                                        </div>
                                        <div className='phtRight'>
                                            {/* <span><Link to='/'><FontAwesomeIcon icon={faHeart} /></Link></span> */}
                                        </div>
                                    </div>
                                    <div className='phTop w-100 d-flex'>
                                        <div className='phtLeft'>
                                            <h5>{t('lang.common.updated')} { formatDate(val.created_at) }</h5>
                                            {/* <p>All Levels . <span>Subtitles</span></p> */}
                                        </div>
                                        <div className='phtRight'>
                                            <div className='phtRightInner'>
                                                <span>{t('lang.common.bset_seller')}</span>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='phDescription w-100'>
                                        {
                                            languages == 'ar' ?

                                            val.short_description_ar != null ?
                                            <p>{`${val.short_description_ar.substring(0, process.env.REACT_APP_CHARACTER_MAX_LENGTH)}...`}</p>
                                            : ''
                                            : <p>{`${val.short_description.substring(0, process.env.REACT_APP_CHARACTER_MAX_LENGTH)}...`}</p>
                                        }
                                    </div>
                                    {
                                        val.purposes.length > 0 ? <div className='phList w-100'>
                                        <ul>
                                            {
                                               val.purposes.map((pV,pI) => {
                                                 return(<li key={pI}>{ languages == 'ar' ? pV.details_ar : pV.details }</li>)
                                               })
                                            }

                                        </ul>
                                    </div> : ''
                                    }

                                    <div className='addCart w-100 text-center'>
                                        <button type='button' onClick={ (e) => {handleAddToCart(val.id, 'courses', 'courses'+val.id, 1)}} className='addCartBtn me-2'>{t('lang.common.add_to_cart')}</button>
                                        <Link to={`/courses/${val.slug}`} className="addCartBtn">{t('lang.common.view')}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>)
                        })
                        : parse(`<h3>${t('lang.common.no_course_found')}</h3>`)
                    }
                </div>
            </div>
        </section>
        <Footer />
    </>
  )
}

export default CourseCategory