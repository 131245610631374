import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from './Loader';
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const ForgotPassword = () => {
    const { register, formState: { errors }, handleSubmit } = useForm();
    let navigate                       = useNavigate();
    const [settings, setSettings]      = useState([]);
    const [password, setPassword]      = useState("");
    const [email, setEmail]            = useState("");
    const [errorMsg, setMessage]       = useState([]);
    const [error404Msg, set404Message] = useState([]);
    const [loading, setLoading]        = useState(false);
    const [t, i18n] = useTranslation('common');

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('language'));

        if(settings.length <= 0){
          try {
            axios.get(process.env.REACT_APP_API_URL+"settings")
              .then(async (res) => {
                 if(res.status === 200){
                    setSettings(res.data.data);
                }else{
                  return console.log("Error !");
                }

              }).catch(error =>
                console.log("Error !")
              );
          } catch (error) {
            //console.error('error');
          }
        }

      },[]);

    const onSubmit = (data)  => {
    try {

        setLoading(true);

        const formData = new FormData();
        formData.append("email", email);

        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL+"password/email",
            data: formData,
        }).then(function(res) {
            console.log(res.data);
            setLoading(false);
            if(res.status === 200){
                if(res.data.status == 0){
                    set404Message(t('lang.validation.emailNotExists'));
                }else if(res.data.status == 1){

                    setMessage(t('lang.common.resetSuccessMessage'));
                    setTimeout(() => {
                        navigate('/');
                    }, 8000);
                }
            }else if(res.data.data.status === 422){
                setLoading(false);
                set404Message(res.data.data.errors.email);
            }

        }).catch(function (error){
            setLoading(false);
            set404Message(error.response.data.data);
        });
    } catch (error) {
        setLoading(false);
        //console.error('error');
    }
    //console.log(data);
    };

    const handleFields = async (e) => {
        if (e.target.name === "email") {
            setEmail(e.target.value);
        }
    }

    return (
        <>
        {
            loading === true ? (<Loader />) : ''
        }
            <section className='Login' style={{
  backgroundImage: "url(" + process.env.REACT_APP_UPLOAD_URL+'sitesettings/'+settings.login_banner + ")",
  'minHeight': '100vh',
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
}}>
                <div className='loginWrap d-flex justify-content-end'>
                    <div className='LoginInner d-flex align-items-center'>
                        <div className='loginForm'>
                            <div className="logo">
                                <Link to="/">
                                    <img src={process.env.REACT_APP_UPLOAD_URL+'sitesettings/'+settings.logo} alt="" />
                                </Link>
                            </div>
                            <div className='loginFormInner'>
                            <div className="logins message text-success"><ul><li>{errorMsg}</li></ul></div>
                            <div className="logins message text-danger"><ul><li>{error404Msg}</li></ul></div>
                                <h3>{t('lang.common.forgotYourPassword')}</h3>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-floating mb-3">
                                        <input type="email" name="email" className="form-control" value={email} id="floatingInput" placeholder="name@example.com" {...register("email", { required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/  })} onChange={ e => { handleFields(e) }} autoComplete="off" />
                                        <label htmlFor="floatingInput">{t('lang.common.emailAddress')}</label>
                                        {errors?.email?.type === "required" && <p className='errors'>{t('lang.validation.email')}</p>}
                                        {errors?.email?.type === "pattern" && <p className='errors'>{t('lang.validation.emailPattern')}</p>}
                                    </div>


                                    <div className="form-group mb-3">
                                          <button type="submit" className="logInBtn">{t('lang.common.sendResetCode')}</button>
                                    </div>
                                    <div className="form-group mb-3 text-center">
                                          <p>{t('lang.common.dontAccount')} <Link to="/register">{t('lang.common.signupNow')}</Link></p>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default ForgotPassword