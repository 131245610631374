import React from 'react'

export const Loader = () => {
  return (
    <div id="carLodaer">
        <div id="loading_container">
            <div className="custom-line">
                <div className="loader loader-1">
                <img src={process.env.REACT_APP_UPLOAD_URL+'icon.png'} alt="" />
                </div>
            </div>
        </div>
      </div>
  )
}