import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { Loader } from './Loader';
import Select from 'react-select';
import { Controller, useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'awesome-bootstrap-checkbox/demo/build.css';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const StudentRegistration = (props) => {

    const [settings, setSettings]              = useState([]);
    const [active, setActive]                  = useState(0);
    const [errorMsg, setMessage]               = useState([]);
    const [successMsg, setSuccessMsg]          = useState([]);
    const [loading, setLoading]                = useState(false);

    const [studentPhone, setStudentPhone]      = useState();
    const [t, i18n] = useTranslation('common');

    //Form Elements
    //Student Form Registration
    const [resgiterAs, setResgiterAs] = useState(0);
    const [fullName, setFullName]     = useState('');
    const [email, setEmail]           = useState("");
    const [phone, setPhone]           = useState("");
    const [password, setPassword]     = useState("");

    const [age, setAge]               = useState();

    let navigate = useNavigate();

    const { register, formState: { errors }, handleSubmit, control } = useForm();
    const onSubmit = (data)  => {

        try {
            setLoading(true);
            const formData = new FormData();
            formData.append("resgiterAs", 0);

            formData.append("fullName", data.fullName);
            formData.append("email", data.email);
            formData.append("phone", data.phone);
            formData.append("password", data.password);
            formData.append("age", data.age);
            formData.append("timezone", localStorage.getItem('timezones'));

            axios({
                method: 'post',
                url: process.env.REACT_APP_API_URL+"auth/register",
                data: formData,
            }).then(function(res) {

                if(res.status === 200){
                    setLoading(false);
                    //localStorage.setItem('authToken', res.data.token);
                    if(res.data.data.user_type === 2){
                        toast(t('lang.common.verifyYourEmail'));
                        //setSuccessMsg('Thank you for registration. Admin will approve your account.');
                        setTimeout(() => {
                            navigate('/');
                        }, 3000);
                    }else if(res.data.data.user_type === 3){
                        toast(t('lang.common.verifyYourEmail'));
                        //window.location.replace(process.env.REACT_APP_LOGIN_URL+'?user='+res.data.data.email+'&tok='+res.data.token);
                        setTimeout(() => {
                            navigate('/');
                        }, 3000);
                    }
                }else if(res.status === 404){
                    setLoading(false);
                    setMessage(res.data.data);
                }

            }).catch(function (error){
                setLoading(false);
                setMessage(error.response.data.data);
                //console.log(error.response.data.data);
            });
        } catch (error) {
            setLoading(false);
            console.error('error',error);
        }
        //console.log(data);
    };

    let countries   = [];
    let states      = [];
    let cities      = [];
    // let languages   = [];
    let genres      = [];
    let instruments = [];


    const resetForm =() => {
        var clickeRadio = document.querySelector('input[name="register"]:checked').value;

        if(clickeRadio === 1){
            setActive(1);
            setResgiterAs(1);
        }else{
            setActive(0);
            setResgiterAs(0);
        }
    }


    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('language'));
        if(localStorage.getItem('registerType') === 'student'){
            setActive(0);
            setResgiterAs(0);
        }else{
            setActive(1);
            setResgiterAs(1);
            //document.getElementById("teacherForm").reset();
        }
        //Get Logo

        if(settings.length <= 0){
          try {
            axios.get(process.env.REACT_APP_API_URL+"settings")
              .then(async (res) => {
                 if(res.status === 200){
                    setSettings(res.data.data);
                }else{
                  return console.log("Error !");
                }

              }).catch(error =>
                console.log("Error !")
              );
          } catch (error) {
            //console.error('error');
          }
        }

        //Get Country


      },[]);

     const handleFields = async (e) => {

        if (e.target.name === "fullName") {
            setFullName(e.target.value);
        } else if (e.target.name === "email") {
            setEmail(e.target.value);
        }else if (e.target.name === "phone") {
            setPhone(e.target.value);
        }else if (e.target.name === "password") {
            setPassword(e.target.value);
        }else if (e.target.name === "age") {
            setAge(e.target.value);
        }

     }


    return (
        <>
        {
            loading === true ? (<Loader />) : ''
        }
            <section className='Login d-flex justify-content-end' style={{
  backgroundImage: "url(" + process.env.REACT_APP_UPLOAD_URL+'sitesettings/'+settings.login_banner + ")",
  'minHeight': '100vh',
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
}}>
                <div className='regWrap'>
                    <div className="logo">
                        <Link to="/">
                        <img src={process.env.REACT_APP_UPLOAD_URL+'sitesettings/'+settings.logo} alt="" />
                        </Link>
                    </div>
                    <h2>{t('lang.common.registerMsg')}</h2>
                    <h6>{t('lang.common.regisretSubMsg')}</h6>


                        {/* <div className="success">{successMsg}</div> */}
                        <ToastContainer autoClose={2000} />
                        <div className="message text-center errors"><ul>{(errorMsg ?
                            (
                                errorMsg.map((v,i) => {
                                    return(<><li>{v}</li></>)
                                })
                            )

                            : null)}</ul></div>
                        {
                             <div className='regWrapInner forStudent' id='registerStudent'>
                             <form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
                             <input type="hidden"  value="0" name="resgiterAs" />
                             <div className="row g-3 g-2 g-md-3 g-lg-4">
                                 <div className="col-md-4 form-floating">
                                     <input type="text" name="fullName" className="form-control" id="validationCustom01" placeholder="Full name" {...register("fullName", { required: true, maxLength: 30 })} />
                                     <label htmlFor="validationCustom01">{t('lang.common.fullName')}*</label>

                                     {errors?.fullName?.type === "required" && <p className='errors'>{t('lang.validation.fullName')}</p>}
                                     {errors?.fullName?.type === "maxLength" && (
                                         <p className='errors'>{t('lang.validation.CannotExceed30Characters')}</p>
                                     )}
                                 </div>
                                 <div className="col-md-4 form-floating mb-3">
                                     <input type="email" name="email" className="form-control" id="floatingInput" placeholder="name@example.com" {...register("email", { required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/  })} />
                                     <label htmlFor="floatingInput">{t('lang.common.emailAddress')}*</label>

                                     {errors?.email?.type === "required" && <p className='errors'>{t('lang.validation.email')}</p>}
                                     {errors?.email?.type === "pattern" && <p className='errors'>{t('lang.validation.emailPattern')}</p>}
                                 </div>
                                 <div className="col-md-4 form-floating mb-3">
                                     <input type="number" name="age" className="form-control" value={age} id="floatingAge" placeholder="30" {...register("age", { required: true, maxLength:2  })} onChange={ e => { handleFields(e) }} />
                                     <label htmlFor="floatingAge">{t('lang.common.age')}*</label>

                                     {errors?.age?.type === "required" && <p className='errors'>{t('lang.validation.age')}</p>}
                                     {errors?.age?.type === "maxLength" && <p className='errors'>{t('lang.validation.maximum2')}</p>}
                                 </div>
                                 <div className="col-md-6 form-floating mb-3 piArea">

                                    <Controller
                                        control={control}
                                        name="phone"
                                        rules={{ required: true }}
                                        render={({ field: { ref, ...field } }) => (
                                        <PhoneInput
                                            {...field}
                                            inputExtraProps={{
                                            ref,
                                            required: true,
                                            autoFocus: true
                                            }}
                                            country={"us"}
                                            //onlyCountries={["in"]}
                                            countryCodeEditable={false}
                                            specialLabel={"Player Mobile Number"}
                                        />
                                        )}
                                    />
                                     {/*  <input type="text" name="phone" className="form-control" value={phone} id="Phone" placeholder='Phone' {...register("phone", { required: true, maxLength: 20 })} onChange={ e => { handleFields(e) }} /> */}
                                     <label htmlFor="Phone"></label>
                                     {errors?.phone?.type === "required" && <p className='errors'>{t('lang.validation.phone')}</p>}
                                     {errors?.phone?.type === "maxLength" && (
                                         <p className='errors'>{t('lang.validation.phone20')}</p>
                                     )}
                                 </div>

                                 <div className="col-md-6 form-floating mb-3">
                                     <input type="password" name="password"  className="form-control" value={password} id="Password" placeholder="Password" {...register("password", { required: true, minLength: 8, pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/ })} onChange={ e => { handleFields(e) }} />
                                     <label htmlFor="Password">{t('lang.common.password')}*</label>
                                     {errors?.password?.type === "required" && <p className='errors'>{t('lang.validation.passwordRequired')}</p>}
                                     {errors?.password?.type === "minLength" && (
                                         <p className='errors'>{t('lang.validation.phone8')}</p>
                                     )}
                                     {errors?.password?.type === "pattern" && <p className='errors'>{t('lang.validation.phoneRegex')}</p>}
                                 </div>
                                 <div className="col-12">
                                     <div className="form-check abc-checkbox abc-checkbox-info">
                                         <input className="form-check-input" type="checkbox" id="singleCheckbox" value="1" aria-label="Single checkbox One" {...register("terms", { required: true})} />
                                         <label className="form-check-label" htmlFor="singleCheckbox">
                                             <Link to="/pages/terms-and-conditions" target="_blank">{t('lang.common.agreeMsgs')}</Link>
                                         </label>
                                         {errors?.terms?.type === "required" && <p className='errors'>{t('lang.validation.agreeMsg')}</p>}

                                     </div>

                                 </div>
                                 <div className="col-12 text-center">
                                     <button className="logInBtn width-auto" type="submit">{t('lang.common.submitForm')}</button>
                                 </div>
                             </div>
                             </form>
                         </div>
                        }
                </div>
            </section>
        </>
    )
}

export default StudentRegistration