import React, { useEffect, useRef, useState, useMemo } from "react";
import ReactPlayer from "react-player";
import Header from '../includes/Header';
import Footer from '../includes/Footer';
import { createMeeting } from "./MeetingAPI";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Tooltip } from 'react-tooltip'
import axios from 'axios';
import { Loader } from './Loader';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";
import i18next from "i18next";

import {
  MeetingProvider,
  MeetingConsumer,
  useMeeting,
  useParticipant,
  usePubSub,
} from "@videosdk.live/react-sdk";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faEdit, faSearch, faMicrophone, faMicrophoneLines, faSignOut,  faShare, faVideoCamera, faVideoSlash, faStopCircle,faPaperPlane,faMessage } from '@fortawesome/free-solid-svg-icons'

let userRole = localStorage.getItem('loginUserRole');
localStorage.setItem('chatBox', false);
var authToken1 = "";
// let mikeHandelar = localStorage.getItem('mike');

//https://javascript.plainenglish.io/how-to-pass-props-from-child-to-parent-component-in-react-d90752ff4d01

function onParticipantJoined(participant) {
  //console.log(" onParticipantJoined", participant);
}

function JoinScreen({ getMeetingAndToken, participantName, classId, joinMeetingId  }) {
  const [meetingId, setMeetingId] = useState(null);
  const [participantNames, setParticipantName] = useState(null);
  const [classRoomIds, setClassRoomIds] = useState('');

  const [searchParams] = useSearchParams();

  const params = useParams();

  //console.log("searchParams =", searchParams.get('joinId'));
  useMemo(()=>{
    if(params.joinId != ''){
      setMeetingId(params.joinId);
      setClassRoomIds(params.joinId);
    }
  },[searchParams])


  const [t, i18n] = useTranslation('common');

  //console.log("meetingId =", meetingId);
  /* const setParticipantName = (e) =>{
    participantName = e.target.value;
    console.log("participantName ", participantName);
  } */

  const onClick = async () => {
    await getMeetingAndToken(meetingId);

  };
  return (

      userRole !== null && userRole == 3 ?
      <>
      {/* <p>Please copy the room id: {classRoomIds}</p> */}
      <input type="hidden" placeholder="Enter Room Id" defaultValue={classRoomIds} className="form-control mb-3" readOnly />
      <button className="buttonJoin" onClick={onClick}>{t('lang.common.validateRoom')}</button>
    </>
    :  userRole !== null && userRole == 2 ? <button className="buttonJoin" onClick={onClick}>{t('lang.common.createClass')}</button> : ''

  );
}

const chunk = (arr) => {
  const newArr = [];
  while (arr.length) newArr.push(arr.splice(0, 3));
  return newArr;
};

function VideoComponent(props) {
  const webcamRef      = useRef(null);
  const micRef         = useRef(null);
  const screenShareRef = useRef(null);
  const [t, i18n] = useTranslation('common');
  const [userName, setUserName] = useState('');

  const { displayName, webcamStream, micStream, screenShareStream, webcamOn, micOn, screenShareOn, isLocal } = useParticipant(
    props.participantId, props.userV
  );


  /* const videoStream = useMemo(() => {
    if (webcamOn && webcamStream || screenShareStream) {
      const mediaStream = new MediaStream();

      if(screenShareOn){
        mediaStream.addTrack(screenShareStream.track);
      }else{
        mediaStream.addTrack(webcamStream.track);
      }

      return mediaStream;
    }
  }, [webcamStream, webcamOn]); */
  useEffect(() => {
    if(typeof displayName !== 'undefined'){

      setUserName(displayName.name);

    }
  },[]);

  const videoStream = useMemo(() => {
    if (webcamOn && webcamStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(webcamStream.track);
      return mediaStream;
    }
  }, [webcamStream, webcamOn]);


  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('language'));
    if (webcamRef.current) {
      if (webcamOn) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(webcamStream.track);

        webcamRef.current.srcObject = mediaStream;
        webcamRef.current
          .play()
          .catch((error) =>
            console.error("videoElem.current.play() failed", error)
          );
      } else {
        webcamRef.current.srcObject = null;
      }


    }
  }, [webcamStream, webcamOn]);

  useEffect(() => {
    if (micRef.current) {
      if (micOn && micStream) {
        const mediaStream = new MediaStream();
        //console.log("mediaStream1 =", mediaStream);
        mediaStream.addTrack(micStream.track);

        micRef.current.srcObject = mediaStream;
        micRef.current
          .play()
          .catch((error) =>
            console.error("videoElem.current.play() failed", error)
          );
      } else {
        micRef.current.srcObject = null;
      }
    }
  }, [micStream, micOn]);

  useEffect(() => {
    if (screenShareRef.current) {
      if (screenShareOn) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(screenShareStream.track);

        screenShareRef.current.srcObject = mediaStream;
        screenShareRef.current
          .play()
          .catch((error) =>
            console.error("videoElem.current.play() failed", error)
          );
      } else {
        screenShareRef.current.srcObject = null;
      }
    }
  }, [screenShareStream, screenShareOn]);
  //console.log("displayName =", displayName);
  /* let dName = JSON.parse(displayName); */

  return (
    <>

      <h4>{ userName != '' ? userName : '' }</h4>
      <audio ref={micRef} autoPlay />

  {
    webcamOn && webcamStream && !screenShareOn && (

          <ReactPlayer
            //
            playsinline // very very imp prop
            pip={false}
            light={false}
            controls={false}
            muted={false}
            playing={true}
            //
            url={videoStream}
            //
            height={"100%"}
            width={"100%"}
            onError={(err) => {
              console.log(err, "participant video error");
            }}
          />
        )
  }

  {
  screenShareOn ? (
          <>
            <video  width={"100%"} ref={screenShareRef} autoPlay />
          </>
        ) : null
  }

    </>
  );
}

var topic = "CHAT";

function Container(props) {
  const [joined, setJoined] = useState(false);
  // destructure publish method from usePubSub hook
  //const { publish, messages } = usePubSub("CHAT");
  const [t, i18n] = useTranslation('common');
  var sendBy = '';
  const { publish, messages } = usePubSub("CHAT", {
    onMessageReceived: (message)=>{
      sendBy = JSON.parse(message.senderName);
      const localParticipantId = mMeeting?.localParticipant?.id;

      if(localParticipantId != message.senderId){
        toast(`${t('lang.validation.message')} ${message.message} ${t('lang.common.from')} ${sendBy?.name}`);
        //window.alert(message.senderName + "says" + message.message);
      }

    }
  });

  const { join, leave, toggleMic, toggleWebcam, toggleScreenShare } = useMeeting();
  const { participants } = useMeeting();
  const [joinMessage, setJoinMessage] = useState('');
  const [loading, setLoading]         = useState(false);
  const [classType, setClassType] = useState('');
  const mMeeting = useMeeting({ onMeetingJoined, onMeetingLeft });
  const [toggleWebcamShow, setToggleWebcamShow] = useState(true);
  const [togglMikeShow, setTogglMikeShow] = useState(true);



  //const { RAISEHAND } = usePubSub("RAISE_HAND");

  const [cameraStatus, setCameraStatus] = useState(0);

  const [userV, setUserV] = useState(1);

  const localParticipant = mMeeting.localParticipant;

  const participants1 = mMeeting.participants;

  const [endClassEvent, setEndClassEvent] = useState(true);
  let navigate = useNavigate();

  function onMeetingLeft() {

    toast(t('lang.common.meetingEndMessage'));

    setTimeout(() => {
      navigate('/');

    }, 3000);

  }

  const joinMeeting = () => {

    setJoined(true);
    join();
    setLoading(true);

    if(userRole !== null && userRole == 2){

      try {
        //props.classRoomId; props.participants;

        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL+"send-meeting-ids",
            data: {
              classRoomId: props.classRoomId,
              meetingId: props.meetingId
            },
        }).then(function(res) {

            if(res.status === 200){


            }else if(res.data.data.status === 404){

            }

        }).catch(function (error){

        });
      } catch (error) {
          //console.error('error');
      }

    }

  };


  function onMeetingJoined() {
    setLoading(false);
  }

  const toggleMics = async() =>{

    if(togglMikeShow == true){
      mMeeting?.muteMic();
      setTogglMikeShow(false);
    }else{
      mMeeting?.unmuteMic();
      setTogglMikeShow(true);
    }

  }

  const webcamStatus = async () => {
    // Enable Webcam in Meeting

    if(cameraStatus == 1){
      setCameraStatus(0);
    }else{
      setCameraStatus(1);
    }

    mMeeting?.toggleWebcam();

    /* // Disable Webcam in Meeting
    mMeeting?.disableWebcam();

    // Change Webcam in Meeting
    const webcams = await mMeeting?.getWebcams(); // returns all webcams

    const { deviceId, label } = webcams[0];

    mMeeting?.changeWebcam(deviceId); */
  };

  function toggleScreenShares(){

    mMeeting.toggleScreenShare();

  }

  useEffect(() => {

    if(userRole !== null && userRole == 3){
      setJoinMessage(t('lang.common.joinClass'));
      setClassType("students");
      //console.log("dasda", endClassEvent);
    }else{
      setJoinMessage(t('lang.common.startClass'));
      setClassType("teacher");
    }

  }, [])


  const openChat = () => {
    if(localStorage.getItem('chatBox') == 'false'){
      localStorage.setItem('chatBox', true);
    }else{
      localStorage.setItem('chatBox', false);
    }

    props.func(localStorage.getItem('chatBox'));
  }

  // publish message
  const sendMessage = (message) => {
    publish(message, { persist: true });
  };


  /* const sendMessageToAll = (message) => {

    message = document.getElementById("txtChat").value;
    publish(message, { persist: true });

    var persons = messages[0].senderName; // JSON.parse(messages[0].senderName)
    persons = JSON.parse(persons);

    toast(`${t('lang.validation.message')} ${document.getElementById("txtChat").value} ${t('lang.common.from')} ${persons.name}`);

    document.getElementById('txtChat').value = "";

  } */

  const sendMessageToAll = () => {

    let message = document.getElementById("txtChat").value;

    publish(message, { persist: true });

    var sender = JSON.parse(messages[0].senderName);
    //const localParticipantId = mMeeting?.localParticipant?.id;

    //console.log(messages[0].senderId, localParticipantId);

    /* if(messages.length > 0 && messages[0].senderId != localParticipantId){

      toast(`Message ${document.getElementById("txtChat").value} from ${sender?.name}`);
    }else{
      toast(`Message ${document.getElementById("txtChat").value}`);
    } */


    document.getElementById('txtChat').value = "";


  }

  //props.func('My name is Dean Winchester & this is my brother Sammie');

  const leaveClass = async() => {

    confirmAlert({
      customUI: ({ onClose }) => {
          return (
              <div className="custom-confirmation-box">
                  <h1>{t('lang.common.areYouSure')}</h1>
                  <p>{t('lang.common.leaveClass')}</p>
                  <button onClick={onClose} className="no-btn">{t('lang.common.no')}</button>
                  <button
                      className="yes-btn"
                      onClick={async () => {
                          mMeeting.leave();
                          await setEndClassEvent(false);
                          //setEndClassEvent(true);
                          navigate('/');
                          onClose();
                      }}
                  >
                      {t('lang.common.yes')}
                  </button>
              </div>
          );
      }
    });
  }

  const endClass = async() => {

    confirmAlert({
      customUI: ({ onClose }) => {
          return (
              <div className="custom-confirmation-box">
                  <h1>{t('lang.common.areYouSure')}</h1>
                  <p>{t('lang.common.closeClass')}</p>
                  <button onClick={onClose} className="no-btn">{t('lang.common.no')}</button>
                  <button
                      className="yes-btn"
                      onClick={async () => {

                          mMeeting.end();
                          await setEndClassEvent(false);
                          //setEndClassEvent(true);
                          navigate('/');
                          onClose();
                      }}
                  >
                      {t('lang.common.yes')}
                  </button>
              </div>
          );
      }
    });
  }

  const arrLength = Array.from(participants).length;

  var part1 = '';
  var part2 = '';
  if(typeof [...participants.values()][0] !== 'undefined'){
    part1 = [...participants.values()][0];
  }

  if(typeof [...participants.values()][1] !== 'undefined'){
    part2 = [...participants.values()][1];
  }
  //console.log('data = ',[...participants.values()]);

  return (
    <>
        {
            loading === true ? (<Loader />) : ''
        }
        <ToastContainer position="bottom-left" autoClose={2000} />
      <div className="liveVideo">

        {
          userRole !== null && userRole == 2 ? <h5 className="text-center">{t('lang.common.classRoomId')}: {props.meetingId}</h5> : ''
        }


        {joined ? (
          <div className="individual-users d-flex flex-wrap align-items-start">

            {

            //   userRole !== null && userRole == 2 ? <div className="col-8 teacher">
            //   <div className={`bigVideo ${student.id} ---- ${userRole}`}>
            //     <VideoComponent participantId={[...participants.values()][0].id} />
            //   </div>
            // </div> : userRole == 3 ? <div className="col-8 teacher">
            //             <div className={`bigVideo ${teacher.id} ---- ${userRole}`}>
            //               <VideoComponent participantId={teacher.id} />
            //             </div>
            //           </div> : ''
            <div className="col-8 teacher">
              <div className={`bigVideo ${userRole}`}>
                <VideoComponent participantId={part2.id} />
              </div>
            </div>
            }


              <div className="studentWrap-1 col-4">

                {

                <div className={`students ${part1.id}`}>
                  <VideoComponent participantId={part1.id} />
                </div>
                }

              </div>

              <div className="studentWrap-2">

              <div className="msg-wrappage">
                <div className="masgTop d-flex align-items-center justify-content-between">
                  <h3>{t('lang.common.participants')} <span>({arrLength})</span></h3>
                  <span className="closeMsg" onClick={openChat}>X</span>
                </div>
                <div className="masg-order"  id="chatArea">
                </div>
                <div className="box-detailsWrap">
                  <div className="msg-wrap-main">
                    <div className="msg-wrap">
                      <div className="msg-history  msg-history-scrollbar allMassage" id="msg-historyHeight">
                        {
                          messages.length > 0 ?
                          messages.map((v,i) => {
                            let participantName = JSON.parse(v.senderName);
                            return(<div className="outgoing-msg d-flex justify-content-end mb-4">
                            <div className="sent-msg">
                              <p>{v.message}</p>
                              <span className="time-date">{participantName.name} | {moment.utc(v.timestamp).format("llll")}</span>
                            </div>
                            <div className="user-img">
                              <img src={process.env.REACT_APP_UPLOAD_URL+`user.png`} alt="" />
                            </div>
                          </div>)
                          })

                          : ''
                        }


                      </div>
                    </div>
                  </div>
                  <div className="type-msg-wrap">
                    <div className="input-msg-write">
                      <textarea type="text" className="write-msg form-control msgArea" id="txtChat" placeholder="Type a message" defaultValue={""} />
                      <button className="msg-send-btn sendMessage" onClick={sendMessageToAll} type="button"><FontAwesomeIcon icon={faPaperPlane} /></button>
                    </div>
                  </div>
                </div>
              </div>
              </div>

              {/* <div className="wrapper">
                {chunk([...participants.keys()]).map((k) => (
                  <div className="box" key={k} style={{ display: "flex" }}>
                    {k.map((l) => (
                      <VideoComponent key={l} participantId={l} />
                    ))}
                  </div>
                ))}
              </div> */}
              <>

              </>
              {/* <Controls /> */}
              <div className="col-12">
                <div className="buttonWrap">
                  {/* <button className="buttonV" onClick={leave} data-tip="Leave" data-place="bottom"><FontAwesomeIcon icon={faSignOut} /> Leave</button>
                  <button className="buttonV" onClick={toggleMics} data-tip="Leave" data-place="bottom"><FontAwesomeIcon icon={faMicrophone} /> { togglMikeShow ? 'Turn Off Mike' : 'Turn On Mike' }</button>
                  <button className="buttonV" onClick={toggleWebcam} data-tip="Turn Off Webcam" data-place="bottom"><FontAwesomeIcon icon={faVideoCamera} /> { toggleWebcamShow ? 'Turn Off Webcam' : 'Turn On Webcam' }</button>
                  <button className="buttonV" onClick={toggleScreenShares} data-tip="Share Screen" data-place="bottom"><FontAwesomeIcon icon={faShare} /> Share Screen</button>

                  <button className="buttonV" id="btnSend" data-tip="Share Screen" data-place="bottom"><FontAwesomeIcon icon={faShare} /> SendMessage</button>
                  <button className="buttonV" onClick={openChat} data-tip="Open Chat" data-place="bottom"><FontAwesomeIcon icon={faMessage} /> Open Chat</button> */}

                  <button className="buttonV" onClick={leaveClass} data-tip="Leave" data-place="bottom" title="Leave Class"><FontAwesomeIcon icon={faSignOut} /></button>
                  <button className={`buttonV ${!togglMikeShow ? 'danger' : ''}` } onClick={toggleMics} data-tip="Leave" title={togglMikeShow ? 'Mic Off' : 'Mic On'} data-place="bottom"> { togglMikeShow ? <FontAwesomeIcon icon={faMicrophone} /> : <FontAwesomeIcon icon={faMicrophoneLines} /> }</button>
                  <button className={`buttonV ${ cameraStatus == 1 ? 'danger' : '' }` } onClick={webcamStatus} data-tip="Turn Off Webcam" title={ cameraStatus == 0 ? 'Turn Off Webcam' : 'Turn On Webcam' } data-place="bottom"> { cameraStatus == 0 ? <FontAwesomeIcon icon={faVideoCamera} /> : <FontAwesomeIcon icon={faVideoSlash} /> }</button>
                  <button className={`buttonV`} onClick={toggleScreenShares} data-tip="Share Screen" data-place="bottom" title="Share Screen"><FontAwesomeIcon icon={faShare} /> </button>
                  <button className={`buttonV ${ localStorage.getItem('chatBox') == 'true' ? 'active' : '' } `} onClick={openChat} title="Open Chat" data-tip="Open Chat" data-place="bottom"><FontAwesomeIcon icon={faMessage} /></button>

                  {userRole !== null && userRole == 2 ? <button className="buttonV" id="endClassRoom" onClick={endClass} data-tip="End Class" title="End Class" data-place="bottom"><FontAwesomeIcon icon={faStopCircle} /> End Class</button> : ''}
                  {/* <button className="buttonV" onClick={endClass} id="endClassRoom" title="End Class" data-tooltip-content="End Class" data-place="bottom"><FontAwesomeIcon icon={faStopCircle} /> End Class</button> */}

                </div>
              </div>
          </div>
        ) : (
          <div className="text-center"><button className="buttonV" onClick={joinMeeting}>{joinMessage}</button></div>
        )}
      </div>
    </>
  );
}



const ClassRoom = (props) => {
  const videoPlayerRef = useRef();
  let navigate = useNavigate();
  const [meetingId, setMeetingId] = useState(null);

  //const [micOn, setMicOn] = useState(true);
  const [isMeetingStarted, setMeetingStarted] = useState(false);
  const [isMeetingLeft, setIsMeetingLeft] = useState(false);
  const [raisedHandsParticipants, setRaisedHandsParticipants] = useState([]);
  const [totalItem, setTotalItem]   = useState(0);
  const [joinScreenMessage, setJoinScreenMessage] = useState('');
  const [ chatBox, setChatBox] = useState('');
  const [uName, setUname] = useState('{name:a,role:1}')
  const [classDetails, setClassDetails] = useState([]);
  const [teacherPhoto, setTeacherPhoto] = useState('');
  const [currency, setCurrency] = useState('');
  const [changeClass, setChangeClass] = useState('');
  const [mikeStatus, setMikeStatus] = useState(false);

  const [classMainName, setClassMainName] = useState('');

  const [t, i18n] = useTranslation('common');

  const [userName, setUserName] = useState('');

  //const singleParticipants = useParticipant({dName});

  const params = useParams();
  //console.log("params =", params.id);

  const { displayName } = useParticipant(props.participantId);

  let loginUser = localStorage.getItem('loginUser');

  function onStreamEnabled(stream) {
    // This event will be triggered whenever a participant's video, audio or screen share stream is enabled.
    //console.log(" onStreamEnabled", stream);
  }

  useEffect(() => {

    var products = localStorage.getItem('products');
    if(products !== '' && products !==null){
        setTotalItem(JSON.parse(localStorage.getItem('products')).length);
    }

    if(userRole == null){
      navigate('/');
    }

    if(userRole == 2){
      setMikeStatus(true);
    }else{
      setMikeStatus(false);
    }

    try {
      axios.get(process.env.REACT_APP_API_URL+`classroom/${params.id}?&loginUser=${loginUser}`)
        .then(async (res) => {
          if(res.status === 200){
             setClassDetails(res.data.data.class);
             setTeacherPhoto(res.data.data.teacher);

             let cName = res.data.data.class.lesson_name;

             cName = cName.replace("lessons", "Lesson");

             setClassMainName(cName.substr(1));

             if(res.data.data.currency == 'USD'){
                setCurrency('$');
             }else{
                setCurrency('£');
             }
             setUserName(res.data.data.userName);

            if(userRole == 2){
              setUname('{"name":"'+res.data.data.userName+'","role":"1"}');
            }else{
              setUname('{"name":"'+res.data.data.userName+'","role":"2"}');
            }

          }else{
            return console.log("Error !");
          }

        }).catch(error =>
          console.log("Error !")
        );
  } catch (error) {
      //console.error('error');
  }


    /* if(userRole == 3){
      setJoinScreenMessage("Join Screen");
      setMicOn(false);
    }else{
      setJoinScreenMessage();
      setMicOn(true);
    } */


    //const videoSrcObject = new MediaStream([videoTrack]);

  },[params.id])

  //console.log("uName=",uName);
  const openChat = (data) => {
    //console.log(data);
    setChatBox(data);

    let hHeight = document.querySelector('#mainHeader');
    let footerHeight = document.querySelector('#footerHeight');
    let height = hHeight.offsetHeight;
    let fHeight = footerHeight.offsetHeight;

    let totalHeight = height + fHeight;

    document.getElementById("msg-historyHeight").style.height = `${totalHeight}px`;
  }


  const getMeetingAndToken = async (id) => {

    await fetch("https://classes.zeryabmusicacademy.com/gettoken.php")
    //await fetch("http://localhost/tokens/gettoken.php")
      .then((data) => {
        return data.json();
      })
      .then(async (post) => {
        authToken1 = post.token;
      });

    const meetingId =
      id == null ? await createMeeting({ token: authToken1 }) : id;
      setMeetingId(meetingId);

  };

    return (
        <>
        <Header itemCount={totalItem} />
        <section className={`videoSection ${ chatBox == 'true' ? "pr-30p" : ''}`}>
            <div className="container-fluid">
              <div className="row">
                    {
                      meetingId ? (

                        <div className="col-12">
                            <MeetingProvider
                              config={{
                                meetingId,
                                micEnabled: mikeStatus,
                                webcamEnabled: true,
                                name: uName,
                                multiStream: true,
                                mode: "CONFERENCE",
                              }}
                              token={authToken1}
                            >
                            <MeetingConsumer>
                              {() => <Container meetingId={meetingId} func={openChat} classRoomId={params.id} />}
                            </MeetingConsumer>
                          </MeetingProvider>
                        </div>

                      ) : (
                        <>
                          <div className="col-9">
                            {/* <video
                              autoPlay
                              playsInline
                              muted
                              ref={videoPlayerRef}
                              controls={true}
                              className={"w-100 flip"}
                            /> */}
                            <div className="lson-checkout-list lclMd">
                            <div className="lson-checkout-wrap d-flex">
                              <div className="lson-checkout-lft">
                                <div className="lson-checkout-img">
                                  {
                                    teacherPhoto != '' ? <img src={process.env.REACT_APP_UPLOAD_URL+`teacher/photos/${teacherPhoto}`} alt="" />  : <img src="../images/avatar.jpg" alt="" />
                                  }

                                    </div>
                                    </div>
                                    <div className="lson-checkout-rgt">
                                      <div className="lson-checkout-text">
                                          <h4>{classMainName}</h4>
                                          {/* <p>4 lessons a month</p> */}
                                          <p><span>{currency}{classDetails.booking_price}</span></p>
                                          <p>{t('lang.common.date')}: {classDetails.lesson_date}</p>
                                          <p>{t('lang.common.timing')}: {classDetails.lesson_time}</p>
                                          {userRole == 3 ? <p><strong>{t('lang.common.forClassRoomId')}</strong></p> : ''}

                                        </div>
                                      </div>
                                    </div>
                            </div>
                          </div>
                          {/* <ReactPlayer
                            //
                            playsinline // very very imp prop
                            pip={false}
                            light={false}
                            controls={true}
                            muted={true}
                            playing={true}
                            //
                            url={videoPlayerRef}
                            //
                            height={"100%"}
                            width={"100%"}
                            onError={(err) => {
                              console.log(err, "participant video error");
                            }}
                          /> */}
                          </>
                      )
                    }
                   {
                    meetingId == null ? <div className="col-3 d-flex align-items-center">
                    <div className="JoinDiv">
                        <h5>{joinScreenMessage}</h5>
                        <JoinScreen  getMeetingAndToken={getMeetingAndToken} classId={params.id} joinMeetingId={meetingId}  />
                      </div>
                    </div> : ''
                   }

              </div>
          </div>
        </section>
        <Footer />
        </>
    );
}

export default ClassRoom