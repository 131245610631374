import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Loader } from './Loader';
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const ReviewForm = (props) => {
  const { register, formState: { errors }, handleSubmit, reset  } = useForm();
  const [errorMsg, setMessage]       = useState([]);
  const [error404Msg, set404Message] = useState([]);
  const [loading, setLoading]        = useState(false);
  const [rating, setRating]          = useState("");
  const [review, setReview]          = useState("");
  const [t, i18n] = useTranslation('common');

  let loginUser    = localStorage.getItem('loginUser');

  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('language'));
  }, []);

  const handleFields = async (e) => {
    if (e.target.name === "rating") {
        setRating(e.target.value);
    }else if (e.target.name === "review") {
        setReview(e.target.value);
    }
 }

  const onSubmit = (data)  => {
    var userRating = 5;

    if(rating != ''){
        userRating =  rating;
    }

    try {

            setLoading(true);

            const formData = new FormData(); 
            formData.append("rating", userRating);
            formData.append("review", review);
            formData.append("user", loginUser);
            formData.append("item", props.item);
            formData.append("type", props.type);
        
            axios({
                method: 'post',
                url: process.env.REACT_APP_API_URL+"add-review",
                data: formData, 
            }).then(function(res) {
              
                if(res.status === 200){
                    setLoading(false);
                    if(res.data.status == 1){
                        toast.success(res.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }else if(res.data.status == 0){
                        toast.error(res.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                    
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                    //toast("dasdasd");
                    
                    reset();

                }else if(res.data.data.status === 0){
                    setLoading(false);
                    set404Message(res.data.data);
                    toast.success(res.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
                
            }).catch(function (error){
                setLoading(false);
                setMessage(error.response.data.data);
                toast.success(error.response.data.data, {
                    position: toast.POSITION.TOP_CENTER
                });
            });
        } catch (error) {
            setLoading(false);
            //console.error('error');
        }
        //console.log(data);
    };

  return (
    <>
    {
        loading === true ? (<Loader />) : ''
    }
    <form className="full" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
            
        <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="newsReplyForm_fild">
                <fieldset>
                    <span className="star-cb-group">
                        <input type="radio" id="rating-5" name="rating" value="5" defaultChecked="checked" onChange={ e => { handleFields(e) }} /><label htmlFor="rating-5">5</label>
                        <input type="radio" id="rating-4" name="rating" value="4" onChange={ e => { handleFields(e) }} /><label htmlFor="rating-4">4</label>
                        <input type="radio" id="rating-3" name="rating" value="3" onChange={ e => { handleFields(e) }} /><label htmlFor="rating-3">3</label>
                        <input type="radio" id="rating-2" name="rating" value="2" onChange={ e => { handleFields(e) }} /><label htmlFor="rating-2">2</label>
                        <input type="radio" id="rating-1" name="rating" value="1" onChange={ e => { handleFields(e) }} /><label htmlFor="rating-1">1</label>
                        <input type="radio" id="rating-0" name="rating" value="0" className="star-cb-clear" /><label htmlFor="rating-0">0</label>
                    </span>
                </fieldset>

                {/* <select name='rating' className='form-select' {...register("rating", { required: true })} onChange={ e => { handleFields(e) }}>
                    <option value='' defaultValue>Select Rating</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                    <option value="4">Four</option>
                    <option value="5">Five</option>
                </select> */}
                {errors?.rating?.type === "required" && <p className='errors'>{ t('lang.validation.please_select_rating') }.</p>}
            </div>
        </div>
        <h3>{ t('lang.common.leave_a_review') }</h3>
        <div className="col-md-12 col-sm-12 col-xs-6">
            <div className="newsReplyForm_fild full">
                <textarea type="text" name="review" placeholder={ t('lang.validation.message') } className="form-control text_area" {...register("review", { required: true })} onChange={ e => { handleFields(e) }} defaultValue={""} />
                {errors?.review?.type === "required" && <p className='errors'>{ t('lang.validation.please_enter_review') }.</p>}
            </div>
        </div>
        <div className="col-md-12 col-sm-12 col-xs-6">
            <div className="newsReplyForm_fild text-right full">
                <button type="submit" className="all_btn radius2 btn btn-primary mt-2 mb-2">{ t('lang.validation.submit') }</button>
            </div>
        </div>
        </div>
    </form>
    
    </>
  )
}

export default ReviewForm