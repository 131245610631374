import React, { useEffect, useState } from 'react'

import Header from '../includes/Header'
import Footer from '../includes/Footer'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const PayLater = () => {

  const [totalItem, setTotalItem]   = useState(0);
  const [t, i18n] = useTranslation('common');

  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('language'));
    var products = localStorage.getItem('products');
    if(products !== '' && products !==null){
        setTotalItem(JSON.parse(localStorage.getItem('products')).length);
    }
  },[]);

  return (
    <>
    <Header itemCount={totalItem} />
    <section className=" thankYouSection">
            <div className="container">
                <div className="text-center text-style-design thank-you">
                    <span className="text-icon"><FontAwesomeIcon icon={faCircleCheck} /></span>
                    <h1>{t('lang.common.thankYou')}</h1>
                    <p><strong></strong></p>
                    <Link to="/" className='continueBtn'>{t('lang.common.backMessage')}</Link> 
            </div>
        </div>
    </section>
    <Footer />
    </>
  )
}

export default PayLater