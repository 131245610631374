import React, { useState, useEffect,useMemo } from 'react'
import Header from '../includes/Header'
import Footer from '../includes/Footer'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import axios from 'axios';
import moment from 'moment';
import parse from 'html-react-parser';
import { Link, useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Loader } from './Loader';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {useTranslation} from "react-i18next";
import i18next from "i18next";

function BookingLesson() {
    const [studentSelectDates,setStudentSelectDates] = useState([]);
    const params = useParams();
    const [totalItem, setTotalItem]                     = useState(0);
    const [tutor, setTutor]                             = useState([]);
    const [user, setUser]                               = useState([]);
    const [loading, setLoading]                         = useState(false);
    const [teacherRate, setTeacherRate]                 = useState([]);
    const [teacherLanguage, setTeacherLanguage]         = useState([]);
    const [teacherInstrument, setTeacherInstrument]     = useState([]);
    const [teacherGenre, setTeacherGenre]               = useState([]);
    const [lessonFees, setLessonFees]                   = useState(80);
    const [lessonTime, setLessonTime]                   = useState(1);
    const [teacherAvailability, setTeacherAvailability] = useState([]);
    const [calendarColor, setCalendarColor]             = useState('');
    const [totalDateSelect, setTotalDateSelect]         = useState(0);

    const [availabilityDate, setAvailabilityDate] = useState('');

    const [bookingDetails, setBookingDetails] = useState({data:""})

    const genreSelect      = localStorage.getItem('genreSelect');
    const instrumentSelect = localStorage.getItem('instrumentSelect');
    const lessonSelected   = localStorage.getItem('lessonSelected');
    const [t, i18n] = useTranslation('common');

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        setTotalDateSelect(0);
        setStudentSelectDates([]);

    }
    const handleShow = () => setShow(true);
    const [errorMsg, setMessage]                     = useState([]);

    const [selectedEventDate, setSelectedEventDates] = useState([]);
    const [selectedEventTime, setSelectedEventTime]  = useState([]);
    let loginUser                                    = localStorage.getItem('loginUser');
    const [currencySymbol, setCurrencySymbol]        = useState('$');
    var currency                                     = localStorage.getItem('currency');
    var currencyRate                                 = localStorage.getItem('currencyRate');

    const [showCal, setShowCal] = useState(false);

    const handleCloseCal = () => setShowCal(false);
    const handleShowCal = () => setShowCal(true);
    let navigate = useNavigate();
    let timezones = localStorage.getItem('timezones');
    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('language'));
        window.scrollTo(0,0);
        setLoading(true);
        var products = localStorage.getItem('products');
        if(products !== '' && products !==null){
            setTotalItem(JSON.parse(localStorage.getItem('products')).length);
        }

        if(currency == 2){
            setCurrencySymbol('£');
        }

        try {
            axios.get(process.env.REACT_APP_API_URL+`tutor/${params.slug}`+'?timezones='+timezones)
              .then(async (res) => {
                if(res.status === 200){
                    setTutor(res.data.data.tutors);
                    setUser(res.data.data.tutors.user);
                    setTeacherRate(res.data.data.tutors.teacher_rate);

                    //console.log("res.data.data.genre =",res.data.data.genre);

                    if(res.data.data.teacherInstrument !=''){
                        res.data.data.teacherInstrument.map((val,inxd) => {
                            if(val.id == instrumentSelect){
                                setTeacherInstrument(val.instruments.name);
                            }
                        })
                    }

                    if(res.data.data.genre !=''){
                        res.data.data.genre.map((val,inxd) => {
                            if(val.id == genreSelect){
                                setTeacherGenre(val.genre.name);
                            }
                        })
                    }

                    if(res.data.data.availability !=''){
                        setTeacherAvailability(res.data.data.availability);
                        //console.log("res.data.data.availability =", res.data.data.availability);
                    }

                    setLoading(false);

                    if(lessonSelected == 1){

                        if(res.data.data.tutors.teacher_currency == currency){
                            setLessonFees(res.data.data.tutors.teacher_rate.lesson_one);
                        }else{
                            let price = Number(res.data.data.tutors.teacher_rate.lesson_one)* Number(currencyRate);
                            setLessonFees(Math.round(price));
                        }

                        /* if(currency == 2){
                            let price = Number(res.data.data.tutors.teacher_rate.lesson_one) * Number(currencyRate);
                            setLessonFees(Math.round(price));
                        }else{
                            setLessonFees(res.data.data.tutors.teacher_rate.lesson_one);
                        } */

                    }else if(lessonSelected == 4){

                        if(res.data.data.tutors.teacher_currency == currency){
                            setLessonFees(res.data.data.tutors.teacher_rate.lesson_four);
                        }else{
                            let price = Number(res.data.data.tutors.teacher_rate.lesson_four)* Number(currencyRate);
                            setLessonFees(Math.round(price));
                        }

                        /* if(currency == 2){
                            let price1 = Number(res.data.data.tutors.teacher_rate.lesson_four) * Number(currencyRate);
                            setLessonFees(Math.round(price1));
                        }else{
                            setLessonFees(res.data.data.tutors.teacher_rate.lesson_four);
                        } */
                        //setLessonFees(res.data.data.tutors.teacher_rate.lesson_four);
                    }else{
                        if(res.data.data.tutors.teacher_currency == currency){
                            setLessonFees(res.data.data.tutors.teacher_rate.lesson_eight);
                        }else{
                            let price = Number(res.data.data.tutors.teacher_rate.lesson_eight)* Number(currencyRate);
                            setLessonFees(Math.round(price));
                        }

                        /* if(currency == 2){
                            let price2 = Number(res.data.data.tutors.teacher_rate.lesson_eight) * Number(currencyRate);
                            setLessonFees(Math.round(price2));
                        }else{
                            setLessonFees(res.data.data.tutors.teacher_rate.lesson_eight);
                        } */
                       // setLessonFees(res.data.data.tutors.teacher_rate.lesson_eight);
                    }


                }else{
                  return console.log("Error !");
                }

              }).catch(error =>
                console.log("Error !")
              );
        } catch (error) {
            //console.error('error');
        }

    }, [params.slug]);

    /* const handelNew = (info) => {
        console.log("info =", info);
    } */

    const  handleDateClick = (arg) => { // bind with an arrow function
        //alert(arg.event.title);
        //https://momentjs.com/docs/

        let date = arg.event.start;
        date = new Date(date).toISOString();
        let newDate2 = moment.utc(date).add(1, 'days').format("dddd, MMM D, YYYY");

        //console.log("arg.event", arg.event.backgroundColor);

        //console.log('converted date', newDate2);
        //handleShowCal();
        setSelectedEventDates(newDate2);
        setSelectedEventTime(arg.event.title);
        setAvailabilityDate(arg.event.id);

        let totalDates = totalDateSelect +1;

        if(arg.event.backgroundColor != 'green'){

            setTotalDateSelect(totalDates);

            if(totalDates == lessonSelected){
                arg.event.setProp("backgroundColor","green");
                handleShowCal();
                const addEvent ={
                    'date': newDate2,
                    'time' : arg.event.title,
                    'availabilityDays' : arg.event.id
                }
                setStudentSelectDates([...studentSelectDates,addEvent]);
                handleShowCal();
            }else if(totalDateSelect >= lessonSelected){

                alert(`${t('lang.common.pleaseSelectOnly')} ${lessonSelected}`);

                arg.event.setProp("backgroundColor", "#3788d8");

                const updateEvents = studentSelectDates.filter((val)=>val.availabilityDays!==arg.event.id);
                setStudentSelectDates(updateEvents);

                totalDates = totalDates -1;
                setTotalDateSelect(totalDates);

                handleShowCal();
            }else{

                arg.event.setProp("backgroundColor","green");
                const addEvent ={
                    'date': newDate2,
                    'time' : arg.event.title,
                    'availabilityDays' : arg.event.id
                }
                setStudentSelectDates([...studentSelectDates,addEvent]);
            }

        }else{

            arg.event.setProp("backgroundColor", "#3788d8");

            const updateEvents = studentSelectDates.filter((val)=>val.availabilityDays!==arg.event.id);
            setStudentSelectDates(updateEvents);

            totalDates = totalDateSelect -1;
            //console.log("totalDates -1", totalDateSelect -1);
            //console.log("totalDateSelect", totalDateSelect);
            setTotalDateSelect(totalDates);
        }


    }
    useMemo(()=>{
        //console.log('aaa',studentSelectDates);
        //console.log("totalDateSelect", totalDateSelect);
    },[studentSelectDates,totalDateSelect]);
    const gotoBookingCheckout =() => {
        setShowCal(false);
        navigate("/booking-checkout", {
            state:{
                tutor             : tutor.user_id,
                lesson            : lessonSelected,
                teacherGenre      : teacherGenre,
                teacherInstrument : teacherInstrument,
                lessonFees        : lessonFees,
                teacherPhoto      : user.photo,
                teacherName       : tutor.first_name+' '+tutor.last_name,
                selectedEventDate : selectedEventDate,
                lessonTiming      : selectedEventTime,
                availabilityDate  : availabilityDate,
                studentSelectDates:studentSelectDates
            }
        });
    }

    const schduleLater = () => {

        try {

            setLoading(true);

            const formData = new FormData();
            formData.append("tutor", tutor.user_id);
            formData.append("loginUser", loginUser);
            formData.append("lessonDate", selectedEventDate);
            formData.append("teacherInstrument", teacherInstrument);
            formData.append("description", `${lessonSelected} lessons ${currencySymbol}${teacherGenre} / ${teacherInstrument} a month with ${tutor.first_name+' '+tutor.last_name}`);

            //console.log(Object.fromEntries(formData));

            axios({
                method: 'post',
                url: process.env.REACT_APP_API_URL+"booking-live-class-later",
                data: formData,
            }).then(function(res) {
                if (res.status === 200) {
                    setLoading(true);
                    //localStorage.removeItem("products");
                    navigate('/pay-later');
                } else if (res.status === 404) {
                    setLoading(false);
                    setMessage(res.data.message);
                }

            }).catch(function (error){
                setLoading(false);
                //setMessage(error.response.data.data);
            });
        } catch (error) {
            setLoading(false);
            //console.error('error');
        }


    }

    //console.log("teacherGenre =", teacherInstrument);

  return (
    <>
    {
        loading === true ? (<Loader />) : ''
    }
    <Header itemCount={totalItem} />
        <section className="leson-check-sec">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                        <div className="lson-checkout-list">
                            <div className="lson-checkout-wrap d-flex">
                                <div className="lson-checkout-lft">
                                    <div className="lson-checkout-img">
                                    {
                                        user.photo != null  ? <img src={process.env.REACT_APP_UPLOAD_URL+`teacher/photos/${user.photo}`} alt={user.name} /> : <img src="../../images/avatar.jpg" alt={user.name} />
                                    }
                                    </div>
                                </div>
                                <div className="lson-checkout-rgt">
                                    <div className="lson-checkout-text">
                                        <h4>{lessonSelected} {t('lang.common.lessons')} {teacherGenre}/ {teacherInstrument} {t('lang.common.aMonthWith')} {tutor.first_name} {tutor.last_name}</h4>
                                        <p>{lessonSelected} {t('lang.common.lessonsMonth')}</p>
                                        <p><span>{currencySymbol}{lessonFees}</span></p>
                                    </div>
                                </div>
                            </div>

                            <Modal className='lbModal' show={showCal} onHide={handleCloseCal} fullscreen="sm-down" backdrop="static" keyboard={false}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{t('lang.common.bookLessonWith')}<span>{tutor.first_name} {tutor.last_name}</span></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <h5>{t('lang.common.lesson')}: <span>{lessonSelected} {t('lang.common.lessons')} {teacherGenre}/ {teacherInstrument}</span></h5>
                                    <div className="row g-3">
                                        { studentSelectDates.length > 0 ?
                                            studentSelectDates.map((val,inx) => {
                                                return(parse(`
                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                                        <div className="book-lesson-box">
                                                            <h6>${t('lang.common.date')}:</h6>
                                                            <p>${val.date}</p>
                                                            <h6>${t('lang.common.timing')}:</h6>
                                                            <p>${val.time}</p>
                                                        </div>
                                                    </div>
                                            `))
                                            })
                                        : '' }
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseCal}>
                                    {t('lang.common.close')}
                                </Button>
                                <Button variant="primary" onClick={gotoBookingCheckout}>
                                    {t('lang.common.checkout')}
                                </Button>
                                </Modal.Footer>
                            </Modal>


                            <Modal show={show} size="lg" onHide={handleClose} fullscreen="xxl-down" backdrop="static" keyboard={false}>
                                <Modal.Header closeButton>
                                <Modal.Title>{t('lang.common.lessonSchdule')}({t('lang.common.select')} {lessonSelected} {t('lang.common.lessons')})</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                                <FullCalendar
                                    plugins={[ dayGridPlugin, interactionPlugin ]}
                                    initialView="dayGridMonth"
                                    //weekends={false}
                                    events={teacherAvailability}
                                    eventClick={handleDateClick}
                                    //eventColor={calendarColor}
                                    //dateClick={handelNew}
                                    //selectable={true}
                                />
                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    {t('lang.common.close')}
                                </Button>
                                {/* <Button variant="primary" onClick={handleClose}>
                                    Save Changes
                                </Button> */}
                                </Modal.Footer>
                            </Modal>

                            <div className="lson-checkout-btn-wrap d-flex justify-content-end">
                                <ul className="d-flex">
                                    <li><Button variant="primary" className='schduleLesson' onClick={handleShow}>{t('lang.common.schdule')}</Button></li>
                                    <li><Button className="schduleLesson" variant="primary" onClick={schduleLater}>{t('lang.common.schduleLater')}</Button></li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className="leson-checkout mt-4 text-end">
                            <Link to="#" className="bookLesson">Checkout</Link>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
        {/* <div className="modal fade book-lson-modal" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">Lesson Schdule</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><FontAwesomeIcon icon={faTimes} /></button>
                </div>
                <div className="modal-body">
                        <FullCalendar
                            plugins={[ dayGridPlugin, interactionPlugin ]}
                            initialView="dayGridMonth"
                            //weekends={false}
                            events={[
                                { title: 'event 1', date: '2022-09-10' },
                                { title: 'event 2', date: '2022-09-12' }
                            ]}
                            dateClick={handleDateClick}
                        />
                </div>
                </div>
            </div>
        </div> */}
        <Footer />
    </>
  )
}

export default BookingLesson