import React, { useEffect, useState } from 'react'

import Header from '../includes/Header'
import Footer from '../includes/Footer'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faEdit, faSearch } from '@fortawesome/free-solid-svg-icons'
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const Rejected = () => {
  const [totalItem, setTotalItem]   = useState(0);
  const [t, i18n] = useTranslation('common');
  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('language'));
    var products = localStorage.getItem('products');
    if(products !== '' && products !==null){
        setTotalItem(JSON.parse(localStorage.getItem('products')).length);
    }
  },[]);
  return (
    <>
    <Header itemCount={totalItem} />
    <section className=" thankYouSection">
            <div className="container">
                <div className="text-center text-style-design rejeted">
                    <span className="text-icon"><FontAwesomeIcon icon={faTimesCircle} /></span>
                    <h1>Payment Rejected</h1>
                    <p><strong>Your payment rejected.</strong></p>
                       <Link to="/" className='continueBtn'>Continue to homepage</Link> 
            </div>
        </div>
    </section>
    <Footer />
    </>
    
  )
}

export default Rejected