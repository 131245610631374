import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Select from 'react-select';
import Header from '../includes/Header'
import Footer from '../includes/Footer'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'awesome-bootstrap-checkbox/demo/build.css';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
/* import { parse } from '@fortawesome/fontawesome-svg-core'; */
import parse from 'html-react-parser';
import { Loader } from './Loader';
import {useTranslation} from "react-i18next";
import i18next from "i18next";


const Checkout = () => {

    const storeProduct = localStorage.getItem('products');
    var currency       = localStorage.getItem('currency');
    var currencyRate   = localStorage.getItem('currencyRate');
    const [t, i18n]    = useTranslation('common');
    let loginUser      = localStorage.getItem('loginUser');

    let countries = [];
    let states = [];
    let cities = [];

    let shippingStates = [];
    let shippingCities = [];

    //Paypal Payment
    const [show, setShow] = useState(false);
    const [success, setSuccess] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState("");
    const [orderID, setOrderID] = useState(false);
    //End Paypal Payment

    const [country, setCountry] = useState([]);
    const [state, setStates] = useState([]);
    const [city, setCity] = useState([]);
    const [shippingState, setShippingStates] = useState([]);
    const [shippingCity, setShippingCity] = useState([]);
    const [shippingInfo, setShippingInfo] = useState(0);
    const [currencySymbol, setCurrencySymbol] = useState('$');

    const [currencyCode, setCurrencyCode] = useState(localStorage.getItem('currencyCode'));
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastname] = useState("");
    const [tutorPhone, setTutorphone] = useState("");
    const [tutorEmail, setTutorEmail] = useState("");
    const [tutorPassword, setTutorPassword] = useState("");
    const [formCountry, setFormCountry] = useState("");
    const [formState, setFormState] = useState("");
    const [errorMsg, setMessage] = useState([]);
    const [successMsg, setSuccessMsg] = useState([]);
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [afterPaymentProduct, setAfterPaymentProduct] = useState([]);
    const [orderProducts, setOrderProducts] = useState([]);
    const [totalItem, setTotalItem] = useState(0);
    const [totalOrder, setTotalOrder] = useState(1);
    const [userDtl,setUserDtl] = useState({});

    let navigate = useNavigate();


    const { register, formState: { errors }, handleSubmit,reset } = useForm();

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('language'));
        const prodString = JSON.stringify(JSON.parse(storeProduct));
        var products = localStorage.getItem('products');
        if(products !== '' && products !==null){
            setTotalItem(JSON.parse(localStorage.getItem('products')).length);
        }
        if(currency == 2){
            setCurrencySymbol('£');
            setCurrencyCode("GBP")
        }

        axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + "store/get-product?items=" + prodString +'&loginUser='+loginUser,
            // data: prodString,
        }).then(function (res) {
            if (res.status === 200) {
                // setStates(res.data.data);
                // setFormCountry(getStates);

                if(res.data.user != ''){
                    setUserDtl(res.data.user);
                }


                let orderProd = res.data.data.map((value, i) => {
                    var price = value.price;
                    var dPrice = value.discount;
                    if(value.type == "courses"){
                        if(value.currency == currency){
                            price = value.price;
                            dPrice = value.discount;
                        }else if(value.currency != currency){
                            price = value.price * Number(currencyRate);
                        }
                    }else{

                        if(currency == 2){
                            price = value.price * Number(currencyRate);
                        }else{
                            price = value.price;
                        }
                    }

                    let discountedPrice = price - (price * dPrice / 100);

                    discountedPrice = Math.round(discountedPrice);

                    if(value.price != 0) {
                        return {
                            reference_id: i,
                            description: value.name,
                            item_count: value.item_count,
                            item_id: value.id,
                            item_type: value.type,
                            amount: {
                                currency_code: currencyCode,
                                value: discountedPrice * value.item_count,
                            },
                        };
                    }


                });


                const afterPaymentProducts = res.data.data.map((value, i) => {
                    var price = value.price;
                    var dPrice = value.discount;
                    if(value.type == "courses"){
                        if(value.currency == currency){
                            price = value.price;
                            dPrice = value.discount;
                        }else if(value.currency != currency){
                            price = value.price * Number(currencyRate);
                        }
                    }else{

                        if(currency == 2){
                            price = value.price * Number(currencyRate);
                        }else{
                            price = value.price;
                        }
                    }

                    let discountedPrice = price - (price * dPrice / 100);

                    discountedPrice = Math.round(discountedPrice);

                    return {
                        reference_id: i,
                        description: value.name,
                        item_count: value.item_count,
                        item_id: value.id,
                        item_type: value.type,
                        amount: {
                            currency_code: currencyCode,
                            value: discountedPrice * value.item_count,
                        },
                    };

                });

                setAfterPaymentProduct(afterPaymentProducts);

                const filteredOrderProd = orderProd.filter(val=>val!==undefined)

                setOrderProducts(filteredOrderProd);

                let sub_total_new = 0;
                res.data.data.map((val, i) => {
                    var price = val.price;
                    var dPrice = val.discount;

                    if(val.type == "courses"){
                        if(val.currency == currency){
                            price = val.price;
                            dPrice = val.discount;
                        }else if(val.currency != currency){
                            price = val.price * Number(currencyRate);
                        }
                    }else{

                        if(currency == 2){
                            price = val.price * Number(currencyRate);
                        }else{
                            price = val.price;
                        }
                    }

                    let discountedPrice = price - (price * dPrice / 100);

                    let total = discountedPrice * val.item_count;
                    sub_total_new += total;

                })

                setProducts(res.data.data);

                if(sub_total_new == 0){
                    setTotalOrder(0);
                }

            } else {
                return console.log("Error !");
            }
            //console.log('res', res);

        }).catch(function (error) {
            console.log(error);
        });
        //console.log('prodString', prodString);

    }, [storeProduct]);

    useEffect(() => {
		setDefaultValueForm();
	}, [userDtl]);

	const setDefaultValueForm = async () => {
		let defaultValues = {};

		defaultValues.email = userDtl?.email;
		defaultValues.firstName = userDtl?.first_name;
		defaultValues.lastName = userDtl?.last_name;

		await reset({ ...defaultValues });
	};

    const toggleClass = () => {
        var element = document.getElementById("shipping_info");
        element.classList.toggle("active");

        if (element.classList.contains('active')) {
            setShippingInfo(1);
        } else {
            setShippingInfo(0);
        }
    }

    if (country.length <= 0) {
        try {
            axios.get(process.env.REACT_APP_API_URL + "country")
                .then(async (res) => {
                    if (res.status === 200) {
                        setCountry(res.data.data);
                    } else {
                        return console.log("Error !");
                    }

                }).catch(error =>
                    console.log("Error !")
                );
        } catch (error) {
            console.error('error');
        }
    }

    function getStates(val) {
        var getStates = '';

        setTimeout(() => {

            getStates = document.getElementsByName("country")[0].value;

            if (getStates !== '') {

                const stateData = { state: getStates }

                try {

                    axios({
                        method: 'post',
                        url: process.env.REACT_APP_API_URL + "states",
                        data: stateData,
                    }).then(function (res) {
                        if (res.status === 200) {
                            setStates(res.data.data);
                            setFormCountry(getStates);
                        } else {
                            return console.log("Error !");
                        }

                    }).catch(function (error) {
                        console.log(error);
                    });
                } catch (error) {
                    console.error('error');
                }
            }

        }, 1000);

    }


    function getShippingStates(val) {
        var getStates = '';

        setTimeout(() => {

            getStates = document.getElementsByName("shippingCountry")[0].value;

            if (getStates !== '') {

                const stateData = { state: getStates }

                try {

                    axios({
                        method: 'post',
                        url: process.env.REACT_APP_API_URL + "states",
                        data: stateData,
                    }).then(function (res) {
                        if (res.status === 200) {
                            setShippingStates(res.data.data);
                            //setFormCountry(getStates);
                        } else {
                            return console.log("Error !");
                        }

                    }).catch(function (error) {
                        console.log(error);
                    });
                } catch (error) {
                    console.error('error');
                }
            }

        }, 1000);

    }


    function getCities() {
        var getCity = '';
        setTimeout(() => {

            getCity = document.getElementsByName("state")[0].value;

            if (getCity !== '') {

                const cityData = { city: getCity, }

                try {

                    axios({
                        method: 'post',
                        url: process.env.REACT_APP_API_URL + "city",
                        data: cityData,
                    }).then(function (res) {
                        if (res.status === 200) {
                            setCity(res.data.data);
                            setFormState(getCity);
                        } else {
                            return console.log("Error !");
                        }

                    }).catch(function (error) {
                        console.log(error);
                    });
                } catch (error) {
                    console.error('error');
                }
            }

        }, 1000);
    }

    function getShippingCities() {
        var getCity = '';
        setTimeout(() => {

            getCity = document.getElementsByName("shippingState")[0].value;

            if (getCity !== '') {

                const cityData = { city: getCity, }

                try {

                    axios({
                        method: 'post',
                        url: process.env.REACT_APP_API_URL + "city",
                        data: cityData,
                    }).then(function (res) {
                        if (res.status === 200) {
                            setShippingCity(res.data.data);
                            //setFormState(getCity);
                        } else {
                            return console.log("Error !");
                        }

                    }).catch(function (error) {
                        console.log(error);
                    });
                } catch (error) {
                    console.error('error');
                }
            }

        }, 1000);
    }

    if (country.length > 0) {
        country.map((v, i) => {
            countries.push({ value: v.id, label: v.name, });
        })
    }

    if (state.length > 0) {
        state.map((v, i) => {
            states.push({ value: v.id, label: v.name, });
        })
    }

    if (city.length > 0) {
        city.map((v, i) => {
            cities.push({ value: v.id, label: v.city_name, });
        })
    }

    if (shippingState.length > 0) {
        shippingState.map((v, i) => {
            shippingStates.push({ value: v.id, label: v.name, });
        })
    }

    if (shippingCity.length > 0) {
        shippingCity.map((v, i) => {
            shippingCities.push({ value: v.id, label: v.city_name, });
        })
    }

    const handleFields = async (e) => {

        /* if (e.target.name === "firstName") {
            setFirstName(e.target.value);
        } else if (e.target.name === "lastName") {
            setLastname(e.target.value);
        } */
    }
    //Create Order
    const createOrder = (data, actions) => {
        return actions.order
            .create({
                purchase_units: orderProducts,
                // not needed if a shipping address is actually needed
                application_context: {
                    shipping_preference: "NO_SHIPPING",
                },
            })
            .then((orderID) => {
                setOrderID(orderID);
                return orderID;
            });
    };


    // check Approval
    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            const { payer } = details;
            //console.log("details", details);
            setSuccess("");
            setLoading(true);
            if (details.status == 'COMPLETED') {

                const formData = new FormData();
                formData.append("firstName", document.getElementsByName("firstName")[0].value);
                formData.append("lastName", document.getElementsByName("lastName")[0].value);
                formData.append("companyName", document.getElementsByName("companyName")[0].value);
                formData.append("country", document.getElementsByName("country")[0].value);
                formData.append("address", document.getElementsByName("address")[0].value);
                formData.append("apartment", document.getElementsByName("apartment")[0].value);
                formData.append("state", document.getElementsByName("state")[0].value);
                formData.append("formCity", document.getElementsByName("formCity")[0].value);
                formData.append("notes", document.getElementsByName("notes")[0].value);
                formData.append("zip", document.getElementsByName("zip")[0].value);
                formData.append("phone", document.getElementsByName("phone")[0].value);
                formData.append("email", document.getElementsByName("email")[0].value);


                // If the checkbox is checked, display the output text
                if (document.getElementById("cb-2").checked == true){
                    formData.append("shipDiffAddress", document.getElementsByName("shipDiffAddress")[0].value);
                    formData.append("shippingFirstName", document.getElementsByName("shippingFirstName")[0].value);
                    formData.append("shippingLastName", document.getElementsByName("shippingLastName")[0].value);
                    formData.append("shippingCompanyName", document.getElementsByName("shippingCompanyName")[0].value);
                    formData.append("shippingCountry", document.getElementsByName("shippingCountry")[0].value);
                    formData.append("shippingAddress", document.getElementsByName("shippingAddress")[0].value);
                    formData.append("shippingApartment", document.getElementsByName("shippingApartment")[0].value);
                    formData.append("shippingState", document.getElementsByName("shippingState")[0].value);
                    formData.append("shippingCity", document.getElementsByName("shippingCity")[0].value);
                    formData.append("shippingZip", document.getElementsByName("shippingZip")[0].value);

                }

                formData.append("orderProducts", JSON.stringify(afterPaymentProduct));
                formData.append("orderID", details.id);
                formData.append("paymentTime", details.create_time);
                formData.append("paymentLink", details.links[0].href);
                formData.append("currencyCode", currencyCode);


                axios({
                    method: 'post',
                    url: process.env.REACT_APP_API_URL + "payment",
                    data: formData,
                }).then(function (res) {

                    if (res.status === 200) {
                        setLoading(true);
                        localStorage.removeItem("products");
                        navigate('/thankyou/'+res.data.orderID);
                    } else if (res.status === 404) {
                        setLoading(false);
                        setMessage(res.data.message);
                    }

                }).catch(function (error) {
                    setLoading(false);
                    setMessage(error.response.data.message);
                    //console.log(error.response.data.data);
                });
            }
        });
    };
    //capture likely error
    const onError = (data, actions) => {
        setErrorMessage("An Error occured with your payment ");
    };

    const onSubmit = (data) => {

        try {
            //setLoading(false);

            setShow(true);

            if(totalOrder == 0){
                const formData = new FormData();

                formData.append("firstName", document.getElementsByName("firstName")[0].value);
                formData.append("lastName", document.getElementsByName("lastName")[0].value);
                formData.append("companyName", document.getElementsByName("companyName")[0].value);
                formData.append("country", document.getElementsByName("country")[0].value);
                formData.append("address", document.getElementsByName("address")[0].value);
                formData.append("apartment", document.getElementsByName("apartment")[0].value);
                formData.append("state", document.getElementsByName("state")[0].value);
                formData.append("formCity", document.getElementsByName("formCity")[0].value);
                formData.append("notes", document.getElementsByName("notes")[0].value);
                formData.append("zip", document.getElementsByName("zip")[0].value);
                formData.append("phone", document.getElementsByName("phone")[0].value);
                formData.append("email", document.getElementsByName("email")[0].value);


                // If the checkbox is checked, display the output text
                if (document.getElementById("cb-2").checked == true){
                    formData.append("shipDiffAddress", document.getElementsByName("shipDiffAddress")[0].value);
                    formData.append("shippingFirstName", document.getElementsByName("shippingFirstName")[0].value);
                    formData.append("shippingLastName", document.getElementsByName("shippingLastName")[0].value);
                    formData.append("shippingCompanyName", document.getElementsByName("shippingCompanyName")[0].value);
                    formData.append("shippingCountry", document.getElementsByName("shippingCountry")[0].value);
                    formData.append("shippingAddress", document.getElementsByName("shippingAddress")[0].value);
                    formData.append("shippingApartment", document.getElementsByName("shippingApartment")[0].value);
                    formData.append("shippingState", document.getElementsByName("shippingState")[0].value);
                    formData.append("shippingCity", document.getElementsByName("shippingCity")[0].value);
                    formData.append("shippingZip", document.getElementsByName("shippingZip")[0].value);

                }

                formData.append("orderProducts", JSON.stringify(orderProducts));
                formData.append("currencyCode", currencyCode);

                setLoading(true);

                axios({
                    method: 'post',
                    url: process.env.REACT_APP_API_URL + "free-payment",
                    data: formData,
                }).then(function (res) {

                    if (res.status === 200) {

                        navigate('/thankyou/'+res.data.orderID);
                    } else if (res.status === 404) {
                        setLoading(false);
                        setMessage(res.data.data);
                    }

                }).catch(function (error) {
                    setLoading(false);
                    setMessage(error.response.data.data);
                    //console.log(error.response.data.data);
                });
            }


        } catch (error) {
            setLoading(false);
            //console.error('error');
        }
        //console.log(data);
    };

    let sub_total = 0;
    return (
        <>
        {
            loading === true ? (<Loader />) : ''
        }
            <Header itemCount={totalItem} />

            <section className='checkout'>
                <div className="container">
                    <div className="row">
                        {
                            storeProduct != null ?
                                <>
                                    {/* <div className="checkout-discount">
                                        <form id="checkout-discount-form">
                                            <input
                                                type="text"
                                                className="form-control"
                                                required=""
                                                id="checkout-discount-input"
                                                placeholder='Have a coupon? Click here to enter your code'
                                            />
                                        </form>
                                    </div> */}
                                    <PayPalScriptProvider
                                        options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID, currency:currencyCode }}>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="row">
                                                <div className="col-lg-8">
                                                    <h2 className="checkout-title">{t('lang.common.billingDetails')}</h2>
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <label>{t('lang.common.firstName')} *</label>
                                                                <input type="text" name="firstName" className="form-control" {...register("firstName", { required: true, maxLength: 30 })} onChange={e => { handleFields(e) }} />
                                                                {errors?.firstName?.type === "required" && <p className='errors'>{t('lang.validation.firstName')}</p>}
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <label>{t('lang.common.lastName')} *</label>
                                                                <input type="text" name="lastName" className="form-control" {...register("lastName", { required: true, maxLength: 30 })} onChange={e => { handleFields(e) }} />
                                                                {errors?.lastName?.type === "required" && <p className='errors'>{t('lang.validation.lastName')}</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>{t('lang.common.companyName')}</label>
                                                        <input type="text" className="form-control" name="companyName" onChange={e => { handleFields(e) }} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>{t('lang.common.country')} *</label>
                                                        <Select name="country" options={countries} onChange={getStates} />
                                                        {errors?.country?.type === "required" && <p className='errors'>{t('lang.validation.country')}</p>}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>{t('lang.common.streetAddress')} *</label>
                                                        <input type="text" className="form-control" placeholder="House number and Street name" name="address" {...register("address", { required: true })} onChange={e => { handleFields(e) }} />
                                                        {errors?.address?.type === "required" && <p className='errors'>{t('lang.validation.address')}</p>}
                                                    </div>
                                                    <div className="form-group">
                                                        <label>{t('lang.common.apartment')} *</label>
                                                        <input type="text" className="form-control" placeholder="Apartments, suite, unit etc ..." name="apartment" {...register("apartment", { required: true })} onChange={e => { handleFields(e) }} />
                                                        {errors?.apartment?.type === "required" && <p className='errors'>{t('lang.validation.apartment')}</p>}
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <label>{t('lang.common.state')} *</label>
                                                                <Select name="state" options={states} onChange={getCities} />
                                                                {errors?.state?.type === "required" && <p className='errors'>{t('lang.validation.state')}</p>}
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <label>{t('lang.common.townCity')} *</label>
                                                                <Select name="formCity" id="formCity" options={cities} onChange={e => { handleFields(e) }} />
                                                                {errors?.city?.type === "required" && <p className='errors'>{t('lang.validation.city')}</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <label>{t('lang.common.zip')} *</label>
                                                                <input type="text" className="form-control" name="zip" onChange={e => { handleFields(e) }} {...register("zip", { required: true })} />
                                                                {errors?.zip?.type === "required" && <p className='errors'>{t('lang.validation.zip')}</p>}
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <label>{t('lang.common.phone')} *</label>
                                                                <input type="tel" className="form-control" name="phone" onChange={e => { handleFields(e) }} {...register("phone", { required: true })} />
                                                                {errors?.phone?.type === "required" && <p className='errors'>{t('lang.validation.phone')}</p>}
                                                            </div>
                                                            <div className="col-sm-12">
                                                                <label>{t('lang.common.emailAddress')} *</label>
                                                                <input type="email" className="form-control" name="email" onChange={e => { handleFields(e) }} {...register("email", { required: true })} />
                                                                {errors?.email?.type === "required" && <p className='errors'>{t('lang.validation.email')}</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="form-group">
                                                        <div className="form-check abc-checkbox">
                                                            <input className="form-check-input" id="cb-1" type="checkbox" />
                                                            <label className="form-check-label" htmlFor="cb-1">Create an account?</label>
                                                        </div>
                                                    </div> */}

                                                    <div className="form-group">
                                                        <div className="form-check abc-checkbox">
                                                            <input className="form-check-input" id="cb-2" name="shipDiffAddress" value="1" type="checkbox" />
                                                            <label className="form-check-label" htmlFor="cb-2" onClick={toggleClass}>{t('lang.common.ship')}</label>
                                                        </div>
                                                        <div className="shipping-info" id="shipping_info">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>
                                                                            {t('lang.common.firstName')} <span className="required">*</span>
                                                                        </label>
                                                                        {
                                                                            shippingInfo == 1 ?
                                                                                <>
                                                                                    <input type="text" name="shippingFirstName" className="form-control" onChange={e => { handleFields(e) }} {...register("shippingFirstName", { required: true })} />
                                                                                    {errors?.shippingFirstName?.type === "required" && <p className='errors'>Shipping First Name is required</p>}</>
                                                                                : ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>
                                                                            {t('lang.common.lastName')} <span className="required">*</span>
                                                                        </label>
                                                                        {
                                                                            shippingInfo == 1 ?
                                                                                <>
                                                                                    <input type="text" name="shippingLastName" className="form-control" onChange={e => { handleFields(e) }} {...register("shippingLastName", { required: true })} />
                                                                                    {errors?.shippingLastName?.type === "required" && <p className='errors'>Shipping Last Name is required</p>}</>
                                                                                : ''
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label>{t('lang.common.companyName')}</label>
                                                                <input type="text" className="form-control" name="shippingCompanyName" onChange={e => { handleFields(e) }} />
                                                            </div>
                                                            <div className="select-custom">
                                                                <label>
                                                                    {t('lang.common.region')} <span className="required">*</span>
                                                                </label>
                                                                <Select name="shippingCountry" options={countries} onChange={getShippingStates} />
                                                                {errors?.shippingCountry?.type === "required" && <p className='errors'>{t('lang.validation.shippingCountry')}</p>}
                                                            </div>
                                                            <div className="form-group mb-1 pb-2">
                                                                <label>
                                                                    {t('lang.common.streetAddress')} <span className="required">*</span>
                                                                </label>
                                                                {
                                                                    shippingInfo == 1 ?
                                                                        <>
                                                                            <input type="text" name="shippingAddress" className="form-control" placeholder="House number and street name" onChange={e => { handleFields(e) }} {...register("shippingAddress", { required: true })} />
                                                                            {errors?.shippingAddress?.type === "required" && <p className='errors'>{t('lang.validation.shippingAddress')}</p>}</>
                                                                        : ''
                                                                }
                                                            </div>
                                                            <div className="form-group">
                                                                <input type="text" name="shippingApartment" className="form-control" placeholder="Apartment, suite, unit, etc. (optional)" onChange={e => { handleFields(e) }} />
                                                            </div>
                                                            <div className="select-custom">
                                                                <label>
                                                                    {t('lang.common.state')} <span className="required">*</span>
                                                                </label>
                                                                {
                                                                    shippingInfo == 1 ?
                                                                        <>
                                                                            <Select name="shippingState" options={shippingStates} onChange={getShippingCities} />
                                                                            {errors?.shippingState?.type === "required" && <p className='errors'>{t('lang.validation.state')}</p>}</>
                                                                        : ''
                                                                }

                                                            </div>
                                                            <div className="form-group">
                                                                <label>{t('lang.common.townCity')} <span className="required">*</span></label>
                                                                {
                                                                    shippingInfo == 1 ?
                                                                        <>
                                                                            <Select name="shippingCity" id="shippingCity" options={shippingCities} onChange={e => { handleFields(e) }} />
                                                                            {errors?.shippingCity?.type === "required" && <p className='errors'>{t('lang.validation.city')}</p>}</>
                                                                        : ''
                                                                }
                                                            </div>

                                                            <div className="form-group">
                                                                <label>
                                                                    {t('lang.common.zip')} <span className="required">*</span>
                                                                </label>
                                                                {
                                                                    shippingInfo == 1 ?
                                                                        <>
                                                                            <input type="text" name="shippingZip" className="form-control" onChange={e => { handleFields(e) }} {...register("shippingZip", { required: true })} />
                                                                            {errors?.shippingZip?.type === "required" && <p className='errors'>{t('lang.validation.shippingZip')}</p>}</>
                                                                        : ''
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>{t('lang.common.orderNotes')}</label>
                                                        <textarea className="form-control" name="notes" cols={30} rows={4} placeholder={t('lang.common.deliveryNotes')} defaultValue={""} onChange={e => { handleFields(e) }} />
                                                    </div>
                                                </div>
                                                <aside className="col-lg-4">
                                                    <div className="summary">
                                                        <h4 className="summary-title">{t('lang.common.yourOrder')}</h4>
                                                        <div className='tableSummaryArea'>
                                                            <table className="table table-summary">
                                                                <thead>
                                                                    <tr>
                                                                        <th className='totalRs'>{t('lang.common.product')}</th>
                                                                        <th className='totalRs text-end'>{t('lang.common.total')}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {

                                                                        products.map((val, i) => {

                                                                            let price = val.price;
                                                                            let dPrice = val.discount;

                                                                            if(val.type == "courses"){
                                                                                if(val.currency == currency){
                                                                                    price = val.price;
                                                                                    dPrice = val.discount;
                                                                                }else if(val.currency != currency){
                                                                                    price = val.price * Number(currencyRate);
                                                                                }
                                                                            }else{

                                                                                if(currency == 2){
                                                                                    price = val.price * Number(currencyRate);
                                                                                }else{
                                                                                    price = val.price;
                                                                                }
                                                                            }

                                                                            let discountedPrice = price - (price * dPrice / 100);

                                                                            let total = discountedPrice * val.item_count;
                                                                            sub_total += total;




                                                                            return (
                                                                                <tr key={i}>
                                                                                    <td>
                                                                                        <Link to={`${val.slug}`}>
                                                                                            {val.name} X ({val.item_count})
                                                                                        </Link>
                                                                                    </td>
                                                                                    <td align='right'>
                                                                                        { total != 0 ? currencySymbol+ '' + Math.round(total) : 'Free' }
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }

                                                                    {/* <tr>
                                                                        <td>
                                                                            <Link to='/'>
                                                                                Can 2-Seater Sofa
                                                                            </Link>
                                                                        </td>
                                                                        <td align='right'>$60.00</td>
                                                                    </tr> */}
                                                                    <tr className="summary-subtotal">
                                                                        <td>{t('lang.common.subtotal')}:</td>
                                                                        <td align='right'>

                                                                            { sub_total != 0 ? currencySymbol+ '' + Math.round(sub_total) : t('lang.common.free') }
                                                                            </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>{t('lang.common.shipping')}:</td>
                                                                        <td align='right'>{t('lang.common.freeShipping')}</td>
                                                                    </tr>
                                                                    <tr className="summary-total">
                                                                        <td className='totalRs'>{t('lang.common.total')}:</td>
                                                                        <td className='totalRs' align='right'>{currencySymbol}{Math.round(sub_total)}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div className="accordionSummary">
                                                        {
                                                            sub_total != 0 ? <>
                                                            {/* <h4>{t('lang.common.paymentMethod')}</h4> */}
                                                            {/* <div className="accordion" id="accordionExample">
                                                                <div className="accordion-item">
                                                                    <div className="accordion-header" id="headingOne">
                                                                        <button className="accordion-button  d-flex " type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                            Paypal
                                                                        </button>
                                                                    </div>
                                                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body">
                                                                            <p>{t('lang.common.paypalMessage')}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                        </> : ''

                                                        }
                                                    </div>
                                                    {success}
                                                    {ErrorMessage}
                                                    {sub_total != 0 && show ? (
                                                        <>
                                                        <h3>{t('lang.common.paymentMethods')}:</h3>
                                                        <PayPalButtons
                                                            style={{ layout: "vertical" }}
                                                            createOrder={createOrder}
                                                            onApprove={onApprove}
                                                        />
                                                        </>

                                                    ) : null}
                                                    {sub_total != 0 && !show ?
                                                    <>
                                                    <button type="submit" className="addCartBtn">
                                                        <span className="btn-text">{t('lang.common.placeOrder')}</span>
                                                    </button>
                                                    </>
                                                    : ''}
                                                </aside>
                                            </div>
                                        </form>
                                    </PayPalScriptProvider>
                                </>
                                : parse(`<h3>${t('lang.common.cartEmpty')}</h3>`)

                        }
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Checkout