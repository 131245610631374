import React, { useState, useEffect } from 'react'

import Header from '../includes/Header'
import Footer from '../includes/Footer'
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faThLarge, faThList, faCartShopping } from '@fortawesome/free-solid-svg-icons'
import { Loader } from './Loader';
import axios from 'axios';
import parse from 'html-react-parser';
import InfiniteScroll from "react-infinite-scroll-component";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const Teachers = () => {

    const [totalItem, setTotalItem] = useState(0);
    const [teachers, setTeachers]   = useState([]);
    const [currencySymbol, setCurrencySymbol] = useState('$');
    var currency                    = localStorage.getItem('currency');
    var currencyRate                = localStorage.getItem('currencyRate');
    const [t, i18n]                 = useTranslation('common');
    let languages                   = localStorage.getItem('language');

    useEffect(() => {

        var products = localStorage.getItem('products');
        if(products !== '' && products !==null){
            setTotalItem(JSON.parse(localStorage.getItem('products')).length);
        }

        if(currency == 2){
            setCurrencySymbol('£');
        }
        i18next.changeLanguage(localStorage.getItem('language'));

        try {
            axios.get(process.env.REACT_APP_API_URL+"tutor")
              .then(async (res) => {
                //console.log("res =", res.data);
                if(res.status === 200){
                    setTeachers(res.data.data.tutors);
                }else{
                  return console.log("Error !");
                }

              }).catch(error =>
                console.log("Error !")
              );
            } catch (error) {
                //console.error('error');
            }

    }, []);

  return (
    <>
    <Header itemCount={totalItem} />

    <section className='storeSection'>
                <div className='container-fluid ph-40'>
                    <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                            <div className='listGridTop row align-items-center justify-content-between'>
                                <div className='listGridTopLeft col-auto'>
                                    <h2>{t('lang.common.findYourTutor')}</h2>
                                </div>

                            </div>
                            <div className='storeBoxArea'>
                                <div className="row">

                                    {
                                        teachers.map((val,indx) => {

                                            var price = 0;
                                            var lesson_one = 0;

                                            if(val.teacher_rate != null){

                                                if(val.teacher_rate.lesson_currency == currency){

                                                    lesson_one = val.teacher_rate.lesson_one;
                                                }else if(val.teacher_rate.lesson_currency != currency){

                                                    lesson_one = val.teacher_rate.lesson_one * Number(currencyRate);
                                                }
                                            }


                                            return(<div className='col-lg-6 col-md-6 col-sm-12 mb-3 col-12'>
                                            <div className='storeBox d-flex flex-wrap w-100'>
                                                <div className='storeBoxLeft'>
                                                    <span className='relative'>
                                                        {
                                                            val.photo != null  ? <img src={process.env.REACT_APP_UPLOAD_URL+`teacher/photos/${val.photo}`} alt={val.name} /> : <img src="../images/avatar.jpg" alt={val.name} />
                                                        }
                                                    </span>
                                                </div>
                                                <div className='storeBoxRight d-flex'>
                                                    <div className='ibrInner'>
                                                        <h4><Link to={`/tutor/${val.teachers.teacher_slug}`} className="black">{val.teachers.first_name} {val.teachers.last_name}</Link></h4>
                                                        <p>{val.teachers.short_desc}</p>
                                                        <div className='d-flex align-items-center starArea w-100'>
                                                        <ul className='d-flex ms-0'>
                                                            <li>
                                                                {
                                                                    val.rating != '' &&
                                                                    (() => {
                                                                        let td = [];
                                                                        for (let i = 1; i <= val.rating; i++) {
                                                                        td.push(<FontAwesomeIcon icon={faStar} />);
                                                                        }
                                                                        return td;
                                                                    })()
                                                                }
                                                            </li>
                                                        </ul>
                                                        {
                                                            val.totalRating > 0 && <span className='rvs'>({val.totalRating})</span>
                                                        }
                                                        </div>
                                                        {
                                                            val.totalRating > 0 && <div className='brand'>
                                                            <h5>{t('lang.common.popular')}</h5>
                                                        </div>
                                                        }

                                                        <div className='sbPrice-2 d-flex w-100 justify-content-end'>
                                                            <ul className='text-center d-flex align-items-center'>
                                                                {
                                                                    val.teacher_rate != null ? <li className='newPrice'>{currencySymbol}{Math.round(lesson_one)} / 1 {t('lang.common.lessonsMonth')}</li>

                                                                     : ''
                                                                }

                                                                <li className='mx-2'><Link  to={`/tutor/${val.teachers.teacher_slug}`} className='addCartBtn'>{t('lang.common.view')}</Link></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)
                                        })
                                    }




                                    {/* <div className='col-lg-6 col-md-6 col-sm-12  mb-3 col-12'>
                                        <div className='storeBox d-flex flex-wrap w-100'>
                                            <div className='storeBoxLeft'>
                                                <span className='relative'><img src="../images/teacher-2.jpg" alt="" /></span>
                                            </div>
                                            <div className='storeBoxRight d-flex'>
                                                <div className='ibrInner'>
                                                    <h4><Link to="#" className="black">Fateen Kanaan</Link></h4>
                                                    <p>OUD & Oriental music teacher</p>
                                                    <div className='d-flex align-items-center starArea w-100'>
                                                        <span className='rtng'>4.5</span>
                                                        <ul className='d-flex'>
                                                            <li><FontAwesomeIcon icon={faStar} /></li>
                                                            <li><FontAwesomeIcon icon={faStar} /></li>
                                                            <li><FontAwesomeIcon icon={faStar} /></li>
                                                            <li><FontAwesomeIcon icon={faStar} /></li>
                                                            <li><FontAwesomeIcon icon={faStar} /></li>
                                                        </ul>
                                                        <span className='rvs'>(2,354)</span>
                                                    </div>
                                                    <div className='brand'>
                                                        <h5>POPULAR</h5>
                                                    </div>
                                                    <div className='sbPrice-2 d-flex w-100 justify-content-end'>
                                                        <ul className='text-center d-flex align-items-center'>
                                                            <li className='newPrice'>$30 / 30 minutes</li>
                                                            <li className='mx-2'><Link  to='/tutor/fateen-kanaan' className='addCartBtn'>View</Link></li>
                                                        </ul>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div> */}

                                    {/* <div className='col-lg-6 col-md-6 col-sm-12  mb-3 col-12'>
                                        <div className='storeBox d-flex flex-wrap w-100'>
                                            <div className='storeBoxLeft'>
                                                <span className='relative'><img src="../images/teacher-3.jpg" alt="" /></span>
                                            </div>
                                            <div className='storeBoxRight d-flex'>
                                                <div className='ibrInner'>
                                                    <h4><Link to="#" className="black">Dhruv Bedi</Link></h4>
                                                    <p>Sitar teacher and performing artist</p>
                                                    <div className='d-flex align-items-center starArea w-100'>
                                                        <span className='rtng'>4.5</span>
                                                        <ul className='d-flex'>
                                                            <li><FontAwesomeIcon icon={faStar} /></li>
                                                            <li><FontAwesomeIcon icon={faStar} /></li>
                                                            <li><FontAwesomeIcon icon={faStar} /></li>
                                                            <li><FontAwesomeIcon icon={faStar} /></li>
                                                            <li><FontAwesomeIcon icon={faStar} /></li>
                                                        </ul>
                                                        <span className='rvs'>(2,145)</span>
                                                    </div>
                                                    <div className='brand'>
                                                        <h5>POPULAR</h5>
                                                    </div>
                                                    <div className='sbPrice-2 d-flex w-100 justify-content-end'>
                                                        <ul className='text-center d-flex align-items-center'>
                                                            <li className='newPrice'>$60 / 30 minutes</li>
                                                            <li className='mx-2'><Link  to='/tutor/dhruv-bedi' className='addCartBtn'>View</Link></li>
                                                        </ul>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div> */}

                                    {/* <div className='col-lg-6 col-md-6 col-sm-12  mb-3 col-12'>
                                        <div className='storeBox d-flex flex-wrap w-100'>
                                            <div className='storeBoxLeft'>
                                                <span className='relative'><img src="../images/teacher-4.jpg" alt="" /></span>
                                            </div>
                                            <div className='storeBoxRight d-flex'>
                                                <div className='ibrInner'>
                                                    <h4><Link to="#" className="black">Barak</Link></h4>
                                                    <p>Saz and Baglama Online teacher</p>
                                                    <div className='d-flex align-items-center starArea w-100'>
                                                        <span className='rtng'>4.5</span>
                                                        <ul className='d-flex'>
                                                            <li><FontAwesomeIcon icon={faStar} /></li>
                                                            <li><FontAwesomeIcon icon={faStar} /></li>
                                                            <li><FontAwesomeIcon icon={faStar} /></li>
                                                            <li><FontAwesomeIcon icon={faStar} /></li>
                                                            <li><FontAwesomeIcon icon={faStar} /></li>
                                                        </ul>
                                                        <span className='rvs'>(2,354)</span>
                                                    </div>
                                                    <div className='brand'>
                                                        <h5>POPULAR</h5>
                                                    </div>
                                                    <div className='sbPrice-2 d-flex w-100 justify-content-end'>
                                                        <ul className='text-center d-flex align-items-center'>
                                                            <li className='newPrice'>$25 / 30 minutes</li>
                                                            <li className='mx-2'><Link  to='/tutor/barak' className='addCartBtn'>View</Link></li>
                                                        </ul>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>  */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
    <Footer />

    </>
  )
}

export default Teachers