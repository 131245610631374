import React, { Component } from 'react'
import Header from '../includes/Header'
import Footer from '../includes/Footer'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import {useTranslation} from "react-i18next";
import i18next from "i18next";

export default class ProdectList extends Component {
    render() {
        return (
            <>
                <Header />
                <section className='prodectListWrap'>
                    <div className='container'>
                        <div className='row g-2 g-md-3 g-lg-4'>
                            <aside className='prodectListLeft'>
                                <div className='prodectListLeftInner'>
                                    <div className="accordion" id="accordionPanelsStayOpenExample">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne" >
                                                    Sorted By
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne" >
                                                <div className="accordion-body">
                                                    <div className='accorBox'>
                                                        <ul>
                                                            <li><Link to='/'>Most Popular</Link></li>
                                                            <li><Link to='/'>Highest Rated</Link></li>
                                                            <li><Link to='/'>Newest</Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo" >
                                                Rating 
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingTwo" >
                                                <div className="accordion-body">
                                                    <div className='accorBox radioBox'>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="rb1" />
                                                            <label className="form-check-label" htmlFor="rb1"><FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> <span>4.5 & above</span></label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="rb2" defaultChecked="" />
                                                            <label className="form-check-label" htmlFor="rb2"><FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> <span>4 & above </span></label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="rb3" defaultChecked="" />
                                                            <label className="form-check-label" htmlFor="rb3"><FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> <span>3 & above</span></label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="rb4" defaultChecked="" />
                                                            <label className="form-check-label" htmlFor="rb4"> <FontAwesomeIcon icon={faStar} /> <FontAwesomeIcon icon={faStar} /> <span>2 & above</span></label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="rb5" defaultChecked="" />
                                                            <label className="form-check-label" htmlFor="rb5"><FontAwesomeIcon icon={faStar} /> <span>2 & above</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree" >
                                                Catagory
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingThree" >
                                                <div className="accordion-body">
                                                    <div className='accorBox radioBox'>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="radio-2" id="rb6" />
                                                            <label className="form-check-label" htmlFor="rb6">Lorem Ipsum</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="radio-2" id="rb7" defaultChecked="" />
                                                            <label className="form-check-label" htmlFor="rb7">Lorem Ipsum</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="radio-2" id="rb8" defaultChecked="" />
                                                            <label className="form-check-label" htmlFor="rb8">Lorem Ipsum</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="radio-2" id="rb9" defaultChecked="" />
                                                            <label className="form-check-label" htmlFor="rb9">Lorem Ipsum</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="radio-2" id="rb10" defaultChecked="" />
                                                            <label className="form-check-label" htmlFor="rb10">Lorem Ipsum</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingFour">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour" >
                                                Price
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseFour" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingFour" >
                                                <div className="accordion-body">
                                                    <div className='accorBox radioBox'>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="radio-3" id="rb11" />
                                                            <label className="form-check-label" htmlFor="rb11">Lorem Ipsum</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="radio-3" id="rb12" defaultChecked="" />
                                                            <label className="form-check-label" htmlFor="rb12">Lorem Ipsum</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="panelsStayOpen-headingFive">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive" >
                                                Brand
                                                </button>
                                            </h2>
                                            <div id="panelsStayOpen-collapseFive" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFive" >
                                                <div className="accordion-body">
                                                <div className='accorBox radioBox'>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="radio-4" id="rb13" defaultChecked="" />
                                                            <label className="form-check-label" htmlFor="rb13">Lorem Ipsum</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="radio-4" id="rb14" defaultChecked="" />
                                                            <label className="form-check-label" htmlFor="rb14">Lorem Ipsum</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="radio-4" id="rb15" defaultChecked="" />
                                                            <label className="form-check-label" htmlFor="rb15">Lorem Ipsum</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="radio-4" id="rb16" defaultChecked="" />
                                                            <label className="form-check-label" htmlFor="rb16">Lorem Ipsum</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </aside>
                            <div className='col'>
                                <div className='listBoxArea'>
                                    <div className='listBox d-flex flex-wrap w-100'>
                                        <div className='listBoxLeft'>
                                            <span className='relative'><img src="../images/product-list/1.jpg" alt="" /><span className='bestSeller'>Best Seller</span></span>
                                        </div>
                                        <div className='listBoxRight'>
                                            <div className='ibrInner'>
                                                <h5>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut facilisis.</h5>
                                                <div className='d-flex align-items-center starArea w-100'>
                                                    <span className='rtng'>4.5</span>
                                                    <ul className='d-flex'>
                                                        <li><FontAwesomeIcon icon={faStar} /></li>
                                                        <li><FontAwesomeIcon icon={faStar} /></li>
                                                        <li><FontAwesomeIcon icon={faStar} /></li>
                                                        <li><FontAwesomeIcon icon={faStar} /></li>
                                                        <li><FontAwesomeIcon icon={faStar} /></li>
                                                    </ul>
                                                    <span className='rvs'>(2,354)</span>
                                                </div>
                                                <div className='limitedTime'>
                                                    <p>Limited time to deal</p>
                                                </div>
                                                <div className='prOffer w-100'>
                                                    <span className="currentPrice">$3,499</span> <span className='line-through'>$3,800</span> <span className="percentOff">30% off</span>
                                                </div>
                                                <div className='prOffer w-100'>
                                                    <ul className='d-flex'>
                                                        <li className='saveColor'>Save $400</li>
                                                        <li>with coupon</li>
                                                    </ul>
                                                </div>
                                                <div className='freeDelivery w-100'>
                                                    <p>FREE Delivery</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='listBox d-flex flex-wrap w-100'>
                                        <div className='listBoxLeft'>
                                            <span className='relative'><img src="../images/product-list/2.jpg" alt="" /><span className='bestSeller'>Best Seller</span></span>
                                        </div>
                                        <div className='listBoxRight'>
                                            <div className='ibrInner'>
                                                <h5>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut facilisis.</h5>
                                                <div className='d-flex align-items-center starArea w-100'>
                                                    <span className='rtng'>4.5</span>
                                                    <ul className='d-flex'>
                                                        <li><FontAwesomeIcon icon={faStar} /></li>
                                                        <li><FontAwesomeIcon icon={faStar} /></li>
                                                        <li><FontAwesomeIcon icon={faStar} /></li>
                                                        <li><FontAwesomeIcon icon={faStar} /></li>
                                                        <li><FontAwesomeIcon icon={faStar} /></li>
                                                    </ul>
                                                    <span className='rvs'>(2,354)</span>
                                                </div>
                                                <div className='limitedTime'>
                                                    <p>Limited time to deal</p>
                                                </div>
                                                <div className='prOffer w-100'>
                                                    <span className="currentPrice">$3,499</span> <span className='line-through'>$3,800</span> <span className="percentOff">30% off</span>
                                                </div>
                                                <div className='prOffer w-100'>
                                                    <ul className='d-flex'>
                                                        <li className='saveColor'>Save $400</li>
                                                        <li>with coupon</li>
                                                    </ul>
                                                </div>
                                                <div className='freeDelivery w-100'>
                                                    <p>FREE Delivery</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='listBox d-flex flex-wrap w-100'>
                                        <div className='listBoxLeft'>
                                            <span className='relative'><img src="../images/product-list/3.jpg" alt="" /><span className='bestSeller'>Best Seller</span></span>
                                        </div>
                                        <div className='listBoxRight'>
                                            <div className='ibrInner'>
                                                <h5>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut facilisis.</h5>
                                                <div className='d-flex align-items-center starArea w-100'>
                                                    <span className='rtng'>4.5</span>
                                                    <ul className='d-flex'>
                                                        <li><FontAwesomeIcon icon={faStar} /></li>
                                                        <li><FontAwesomeIcon icon={faStar} /></li>
                                                        <li><FontAwesomeIcon icon={faStar} /></li>
                                                        <li><FontAwesomeIcon icon={faStar} /></li>
                                                        <li><FontAwesomeIcon icon={faStar} /></li>
                                                    </ul>
                                                    <span className='rvs'>(2,354)</span>
                                                </div>
                                                <div className='limitedTime'>
                                                    <p>Limited time to deal</p>
                                                </div>
                                                <div className='prOffer w-100'>
                                                    <span className="currentPrice">$3,499</span> <span className='line-through'>$3,800</span> <span className="percentOff">30% off</span>
                                                </div>
                                                <div className='prOffer w-100'>
                                                    <ul className='d-flex'>
                                                        <li className='saveColor'>Save $400</li>
                                                        <li>with coupon</li>
                                                    </ul>
                                                </div>
                                                <div className='freeDelivery w-100'>
                                                    <p>FREE Delivery</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='listBox d-flex flex-wrap w-100'>
                                        <div className='listBoxLeft'>
                                            <span className='relative'><img src="../images/product-list/4.jpg" alt="" /><span className='bestSeller'>Best Seller</span></span>
                                        </div>
                                        <div className='listBoxRight'>
                                            <div className='ibrInner'>
                                                <h5>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut facilisis.</h5>
                                                <div className='d-flex align-items-center starArea w-100'>
                                                    <span className='rtng'>4.5</span>
                                                    <ul className='d-flex'>
                                                        <li><FontAwesomeIcon icon={faStar} /></li>
                                                        <li><FontAwesomeIcon icon={faStar} /></li>
                                                        <li><FontAwesomeIcon icon={faStar} /></li>
                                                        <li><FontAwesomeIcon icon={faStar} /></li>
                                                        <li><FontAwesomeIcon icon={faStar} /></li>
                                                    </ul>
                                                    <span className='rvs'>(2,354)</span>
                                                </div>
                                                <div className='limitedTime'>
                                                    <p>Limited time to deal</p>
                                                </div>
                                                <div className='prOffer w-100'>
                                                    <span className="currentPrice">$3,499</span> <span className='line-through'>$3,800</span> <span className="percentOff">30% off</span>
                                                </div>
                                                <div className='prOffer w-100'>
                                                    <ul className='d-flex'>
                                                        <li className='saveColor'>Save $400</li>
                                                        <li>with coupon</li>
                                                    </ul>
                                                </div>
                                                <div className='freeDelivery w-100'>
                                                    <p>FREE Delivery</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='loadMoreArea w-100'>
                                        <Link to='/' className='loadMore'>Load More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}
