import React, { useEffect, useState } from 'react'
import Header from '../includes/Header';
import Footer from '../includes/Footer';
import { Link } from "react-router-dom";
import axios from 'axios';
import parse from 'html-react-parser';
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const Blog = () => {
    const [blog, setBlog]             = useState([]);
    const [recentBlog, setRecentBlog] = useState([]);
    const [totalItem, setTotalItem]   = useState(0);
    const [t, i18n] = useTranslation('common');

    const date = new Date();

    let languages = localStorage.getItem('language');

    function formatDate(date) {
      var monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

      var d = new Date(date),
          month = '' + monthShortNames[d.getMonth()], //(d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
  
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
  
      return [day, month, year].join(' ');
  }

    useEffect(() => {
        var products = localStorage.getItem('products');
        if(products !== '' && products !==null){
            setTotalItem(JSON.parse(localStorage.getItem('products')).length);
        }
        //setTotalItem(JSON.parse(localStorage.getItem('products')).length);
        i18next.changeLanguage(localStorage.getItem('language'));
        window.scrollTo(0,0);
        
            try {
                axios.get(process.env.REACT_APP_API_URL+"blog")
                  .then(async (res) => {
                    if(res.status === 200){
                        setBlog(res.data.data.blog);
                        setRecentBlog(res.data.data.recentBlog);
                    }else{
                      return console.log("Error !");
                    }
                    
                  }).catch(error => 
                    console.log("Error !")
                  );
                } catch (error) {
                    //console.error('error');
                }

       
    }, []);

  return (
    <>
        <Header itemCount={totalItem} />
        <section className="blogSection">
            <div className="container">
                <div className="row">
                <div className="col-lg-9 col-md-8 col-sm-12 col-12">
                    <div className="blog-title">
                    <h2>{t('lang.common.blog')}</h2>
                    </div>
                    {
                        blog.map((val,i) => {
                            return(<div className="blog-list-wrap">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                <div className="blog-thumb relative">
                                <Link to={`/blog/${val.slug}`} className="blog-img"><img src={process.env.REACT_APP_UPLOAD_URL+`blog/${val.image}`} alt="" /></Link>
                                    <span className="blog-post-date">{val.date}</span>
                                </div>
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                                <div className="blog-content">
                                <Link to={`/blog/${val.slug}`} className="blog-title">{ languages == 'ar' ? val.title_ar : val.title }</Link>
                                    <div className="blogText">
                                        {parse(`${ languages == 'ar' ?  val.detail_ar.substring(0, process.env.REACT_APP_CHARACTER_MAX_LENGTH) : val.detail.substring(0, process.env.REACT_APP_CHARACTER_MAX_LENGTH) }...`)}
                                    </div>
                                    <div className="btncover">
                                        <Link to={`/blog/${val.slug}`} className="blog-read-more">{t('lang.common.readMore')}</Link>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>)
                        })
                    }
                    
                </div>
                <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                    <div className="recent-post-wrap sticky-100">
                    <div className="blog-title">
                        <h2>{t('lang.common.recentPosts')}</h2>
                    </div>
                    <ul className="recentPostul">
                        {
                            recentBlog.map((v,i) => {
                                return(<li>

                                    <Link to={`/blog/${v.slug}`}>
                                        <div className="blogRecentprt">
                                        <img src={process.env.REACT_APP_UPLOAD_URL+`blog/${v.image}`} alt="" />
                                        </div>
                                        <div className="blogRightPrt">
                                        
                                        <h5>{ languages == 'ar' ? v.title_ar : v.title }</h5>
                                        <p>{parse(`${ languages == 'ar' ?  v.detail_ar.substring(0, process.env.REACT_APP_CHARACTER_MAX_LENGTH) : v.detail.substring(0, process.env.REACT_APP_CHARACTER_MAX_LENGTH) }...`)}</p>
                                        </div>
                                    </Link>
                                    </li>)
                            })
                        }
                    </ul>
                    </div>
                </div>
                </div>
            </div>
            </section>
    
        <Footer />
    </>
  )
}

export default Blog