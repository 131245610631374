import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Header from '../includes/Header'
import Footer from '../includes/Footer'
import { Loader } from './Loader';
import parse from 'html-react-parser';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const BookingCheckout = () => {
  const [totalItem, setTotalItem]         = useState(0);
  const [loading, setLoading]             = useState(false);
  const location                          = useLocation();
  const [errorMsg, setMessage]            = useState([]);
  const [orderID, setOrderID]             = useState(false);
  const [success, setSuccess]             = useState(false);
  const [orderProducts, setOrderProducts] = useState([]);
  let navigate                            = useNavigate();
  const [currencyCode, setCurrencyCode]   = useState(localStorage.getItem('currencyCode'));
  const [t, i18n] = useTranslation('common');

  let loginUser                             = localStorage.getItem('loginUser');
  const [currencySymbol, setCurrencySymbol] = useState('$');
  var currency                              = localStorage.getItem('currency');
  var currencyRate                          = localStorage.getItem('currencyRate');
  var lessonSelected                        = localStorage.getItem('lessonSelected');

  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('language'));
    window.scrollTo(0,0);
    setLoading(false);
    var products = localStorage.getItem('products');
    if(products !== '' && products !==null){
        setTotalItem(JSON.parse(localStorage.getItem('products')).length);
    }
    if(currency == 2){
      setCurrencySymbol('£');
      setCurrencyCode('GBP');
    }

  }, []);

  //Create Order
  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: `${location.state.lesson} lessons ${location.state.teacherGenre} / ${location.state.teacherInstrument} a month with ${location.state.teacherName}`,
            amount: {
              currency_code: currencyCode,
              value: location.state.lessonFees,
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
         setOrderID(orderID);
         return orderID;
      });
  };

  // check Approval
  const onApprove = (datas, actions) => {
    return actions.order.capture().then(function (details) {
        const { payer } = details;
        const { purchase_units } = details.purchase_units;
        const orderDetails = details;
        setLoading(true);
        setSuccess(true);
        if (details.status == 'COMPLETED') {

            let data = {
              tutor: location.state.tutor,
              loginUser: loginUser,
              amount: details.purchase_units[0].amount,
              description: details.purchase_units[0].description,
              payments: details.purchase_units[0].payments,
              payer:payer,
              lessonDate: location.state.selectedEventDate,
              lesonTime: location.state.lessonTiming,
              availabilityDate: location.state.availabilityDate,
              instrumentName: location.state.teacherInstrument,
              lessonSelected: location.state.lesson,
              studentSelectDates: location.state.studentSelectDates
            };

            axios({
                method: 'post',
                url: process.env.REACT_APP_API_URL + "booking-live-class",
                data: data,
            }).then(function (res) {
                //console.log("res =", res);
                if (res.status === 200) {
                    setLoading(true);
                    //localStorage.removeItem("products");
                    navigate('/thankyou/'+res.data.orderID);
                } else if (res.status === 404) {
                    setLoading(false);
                    setMessage(res.data.message);
                }

            }).catch(function (error) {
                setLoading(false);
                setMessage(error.response.data.message);
                //console.log(error.response.data.data);
            });
        }
    });
  };

  return (
    <>
    {
        loading === true ? (<Loader />) : ''
    }
      <Header itemCount={totalItem} />

      <section className='finalStepBooking'>
        <div className="container ph-40">
          <div className="row">
            <div className="col-12">
              <div className="fsbInner">
                <h3>{t('lang.common.finalStepToBooking')}</h3>
                <div className="fsbInnerArea">
                  <table className="table table-borderless m-0">
                    <tbody>
                      <tr>
                        <td className='w-90'>
                          {
                            location.state.teacherPhoto != null  ? <img src={process.env.REACT_APP_UPLOAD_URL+`teacher/photos/${location.state.teacherPhoto}`} alt={location.state.teacherName} /> : <img src="../images/avatar.jpg" alt={location.state.teacherName} />
                          }
                          </td>
                        <td>
                        <h5>{location.state.lesson} {t('lang.common.lessons')} {location.state.teacherGenre}/ {location.state.teacherInstrument} {t('lang.common.aMonthWith')} {location.state.teacherName}</h5>

                          {/* <p>{location.state.lessonTiming}  <span>{location.state.selectedEventDate}</span></p> */}
                        </td>
                        <td className='noWrap'>{t('lang.common.qty')} 1</td>
                        <td>{currencySymbol}{location.state.lessonFees}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="row g-2">
                    <div className="col-12">
                      <h5>{t('lang.common.lessonDateTime')}</h5>
                    </div>
                    {
                      location.state.studentSelectDates.length > 0 ?
                      location.state.studentSelectDates.map((val,indx) => {
                            return(parse(`
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                              <div className="final-lesson-book">
                                  <p>${val.time}</p>
                                  <p>${val.date}</p>
                              </div>
                            </div>
                            `))
                        })
                      : ''
                    }
                  </div>
                </div>
                <div className="fsbInnerTotal">
                  <div className='totalOrder d-flex justify-content-between'>
                    <h6>{t('lang.common.totalOrder')}</h6>
                    <p>{currencySymbol}{location.state.lessonFees}</p>
                  </div>
                </div>
                <div className='text-center mt-4'>
                <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID, "currency": currencyCode }}>
                    <PayPalButtons
                        style={{ layout: "vertical" }}
                        createOrder={createOrder}
                        onApprove={onApprove}
                    />
                    {/* <PayPalButtons
                        style={{ layout: "vertical" }}
                        createOrder={createOrder}
                        onApprove={onApprove}
                    /> */}
                </PayPalScriptProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default BookingCheckout

