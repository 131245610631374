import React, { Component } from 'react'
import Header from '../includes/Header'
import Footer from '../includes/Footer'
import { Loader } from './Loader';
import { Link, Navigate } from "react-router-dom";
// import MainSearch from '../includes/MainSearch'
import { Parallax } from 'react-parallax';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faHeart, faStar } from '@fortawesome/free-solid-svg-icons'

import ProgressBar from "@ramonak/react-progress-bar";

import ModalVideo from 'react-modal-video'
import 'react-modal-video/css/modal-video.css';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import parse from 'html-react-parser';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ExternalLink } from 'react-external-link';
import ReviewForm from './ReviewForm';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import i18next from "i18next";

import { withTranslation, WithTranslation } from 'react-i18next';

//https://stackoverflow.com/questions/66027530/i-want-to-use-i18next-in-the-class-component-of-my-project-but-i-have-this-error

/* import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton
} from "react-share"; */

import { FacebookShareButton, TwitterShareButton, EmailShareButton } from "react-share";
import { FacebookIcon, TwitterIcon, EmailIcon } from "react-share";

function withParams(Comp) {
  // eslint-disable-next-line func-names
  return function (props) {
    // eslint-disable-next-line react/jsx-filename-extension
    return <Comp {...props} params={useParams()} />;
  };
}

var rating1 = 0; var rating2 = 0;  var rating3 = 0;  var rating4 = 0; var rating5 = 0;
let languages = localStorage.getItem('language');

class CourseDetail extends Component {

  /* static async main() {
      await zip(process.env.REACT_APP_UPLOAD_URL+'/courses/material/material20220624060926J8HwDP', process.env.REACT_APP_UPLOAD_URL+'/courses/material/archive.zip');
  } */

  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      isOpenMain: false,
      isMaterialModal: false,
      freeModal: false,
      applyCoupon: false,
      shareModel:false,
      courseData: {
      title: '',
      },
      loading: false,
      teacherData: {
        name: '',
      },
      teacherPhoto:[],
      includeData: {
        details: '',
      },
      purposes:[],
      courseDetails: [],
      courseIncludes: [],
      studentBought: [],
      category: {
        title: '',
      },
      courseUpdate: '',
      discountPercent: 0,
      discountedPrice: 0,
      introVideoUrl: '',
      videoChannel: 'custom',
      totalCourse  : 1,
      totalLectures : 0,
      totalDuration : 0,
      redirect : false,
      purchased: 0,
      //Add To Cart
      totalItem : 0,
      products: [],
      modelValue: '',
      materialList: [],
      ratingAvg:5,
      purchaseCourse:[],
      reviews: [],
      teacherRatings: 5,
      reviewCount: '',
      teacherReviewCount: '',
      currencySymbol: '$',
      courePrice: 0,
      coureDiscountPrice: 0,
      courseDiscountPercent: 0,
      courseAvarageRating: [],
      reviewGiven: 0,
      featureReview: []

    }
    this.openModal = this.openModal.bind(this);

    this.openModalTop = this.openModalTop.bind(this);

    this.handleClose = this.handleClose.bind(this);

    this.handleCloseFreeModal = this.handleCloseFreeModal.bind(this);

    this.handleCloseShareModal = this.handleCloseShareModal.bind(this);

    this.handleCloseApplyCouponModal = this.handleCloseApplyCouponModal.bind(this);

  }

  openModal(e) {

    this.setState({ modelValue: e })
    this.setState({ isOpen: true })
  }

  demoModalShow = () => {
    this.setState({ freeModal: true })
  }

  handleCloseFreeModal(){
    this.setState({freeModal : false});
  }

  handleCloseApplyCouponModal(){
    this.setState({applyCoupon : false});
  }

  shareModalShow = () => {
    this.setState({ shareModel: true })
  }

  applyCouponModalShow = () => {
    this.setState({ applyCoupon: true })
  }

  handleCloseShareModal(){
    this.setState({shareModel : false});
  }

  openModalTop() {
    this.setState({ isOpenMain: true })
  }

  YouTubeGetID(url){
      url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
  }

  handleClose(){
    this.setState({isMaterialModal : false});
  }

  checkCouponCode(){
     //alert("dsad");
  }

  openMaterialModal(e){
     this.setState({ materialList : e });
     this.setState({isMaterialModal : true});
  }

  handleAddToCart(id, type, uniqueid, total) {
    let getProducts = [];
    let selectedProduct = localStorage.getItem('products');
    let check = 1;

    let alreadyAdded = '';

    if(localStorage.getItem('language') == 'ar'){
        alreadyAdded = 'تمت إضافة عربة الانتقال بالفعل';
    }else{
        alreadyAdded = 'Already added goto cart';
    }

    let data = {
        id: id,
        type: type,
        uniqueid: uniqueid,
        total: total,
    };

    if(selectedProduct != '' && selectedProduct != null){
        selectedProduct = JSON.parse(selectedProduct);
        selectedProduct.map((v,i) => {
            if(v.uniqueid == uniqueid && v.type == type){
                toast.success(alreadyAdded, {
                    position: toast.POSITION.TOP_RIGHT
                });

                check = 0;

            }
        })
    }

    if(check == 0){
      return false;
    }
    var products = localStorage.getItem('products');
    if(products !== '' && products !==null){

    //if(localStorage.getItem('products') !== '' && localStorage.getItem('products')!==null){
      getProducts = JSON.parse(localStorage.getItem('products'));

      //console.log("getProducts ss",getProducts);
      if(getProducts.length > 0){

        getProducts.map((v,i) => {
            //console.log("v =",v);
            if(v.id === id){
              getProducts.splice(i, 1);
            }
        });

        getProducts.push(data);
        localStorage.setItem('products', JSON.stringify(getProducts));

        this.setState({ totalItem  : JSON.parse(localStorage.getItem('products')).length});

      }else{
        localStorage.setItem('products', JSON.stringify([data]));
        this.setState({ totalItem  : JSON.parse(localStorage.getItem('products')).length});
      }
    }else{

      localStorage.setItem('products', JSON.stringify([data]));
      this.setState({ totalItem  : JSON.parse(localStorage.getItem('products')).length});
    }
  }

  buyNow =(e) => {
      let data = {
          id:e.id,
          item:e.item
      };

      const getStoreProduct = JSON.parse(localStorage.getItem('storeProduct'));

      if(localStorage.getItem('storeProduct') != null){

          if(getStoreProduct.length > 0){
              getStoreProduct.map((v,i) => {
                  if(v.id === data.id && data.item == 'courses'){
                      getStoreProduct.splice(i, 1);
                  }
              });
              getStoreProduct.push(data);
              localStorage.setItem('storeProduct', JSON.stringify(getStoreProduct));

              this.setState({ totalShopItem  : JSON.parse(localStorage.getItem('storeProduct')).length});
          }else{
              localStorage.setItem('storeProduct', JSON.stringify([data]));
              this.setState({ totalShopItem  : JSON.parse(localStorage.getItem('storeProduct')).length});
          }

      }else{
          localStorage.setItem('storeProduct', JSON.stringify([data]));
          this.setState({ totalShopItem  : JSON.parse(localStorage.getItem('storeProduct')).length});
      }
      this.state.redirect = true;
  }

  async componentDidMount() {

    window.scrollTo(0, 0);

    const slug = this.props.params.slug;

    var products = localStorage.getItem('products');

    if(products !== '' && products !==null){
      this.setState({ totalItem  : JSON.parse(localStorage.getItem('products')).length});
    }

    if(localStorage.getItem('currency') == 2){
      this.setState({ currencySymbol: '£' });
    }

    if(slug !== ""){
      let loginUser = localStorage.getItem('loginUser');
      try {
        await this.setState({loading:true});

        axios.get(process.env.REACT_APP_API_URL+`courses/${slug}?&loginUser=${loginUser}`)
          .then(async (res) => {
             if(res.status === 200){

                this.setState({ courseData: res.data.data.course });
                this.setState({ teacherData: res.data.data.course.teacher });
                this.setState({ teacherPhoto: res.data.data.course.teacher_photo });
                this.setState({ totalCourse: res.data.data.total_course });
                this.setState({ courseIncludes: res.data.data.includes });
                this.setState({ category: res.data.data.course.category });
                this.setState({ purposes: res.data.data.course.purposes });
                this.setState({ courseDetails: res.data.data.course.course_details });
                this.setState({totalLectures: res.data.data.lectures});
                this.setState({totalDuration: res.data.data.duration});
                this.setState({purchased: res.data.data.purchased});
                this.setState({purchaseCourse: res.data.data.purchaseCourse});
                this.setState({ reviews: res.data.data.reviews });
                this.setState({ reviewCount: res.data.data.reviewCount });
                this.setState({ teacherReviewCount: res.data.data.teacherReviews });
                this.setState({ teacherRatings: res.data.data.teacherRatings });
                this.setState({ ratingAvg: res.data.data.ratingAvg });
                this.setState({studentBought: res.data.data.studentBought});
                this.setState({courseAvarageRating: res.data.data.courseAvarageRatings});
                this.setState({ loading: false });
                this.setState({reviewGiven: res.data.data.reviewGiven});
                this.setState({featureReview: res.data.data.featureReview});

                if(res.data.data.courseAvarageRatings != ''){

                  rating1 = res.data.data.courseAvarageRatings[1];
                  rating2 = res.data.data.courseAvarageRatings[2];
                  rating3 = res.data.data.courseAvarageRatings[3];
                  rating4 = res.data.data.courseAvarageRatings[4];
                  rating5 = res.data.data.courseAvarageRatings[5];
                }


                const date = new Date(res.data.data.course.updated_at);
                this.setState({ courseUpdate : `${date.getMonth()+1}`.padStart(2, "0") + `/${date.getFullYear()}` });

                const percent = (res.data.data.course.discount_price * 100)/res.data.data.course.price;

                const discountPrice = res.data.data.course.price - (res.data.data.course.price * res.data.data.course.discount_price / 100);


                let price = res.data.data.course.price;
                let dPrice = res.data.data.course.discount_price;

                /* if(localStorage.getItem('currency') == 2){
                    price = price * Number(localStorage.getItem('currencyRate'));
                }else{
                    price = price;
                } */

                if(res.data.data.course.currency == localStorage.getItem('currency')){
                    price = res.data.data.course.price;
                    dPrice = res.data.data.course.discount_price;
                }else if(res.data.data.course.currency != localStorage.getItem('currency')){
                    price = res.data.data.course.price * Number(localStorage.getItem('currencyRate'));
                }


                let discountedPrice = price - (price * dPrice / 100);

                this.setState({ courseDiscountPercent : dPrice });

                this.setState({ courePrice : price });
                this.setState({ coureDiscountPrice : discountedPrice });

                this.setState({ discountedPrice : discountPrice });
                this.setState({ discountPercent : percent });
                let introVideo = ''; let channels = ''; let channerId = '';
                if(res.data.data.course.introductory_video_type === '1'){

                  introVideo = res.data.data.course.introductory_video; //process.env.REACT_APP_UPLOAD_URL+`/courses/introvideo/${res.data.data.course.introductory_video}`;
                  channels = 'vimeo';
                  channerId = res.data.data.course.introductory_video;
                }else if(res.data.data.course.introductory_video_type === '2'){
                  introVideo = res.data.data.course.introductory_video_url;
                  channels = 'youtube';
                  channerId = this.YouTubeGetID(res.data.data.course.introductory_video_url);
                }else{
                  introVideo = '';
                  channels = 'custom';
                  channerId = '';
                }

                this.setState({ introVideoUrl: introVideo });
                this.setState({ setChannel: channels });
                this.setState({ channerIds: channerId });

                this.setState({loading:false});

            }else{
              this.setState({loading:false});
              return console.log("Error !");
            }

          }).catch(error =>{
            this.setState({loading:false});
            console.log("Error !",error)
          }

          );
      } catch (error) {
          //console.error('error');
          this.setState({loading:false});
      }
    }

    // this.setState({ loading: false });

  }



  render() {
    //console.log("this.state.purchaseCourse.length", this.state.purchaseCourse);
    //console.log("courseData", this.state.courseData);
    let { t, i18n } = this.props;
    return (
      <>
      {
        this.state.loading === true ? (<Loader />) : ''
      }
      {this.state.redirect && <Navigate to="/checkout" />}
      <Modal show={this.state.isMaterialModal} onHide={this.handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Materials</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className='downLoadDtnArea text-center'>
                  <ul className='row row-cols-3 g-4'>
                    {
                      this.state.materialList.map((val,indx) => {
                        return(<li className='col'>
                          <ExternalLink href={val}>
                              {t('lang.common.material')} {indx+1}<span><FontAwesomeIcon icon={faDownload} /> {t('lang.common.download')}</span>
                          </ExternalLink>
                      </li>)
                      })
                    }
                  </ul>
                </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            {t('lang.common.close')}
          </Button>
          {/* <Button variant="primary" onClick={this.handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
        <ToastContainer autoClose={2000} />
        <Header itemCount={this.state.totalItem} />
        {/* <MainSearch /> */}


        {
          this.state.courseData.title != '' ?

          <>
             <Parallax blur={{ min: -20, max: 20 }} bgImage={(`${process.env.REACT_APP_UPLOAD_URL+'courses/banner/'+this.state.courseData.banner}`)} bgImageAlt="{this.state.courseData.title}" strength={-200} >
          <div className='container'>
            <div className='row cdInner g-lg-5 g-md-5'>
              <div className='col-lg-5 col-md-5 col-sm-6 col-12'>
              {/* {
                this.state.purchased != 1 &&
                <div className='videoArea relative w-100'>
                {

                  this.state.introVideoUrl !== null  ?
                  <>
                  <img src={`${process.env.REACT_APP_UPLOAD_URL+'courses/banner/'+this.state.courseData.banner}`} alt="" />

                   <React.Fragment>
                    <ModalVideo videoId={this.state.channerIds} channel={this.state.setChannel} url={this.state.introVideoUrl} isOpen={this.state.isOpenMain} onClose={() => this.setState({ isOpenMain: false })} />
                    <button className='playIcon' onClick={this.openModalTop}>
                      <FontAwesomeIcon icon={faYoutube} />
                    </button>
                  </React.Fragment>
                  </>
                 : ''

                }
                </div>
              } */}

                <div className='videoArea relative w-100'>
                {

                  this.state.introVideoUrl !== null  ?
                  <>
                  <img src={`${process.env.REACT_APP_UPLOAD_URL+'courses/banner/'+this.state.courseData.banner}`} alt="" />

                   <React.Fragment>
                    <ModalVideo videoId={this.state.channerIds} channel={this.state.setChannel} url={this.state.introVideoUrl} isOpen={this.state.isOpenMain} onClose={() => this.setState({ isOpenMain: false })} />
                      <button className='playIcon' onClick={this.openModalTop}>
                        <FontAwesomeIcon icon={faYoutube} />
                      </button>
                  </React.Fragment>
                  </>
                 : ''

                }
                </div>
              </div>

              <div className='col-lg col-md col-sm-6 col-12'>
                <div className='cdInnerBox'>
                  <h2>{languages == 'ar' ? this.state.courseData.title_ar : this.state.courseData.title}</h2>
                  <p>{languages == 'ar' ? this.state.courseData.short_description_ar : this.state.courseData.short_description}</p>
                  <div className='d-flex align-items-center starArea w-100'>
                    <div className="bestSeller"><span>{t('lang.common.bset_seller')}</span></div>
                    {/* <span className='rtng'>4.5</span> */}
                    <ul className='d-flex'>
                      <li>
                          {
                              this.state.ratingAvg != '' &&
                              (() => {
                                  let td = [];
                                  for (let i = 1; i <= this.state.ratingAvg; i++) {
                                    td.push(<FontAwesomeIcon icon={faStar} />);
                                  }
                                  return td;
                                })()
                          }
                      </li>
                    </ul>
                    {
                        this.state.reviewCount != '' ? <span className='rvs'>({this.state.reviewCount})</span> : ''
                    }

                  </div>
                  <div className='createdBy'>
                    <p>{t('lang.common.createdBy')}: <span>{this.state.teacherData.first_name+' '+this.state.teacherData.last_name}</span></p>
                  </div>
                  <div className='createdBy'>
                    <ul className='d-flex'>
                      <li>{t('lang.common.lastUpdate')} : <span>{this.state.courseUpdate}</span></li>
                      <li><span><img src="../images/svg/globe.svg" alt="" /></span> { languages == 'ar' ? t('lang.common.arLanguages') : t('lang.common.languages') } </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Parallax>

        <section className='courseDtlsSection'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-8 col-md-8 col-sm-12 col-12'>
                <div className='cdLeft'>
                  {
                    this.state.purposes.length > 0 ?
                  <div className='whatYouLearn w-100'>
                    <h3>{t('lang.common.whatLearn')}</h3>
                    <ul className='d-flex flex-wrap w-100'>
                      {
                        this.state.purposes.map((v,i) => {
                          return(<li>{ languages == 'ar' ? v.details_ar : v.details }</li>)
                        })
                      }
                    </ul>
                  </div>
                  : ''
                  }
                  <div className='courseContent w-100'>
                    <h3>{ t('lang.common.course_content') }</h3>
                    {/* <h6>{this.state.totalLectures} lectures • {this.state.totalDuration} total length</h6> */}
                    <div className='w-100 cdAccordion'>
                      <div className="accordion" id="accordionExample">
                      {
                        this.state.courseDetails.map((val,indx) => {
                          //console.log("val.material_folder ", typeof val.material_folder);
                          return(
                            val.status == '1' ?
                            <>
                            <div className="accordion-item">
                              <div className="accordion-header" id={`headingOne${indx}`}>
                                <button className="accordion-button d-flex " type="button" data-bs-toggle="collapse" data-bs-target={`#collapseOne${indx}`}  aria-expanded="true" aria-controls={`collapseOne${indx}`}>
                                  { languages == 'ar' ? val.title_ar : val.title }
                                </button>
                              </div>
                              <div id={`collapseOne${indx}`} className={`accordion-collapse collapse ${indx == 0 ? "show" : "show"}`} aria-labelledby={`headingOne${indx}`} data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                  <div className="accordion accordion-flush" id="accordionFlushExample">
                                    <div className="accordion-item d-flex justify-content-between">
                                        <div className='contentDetails' style={{ maxWidth:400 }}>
                                          {
                                            languages == 'ar' ? val.lesson_description_ar != null ? parse(`${val.lesson_description_ar}`) : ''
                                            : val.lesson_description != null ? parse(`${val.lesson_description}`) : ''

                                          }
                                        {/* { val.lesson_description != null ? parse(`${val.lesson_description}`) : ''} */}

                                        {
                                          val.material_folder !=  null && this.state.purchased == 1 && val.material_folder !=  null ?

                                          <div className="download-file">
                                            <h4>{t('lang.common.download')} :</h4>
                                            <ul>

                                                {
                                                  val.materials.map((v,i) => {
                                                      //console.log("v =", v);
                                                      let materialName = v.substring(v.lastIndexOf('/') + 1);
                                                      //console.log(v.substring(v.lastIndexOf('/') + 1));
                                                      return(
                                                        <><li><a target="_blank" href={v} download>{materialName} <img src={process.env.REACT_APP_UPLOAD_URL+`icons/downloadable.png`} alt="" /></a></li></>
                                                      )
                                                  })
                                                }

                                            </ul>
                                          </div>

                                        : ''

                                        }

                                        </div>

                                        <div className='prvTime'>
                                            <ul className='d-flex'>

                                            <li>

                                              {/* {
                                                val.material_folder !=  null ?  this.state.purchased == 1 && val.material_folder !=  null ?
                                                <span data-bs-toggle="modal" onClick={ () => this.openMaterialModal(val.materials)}><img src={process.env.REACT_APP_UPLOAD_URL+`icons/downloadable.png`} alt="" /> Download</span>
                                              : <span><img src={process.env.REACT_APP_UPLOAD_URL+`icons/downloadable.png`} alt="" /></span> : ''

                                              } */}
                                              {
                                                this.state.purchased == 1 ? <span>
                                                <button className="videoBtns" onClick={ () => this.openModal(val)}><img src={process.env.REACT_APP_UPLOAD_URL+`icons/video.png`} alt=""/> {t('lang.common.watch')}</button>
                                                </span> : <span onClick={ () => this.demoModalShow()}><img src={process.env.REACT_APP_UPLOAD_URL+`icons/video.png`} alt=""/></span>
                                              }

                                              </li>
                                              <Modal show={this.state.freeModal} onHide={this.handleCloseFreeModal} backdrop="static" keyboard={false} centered>
                                              <Modal.Header closeButton>
                                                <Modal.Title>{t('lang.common.purchaseCourse')}</Modal.Title>
                                              </Modal.Header>
                                              <Modal.Body>
                                              <div className='downLoadDtnArea text-center'>
                                                <h3><a href={`/courses/${this.props.params.slug}`}>{t('lang.common.purchaseCourseMessage')}</a></h3>
                                              </div>
                                              </Modal.Body>
                                              <Modal.Footer>
                                                <Button variant="secondary" onClick={this.handleCloseFreeModal}>
                                                  {t('lang.common.close')}
                                                </Button>
                                                {/* <Button variant="primary" onClick={this.handleClose}>
                                                  Save Changes
                                                </Button> */}
                                              </Modal.Footer>
                                            </Modal>
                                              {/* <li>
                                              <button className="videoBtns" onClick={ () => this.openModal(val)}>
                                                Preview
                                              </button> */}
                                              {/* { val.video_type == '1' && val.video != null
                                                ? <React.Fragment>
                                                    <ModalVideo channel='custom' url={process.env.REACT_APP_UPLOAD_URL+`courses/video/${val.video}`} isOpen={this.state.isOpen} onClose={() => this.setState({ isOpen: false })} />
                                                    <button className="videoBtns" onClick={ () => this.openModal(val)}>
                                                      Preview
                                                    </button>
                                                  </React.Fragment>
                                                : <>
                                                    {val.video_type == '2' && val.video_url != null
                                                      ? <React.Fragment>
                                                          <ModalVideo channel='custom' url={val.video_url}  isOpen={this.state.isOpen} onClose={() => this.setState({ isOpen: false })} />

                                                          <button className="videoBtns" onClick={this.openModal}>
                                                            Preview
                                                          </button>
                                                        </React.Fragment>
                                                      : null
                                                    }

                                                  </>
                                              } */}

                                                {/* </li> */}
                                              <li>{val.duration}</li>
                                            </ul>
                                        </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </>
                        : ''
                        )

                        })
                      }
                        {/* videoId={this.state.channerIds} channel={this.state.setChannel} */}
                        <ModalVideo videoId={this.state.modelValue.video_type == '2' ? this.YouTubeGetID(this.state.modelValue.video_url) : this.state.modelValue.video} channel={this.state.modelValue.video_type == '1' ? 'vimeo' : 'youtube'} url={this.state.modelValue.video_type == '1' ? this.state.modelValue.video : this.state.modelValue.video_url} isOpen={this.state.isOpen} onClose={() => this.setState({ isOpen: false })} />
                      </div>
                    </div>
                  </div>
                  <div className='requirements w-100'>
                    <h3>{ t('lang.common.to_whom_it_is_created') }</h3>
                    {
                      languages == 'ar' ?
                      parse(`${this.state.courseData.requirement_ar}`) : parse(`${this.state.courseData.requirement}`)
                    }

                    {/* <div dangerouslySetInnerHTML={{__html: this.state.courseData.requirement}}></div> */}
                  </div>
                  <div className='description w-100'>
                    <h3>{ t('lang.common.expected_video') }</h3>
                    {
                      languages == 'ar' ?
                      parse(`${this.state.courseData.description_ar}`) : parse(`${this.state.courseData.description}`)
                    }

                  </div>
                  {
                    this.state.featureReview != '' ? <div className='featrueReview w-100'>
                    <h3>{ t('lang.common.featrue_review') }</h3>
                    <div className='d-flex flex-wrap frBox w-100'>
                      <div className='frBoxImg'>
                        <span>
                          {
                            this.state.featureReview.userType == '3' ? <img src={process.env.REACT_APP_UPLOAD_URL+`student/photos/${this.state.featureReview.photo}`} alt="" /> :
                            this.state.featureReview.userType == '2' ? <img src={process.env.REACT_APP_UPLOAD_URL+`teacher/photos/${this.state.featureReview.photo}`} alt="" /> : <img src="../images/thumb-1.jpg" alt="" />
                          }
                          <img src={process.env.REACT_APP_UPLOAD_URL+`user.png`} alt="" />
                        </span>
                      </div>
                      <div className='frBoxDesc'>
                        <div className='frBoxDescTop d-flex flex-wrap justify-content-between'>
                          <div className='frBoxDescTopLeft'>
                            <h5>{this.state.featureReview.name}</h5>
                            <ul className='d-flex'>
                              <li>{this.state.featureReview.totalPurchaseCourse} courses</li>
                              <li>{this.state.featureReview.totalReview} { t('lang.common.review') }</li>
                            </ul>
                          </div>
                          <div className='frBoxDescTopRight text-center'>
                            <ul className='d-flex justify-content-center'>
                            {
                                this.state.featureReview.ratingStars != '' &&
                                (() => {
                                    let td = [];
                                    for (let i = 1; i <= this.state.featureReview.ratingStars; i++) {
                                    td.push(<li><FontAwesomeIcon icon={faStar} /></li>);
                                    }
                                    return td;
                                })()
                            }

                            </ul>
                            <p>{this.state.featureReview.lastReview}</p>
                          </div>
                        </div>
                        <div className='frBoxDescBtm w-100'>
                          <p>{this.state.featureReview.reviewMsg}</p>
                        </div>
                      </div>
                    </div>
                  </div> : ''
                  }

                  {

                    this.state.studentBought.length > 0 ?
                    <div className='studentsBought'>
                      <h3>{ t('lang.common.students_bought') }</h3>
                      <div className='instructorInner'>
                      <div className='row g-3'>
                        {
                          this.state.studentBought.map((val,indx) => {
                            let courseDate = new Date(val.updated_at);


                            let price = val.price;
                            let dPrice = val.discount_price;

                            /* if(localStorage.getItem('currency') == 2){
                                price = price * Number(localStorage.getItem('currencyRate'));

                            }else{
                                price = price;

                            } */

                            if(val.currency == localStorage.getItem('currency')){
                                price = val.price;
                                dPrice = val.discount_price;
                            }else if(val.currency != localStorage.getItem('currency')){
                                price = val.price * Number(localStorage.getItem('currencyRate'));
                            }

                            let discountedPrice = price - (price * dPrice / 100);


                            return(
                            <div className='col-lg-4 col-md-4 col-sm-6 col-12' key={indx}>
                              <div className="projectInner w-100">
                                <div className="projectInnerTop w-100">
                                  <a href={`/courses/${val.slug}`}><img src={`${process.env.REACT_APP_UPLOAD_URL+'courses/banner/'+val.banner}`} alt={val.title} /></a>
                                </div>
                                <div className="projectInnerMid w-100">
                                  <h4><a href={`/courses/${val.slug}`}>{ languages == 'ar' ? val.title_ar : val.title }</a></h4>
                                  <div className='d-flex align-items-center starArea w-100'>
                                    <ul className='d-flex mx-0'>

                                          {
                                              val.rating != '' &&
                                              (() => {
                                                  let td = [];
                                                  for (let i = 1; i <= val.rating; i++) {
                                                  td.push(<li><FontAwesomeIcon icon={faStar} /></li>);
                                                  }
                                                  return td;
                                              })()
                                          }

                                    </ul>
                                    {
                                        val.totalRating > 0 && <span className='rvs px-2'>({val.totalRating})</span>
                                    }
                                    {/* <span className='redHeart'><Link to="/"><FontAwesomeIcon icon={faHeart} /></Link></span> */}
                                  </div>
                                  <div className='totalUpdate'>
                                    <ul>
                                      { dPrice != 0 ? <li>{ t('lang.common.discount') }: <span>{dPrice}%</span></li> : ''}

                                      <li>{ t('lang.common.update') }: <span>{`${courseDate.getMonth()+1}`.padStart(2, "0") + `/${courseDate.getFullYear()}` }</span></li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="price-sec w-100">
                                  <div className="row justify-content-between align-items-center">
                                    <div className="col-auto">
                                      <div className="price-sec-text">
                                        <p>{ discountedPrice !=0 ? this.state.currencySymbol+Math.round(discountedPrice) : t('lang.common.free') } { price != 0 && dPrice != 0 ? <span>{this.state.currencySymbol+Math.round(price)}</span> : '' } </p>
                                      </div>
                                    </div>
                                    <div className="col-auto">
                                      <div className="bestSeller"><span>{ t('lang.common.bset_seller') }</span></div>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>


                          )
                          })
                        }
                        </div>
                      </div>
                      {/* <div className='studentsBoughtBtm d-flex justify-content-center mt-4'>
                        <Link to='/' className='showMoreBtn'>Show More</Link>
                      </div> */}

                    </div>
                    : ''
                  }


                  <div className='instructor'>
                    <h3>{ t('lang.common.instructor') }</h3>
                    <div className='instructorInner d-flex flex-wrap justify-content-between w-100'>
                      <div className='instructorLeft'>
                        <span>
                          {
                            this.state.teacherPhoto.photo != null  ? <img src={process.env.REACT_APP_UPLOAD_URL+`teacher/photos/${this.state.teacherPhoto.photo}`} alt={this.state.teacherData.first_name+' '+this.state.teacherData.last_name} /> : <img src="../images/avatar.jpg" alt="" />
                          }

                        </span>
                      </div>
                      <div className='instructorRight'>
                        <div className='irTop d-flex flex-wrap justify-content-between  align-items-center w-100'>
                          <div className='irTopLeft'>
                            <h4>{this.state.teacherData.first_name+' '+this.state.teacherData.last_name}</h4>
                            <ul>
                              <li>{ t('lang.common.category') } : <span><Link to={`/courses/category/`+this.state.category.slug}>{this.state.category.title}</Link></span></li>
                              {
                                this.state.teacherData.experiance != null ? <li>{ t('lang.common.experience') } : <span>{ this.state.teacherData.experiance } { this.state.teacherData.experiance > 1 && this.state.teacherData.experiance!= null ? 'yrs' : 'year' }</span></li> : ''
                              }
                              {/* <li>Experience : <span>{ this.state.teacherData.experiance!= null  } { this.state.teacherData.experiance > 1 && this.state.teacherData.experiance!= null ? 'yrs' : 'year' } </span></li> */}
                              {/* <li>Students : <span> 6,827</span></li> */}
                              <li>{ t('lang.menu.menu_11') } : <span>{this.state.totalCourse}</span></li>
                            </ul>
                          </div>
                          <div className='irTopRight'>
                            <div className='d-flex align-items-center starArea w-100'>
                              {/* <span className='rtng'>4.5</span> */}
                              <ul className='d-flex'>
                                {
                                    this.state.teacherRatings != '' &&
                                    (() => {
                                        let td = [];
                                        for (let i = 1; i <= this.state.teacherRatings; i++) {
                                          td.push(<li><FontAwesomeIcon icon={faStar} /></li>);
                                        }
                                        return td;
                                      })()
                                }
                                {/* <li><FontAwesomeIcon icon={faStar} /></li>
                                <li><FontAwesomeIcon icon={faStar} /></li>
                                <li><FontAwesomeIcon icon={faStar} /></li>
                                <li><FontAwesomeIcon icon={faStar} /></li>
                                <li><FontAwesomeIcon icon={faStar} /></li> */}
                              </ul>
                            </div>
                            <div className='rvw'>
                            {
                                this.state.teacherReviewCount != '' ? <p><span>({this.state.teacherReviewCount})</span> { t('lang.common.reviews') }</p> : ''
                            }
                            </div>
                          </div>
                        </div>
                        <div>

                        </div>
                      </div>
                    </div>
                    <div className='irBtm'>
                      { parse(`${this.state.teacherData.about}`) }
                    </div>
                  </div>
                  <div className='studentFeedback'>
                    <h3>{ t('lang.common.student_feedback') }</h3>
                    <div className='pbArea'>
                      <div className='pbWrap d-flex align-items-center'>
                        <div className='pbWrapLeft'> <span>5 { t('lang.common.stars') }</span> </div>
                        <div className='pbWrapMid'>
                          <ProgressBar completed={(rating5 * 5)/100} bgColor="#6fdfda" height="16px" isLabelVisible={false} baseBgColor="#ebebeb" labelColor="#e80909" animateOnRender />
                        </div>
                        <div className='pbWrapRight'>{rating5}</div>
                      </div>

                      <div className='pbWrap d-flex align-items-center'>
                        <div className='pbWrapLeft'> <span>4 { t('lang.common.stars') }</span> </div>
                        <div className='pbWrapMid'>
                          <ProgressBar completed={(rating4 * 4)/100} bgColor="#6fdfda" height="16px" isLabelVisible={false} baseBgColor="#ebebeb" labelColor="#e80909" animateOnRender />
                        </div>
                        <div className='pbWrapRight'>{rating4}</div>
                      </div>
                      <div className='pbWrap d-flex align-items-center'>
                        <div className='pbWrapLeft'> <span>3 { t('lang.common.stars') }</span> </div>
                        <div className='pbWrapMid'>
                          <ProgressBar completed={(rating3 * 3)/100} bgColor="#6fdfda" height="16px" isLabelVisible={false} baseBgColor="#ebebeb" labelColor="#e80909" animateOnRender />
                        </div>
                        <div className='pbWrapRight'>{rating3}</div>
                      </div>
                      <div className='pbWrap d-flex align-items-center'>
                        <div className='pbWrapLeft'> <span>2 { t('lang.common.stars') }</span> </div>
                        <div className='pbWrapMid'>
                          <ProgressBar completed={(rating2 * 2)/100} bgColor="#6fdfda" height="16px" isLabelVisible={false} baseBgColor="#ebebeb" labelColor="#e80909" animateOnRender />
                        </div>
                        <div className='pbWrapRight'>{rating2}</div>
                      </div>
                      <div className='pbWrap d-flex align-items-center'>
                        <div className='pbWrapLeft'> <span>1 { t('lang.common.star') }</span> </div>
                        <div className='pbWrapMid'>
                          <ProgressBar completed={(rating1 * 1)/100} bgColor="#6fdfda" height="16px" isLabelVisible={false} baseBgColor="#ebebeb" labelColor="#e80909" animateOnRender />
                        </div>
                        <div className='pbWrapRight'>{rating1}</div>
                      </div>
                    </div>
                  </div>
                  {
                    this.state.reviews != '' &&

                    <div className='reviews'>
                    <h3>{ t('lang.common.reviews') }</h3>

                    {
                      this.state.reviews.map((val, indx) =>{
                        let text = '';
                        let letter = '';
                        let userName = '';
                        var reviewHtml = '';

                        if(val.review_by != null){
                          text = val.review_by.name;
                          letter = text.charAt(0);
                          userName = val.review_by.name;
                          reviewHtml+=`<div className='reviewBox d-flex' key={${indx}}>
                          <div className='reviewBoxIcon'>
                              <span>${letter}</span>
                          </div>
                          <div className='reviewBoxDesc'>
                              <div className='d-flex align-items-center starArea w-100'>
                                  <span className='rtng'>${userName}</span>
                                  <ul className='d-flex'>
                                      <li><FontAwesomeIcon icon={faStar} /></li>
                                      <li><FontAwesomeIcon icon={faStar} /></li>
                                      <li><FontAwesomeIcon icon={faStar} /></li>
                                      <li><FontAwesomeIcon icon={faStar} /></li>
                                      <li><FontAwesomeIcon icon={faStar} /></li>
                                  </ul>
                              </div>
                              <div className='dateMonth w-100'>${moment(val.created_at,"YYYYMMDD").fromNow()}</div>
                              <div className='w-100 reviewBoxDescDtls'>
                                  <p>${val.review}</p>
                              </div>
                          </div>
                      </div>`

                        }


                          return(parse(reviewHtml))
                      })
                  }

                    </div>
                  }

                    {

                      this.state.purchaseCourse != '' && this.state.purchaseCourse != null ?
                      <div className="news_reply_form full">

                            {
                              this.state.reviewGiven == 0 ? <><h3 className="m-b-10">{ t('lang.common.rate_this_course') }</h3><ReviewForm type="1" item={this.state.courseData.id} /></>   : ''
                            }

                      </div> : ''
                    }

                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                <div className='courseRight'>
                  {
                    this.state.purchased != 1 &&
                    <div className='cartBuy w-100'>
                      <div className='cartBuyTop text-center'>

                        <p>{ this.state.coureDiscountPrice != 0 ? this.state.currencySymbol+''+Math.round(this.state.coureDiscountPrice) :  t('lang.common.free') } { this.state.courePrice != 0 && this.state.courseDiscountPercent != 0 ? <span className='line-through'>{this.state.currencySymbol}{Math.round(this.state.courePrice)}</span> : '' } { this.state.courePrice != 0 && this.state.courseDiscountPercent != 0 ? <span>{this.state.courseDiscountPercent}% { t('lang.common.off') }</span> : '' }</p>
                        <ul className='d-flex justify-content-center'>
                          <li><button type='button' onClick={ (e) => {this.handleAddToCart(this.state.courseData.id, 'courses', 'courses'+this.state.courseData.id, 1)}} className='addCartBtn'>{ t('lang.common.add_to_cart') }</button></li>

                        </ul>
                        {/* <small>30 Day Money Back Guarantee</small> */}
                      </div>
                    </div>
                  }


                  {
                    this.state.courseIncludes.length > 0 ?
                    <>
                    <div className='courseIncludes w-100'>
                    <h5>{ t('lang.common.this_course_includes') }:</h5>
                    <ul>
                      {
                        this.state.courseIncludes.map((val,ind) => {
                          return(<li><span><img src={process.env.REACT_APP_UPLOAD_URL+`icons/${val.icons.icon}`} alt={val.details} /></span>{ languages == 'ar' ? val.details_ar : val.details }</li>)
                        })
                      }
                    </ul>
                    </div>
                    </>

                   : ''
                  }
                  {/* {
                    this.state.purchased != 1 &&  */}
                  <div className='shereGift w-100'>
                  <Modal size="lg" show={this.state.shareModel} onHide={this.handleCloseShareModal} backdrop="static" keyboard={false}>
                      <Modal.Header closeButton>
                        <Modal.Title>{ t('lang.common.share_course') }</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                      <div className='downLoadDtnArea text-center'>
                        <div className='add-copybtn'>
                          <input type="text" name="copy_link" className="form-control add-copybtn-input" defaultValue={`${window.location.href}`} placeholder="Copy link"  autoComplete="off" />
                          <div className="copy-btn-wrap">
                            <button className='addCartBtn'
                              onClick={() =>  navigator.clipboard.writeText(window.location.href)}
                            >
                              { t('lang.common.copy') }
                            </button>
                          </div>
                        </div>
                        <ul className='d-flex justify-content-center d-grid gap-3'>
                          <li className='p-2 bg-light border'>
                          <FacebookShareButton
                              url={window.location.href}
                              quote={this.state.courseData.title}
                              hashtag={"#zeryabmusicacademy"}
                              description={this.state.courseData.title}
                              className="Demo__some-network__share-button"
                          >
                            <FacebookIcon size={32} round />
                          </FacebookShareButton>

                          </li>
                          <li className='p-2 bg-light border'>
                          <TwitterShareButton
                            title={this.state.courseData.title}
                            url={window.location.href}
                            hashtags={["Music", "Courses"]}
                          >
                            <TwitterIcon size={32} round />

                          </TwitterShareButton>
                          </li>
                          <li className='p-2 bg-light border'>
                          <EmailShareButton
                            subject={this.state.courseData.title}
                            body={`hey there, pls share my link ${window.location.href}`}
                            separator={" "}
                          >
                            <EmailIcon size={32} round />
                          </EmailShareButton>
                          </li>
                        </ul>

                      </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleCloseShareModal}>
                          { t('lang.common.close') }
                        </Button>
                        {/* <Button variant="primary" onClick={this.handleClose}>
                          Save Changes
                        </Button> */}
                      </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.applyCoupon} onHide={this.handleCloseApplyCouponModal} backdrop="static" keyboard={false} centered>
                    <Modal.Header closeButton>
                      <Modal.Title>{t('lang.common.apply_coupon')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div className='downLoadDtnArea text-center'>
                    <input type="text" name="coupon_code" className="form-control add-copybtn-input"  placeholder={t('lang.common.apply_coupon')}  autoComplete="off" />

                    </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={this.handleCloseApplyCouponModal}>
                        {t('lang.common.close')}
                      </Button>
                      <Button variant="primary" onClick={this.checkCouponCode}>
                        {t('lang.common.apply_coupon')}
                      </Button>
                    </Modal.Footer>
                  </Modal>
                    <ul className='d-flex align-items-center'>
                      <li className='me-2'>
                      {/* onClick={ () => this.shareModalShow()} */}
                        <Link to='#'><span><img src="../images/svg/share.svg" alt="" /></span> { t('lang.common.share') }</Link>
                      </li>
                     {/*  <li>
                        <Link to='#'><span><img src="../images/svg/course.svg" alt="" /></span> { t('lang.common.gift_course') }</Link>
                      </li>
                      <li>

                        <Link to='#' onClick={ () => this.applyCouponModalShow()}><span><img src="../images/svg/coupon.svg" alt="" /></span> { t('lang.common.apply_coupon') }</Link>
                      </li>*/}
                      <li className='me-2'>
                          <FacebookShareButton
                              url={window.location.href}
                              quote={this.state.courseData.title}
                              hashtag={"#zeryabmusicacademy"}
                              description={this.state.courseData.title}
                              className="Demo__some-network__share-button"
                          >
                            <FacebookIcon size={32} round />
                          </FacebookShareButton>

                          </li>
                          <li className='me-2'>
                          <TwitterShareButton
                            title={this.state.courseData.title}
                            url={window.location.href}
                            hashtags={["Music", "Courses"]}
                          >
                            <TwitterIcon size={32} round />

                          </TwitterShareButton>
                          </li>
                          <li>
                          <EmailShareButton
                            subject={this.state.courseData.title}
                            body={`hey there, pls share my link ${window.location.href}`}
                            separator={" "}
                          >
                            <EmailIcon size={32} round />
                          </EmailShareButton>
                          </li>
                    </ul>
                  </div>
                  {/* } */}

                  {/* <div className='tranding w-100'>
                    <h5>Training 5 or more people?</h5>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <div className='w-100 text-center mt-3'>
                      <Link to='/' className='tryBusiness'>Try The Business</Link>
                    </div>
                  </div> */}

                </div>
              </div>
            </div>
          </div>
        </section>
          </>

          : <h3 class="text-center">{ t('lang.common.courseNotFound') }</h3>
        }






        <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">{ t('lang.common.materials') }</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <div className='downLoadDtnArea text-center'>
                  <ul className='row row-cols-3 g-4'>
                    <li className='col'>
                      <Link to={'/'} target="_blank" download>Material 1<span><FontAwesomeIcon icon={faDownload} /> { t('lang.common.download') }</span></Link>
                    </li>
                    <li className='col'>
                      <Link to={'/'} target="_blank" download>Material 2<span><FontAwesomeIcon icon={faDownload} /> { t('lang.common.download') }</span></Link>
                    </li>
                    <li className='col'>
                      <Link to={'/'} target="_blank" download>Material 3<span><FontAwesomeIcon icon={faDownload} /> { t('lang.common.download') }</span></Link>
                    </li>
                    <li className='col'>
                      <Link to={'/'} target="_blank" download>Material 4<span><FontAwesomeIcon icon={faDownload} /> { t('lang.common.download') }</span></Link>
                    </li>
                    <li className='col'>
                      <Link to={'/'} target="_blank" download>Material 5<span><FontAwesomeIcon icon={faDownload} /> { t('lang.common.download') }</span></Link>
                    </li>
                    <li className='col'>
                      <Link to={'/'} target="_blank" download>Material 6<span><FontAwesomeIcon icon={faDownload} /> { t('lang.common.download') }</span></Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}
//export default withParams(CourseDetail);
export default withParams(withTranslation()(CourseDetail));