// https://www.npmjs.com/package/react-infinite-scroll-component
import React, { Component } from 'react'
import Header from '../includes/Header'
import Footer from '../includes/Footer'
import { Loader } from './Loader';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faHeart } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import { withTranslation, WithTranslation } from 'react-i18next';

let languages = localStorage.getItem('language');

class TrendingCourses extends Component {

    constructor(props) {
        super(props)
        this.state = {
          courseData: {
          title: '',
          },
          loading: false,
          totalItem : 0,
          teachers: [],
          categoryList: [],
          topRatedCourses: [],
          featuredCourse: [],
          allCourse: [],
          currencySymbol: '$',
        }

    }
    formatDate(date) {
        var monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        var d = new Date(date),
            month = '' + monthShortNames[d.getMonth()], //(d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join(' ');
    }

    handleAddToCart = (id,type,uniqueid,total) =>{
        let alreadyAdded = '';

        if(localStorage.getItem('language') == 'ar'){
            alreadyAdded = 'تمت إضافة عربة الانتقال بالفعل';
        }else{
            alreadyAdded = 'Already added goto cart';
        }
        let getProducts = [];
        let selectedProduct = localStorage.getItem('products');
        let check = 1;
        let data = {
          id:id,
          type:type,
          uniqueid:uniqueid,
          total:total
        };

        if(selectedProduct != '' && selectedProduct != null){
            selectedProduct = JSON.parse(selectedProduct);
            selectedProduct.map((v,i) => {
                if(v.uniqueid == uniqueid && v.type == type){
                    toast.success(alreadyAdded, {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    check = 0;

                }
            })
        }

        if(localStorage.getItem('products') !== '' && localStorage.getItem('products')!==null){
            getProducts = JSON.parse(localStorage.getItem('products'));

            getProducts.push(data);
            localStorage.setItem('products', JSON.stringify(getProducts));

            this.setState({ totalItem  : JSON.parse(localStorage.getItem('products')).length});

        }else{

          localStorage.setItem('products', JSON.stringify([data]));
          this.setState({ totalItem  : JSON.parse(localStorage.getItem('products')).length});
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        var products = localStorage.getItem('products');

        if(localStorage.getItem('currency') == 2){
            this.setState({ currencySymbol: '£' });
        }

        if(products !== '' && products !== null){
            this.setState({ totalItem  : JSON.parse(localStorage.getItem('products')).length});
        }

          try {
            await this.setState({loading:true});

            axios.get(process.env.REACT_APP_API_URL+`courses-listing`)
              .then(async (res) => {
                 if(res.status === 200){
                    //console.log("res.data.data.teacher =",res.data.data.teachers);
                    this.setState({ teachers: res.data.data.teachers });
                    this.setState({ topRatedCourses: res.data.data.trendingCourses });
                    this.setState({ featuredCourse: res.data.data.featuredCourses });
                    this.setState({ allCourse: res.data.data.allCourses });
                    this.setState({ loading: false });

                }else{
                  return console.log("Error !");
                }

              }).catch(error =>
                console.log("Error !")
              );
          } catch (error) {
              //console.error('error');
          }

          try {
            axios.get(process.env.REACT_APP_API_URL+"courses-category")
              .then(async (res) => {
                 if(res.status === 200){
                    this.setState({ categoryList: res.data.data });
                }else{
                  return console.log("Error !");
                }

              }).catch(error =>
                console.log("Error !")
              );
          } catch (error) {
            console.error('error');
          }
      }

    render() {
        const { t, i18n } = this.props;
        return (
            <>
            {
                this.state.loading === true ? (<Loader />) : ''
            }
                <ToastContainer autoClose={2000} />
                <Header itemCount={this.state.totalItem} />
                <section className='coursesBlock'>
                    <div className='container-fluid ph-40'>
                        <div className='row g-2 g-md-3 g-lg-4'>
                            <div className='col-12'>
                                <div className='sectionTitle d-flex align-items-center justify-content-between'>
                                    <h2 className='p-0'>{ t('lang.common.popular_music_courses') }</h2>
                                    {/* <Link to="/" className='viewAll'>View All</Link> */}
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='courseMenu'>
                                    <ul className='d-flex'>
                                        <li><Link to='/courses' >{t("lang.common.mostPopular")}</Link></li>
                                        <li><Link to='/courses/new-courses'>{t("lang.common.new")}</Link></li>
                                        <li><Link to='/courses/trending-courses' className='active'>{t("lang.common.trending")}</Link></li>
                                    </ul>
                                </div>
                            </div>

                            {
                                this.state.topRatedCourses.map((val,indx) => {

                                    let price = val.price;
                                    let dPrice = val.discount_price;
                                    let currencies = parseInt(localStorage.getItem('currency'));

                                    if(val.currency == currencies){
                                        price = price;
                                        dPrice = dPrice;
                                    }else if(val.currency != currencies){
                                        price = price * Number(localStorage.getItem('currencyRate'));
                                    }

                                    let discountedPrice = price - (price * dPrice / 100);


                                    return(<div className='col-lg-3 col-md-3 col-sm-6 col-12' key={indx}>
                                    <div className="projectInner w-100">
                                        <div className="projectInnerTop relative w-100">
                                            <Link to="/"><img src={process.env.REACT_APP_UPLOAD_URL+'courses/images/'+val.photo} alt={val.title} /></Link>
                                            <span className="bestSeller bestSellerFloting">{ t('lang.common.bset_seller') }</span>
                                            { dPrice != 0 ? <span className="discountPercent">{dPrice}% { t('lang.common.off') }</span> : '' }
                                        </div>
                                        <div className="projectInnerMid w-100">
                                            <h4><Link to={`/courses/${val.slug}`}>{ languages == 'ar' ? val.title_ar : val.title }</Link></h4>
                                            {
                                                languages == 'ar' ?

                                                val.short_description_ar != null ?
                                                <p>{`${val.short_description_ar.substring(0, process.env.REACT_APP_CHARACTER_MAX_LENGTH)}...`}</p>
                                                : ''
                                                : <p>{`${val.short_description.substring(0, process.env.REACT_APP_CHARACTER_MAX_LENGTH)}...`}</p>
                                            }
                                            <div className='d-flex align-items-center starArea w-100'>
                                            <ul className='d-flex'>

                                                {
                                                    val.rating != '' &&
                                                    (() => {
                                                        let td = [];
                                                        for (let i = 1; i <= val.rating; i++) {
                                                        td.push(<li><FontAwesomeIcon icon={faStar} /></li>);
                                                        }
                                                        return td;
                                                    })()
                                                }

                                            </ul>
                                            {
                                                val.totalRating > 0 && <span className='rvs'>({val.totalRating})</span>
                                            }
                                            </div>

                                        </div>
                                        <div className="price-sec w-100">
                                            <div className="row justify-content-between align-items-center">
                                                <div className="col-auto">
                                                    <div className="price-sec-text">
                                                    <p>{
                                                        discountedPrice != 0 ? this.state.currencySymbol+''+Math.round(discountedPrice) : t('lang.common.free')
                                                        } <span className="line-through">{ price != 0 && dPrice != 0 ? this.state.currencySymbol+''+Math.round(price) : ''}
                                                        </span>
                                                    </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='popHoverBox'>
                                            <div className='phTop w-100 d-flex'>
                                                <div className='phtLeft'>
                                                    <h4><Link to={`/courses/${val.slug}`} className="black">{ languages == 'ar' ? val.title_ar : val.title }</Link></h4>
                                                </div>
                                                <div className='phtRight'>
                                                    {/* <span><Link to='/'><FontAwesomeIcon icon={faHeart} /></Link></span> */}
                                                </div>
                                            </div>
                                            <div className='phTop w-100 d-flex'>
                                                <div className='phtLeft'>
                                                    <h5>{ t('lang.common.updated') } { this.formatDate(val.created_at) }</h5>
                                                    {/* <p>All Levels . <span>Subtitles</span></p> */}
                                                </div>
                                                <div className='phtRight'>
                                                    <div className='phtRightInner'>
                                                        <span>{ t('lang.common.bset_seller') }</span>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='phDescription w-100'>
                                                {
                                                    languages == 'ar' ?

                                                    val.short_description_ar != null ?
                                                    <p>{`${val.short_description_ar.substring(0, process.env.REACT_APP_CHARACTER_MAX_LENGTH)}...`}</p>
                                                    : ''
                                                    : <p>{`${val.short_description.substring(0, process.env.REACT_APP_CHARACTER_MAX_LENGTH)}...`}</p>
                                                }
                                            </div>
                                            {
                                                <div className='phList w-100'>
                                                    <ul>
                                                        {
                                                        val.purposes.map((pV,pI) => {
                                                            return(<li key={pI}>{ languages == 'ar' ? pV.details_ar : pV.details }</li>)
                                                        })
                                                        }

                                                    </ul>
                                                </div>
                                            }

                                            <div className='addCart w-100 text-center'>
                                                <button type='button' onClick={ (e) => {this.handleAddToCart(val.id, 'courses', 'courses'+val.id, 1)}} className={`addCartBtn me-2`}>{ t('lang.common.add_to_cart') }</button>
                                                <Link to={`/courses/${val.slug}`} className="addCartBtn">{ t('lang.common.view') }</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                                })
                            }

                        </div>
                    </div>
                </section>

                <section className='featuredCourses'>
                    <div className='container-fluid ph-40'>
                        <div className='row g-2 g-md-3 g-lg-4'>
                            <div className='col-12'>
                                <div className='sectionTitle d-flex align-items-center justify-content-between mb-4'>
                                    <h2 className='p-0'>{ t('lang.common.featuredCourses') }</h2>
                                    {/* <Link to="/" className='viewAll'>View All</Link> */}
                                </div>
                            </div>
                            {
                                this.state.featuredCourse.map((val,ind) => {

                                    let price = val.price;
                                    let dPrice = val.discount_price;

                                    let fCurrencies = parseInt(localStorage.getItem('currency'));

                                    if(val.currency == fCurrencies){
                                        price = price;
                                        dPrice = dPrice;
                                    }else if(val.currency != fCurrencies){
                                        price = price * Number(localStorage.getItem('currencyRate'));
                                    }

                                    let discountedPrice = price - (price * dPrice / 100);

                                    //let discountedPrices = val.price - (val.price * val.discount_price / 100);
                                    return(<div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                    <div className='fcbox d-flex flex-wrap w-100'>
                                        <div className='fcboxLeft relative'>
                                            <span className='imgContainer'>
                                                <img src={process.env.REACT_APP_UPLOAD_URL+'courses/images/'+val.photo} alt={val.title} />
                                            </span>
                                            <div className="bestSeller"><span>{ t('lang.common.bset_seller') }</span></div>
                                            { dPrice != 0 ? <span className="discountPercent">{dPrice}% { t('lang.common.off') }</span> : '' }
                                        </div>
                                        <div className='fcboxRight'>
                                            <div className='fcboxRightInner'>
                                                { languages == 'ar' ?
                                                    <h4><Link to={`/courses/${val.slug}`} className="black">{`${val.title_ar.substring(0, process.env.REACT_APP_CHARACTER_MAX_HEADING_LENGTH)}...`}</Link></h4>
                                                :
                                                    <h4><Link to={`/courses/${val.slug}`} className="black">{`${val.title.substring(0, process.env.REACT_APP_CHARACTER_MAX_HEADING_LENGTH)}...`}</Link></h4>
                                                }
                                                {
                                                    languages == 'ar' ?

                                                    val.short_description_ar != null ?
                                                    <p>{`${val.short_description_ar.substring(0, process.env.REACT_APP_CHARACTER_MAX_LENGTH)}...`}</p>
                                                    : ''
                                                    : <p>{`${val.short_description.substring(0, process.env.REACT_APP_CHARACTER_MAX_LENGTH)}...`}</p>
                                                }
                                                <h5>{ t('lang.common.by') } {val.teacher.first_name+' '+val.teacher.last_name}</h5>
                                                <h6>{ t('lang.common.updated') } { this.formatDate(val.created_at) }</h6>
                                                <div className='w-100 d-flex align-items-center justify-content-between'>
                                                    <div className='d-flex align-items-center starArea'>
                                                        <ul className='d-flex'>

                                                                {
                                                                    val.rating != '' &&
                                                                    (() => {
                                                                        let td = [];
                                                                        for (let i = 1; i <= val.rating; i++) {
                                                                        td.push(<li><FontAwesomeIcon icon={faStar} /></li>);
                                                                        }
                                                                        return td;
                                                                    })()
                                                                }

                                                        </ul>
                                                        {
                                                            val.totalRating > 0 && <span className='rvs'>({val.totalRating})</span>
                                                        }
                                                    </div>
                                                    <div className='newOldRate'>
                                                    <p>{ discountedPrice != 0 ? this.state.currencySymbol+''+Math.round(discountedPrice) : t('lang.common.free') } <span>{ price != 0 && dPrice != 0 ? this.state.currencySymbol+''+Math.round(price) : ''}</span>
                                                    </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                                })
                            }

                        </div>
                    </div>
                </section>
                <section className='popularTopic'>
                    <div className='container-fluid ph-40'>
                        <div className='row g-2 g-md-3 g-lg-4'>
                            <div className='col-12'>
                                <div className='sectionTitle mb-4'>
                                    <h2 className='p-0'>{ t('lang.common.popularTopic') }</h2>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='popularTopicList'>
                                    <ul className='row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-6 g-3'>
                                    {
                                        this.state.categoryList.map((val,index) => {
                                            return(<li className='col' key={index}><Link to={`/courses/category/`+val.slug} className={index === 0 ? `active`: ''}>{languages == 'en' ? val.title : val.title_ar}</Link></li>)
                                        })
                                    }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='coursesBlock'>
                    <div className='container-fluid ph-40'>
                        <div className='row g-2 g-md-3 g-lg-4'>
                            <div className='col-12'>
                                <div className='sectionTitle d-flex align-items-center justify-content-between'>
                                    <h2 className='p-0'>{ t('lang.common.popularTrainer') }</h2>
                                    {/* <Link to="/" className='viewAll'>View All</Link> */}
                                </div>
                            </div>

                            {
                                this.state.teachers.map((val,ind) => {
                                    return(<div className='col-lg-3 col-md-3 col-sm-6 col-12' key={ind}>
                                    <div className="projectInner w-100">
                                        <div className="projectInnerTop w-100">
                                        {
                                            val.photo != null  ? <Link to={`/tutor/${val.teachers.teacher_slug}`}><img src={process.env.REACT_APP_UPLOAD_URL+`teacher/photos/${val.photo}`} alt={val.name} /></Link> : <Link to="/trending-courses"><img src="../images/avatar.jpg" alt="" /></Link>
                                        }

                                        </div>
                                        <div className="projectInnerMid w-100">
                                            <h4><Link to={`/tutor/${val.teachers.teacher_slug}`}>{val.name}</Link></h4>
                                            <ul className='catExp'>
                                                {
                                                    val.course.length > 0 ? <li>{ t('lang.menu.menu_11') } : <span>{ val.course.length }</span></li> : ''
                                                }

                                                {val.teachers.experiance != null ? <li>{ t('lang.common.experience') } : <span>{ val.teachers.experiance } { val.teachers.experiance > 1 && val.teachers.experiance != null ? t('lang.common.yrs')  : t('lang.common.year') }</span></li> : ''}

                                            </ul>
                                            <div className='d-flex align-items-center starArea w-100'>
                                                <ul className='d-flex'>
                                                    <li>
                                                        {
                                                            val.rating != '' &&
                                                            (() => {
                                                                let td = [];
                                                                for (let i = 1; i <= val.rating; i++) {
                                                                td.push(<FontAwesomeIcon icon={faStar} />);
                                                                }
                                                                return td;
                                                            })()
                                                        }
                                                    </li>
                                                </ul>
                                                {
                                                    val.totalRating > 0 && <span className='rvs'>({val.totalRating})</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="coursesBtnArea w-100">
                                            <Link to={`/tutor/${val.teachers.teacher_slug}`} className='coursesBtn'>{ t('lang.pages.home.sec_6') }</Link>
                                        </div>
                                    </div>
                                </div>)
                                })
                            }
                        </div>
                    </div>
                </section>

                <section className='featuredCourses allMusiccourses'>
                    <div className='container-fluid ph-40'>
                        <div className='row g-2 g-md-3 g-lg-4'>
                            <div className='col-12'>
                                <div className='sectionTitle d-flex align-items-center justify-content-between mb-4'>
                                    <h2 className='p-0'>{ t('lang.common.allMusicCourses') }</h2>
                                    {/* <div className='relative shortBy'>
                                            <span className='shortBySpan'>Short by</span>
                                            <select className="form-select" name="" id="">
                                                <option selected value="DEFAULT">Highest Rated</option>
                                                <option value="2">Most Popular</option>
                                                <option value="1">Newest</option>
                                            </select>
                                    </div> */}
                                </div>
                            </div>

                            {
                                this.state.allCourse.map((val,indx) => {

                                    let price = val.price;
                                    let dPrice = val.discount_price;

                                    let allCurrencies = parseInt(localStorage.getItem('currency'));

                                    if(val.currency == allCurrencies){
                                        price = price;
                                        dPrice = dPrice;
                                    }else if(val.currency != allCurrencies){
                                        price = price * Number(localStorage.getItem('currencyRate'));
                                    }

                                    let discountedPrice = price - (price * dPrice / 100);

                                    //let discountedPrices = val.price - (val.price * val.discount_price / 100);
                                    return(<div className='col-lg-3 col-md-3 col-sm-6 col-12' key={indx}>
                                    <div className="projectInner w-100">
                                        <div className="projectInnerTop relative w-100">
                                            <Link to="/"><img src={process.env.REACT_APP_UPLOAD_URL+'courses/images/'+val.photo} alt={val.title} /></Link>
                                            <span className="bestSeller bestSellerFloting">{ t('lang.common.bset_seller') }</span>
                                            { dPrice != 0 ? <span className="discountPercent">{dPrice}% { t('lang.common.off') }</span> : '' }
                                        </div>
                                        <div className="projectInnerMid w-100">
                                            <h4><Link to={`/courses/${val.slug}`}>{ languages == 'ar' ? val.title_ar : val.title }</Link></h4>
                                            {
                                                languages == 'ar' ?

                                                val.short_description_ar != null ?
                                                <p>{`${val.short_description_ar.substring(0, process.env.REACT_APP_CHARACTER_MAX_LENGTH)}...`}</p>
                                                : ''
                                                : <p>{`${val.short_description.substring(0, process.env.REACT_APP_CHARACTER_MAX_LENGTH)}...`}</p>
                                            }
                                            <div className='d-flex align-items-center starArea w-100'>
                                                <ul className='d-flex'>

                                                        {
                                                            val.rating != '' &&
                                                            (() => {
                                                                let td = [];
                                                                for (let i = 1; i <= val.rating; i++) {
                                                                td.push(<li><FontAwesomeIcon icon={faStar} /></li>);
                                                                }
                                                                return td;
                                                            })()
                                                        }

                                                </ul>
                                                {
                                                    val.totalRating > 0 && <span className='rvs'>({val.totalRating})</span>
                                                }
                                            </div>

                                        </div>
                                        <div className="price-sec w-100">
                                            <div className="row justify-content-between align-items-center">
                                                <div className="col-auto">
                                                    <div className="price-sec-text">
                                                    <p>
                                                        {
                                                            discountedPrice != 0 ? this.state.currencySymbol+''+Math.round(discountedPrice) : t('lang.common.free')
                                                        } <span className="line-through">{ price != 0 && dPrice != 0 ? this.state.currencySymbol+''+Math.round(price) : ''}
                                                        </span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='popHoverBox'>
                                            <div className='phTop w-100 d-flex'>
                                                <div className='phtLeft'>
                                                    <h4><Link to={`/courses/${val.slug}`}>{ languages == 'ar' ? val.title_ar : val.title }</Link></h4>
                                                </div>
                                                <div className='phtRight'>
                                                    {/* <span><Link to='/'><FontAwesomeIcon icon={faHeart} /></Link></span> */}
                                                </div>
                                            </div>
                                            <div className='phTop w-100 d-flex'>
                                                <div className='phtLeft'>
                                                    <h5>{ t('lang.common.updated') } { this.formatDate(val.created_at) }</h5>
                                                    {/* <p>All Levels . <span>Subtitles</span></p> */}
                                                </div>
                                                <div className='phtRight'>
                                                    <div className='phtRightInner'>
                                                        <span>{ t('lang.common.bset_seller') }</span>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='phDescription w-100'>
                                                {
                                                    languages == 'ar' ?

                                                    val.short_description_ar != null ?
                                                    <p>{`${val.short_description_ar.substring(0, process.env.REACT_APP_CHARACTER_MAX_LENGTH)}...`}</p>
                                                    : ''
                                                    : <p>{`${val.short_description.substring(0, process.env.REACT_APP_CHARACTER_MAX_LENGTH)}...`}</p>
                                                }
                                            </div>
                                            {
                                                <div className='phList w-100'>
                                                    <ul>
                                                        {
                                                        val.purposes.map((pV,pI) => {
                                                            return(<li key={pI}>{ languages == 'ar' ? pV.details_ar : pV.details }</li>)
                                                        })
                                                        }

                                                    </ul>
                                                </div>
                                            }

                                            <div className='addCart w-100 text-center'>
                                                <button type='button' onClick={ (e) => {this.handleAddToCart(val.id, 'courses', 'courses'+val.id, 1)}} className={`addCartBtn me-2`}>{ t('lang.common.add_to_cart') }</button>
                                                <Link to={`/courses/${val.slug}`} className="addCartBtn">{ t('lang.common.view') }</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                                })
                            }
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}
export default withTranslation()(TrendingCourses);