import React, { useEffect, useState } from 'react'
import Header from '../includes/Header';
import Footer from '../includes/Footer';
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const NotFoundPage = () => {
  const [totalItem, setTotalItem]   = useState(0);
  const [t, i18n] = useTranslation('common');

  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('language'));
    var products = localStorage.getItem('products');
    if(products !== '' && products !==null){
        setTotalItem(JSON.parse(localStorage.getItem('products')).length);
    }
  },[]);

  return (
    <>
    <Header itemCount={totalItem} />
    <section className=" thankYouSection">
          <div className="container">
              <div className="text-center text-style-design thank-you">
                <h1>404</h1>
                <Link to="/" className='continueBtn'>{t('lang.common.homepage')}</Link> 
            </div>
        </div>
    </section>
    <Footer />
    </>
  )
}

export default NotFoundPage