import React, { useEffect } from 'react'
import Header from '../includes/Header'
import Footer from '../includes/Footer'
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const Wishlist = () => {
    const [t, i18n] = useTranslation('common');

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('language'));
    }, []);


    return (
        <>
            <Header/>
            <section className='wishList'>
                <div className='container'>
                    <div className='row g-2 g-md-3 g-lg-4'>
                        <div className='col-12'>
                            <div>
                                <table className="shop-table wishlist-table">
                                    <thead>
                                        <tr>
                                            <th className="product-name">
                                                <span>Product</span>
                                            </th>
                                            <th />
                                            <th className="product-price">
                                                <span>Price</span>
                                            </th>
                                            <th className="product-stock-status">
                                                <span>Stock Status</span>
                                            </th>
                                            <th className="wishlist-action">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="product-thumbnail">
                                                <div className="p-relative">
                                                <Link to='/'>
                                                        <figure>
                                                            <img src="../images/box/12.jpg" alt="" />
                                                        </figure>
                                                    </Link>
                                                    <button type="submit" className="btn btn-close">
                                                        <i className="fas fa-times" />
                                                    </button>
                                                </div>
                                            </td>
                                            <td className="product-name">
                                                <Link to='/'>Blue Sky Trunk</Link>
                                            </td>
                                            <td className="product-price">
                                                <ins className="new-price">$85.00</ins>
                                            </td>
                                            <td className="product-stock-status">
                                                <span className="wishlist-in-stock">In Stock</span>
                                            </td>
                                            <td className="wishlist-action">
                                                <div className="d-lg-flex">
                                                <Link to='/' className="btn btn-quickview btn-outline btn-default btn-rounded btn-sm mb-2 mb-lg-0">
                                                        Quick View
                                                    </Link>
                                                    <Link to='/' className="btn btn-dark btn-rounded btn-sm ml-lg-2 btn-cart" >
                                                        Add to cart
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="product-thumbnail">
                                                <div className="p-relative">
                                                <Link to='/'>
                                                        <figure>
                                                            <img src="../images/box/12.jpg" alt="" />
                                                        </figure>
                                                    </Link>
                                                    <button type="submit" className="btn btn-close">
                                                        <i className="fas fa-times" />
                                                    </button>
                                                </div>
                                            </td>
                                            <td className="product-name">
                                            <Link to='/'>Handmade Ring</Link>
                                            </td>
                                            <td className="product-price">
                                                <ins className="new-price">$5.00</ins>
                                            </td>
                                            <td className="product-stock-status">
                                                <span className="wishlist-in-stock">In Stock</span>
                                            </td>
                                            <td className="wishlist-action">
                                                <div className="d-lg-flex">
                                                <Link to='/' className="btn btn-quickview btn-outline btn-default btn-rounded btn-sm mb-2 mb-lg-0">
                                                        Quick View
                                                    </Link>
                                                    <Link to='/' className="btn btn-dark btn-rounded btn-sm ml-lg-2 btn-cart">
                                                        Add to cart
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="product-thumbnail">
                                                <div className="p-relative">
                                                <Link to='/'>
                                                        <figure>
                                                            <img src="../images/box/12.jpg" alt="" />
                                                        </figure>
                                                    </Link>
                                                    <button type="submit" className="btn btn-close">
                                                        <i className="fas fa-times" />
                                                    </button>
                                                </div>
                                            </td>
                                            <td className="product-name">
                                            <Link to='/'>Pencil Case</Link>
                                            </td>
                                            <td className="product-price">
                                                <ins className="new-price">$54.00</ins>
                                            </td>
                                            <td className="product-stock-status">
                                                <span className="wishlist-in-stock">In Stock</span>
                                            </td>
                                            <td className="wishlist-action">
                                                <div className="d-lg-flex">
                                                    <Link to='/' className="btn btn-quickview btn-outline btn-default btn-rounded btn-sm mb-2 mb-lg-0">
                                                        Quick View
                                                    </Link>
                                                    <Link to='/' className="btn btn-dark btn-rounded btn-sm ml-lg-2 btn-cart">
                                                        Add to cart
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}

export default Wishlist