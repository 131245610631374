import React, { useEffect, useState } from 'react'
import Header from '../includes/Header';
import Footer from '../includes/Footer';
import axios from 'axios';
import parse from 'html-react-parser';
import { Link, useParams } from "react-router-dom";
import { Loader } from './Loader';
import {useTranslation} from "react-i18next";
import i18next from "i18next";

export const BlogDetails = () => {

    const [t, i18n] = useTranslation('common');
    const params = useParams();
    let blogSlug = localStorage.getItem('blogSlug');

    const [blog, setBlog]                 = useState([]);
    const [recentBlog, setRecentBlog]     = useState([]);
    const [blogPostDate, setBlogPostDate] = useState('');
    const [loading, setLoading]           = useState(false);
    const [totalItem, setTotalItem]       = useState(0);

    let languages = localStorage.getItem('language');
    const date = new Date();

    function formatDate(date) {
      var monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

      var d = new Date(date),
          month = '' + monthShortNames[d.getMonth()], //(d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
  
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
  
      return [day, month, year].join(' ');
  }

    /* if(blogSlug !== params.slug){
        try {
            
            axios.get(process.env.REACT_APP_API_URL+`blog/${params.slug}`)
              .then(async (res) => {
                if(res.status === 200){
         
                    setBlog(res.data.data.blog);
                    setRecentBlog(res.data.data.recentBlog);
                    localStorage.setItem('blogSlug', res.data.data.blog.slug);
                    setLoading(false);
                    setBlogPostDate(res.data.data.blog.created_at);
                }else{
                  return console.log("Error !");
                }
                
              }).catch(error => 
                console.log("Error !")
              );
          } catch (error) {
            //console.error('error');
          }
    } */

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('language'));
        
        window.scrollTo(0,0);
        setLoading(true);
        var products = localStorage.getItem('products');
        if(products !== '' && products !==null){
            setTotalItem(JSON.parse(localStorage.getItem('products')).length);
        }
        //setTotalItem(JSON.parse(localStorage.getItem('products')).length);
        
            try {
                axios.get(process.env.REACT_APP_API_URL+`blog/${params.slug}`)
                  .then(async (res) => {
                      if(res.status === 200){
                        setBlog(res.data.data.blog);
                        setRecentBlog(res.data.data.recentBlog);
                        localStorage.setItem('blogSlug', res.data.data.blog.slug);
                        setLoading(false);
                        setBlogPostDate(date);
                    }else{
                      return console.log("Error !");
                    }
                    
                  }).catch(error => 
                    console.log("Error !")
                  );
            } catch (error) {
                //console.error('error');
            }
    }, [params.slug]);
    //console.log("blogPostDate",blogPostDate);

  return (
    <>
        {
            loading === true ? (<Loader />) : ''
        }
        <Header itemCount={totalItem} />
        <section className="prpperty-host-sec pb-50 pt-30">
          <div className="container">
              <div className="row g-2 g-md-3 g-lg-4">
                  <div className="col-lg-9 col-md-9 col-sm-6 col-12">
                      <div className="blog-title">
                          <h2>{t('lang.common.blog')}</h2>
                      </div>
                      <div className="blog-page-single">
                          <div className="blog-big-thumb relative">
                              <img src={process.env.REACT_APP_UPLOAD_URL+`blog/${blog.image}`} alt="" />
                              <span className="blog-post-date">{formatDate(blog.created_at)}</span>
                          </div>
                          <div className="blog-content">
                            <Link to="/blog" className="blog-title">{ languages == 'ar' ? blog.title_ar : blog.title }</Link>
                              <div className="blogText mb-5">
                              {parse(`${ languages == 'ar' ? blog.detail_ar : blog.detail }`)}
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="recent-post-wrap sticky-100">
                          <div className="blog-title">
                              <h2>{t('lang.common.recentPosts')}</h2>
                          </div>
                          <ul className="recentPostul">
                          {
                            recentBlog.map((v,i) => {
                                return(<li key={i}>

                                    <Link to={`/blog/${v.slug}`}>
                                        <div className="blogRecentprt">
                                        <img src={process.env.REACT_APP_UPLOAD_URL+`blog/${v.image}`} alt="" />
                                        </div>
                                        <div className="blogRightPrt">
                                        <h5>{ languages == 'ar' ? v.title_ar : v.title }</h5>
                                        <p>{parse(`${ languages == 'ar' ?  v.detail_ar.substring(0, process.env.REACT_APP_CHARACTER_MAX_LENGTH) : v.detail.substring(0, process.env.REACT_APP_CHARACTER_MAX_LENGTH) }...`)}</p>
                                        </div>
                                    </Link>
                                    </li>)
                            })
                        }

                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </section>
        <Footer />
    </>
  )
}
