import React, { useEffect, useState, useRef } from 'react'
import Header from './includes/Header'
import Footer from './includes/Footer'
import { Link } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';
import OwlCarouselRtl from "react-owl-carousel-rtl";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Flippy, { FrontSide, BackSide } from 'react-flippy';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faArrowLeft } from '@fortawesome/free-solid-svg-icons'


import { Parallax } from 'react-parallax';

import axios from 'axios';

import ReactJkMusicPlayer from 'react-jinke-music-player';
import "react-jinke-music-player/assets/index.css";
import parse from 'html-react-parser';
import CurrencyConvert from './includes/CurrencyConvert';
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import { Loader } from './pages/Loader';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

//https://www.npmjs.com/package/react-loading-skeleton
let language = localStorage.getItem('language');
/* export default function a(){

} */
/* export const Home =(props) =>{ */
const Home =(props) =>{

    const [t, i18n] = useTranslation('common');
    const { register, formState: { errors }, handleSubmit } = useForm();

    /* const changeLanguage = (e) => {
        i18next.changeLanguage(e.target.value);

        setLangSelected(e.target.value);
      } */

    var currency     = localStorage.getItem('currency');
    var currencyRate = localStorage.getItem('currencyRate');

    const [cat1, setCat1]                 = useState([]);
    const [cat2, setCat2]                 = useState([]);
    const [cat3, setCat3]                 = useState([]);
    const [cat4, setCat4]                 = useState([]);
    const [cat5, setCat5]                 = useState([]);
    const [cat6, setCat6]                 = useState([]);
    const [tracks, setTracks]             = useState([]);
    const [teachers, setTeachers]         = useState([]);
    const [banners, setBanners]           = useState([]);
    const [totalItem, setTotalItem]       = useState(0);
    const [home, setHome]                 = useState([]);
    const [homeSettings, setHomeSettings] = useState({});
    const [testimonial, setTestimonial]   = useState([]);
    const [prices, setPrices]  = useState(0);
    const [loading, setLoading]        = useState(false);
    const [errorMsg, setMessage]       = useState([]);
    const [error404Msg, set404Message] = useState([]);
    const [currencySymbol, setCurrencySymbol] = useState('$');

    const [topRatedCourses, setTopRatedCourses] = useState([]);

    const myRef  = useRef();
    const myRef2 = useRef();
    const myRef3 = useRef();
    const myRef4 = useRef();

    /* function shuffle(array) {
        let currentIndex = array.length,  randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex != 0) {

          // Pick a remaining element.
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;

          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }

        return array;
    } */


    useEffect(() => {
        //setLoading(true);
        i18next.changeLanguage(localStorage.getItem('language'));

        if(localStorage.getItem('currency') == ''){
            localStorage.setItem('currency', 1);
        }

        if(localStorage.getItem('currencyCode') == ''){
            localStorage.setItem('currencyCode', 'USD');
        }

        var products = localStorage.getItem('products');
        if(products !== '' && products !==null){
            setTotalItem(JSON.parse(localStorage.getItem('products')).length);
        }

        if(currency == 2){
            setCurrencySymbol('£');
        }

        //localStorage.setItem("timezones", Intl.DateTimeFormat().resolvedOptions().timeZone);

        setInterval(() => {
            //shuffle(arr);
            //console.log(myRef.current);
            /* myRef.current.toggle();
            myRef2.current.toggle();
            myRef3.current.toggle();
            myRef4.current.toggle(); */
            //console.log(arr);
        }, 4000);

        window.scrollTo(0,0);

        if(cat1.length <= 0){
            try {
                axios.get(process.env.REACT_APP_API_URL+"home-page")
                  .then(async (res) => {
                     if(res.status === 200){
                        //console.log(res.data.data.teachers[0].course.length);
                        setCat1(res.data.data.category_id_1);
                        setCat2(res.data.data.category_id_2);
                        setCat3(res.data.data.category_id_3);
                        setCat4(res.data.data.category_id_4);
                        setCat5(res.data.data.category_id_5);
                        setCat6(res.data.data.category_id_6);
                        setBanners(res.data.data.banners);
                        setTeachers(res.data.data.teachers);
                        setHome(res.data.data.home);
                        setTestimonial(res.data.data.testimonial);
                        setHomeSettings(res.data.data.home_settings);

                        setLoading(false);
                    }else{
                      return console.log("Error !");
                    }

                  }).catch(error =>
                    console.log("Error !")
                  );
                } catch (error) {
                    //console.error('error');
                }

        }

        //Top Rated Courses
        if(topRatedCourses.length <= 0){
            try {
                axios.get(process.env.REACT_APP_API_URL+"top-rated-courses")
                .then(async (res) => {
                    if(res.status === 200){
                        setTopRatedCourses(res.data.data.courses);
                        //console.log("dadas", res.data.data.courses);
                    }else{
                    return console.log("Error !");
                    }

                }).catch(error =>
                    console.log("Error !")
                );
            } catch (error) {
                console.error('error');
            }
        }

      }, []);

      const onSubmit = (data)  => {

        try {

            const formData = new FormData();
            formData.append("email", data.email);

            axios({
                method: 'post',
                url: process.env.REACT_APP_API_URL+"subscribe",
                data: formData,
            }).then(function(res) {

                if(res.data.success === 1){
                    //setLoading(false);
                    toast.success(res.data.success_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setTimeout(() => {
                        document.getElementById("subscriberForm").reset();
                    }, 2000);

                }else if(res.data.success === 0){
                    toast.error(res.data.error_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    //toast(res.data.data.error_message);
                    set404Message(res.data.data);
                }

            }).catch(function (error){
                setMessage(error.response.data.data);
            });
        } catch (error) {
            //console.error('error');
        }
        //console.log(data);
    };

    return(
        <>
        <Header itemCount={totalItem} />
        <ToastContainer autoClose={2000} />
        {
            loading === true ? (<Loader />) : ''
        }

        {
            homeSettings.home_sec_1==1 ?

        <section className='banner d-flex'>
                    <div className='bannImg'>
                        <Flippy
                            flipOnHover={false} // default false
                            flipOnClick={false} // default false
                            isFlipped={true}
                            flipDirection="horizontal" // horizontal or vertical
                            ref={myRef}
                            style={{ width: '100%', height: '100%' }}
                        >
                            <FrontSide>
                                <img src={process.env.REACT_APP_UPLOAD_URL+'page/banner/'+banners.image_4} alt="" />
                            </FrontSide>
                            <BackSide><img src={process.env.REACT_APP_UPLOAD_URL+'page/banner/'+banners.image_1} alt="" /></BackSide>
                        </Flippy>
                    </div>
                    <div className='bannImg'>
                        <Flippy
                            flipOnHover={false} // default false
                            flipOnClick={false} // default false
                            isFlipped={true}
                            flipDirection="horizontal" // horizontal or vertical
                            ref={myRef2}
                            style={{ width: '100%', height: '100%' }}
                        >
                            <FrontSide>
                                <img src={process.env.REACT_APP_UPLOAD_URL+'page/banner/'+banners.image_2} alt="" />
                            </FrontSide>
                            <BackSide><img src={process.env.REACT_APP_UPLOAD_URL+'page/banner/'+banners.image_3} alt="" /></BackSide>
                        </Flippy>
                    </div>
                    <div className='bannImg'>
                        <Flippy
                            flipOnHover={false} // default false
                            flipOnClick={false} // default false
                            isFlipped={true}
                            flipDirection="horizontal" // horizontal or vertical
                            ref={myRef3}
                            style={{ width: '100%', height: '100%' }}
                        >
                            <FrontSide>
                                <img src={process.env.REACT_APP_UPLOAD_URL+'page/banner/'+banners.image_3} alt="" />
                            </FrontSide>
                            <BackSide><img src={process.env.REACT_APP_UPLOAD_URL+'page/banner/'+banners.image_2} alt="" /></BackSide>
                        </Flippy>
                    </div>
                    <div className='bannImg'>
                        <Flippy
                            flipOnHover={false} // default false
                            flipOnClick={false} // default false
                            isFlipped={true}
                            flipDirection="horizontal" // horizontal or vertical
                            ref={myRef4}
                            style={{ width: '100%', height: '100%' }}
                        >
                            <FrontSide>
                                <img src={process.env.REACT_APP_UPLOAD_URL+'page/banner/'+banners.image_1} alt="" />
                            </FrontSide>
                            <BackSide><img src={process.env.REACT_APP_UPLOAD_URL+'page/banner/'+banners.image_4} alt="" /></BackSide>
                        </Flippy>
                    </div>
                    <div className='bannSlogan'>
                        {
                            language == 'ar' ? <h2>{banners.slogan_ar_1} <span className='for'>{banners.slogan_ar_2}</span><span className='everyOne'>{banners.slogan_ar_3}</span></h2> : <h2>{banners.slogan_1} <span className='for'>{banners.slogan_2}</span><span className='everyOne'>{banners.slogan_3}</span></h2>
                        }

                    </div>
                </section>
                : <SkeletonTheme /* baseColor="#202020" highlightColor="#a0f631" */>
                        <h1>
                            <Skeleton height={600} count={1} />
                        </h1>
                    </SkeletonTheme>
                }

                {
                    homeSettings.home_sec_2==1 &&
                <section className='topRatedMusic'>
                    <div className='container-fluid ph-40'>
                        <div className='row g-2 g-md-3 g-lg-4'>
                            <div className='col-12'>
                                <div className='sectionTitle text-center'>
                                    <h2>{t('lang.pages.home.sec_1')}</h2>
                                    {/* <h1>{t('lang.home.1', {framework:'React'})}</h1> */}
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                                <Link to={`/courses/category/`+cat1.slug} className='boxImg relative' style={{backgroundImage: "url("+process.env.REACT_APP_UPLOAD_URL+'page/category/'+cat1.image+")", backgroundRepeat: "no-repeat" }}>
                                    <span>{language == 'ar' ? cat1.title_ar : cat1.title}</span>
                                </Link>
                            </div>
                            <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                <Link to={`/courses/category/`+cat2.slug} className='boxImg relative' style={{ backgroundImage: "url("+process.env.REACT_APP_UPLOAD_URL+'page/category/'+cat2.image+")", backgroundRepeat: "no-repeat" }}>
                                    <span>{language == 'ar' ? cat2.title_ar : cat2.title}</span>
                                </Link>
                            </div>
                            <div className='col-lg-5 col-md-5 col-sm-6 col-12'>
                                <Link to={`/courses/category/`+cat3.slug} className='boxImg relative' style={{ backgroundImage: "url("+process.env.REACT_APP_UPLOAD_URL+'page/category/'+cat3.image+")", backgroundRepeat: "no-repeat" }}>
                                    <span>{language == 'ar' ? cat3.title_ar : cat3.title}</span>
                                </Link>
                            </div>
                            <div className='col-lg-5 col-md-5 col-sm-6 col-12'>
                                <Link to={`/courses/category/`+cat4.slug} className='boxImg relative' style={{ backgroundImage: "url("+process.env.REACT_APP_UPLOAD_URL+'page/category/'+cat4.image+")", backgroundRepeat: "no-repeat" }}>
                                <span>{language == 'ar' ? cat4.title_ar : cat4.title}</span>
                                </Link>
                            </div>
                            <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                <Link to={`/courses/category/`+cat5.slug} className='boxImg relative' style={{ backgroundImage: "url("+process.env.REACT_APP_UPLOAD_URL+'page/category/'+cat5.image+")", backgroundRepeat: "no-repeat" }}>
                                <span>{language == 'ar' ? cat5.title_ar : cat5.title}</span>
                                </Link>
                            </div>
                            <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                                <Link to={`/courses/category/`+cat6.slug} className='boxImg relative' style={{ backgroundImage: "url("+process.env.REACT_APP_UPLOAD_URL+'page/category/'+cat6.image+")", backgroundRepeat: "no-repeat" }}>
                                <span>{language == 'ar' ? cat6.title_ar : cat6.title}</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                }
                {
                   homeSettings.home_sec_3==1 &&
                <section className='coursesBlock'>
                    <div className='container-fluid ph-40'>
                        <div className='row g-2 g-md-3 g-lg-4'>
                            <div className='col-12'>
                                <div className='sectionTitle text-center'>
                                    <h2 className='p-0'>{t('lang.pages.home.sec_2')}</h2>
                                </div>
                            </div>

                           {
                                topRatedCourses.map((val,index) => {


                                    var price = val.price;
                                    var dPrice = val.discount_price;

                                    /* if(val.currency == currency){

                                        price = val.price;
                                        dPrice = val.discount_price;
                                    }else if(val.currency != currency){
                                        if(val.currency == 2){
                                            price = val.price * Number(currencyRate);
                                        }else if(val.currency == 1){
                                            price = val.price * Number(currencyRate);
                                        }
                                    } */
                                    let aaa= '';
                                    currency = parseInt(currency);

                                    if(val.currency == currency){
                                        price = price;
                                        dPrice = dPrice;
                                    }else if(val.currency != currency){

                                        price = price * Number(currencyRate);
                                        dPrice = dPrice;
                                    }

                                    let discountedPrice = price - (price * dPrice / 100);


                                    return(
                                        <>
                                            <div className='col-lg-3 col-md-3 col-sm-6 col-12' key={index}>
                                                <div className="projectInner w-100 padBtm">
                                                    <div className="projectInnerTop w-100 relative">
                                                        <Link to={`/courses/`+val.slug}><img src={process.env.REACT_APP_UPLOAD_URL+'courses/images/'+val.photo} alt={val.title} /></Link>
                                                        <span className="bestSeller bestSellerFloting">{t('lang.common.bset_seller')}</span>
                                                        { dPrice != 0 ? <span className="discountPercent">{dPrice}% {t('lang.common.off')}</span> : '' }
                                                    </div>
                                                    <div className="projectInnerMid w-100">
                                                        <h4><Link to={`/courses/${val.slug}`}>{ language == 'ar' ? val.title_ar : val.title }</Link></h4>
                                                        {
                                                            language == 'ar' ?

                                                            val.short_description_ar != null ?
                                                            <p>{`${val.short_description_ar.substring(0, process.env.REACT_APP_CHARACTER_MAX_LENGTH)}...`}</p>
                                                            : ''
                                                            : <p>{`${val.short_description.substring(0, process.env.REACT_APP_CHARACTER_MAX_LENGTH)}...`}</p>
                                                        }

                                                        <div className='d-flex align-items-center starArea w-100'>
                                                            <ul className='d-flex'>
                                                                <li>
                                                                    {
                                                                        val.rating != '' &&
                                                                        (() => {
                                                                            let td = [];
                                                                            for (let i = 1; i <= val.rating; i++) {
                                                                            td.push(<FontAwesomeIcon icon={faStar} />);
                                                                            }
                                                                            return td;
                                                                        })()
                                                                    }
                                                                </li>
                                                            </ul>
                                                            {
                                                                val.totalRating > 0 && <span className='rvs'>({val.totalRating})</span>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="price-sec w-100">
                                                        <div className="price-sec-text">
                                                            <p>{ discountedPrice != 0 ? currencySymbol+ '' + Math.round(discountedPrice) : t('lang.common.free') } {price != 0 && dPrice !=0 ? <span className="line-through">{currencySymbol}{ Math.round(price) }</span> : ''}</p>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>
                </section>
                }

                {
                     homeSettings.home_sec_4==1 &&
                <section className='addBanner' style={{ backgroundImage: "url(../images/add-bg.jpg)", backgroundRepeat: "repeat" }}>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                <div className='addImg w-100 relative'>
                                    <span><img src={process.env.REACT_APP_UPLOAD_URL+'page/banner/'+banners.bottom_banner} alt="" /></span>
                                </div>
                            </div>
                            <div className='col-lg col-md col-sm-12 col-12'>
                                <div className='addBannerDesc text-center'>
                                    <div className='addBannerDesc'>
                                        { language == 'ar' ? parse(`${home.bottom_content_ar}`) : parse(`${home.bottom_content}`) }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                }
                {
                    homeSettings.home_sec_5==1 &&
                <section className='coursesBlock'>
                    <div className='container-fluid ph-40'>
                        <div className='row g-2 g-md-3 g-lg-4'>
                            <div className='col-12'>
                                <div className='sectionTitle text-center'>
                                    <h2 className='p-0'>{t('lang.pages.home.sec_3')}</h2>
                                    {/* <Link to="/" className='viewAll'>View All</Link> */}
                                </div>
                            </div>
                            {
                                teachers.map((val,ind) => {
                                    return(<div className='col-lg-3 col-md-3 col-sm-6 col-12' key={ind}>
                                    <div className="projectInner w-100 projectInnerFixFooter-2">
                                        <div className="projectInnerTop w-100 teacherSections">
                                        {
                                            val.photo != null  ? <Link to={`/tutor/${val.teachers.teacher_slug}`}><img src={process.env.REACT_APP_UPLOAD_URL+`teacher/photos/${val.photo}`} alt={val.name} /></Link> : <Link to={`/tutor/${val.teachers.teacher_slug}`}><img src="../images/avatar.jpg" alt="" /></Link>
                                        }
                                            {/* <Link to="/"><img src="../images/box/11.jpg" alt="" /></Link> */}
                                        </div>
                                        <div className="projectInnerMid w-100">
                                            <h4><Link to={`/tutor/${val.teachers.teacher_slug}`}>{val.name}</Link></h4>
                                            <ul className='catExp'>
                                                {
                                                    val.course.length > 0 ? <li>{t('lang.menu.menu_11')} : <span>{ val.course.length }</span></li> : ''
                                                }

                                                {val.teachers.experiance != null ? <li>{t('lang.common.experience')} : <span>{ val.teachers.experiance } { val.teachers.experiance > 1 && val.teachers.experiance != null ? 'yrs' : 'year' }</span></li> : ''}

                                            </ul>
                                            <div className='d-flex align-items-center starArea w-100'>
                                                    <ul className='d-flex'>

                                                            {
                                                                val.rating != '' &&
                                                                (() => {
                                                                    let td = [];
                                                                    for (let i = 1; i <= val.rating; i++) {
                                                                    td.push(<li><FontAwesomeIcon icon={faStar} /></li>);
                                                                    }
                                                                    return td;
                                                                })()
                                                            }

                                                    </ul>
                                                    {
                                                        val.totalRating > 0 && <span className='rvs'>({val.totalRating})</span>
                                                    }
                                            </div>
                                        </div>
                                        <div className="coursesBtnArea w-100">
                                            <Link to={`/tutor/${val.teachers.teacher_slug}`} className='coursesBtn'>{t('lang.pages.home.sec_6')}</Link>
                                        </div>
                                    </div>
                                </div>)
                                })
                            }


                        </div>
                    </div>
                </section>
                 }

                {
                    homeSettings.home_sec_6==1 &&
                <section className='testimonials'>
                <Parallax bgImage="../../images/test-bg.jpg" strength={-100}>
                    <div className='container ph-40'>
                        <div className='row'>
                            <div className='col-12'>
                                {

                                    language == 'ar' ?
                                    <OwlCarouselRtl className='owl-theme newHomeTestimonialSlider' rtlClass={'owl-rtl'} loop margin={10} nav dots={false} items={1} rtl={true}>
                                    {
                                        testimonial.map((val,indx) => {
                                            return(<div className='item' key={indx}>
                                            <div className='testiInner'>
                                                <div className='testiInnerTop'>
                                                    <div className='testiInnerTopInner'>
                                                        <span><img src={process.env.REACT_APP_UPLOAD_URL+`/testimonial/${val.image}`} alt="" /></span>
                                                        <h5>{val.client_name_ar}</h5>
                                                    </div>
                                                </div>
                                                <div className='testiInnerBtm relative'>
                                                    {parse(`${val.detail_ar}`)}
                                                </div>
                                            </div>
                                        </div>)
                                        })
                                    }

                                </OwlCarouselRtl>
                                :

                                <OwlCarousel className='owl-theme'  loop margin={10} nav dots={false} items={1}>
                                {
                                    testimonial.map((val,indx) => {
                                        return(<div className='item' key={indx}>
                                        <div className='testiInner'>
                                            <div className='testiInnerTop'>
                                                <div className='testiInnerTopInner'>
                                                    <span><img src={process.env.REACT_APP_UPLOAD_URL+`/testimonial/${val.image}`} alt="" /></span>
                                                    <h5>{val.client_name}</h5>
                                                </div>
                                            </div>
                                            <div className='testiInnerBtm relative'>
                                                {parse(`${val.detail}`)}
                                            </div>
                                        </div>
                                    </div>)
                                    })
                                }

                            </OwlCarousel>

                            }

                            </div>
                        </div>
                    </div>
                    </Parallax>
                </section>
                }
                {/* <section className='subscribeWrap'>
                    <Parallax blur={{ min: -100, max: 100 }} bgImage={('../../images/subscribe-bg.jpg')} bgImageAlt="the dog" strength={-200} >
                        <div className='container-fluid ph-40'>
                            <div className='row'>
                                <div className='col-12 d-flex justify-content-end'>
                                    <div className='subscribeInner'>
                                        <h2>SUBSCRIBE OUR NEWSLETTER</h2>
                                        <h5>FOR QUICK UPDATE</h5>
                                        <div className='subsSrc relative'>
                                            <input type="text" className="form-control" placeholder="Enter Your Email" />
                                            <button className='subscribeBtn'>subscribe</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Parallax>
                </section> */}
                {

                    homeSettings.home_sec_7==1 &&
                <section className='subscribeWrap'>
                    <Parallax blur={{ min: -100, max: 100 }} bgImage={('../../images/subscribe-bg.jpg')} bgImageAlt="the dog" strength={-200} >
                        <div className='container-fluid ph-40'>
                            <div className='row align-items-center'>
                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <div className='subscribeLeft'>
                                    { language == 'ar' ? parse(`${home.subscribe_content_ar}`) : parse(`${home.subscribe_content}`) }
                                </div>
                            </div>

                                <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                    <div className='subscribeInner'>
                                        <div className='subsSrc relative'>
                                        <form id='subscriberForm' onSubmit={handleSubmit(onSubmit)}>
                                            <input type="email" name="email" className="form-control"  placeholder={t('lang.pages.home.sec_4')} {...register("email", { required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/  })}  autoComplete="off" />
                                            {errors?.subscribe?.type === "required" && <p className='errors'>{t('lang.validation.enterEmail')}</p>}
                                            {errors?.subscribe?.type === "pattern" && <p className='errors'>{t('lang.validation.emailPattern')}</p>}
                                            <button className='subscribeBtn' type="submit">{t('lang.pages.home.sec_5')}</button>
                                        </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Parallax>
                </section>
                }

                <Footer/>
        </>
    );
}

export default Home;