import React, { useEffect, useState } from 'react'

import Header from '../includes/Header'
import Footer from '../includes/Footer'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import {useParams} from 'react-router-dom';
import { Loader } from './Loader';
import Table from 'react-bootstrap/Table';


const Thankyou = (props) => {

  const [totalItem, setTotalItem]   = useState(0);
  const [t, i18n] = useTranslation('common');
  const [loading, setLoading] = useState(false);
  const [order, setOrder]  = useState([]);
  const [singleOrder, setSingleOrder]  = useState([]);
  const [thankyouMessage, setThankyouMessage] = useState('');

  const params = useParams();
  //console.log("props", params);
  var totalPrice = 0;

  useEffect(() => {
    
    i18next.changeLanguage(localStorage.getItem('language'));
    var products = localStorage.getItem('products');
    if(products !== '' && products !==null){
        setTotalItem(JSON.parse(localStorage.getItem('products')).length);
    }
    setLoading(true);

    try {
        axios.get(process.env.REACT_APP_API_URL+`order/${params.orderid}`)
          .then(async (res) => {
             
              if(res.status === 200){
                setLoading(false);
                //setOrder(res.data.data.orderData.order_detail);
                setSingleOrder(res.data.data.orderDetails);
                setOrder(res.data.data.orderData);

                res.data.data.orderDetails && res.data.data.orderDetails.map((val,idx) => {
                  totalPrice += val.amount;

                });

                //console.log("totalPrice =",totalPrice);
                if(totalPrice == 0){
                  setThankyouMessage(t('lang.common.thankyouWithOutPrice'));
                }else{
                  setThankyouMessage(t('lang.common.thankyouWithPrice'));
                }
            }else{
              return console.log("Error !");
            }
            
          }).catch(error => 
            console.log("Error !")
          );

          

    } catch (error) {
        //console.error('error');
    }

  },[params.orderid]);

  //console.log("order", singleOrder.length);
  
  return (
    <>
    {
            loading === true ? (<Loader />) : ''
        }
    <Header itemCount={totalItem} />
    <section className=" thankYouSection">
            <div className="container">
                <div className="text-center text-style-design thank-you">
                    <span className="text-icon"><FontAwesomeIcon icon={faCircleCheck} /></span>
                    <h1>{t('lang.common.thankYou')}</h1>
                    <p><strong>{thankyouMessage}</strong></p>
                       <Link to="/" className='continueBtn'>{t('lang.common.homepage')}</Link> 
            </div>
        </div>
    </section>

    <section className="orderSummaryTable">
        <div className="container">
          <div className="row">
            <div className="col-12">
            <div className="orderSummary">
            <h2>{t('lang.common.orderSummary')}</h2>
              <div className="orderSummaryTop">
                
                <h5>{t('lang.common.orderID')} : #{params.orderid} <span>{t('lang.common.orderPlacement')}</span></h5>
              </div>
              

              <div className="table-responsive">
                <Table>
                  <tbody>
                    {
                     singleOrder.length > 0 ? 
                     singleOrder && singleOrder.map((val,idx) => {
                       totalPrice += val.amount;
                        return(
                          <tr key={val.id}>
                            <td className='productImgMd'><img src={val.photo && val.photo != '' ? val.photo : 'https://dummyimage.com/300/'} alt=""/></td>
                            <td><h5>{val.item_name}</h5></td>
                            <td className='text-center'>{val.total_item}</td>
                            <td className='text-end'>{val.amount != 0 ? order.currency == 'USD' ? `$${val.amount}` : `£${val.amount}` : 'Free'}</td>
                          </tr>
                        )
                        
                      })
                      : ''
                      
                    }
                    <tr>
                      <td colSpan={3} className='text-end fontBold18'>Total</td>
                      <td className='text-end fontBold18'>{ totalPrice != 0 ?  order.currency == 'USD' ? `$${totalPrice}` : `£${totalPrice}` : 'Free' }</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            </div>
          </div>
        </div>
      </section>
    <Footer />
    </>
    
  )
}

export default Thankyou