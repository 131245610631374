import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import Header from '../includes/Header'
import Footer from '../includes/Footer'
import { Loader } from './Loader';
import parse from 'html-react-parser';
import {useTranslation} from "react-i18next";
import i18next from "i18next";


const PageDetails = () => {

    let pageSlug = localStorage.getItem('pageSlug');
    let languages = localStorage.getItem('language');

    const params                    = useParams();
    const [page, setPage]           = useState([]);  
    const [loading, setLoading]     = useState(false);
    const [totalItem, setTotalItem] = useState(0);
    const [t, i18n] = useTranslation('common');

    if(pageSlug != params.slug){
        try {
            
            axios.get(process.env.REACT_APP_API_URL+"pages/"+params.slug)
              .then(async (res) => {
                 if(res.status === 200){
                    setPage(res.data.data);
                    localStorage.setItem('pageSlug', res.data.data.slug);
                    setLoading(false);
                }else{
                  return console.log("Error !");
                }
                
              }).catch(error => 
                console.log("Error !")
              );
          } catch (error) {
            //console.error('error');
          }
    }

      useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('language'));
        window.scrollTo(0,0);
        setLoading(true);
        var products = localStorage.getItem('products');
        if(products !== '' && products !==null){
            setTotalItem(JSON.parse(localStorage.getItem('products')).length);
        }
        //setTotalItem(JSON.parse(localStorage.getItem('products')).length);

          try {
            axios.get(process.env.REACT_APP_API_URL+"pages/"+params.slug)
              .then(async (res) => {
                 if(res.status === 200){
                    //console.log(res.data.data);
                    setPage(res.data.data);
                    localStorage.setItem('pageSlug', res.data.data.slug);
                    setLoading(false);
                }else{
                  return console.log("Error !");
                }
                
              }).catch(error => 
                console.log("Error !")
              );
          } catch (error) {
            //console.error('error');
          }

      },[]);
      
  return (
    
    <>
        {
            loading === true ? (<Loader />) : ''
        } 
       <Header itemCount={totalItem} />
       <section className='contentPageBanner'>
          <div className="default-banner"><img src={ page.image != null ? process.env.REACT_APP_UPLOAD_URL+`page/${page.image}` : '../images/defaultmusicbanner.jpg' } alt="" /></div>
       </section>
       <section className='contentPageWrap'>
        <div className='container'>
            <div className='row g-2 g-md-3 g-lg-4'>
                <div className='col-12'>
                    <div className='contentPage'>
                        <h3>{ languages == 'ar' ? page.title_ar : page.title }</h3>
                        { languages == 'ar' ? parse(`${page.content_ar}`) : parse(`${page.content}`) }
                    </div>
                </div>
            </div>
        </div>
       </section>
       <Footer /> 
    </>
  )
}

export default PageDetails