import React, { useState, useEffect } from 'react'
import Header from '../includes/Header'
import Footer from '../includes/Footer'
import { Loader } from './Loader';
import { Link, useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faHeart, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useForm } from "react-hook-form";
import axios from 'axios';
import parse from 'html-react-parser';
import ReactReadMoreReadLess from 'react-read-more-read-less';
import ReviewForm from './ReviewForm';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const TutorDetails = () => {
    const params = useParams();
    const [totalItem, setTotalItem]                 = useState(0);
    const [about, setAbout]                         = useState('');
    const [image, setImage]                         = useState('');
    const [tutor, setTutor]                         = useState([]);
    const [user, setUser]                           = useState([]);
    const [reviews, setReviews]                     = useState([]);
    const [loading, setLoading]                     = useState(false);
    const [teacherRate, setTeacherRate]             = useState([]);
    const [teacherLanguage, setTeacherLanguage]     = useState([]);
    const [teacherInstrument, setTeacherInstrument] = useState([]);
    const [teacherGenre, setTeacherGenre]           = useState([]);
    const [lessonFees, setLessonFees]               = useState(80);
    const [lessonTime, setLessonTime]               = useState(1);
    const [lessonTaken, setLessonTaken]             = useState('');
    let loginUser                                   = localStorage.getItem('loginUser');
    const [currencySymbol, setCurrencySymbol]       = useState('$');
    var currency                    = localStorage.getItem('currency');
    var currencyRate                = localStorage.getItem('currencyRate');
    const [t, i18n] = useTranslation('common');
    let languages    = localStorage.getItem('language');

    const navigate = useNavigate();
    const { register, formState: { errors }, handleSubmit } = useForm();



    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('language'));
        window.scrollTo(0,0);
        setLoading(true);
        var products = localStorage.getItem('products');
        if(products !== '' && products !==null){
            setTotalItem(JSON.parse(localStorage.getItem('products')).length);
        }

        if(currency == 2){
            setCurrencySymbol('£');
        }

        try {

            axios.get(process.env.REACT_APP_API_URL+`tutor/${params.slug}?&loginUser=${loginUser}`)
              .then(async (res) => {
                if(res.status === 200){

                    setTutor(res.data.data.tutors);
                    setUser(res.data.data.tutors.user);
                    setTeacherRate(res.data.data.tutors.teacher_rate);
                    setTeacherLanguage(res.data.data.teacherLanguages);
                    setTeacherInstrument(res.data.data.teacherInstrument);
                    setTeacherGenre(res.data.data.genre);
                    setReviews(res.data.data.reviews);
                    setLoading(false);

                    console.log(res.data.data.tutors);

                    if(res.data.data.tutors.teacher_currency == currency){
                        setLessonFees(res.data.data.tutors.teacher_rate.lesson_one);
                    }else if(res.data.data.tutors.teacher_currency != currency){

                        let price = Number(res.data.data.tutors.teacher_rate.lesson_one)* Number(currencyRate);
                        setLessonFees(Math.round(price));
                    }

                    //setLessonFees(res.data.data.tutors.teacher_rate.lesson_one);
                    setLessonTaken(res.data.data.lessonBooking);
                }else{
                  return console.log("Error !");
                }

              }).catch(error =>
                console.log("Error !", error)
              );
        } catch (error) {
            //console.error('error');
        }

    }, [params.slug]);

    const bookingLesson = () =>{
        document.querySelector(".btn-close").click();

        let genreSelect      = document.getElementById("genreSelect").value;
        let instrumentSelect = document.getElementById("instrumentSelect").value;

        localStorage.setItem('genreSelect', genreSelect);
        localStorage.setItem('instrumentSelect', instrumentSelect);
        localStorage.setItem('lessonSelected', lessonTime);

        setTimeout(()=>{
            navigate(`/tutor/${tutor.teacher_slug}/booking-lesson`);
        },100);

    }

    const changeLesson = (e) => {
        if(e == 1){

            if(tutor.teacher_currency == currency){
                setLessonFees(teacherRate.lesson_one);
            }else{
                let lPrice = Number(teacherRate.lesson_one)* Number(currencyRate);
                setLessonFees(Math.round(lPrice));
            }

            /* if(currency == 2){
                setLessonFees(Number(teacherRate.lesson_one)* Number(currencyRate));
            }else{
                setLessonFees(teacherRate.lesson_one);
            } */

            setLessonTime(1);
        }else if(e == 2){
            if(tutor.teacher_currency == currency){
                setLessonFees(teacherRate.lesson_four);
            }else{
                let lPrice = Number(teacherRate.lesson_four)* Number(currencyRate);
                setLessonFees(Math.round(lPrice));
            }
            /* if(currency == 2){
                setLessonFees(Number(teacherRate.lesson_four)* Number(currencyRate));
            }else{
                setLessonFees(teacherRate.lesson_four);
            } */

            //setLessonFees(teacherRate.lesson_four);
            setLessonTime(4);
        }else{

            if(tutor.teacher_currency == currency){
                setLessonFees(teacherRate.lesson_eight);
            }else{
                let lPrice = Number(teacherRate.lesson_eight)* Number(currencyRate);
                setLessonFees(Math.round(lPrice));
                //setLessonFees(Number(teacherRate.lesson_eight)* Number(currencyRate));
            }
            //setLessonFees(teacherRate.lesson_eight);
            setLessonTime(8);
        }

    }

  return (
    <>
    {
        loading === true ? (<Loader />) : ''
    }
    <Header itemCount={totalItem} />
    <ToastContainer autoClose={2000} />

    {/* {(function(){
        switch (params.slug) {
            case 'leah-kruszewski':
                return(
                    <>
                        <section className='teacherArea'>
                            <div className="container">
                                <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                    <div className="teacherImg">
                                    <img src="../images/teacher.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                                    <div className="teacherDesc">
                                    <h3>Leah Kruszewski</h3>
                                    <h5>Classical/ Flaminco Guitar teacher</h5>
                                    <h4>About: </h4>
                                    <p>Zeryab Music Academy Teacher for Flamenco and Classical Guitar, Leah loves introducing flamenco guitarists to the rhythms and melodies of southern Spain and guiding classical players as they develop their technique and repertoire.  She also enjoys guiding beginners in Loog, fingerstyle, and acoustic guitar (ages 6 & up, please).</p>
                                    <ul className='d-flex mt-4'>
                                        <li>Learn Playing Oud- biginer level 3</li>
                                        <li>Learning oud Biginer level 2</li>
                                        <li>Afrobeat music production in FL Studio</li>
                                    </ul>
                                        <Link to={"/tutor"} class="bookLesson mt-4">Book a lesson</Link>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </section>
                    </>
                )
                break;

            case 'fateen-kanaan':
                return(
                    <>
                    <section className='teacherArea'>
                            <div className="container">
                                <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                    <div className="teacherImg">
                                    <img src="../images/teacher-2.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                                    <div className="teacherDesc">
                                    <h3>Fateen Kanaan</h3>
                                    <h5>OUD & Oriental music teacher</h5>
                                    <h4>About: </h4>
                                    <p>Zeryab Music Academy founder and professional Oud teacher with more than 10 years teaching experience. Fateen has been playing Oud for 25 years and he can play and teach different Oriental and middle eastern music schools. Fateen works with his students closely to develop their skills in shorter time.  </p>
                                    <ul className='d-flex mt-4'>
                                        <li>Learn Playing Oud- biginer level 3</li>

                                        <li>Afrobeat music production in FL Studio</li>
                                    </ul>
                                        <Link to={"/tutor"} class="bookLesson mt-4">Book a lesson</Link>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </section>
                    </>
                )
                break;

            case 'dhruv-bedi':
                return(
                    <>
                    <section className='teacherArea'>
                            <div className="container">
                                <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                    <div className="teacherImg">
                                    <img src="../images/teacher-3.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                                    <div className="teacherDesc">
                                    <h3>Dhruv Bedi</h3>
                                    <h5>Sitar teacher and performing artist</h5>
                                    <h4>About: </h4>
                                    <p>I am ' A ' Grade artist from All India Radio and President Awardee in Sitar. I have been teaching and performing from past 24 years. Currently I have more than 160 students from the age group of 5 years to 75 years. I take students from beginners to advance level. I focus on making my students to perform on stage. I start from the basic Fundamental of Sitar such as sitting posture and hand positions. Then do Bandages, Taans, Jhala and Alap and also some light compositions - songs, devotional bhajans, Folk Tunes and many more. You are most welcome to attend a free trial class. I have taken workshops and performed in countries like -USA, UK, Spain Sweden, Russia, New Zealand, South Korea, Mauritius and many more.</p>
                                    <ul className='d-flex mt-4'>

                                        <li>Learning oud Biginer level 2</li>
                                        <li>Afrobeat music production in FL Studio</li>
                                    </ul>
                                        <Link to={"/tutor"} class="bookLesson mt-4">Book a lesson</Link>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </section>
                    </>
                )
                break;

            case 'barak':
                return(
                    <>
                    <section className='teacherArea'>
                            <div className="container">
                                <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                    <div className="teacherImg">
                                    <img src="../images/teacher-4.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                                    <div className="teacherDesc">
                                    <h3>Barak</h3>
                                    <h5>Saz and Baglama Online teacher</h5>
                                    <h4>About: </h4>
                                    <p>Now you can Learn Online by Video. At your own Pace, WHENEVER you want and WHEREVER it is suitable for you.</p>
                                    <p>During the course, you will learn how to develop musical improvisations and special rhythms that exist only in this genre of oriental music. You will study special picking techniques used all over Central Asia.</p>
                                    <p>The melodies you will learn in the course, emphasize Turkish and Kurdish music, but You will learn also about Greek, Mediterranean & Central Asian influences. You will also learn how the Saz can be used to enrich Western music skills.</p>
                                    <ul className='d-flex mt-4'>
                                        <li>Learning oud Biginer level 2</li>
                                        <li>Afrobeat music production in FL Studio</li>
                                    </ul>
                                        <Link to={"/tutor"} class="bookLesson mt-4">Book a lesson</Link>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </section>
                    </>
                )
                break;

            default:
                break;
        }
    })()} */}
    <section className='teacherArea'>
        <div className="container">
            <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                <div className="teacherImg">
                {
                    user.photo != null  ? <img src={process.env.REACT_APP_UPLOAD_URL+`teacher/photos/${user.photo}`} alt={user.name} /> : <img src="../images/avatar.jpg" alt={user.name} />
                }

                </div>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                <h3>{tutor.first_name} {tutor.last_name} { user.age && <span className='teacherAge'>({user.age} years old)</span>}</h3>
                {
                    tutor.short_desc &&  <h5>{languages == 'ar' ? tutor.short_desc_ar : tutor.short_desc }</h5>
                }
                <div className="details-box-corse">
                    {
                        teacherLanguage.length > 0 ? <>
                            <h5>{t('lang.common.languageSpeak')}:</h5>
                            <ul>
                                {
                                    teacherLanguage.map((vals,indx) => {

                                        if(vals.languages != null) {
                                            return(<li key={indx}>  { languages == 'en' ? vals.languages.language : vals.languages.language_ar} </li>)
                                        }

                                    })
                                }
                            </ul>
                        </> : ''
                    }
                    {
                        teacherInstrument.length > 0 ? <>
                            <h5>{t('lang.common.instrumentsTeaches')}:</h5>
                            <ul>
                                {
                                    teacherInstrument.map((vals,indx) => {
                                        if(vals.instruments != null) {
                                            return(<li key={indx}> {languages == 'en' ? vals.instruments.name : vals.instruments.name_ar } </li>)
                                        }
                                    })
                                }
                            </ul>
                        </> : ''
                    }

                    {
                        teacherGenre.length > 0 ? <>
                            <h5>{t('lang.common.teaches')}:</h5>
                            <ul>
                                {
                                    teacherGenre.map((vals,indx) => {
                                        if(vals.genre != null) {
                                            return(<li key={indx}> { languages == 'ar' ? vals.genre.name_ar : vals.genre.name} </li>)
                                        }

                                    })
                                }
                            </ul>
                        </> : ''
                    }

                </div>
                <div className="teacherDesc">
                {
                    tutor.about && <><h4>{t('lang.common.about')}: </h4> {parse(`${languages == 'ar' ? tutor.about_ar : tutor.about }`)}</>
                }
                {
                    tutor.course && tutor.course.length >0 ? <>
                    <h5 className='mt-2'>{t('lang.common.courseDetails')}:</h5>
                    <ul className='row g-3 row-cols-3'>
                    {
                        tutor.course.map((val,inxd) => {
                            return(<li key={inxd}><Link to={`/courses/${val.slug}`}>{ languages == 'ar' ? val.title_ar : val.title }</Link></li>)
                        })
                    }
                </ul>

                </> : ''
                }
                {
                    loginUser != null && tutor.teacher_rate != null && teacherInstrument.length > 0 ? <Link data-bs-toggle="modal" data-bs-target="#staticBackdrop" to="#" className="bookLesson mt-4">{t('lang.common.BuyLiveLessons')}</Link> :
                    teacherInstrument.length > 0 && tutor.teacher_rate != null  ?
                    <Link to="/login" className="bookLesson mt-4">{t('lang.common.loginToBuy')}</Link> : ''
                }

                </div>
                {
                    teacherRate != null ?

                    <>
                    <h5 className="mt-3">{t('lang.common.lessonFees')}:</h5>
                    <ul className="d-flex lesson-price flex-wrap">
                        {
                            teacherRate.lesson_one != null ? <li><p><strong>{t('lang.common.oneLesson')} :</strong> {currencySymbol}{ tutor.teacher_currency == currency ? teacherRate.lesson_one :  Math.round(Number(teacherRate.lesson_one) * Number(currencyRate)) }</p></li> : ''
                        }
                        {
                            teacherRate.lesson_four != null ? <li><p><strong>{t('lang.common.fourLesson')} :</strong> {currencySymbol}{ tutor.teacher_currency == currency ? teacherRate.lesson_four :  Math.round(Number(teacherRate.lesson_four) * Number(currencyRate)) }</p></li> : ''
                        }
                        {
                            teacherRate.lesson_four != null ? <li><p><strong>{t('lang.common.eightLesson')} :</strong> {currencySymbol}{ tutor.teacher_currency == currency ? teacherRate.lesson_eight :  Math.round(Number(teacherRate.lesson_eight) * Number(currencyRate)) }</p></li> : ''
                        }

                    </ul></> : ''
                }

            </div>
            {
                reviews != '' &&
                <div className='reviews'>
                    <h4>{t('lang.common.reviewsOf')} {tutor.first_name} {tutor.last_name}</h4>
                     {
                        reviews.map((val, indx) =>{

                            let text = '';
                            let letter = '';
                            let userName = '';
                            var reviewHtml = '';

                            if(val.review_by != null){
                                text = val.review_by.name;
                                letter = text.charAt(0);
                                userName = val.review_by.name;
                                reviewHtml+=`<div className='reviewBox d-flex' key={${indx}}>
                                <div className='reviewBoxIcon'>
                                    <span>${letter}</span>
                                </div>
                                <div className='reviewBoxDesc'>
                                    <div className='d-flex align-items-center starArea w-100'>
                                        <span className='rtng'>${val.review_by.name}</span>
                                        <ul className='d-flex'>
                                            <li><FontAwesomeIcon icon={faStar} /></li>
                                            <li><FontAwesomeIcon icon={faStar} /></li>
                                            <li><FontAwesomeIcon icon={faStar} /></li>
                                            <li><FontAwesomeIcon icon={faStar} /></li>
                                            <li><FontAwesomeIcon icon={faStar} /></li>
                                        </ul>
                                    </div>
                                    <div className='dateMonth w-100'>${moment(val.created_at,"YYYYMMDD").fromNow()}</div>
                                    <div className='w-100 reviewBoxDescDtls'>
                                        <p>${val.review}</p>
                                    </div>
                                </div>
                            </div>`
                            }

                            return(parse(reviewHtml))
                        })
                     }

                    {/* <div className='w-100 d-flex justify-content-end'>
                      <Link to='/' className='showReview'>Show more Review</Link>
                    </div> */}
                </div>
            }

            {
                lessonTaken != '' && lessonTaken == 2 &&
                <div className="news_reply_form full">
                    <h3 className="m-b-10">{t('lang.common.RateThisTeacher')}</h3>
                    <ReviewForm type="3"  item={tutor.user_id} />
                </div>
            }


            </div>
        </div>
        </section>
    <Footer />
    <div className="modal fade book-lson-modal" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">{t('lang.common.bookLessonWith')} <span>{tutor.first_name} {tutor.last_name}</span></h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><FontAwesomeIcon icon={faTimes} /></button>
            </div>
            <div className="modal-body">
                {
                    teacherInstrument.length > 0 ? <>
                    <div className="book-lson-slt">
                        <label>{t('lang.common.instrumentsTeaches')}:</label>
                        <select className="form-control lson-select" id='instrumentSelect'>
                            {
                                teacherInstrument.map((vals,indx) => {
                                    return(<option key={indx} value={vals.id}> {languages == 'ar' ? vals.instruments.name_ar : vals.instruments.name } </option>)
                                })
                            }
                        </select>
                    </div>
                    </> : ''
                }
                {
                    teacherGenre.length > 0 ? <>
                        <div className="book-lson-slt">
                        <label>{t('lang.common.genre')}:</label>
                        <select className="form-control lson-select" id='genreSelect'>
                            {
                                teacherGenre.map((vals,indx) => {
                                    return(<option key={indx} value={vals.id}> { languages == 'ar' ? vals.genre.name_ar : vals.genre.name} </option>)
                                })
                            }
                        </select>
                        </div>
                    </> : ''
                }
                {
                    teacherRate != null ?
                    <>

                    <div className="lsono-dtls">
                        <h5>{t('lang.common.lessonFees')}:</h5>
                        <ul className="lson-choose d-flex flex-wrap">
                            {
                                teacherRate.lesson_one != null ? <li>
                                <input id="lesson1" type="radio" onClick={(e) =>{changeLesson(1)}} name="lesson" defaultChecked />
                                <label htmlFor="lesson1">{t('lang.common.oneLesson')} : {currencySymbol}{ tutor.teacher_currency == currency ? teacherRate.lesson_one :  Math.round(Number(teacherRate.lesson_one) * Number(currencyRate)) }</label>
                            </li> : ''
                            }
                            {
                                teacherRate.lesson_four != null ? <li>
                                <input id="lesson2" type="radio" onClick={(e) =>{changeLesson(2)}} name="lesson" />
                                <label htmlFor="lesson2">{t('lang.common.fourLesson')} : {currencySymbol}{ tutor.teacher_currency == currency ? teacherRate.lesson_four :  Math.round(Number(teacherRate.lesson_four) * Number(currencyRate)) }</label>
                            </li> : ''
                            }

                            {
                                teacherRate.lesson_eight != null ? <li>
                                <input id="lesson3" type="radio" onClick={(e) =>{changeLesson(3)}} name="lesson" />
                                <label htmlFor="lesson3">{t('lang.common.eightLesson')} : {currencySymbol}{ tutor.teacher_currency == currency ? teacherRate.lesson_eight :  Math.round(Number(teacherRate.lesson_eight) * Number(currencyRate)) }</label>
                            </li> : ''
                            }


                        </ul>
                    </div>
                    </> : ''
                }

                <div className="lsono-price-next mt-2 pt-2">
                    <ul className="d-flex justify-content-between align-items-center">
                        <li>{lessonTime} {t('lang.common.lessonsMonth')} : <span>{currencySymbol}{lessonFees}</span></li>
                        <li><Link to="#" onClick={bookingLesson} className="bookLesson">{t('lang.common.BuyLiveLessons')}</Link></li>
                    </ul>
                </div>

            </div>
            {/* <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary">Understood</button>
            </div> */}
            </div>
        </div>
        </div>
    </>
  )
}

export default TutorDetails