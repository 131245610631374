import React, { useEffect, useState } from 'react'
import Header from '../includes/Header'
import Footer from '../includes/Footer'

import { Link, useParams } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from 'axios';
import { Loader } from './Loader';
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const MusicStreamDetails = () => {

    const params = useParams();

    const [albumList,   setAlbumList]    = useState([]);
    const [musicBanner, setMusicBanner]  = useState([]);
    const [loading, setLoading]          = useState(false);
    const [t, i18n] = useTranslation('common');

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('language'));
        window.scrollTo(0, 0);
        setLoading(true);
       
        try {
            axios.get(process.env.REACT_APP_API_URL+`music-stream/${params.slug}`)
              .then(async (res) => {
                  
                if(res.status === 200){
                    setAlbumList(res.data.data.album);
                    
                    var musicType = document.getElementById("musicType"); 
                    musicType.textContent = res.data.data.type;
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                    
                }else{
                  return console.log("Error !");
                }
                
              }).catch(error => 
                console.log("Error !")
              );
        } catch (error) {
            console.error('error');
        }

        //Music Banners
        try {
            axios.get(process.env.REACT_APP_API_URL+"music-banners")
              .then(async (res) => {
                  
                 if(res.status === 200){
                    setMusicBanner(res.data.data.banners);
                }else{
                  return console.log("Error !");
                }
                
              }).catch(error => 
                console.log("Error !")
              );
        } catch (error) {
            console.error('error');
        }

    }, []);
    const options =  {
        loop: true,
        margin: 10,
        nav: true,
        dots:false,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            }
        },
    };
    
   
  return (
    <>
        {
            loading === true ? (<Loader />) : ''
        }
        <Header />
          <section>
              {
                musicBanner.length > 0 &&
              
              <OwlCarousel className='owl-theme newCourseBanSlider' {...options}>
                 {
                    musicBanner.map((val,indx) => {
                        return(<>
                            <div className='item' key={indx}>
                                <div className='newCourseBanInner'>
                                    <img src={process.env.REACT_APP_UPLOAD_URL+`music/banner/${val.banner_image}`} alt={val.banner_image} />
                                </div>
                            </div>
                        </>)
                    })
                 }
                  
              </OwlCarousel>
              }
          </section>

            
          <section className='newRealeaseSec'>
            <div className='container-fluid ph-40'>
                <div className='row g-3 g-2 g-md-3 g-lg-4'>
                    <div className='col-12'>
                        <div className='sectionTitle d-flex align-items-center justify-content-between'>
                            <h2 id='musicType'></h2>
                            
                        </div>
                    </div>
                    {
                        albumList.map((value,index) => {
                        return (<>
                            <div className='col-xl-2 col-lg-2 col-md-3 col-sm-4 col-12' key={value.id}>
                                <Link to={`/album/${value.slug}`} className='newRealeaseBox'>
                                    <div className='newRealeaseBoxTop'>
                                        <img src={process.env.REACT_APP_UPLOAD_URL+`album/banner/${value.photo}`} alt={value.title} />
                                    </div>
                                    <div className='newRealeaseBoxBtm'>
                                        <h4>{value.title}</h4>
                                        <p>{value.description}</p>
                                    </div>
                                </Link>
                            </div>
                        </>)     
                        })
                    }
                </div>
            </div>
          </section>

        <Footer />
    </>
  )
}

export default MusicStreamDetails