import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { Loader } from './Loader';
import Select from 'react-select';
import { Controller, useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'awesome-bootstrap-checkbox/demo/build.css';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const TeacherRegistration = (props) => {


    const [selectedOptions, setSelectedOptions] = useState([]);
    const [t, i18n] = useTranslation('common');
    const [settings, setSettings]              = useState([]);
    const [active, setActive]                  = useState(0);
    const [country, setCountry]                = useState([]);
    const [state, setStates]                   = useState([]);
    const [city, setCity]                      = useState([]);
    const [language, setLanguage]              = useState('');
    const [languageArr, setLanguageArr]        = useState([]);
    const [genreArr, setGenreArr]              = useState([]);
    const [instrumentsArr, setInstrumentsArr]  = useState([]);
    const [genre, setGenre]                    = useState([]);
    const [instrument, setInstrument]          = useState([]);
    const [errorMsg, setMessage]               = useState([]);
    const [successMsg, setSuccessMsg]          = useState([]);
    const [loading, setLoading]                = useState(false);

    const [studentPhone, setStudentPhone]      = useState();

    let languages = localStorage.getItem('language');

    //Form Elements
    //Student Form Registration
    const [resgiterAs, setResgiterAs] = useState(1);
    const [fullName, setFullName]     = useState('');
    const [email, setEmail]           = useState("");
    const [phone, setPhone]           = useState("");
    const [password, setPassword]     = useState("");

    //Teacher Form Registration
    const [firstName, setFirstName]                 = useState("");
    const [lastName, setLastname]                   = useState("");
    const [tutorPhone, setTutorphone]               = useState("");
    const [tutorEmail, setTutorEmail]               = useState("");
    const [tutorPassword, setTutorPassword]         = useState("");
    const [formCountry, setFormCountry]             = useState("");
    const [formState, setFormState]                 = useState("");
    const [dob, setDob]                             = useState("");
    const [referredBy, setReferredBy]               = useState("");
    const [schools, setSchools]                     = useState("");
    const [degree, setDegree]                       = useState("");
    const [classRoomSettings, setClassRoomSettings] = useState("");
    const [age, setAge]                             = useState();
    const [teacherAge, setTeacherAge]               = useState();

    let navigate = useNavigate();

    // const [formLanguage, setFormLanguage] = useState([{ value: "", label: "select language"}]);

    const [formGenre, setFormGenre]                 = useState("");
    const [formInstruments, setFormInstruments]     = useState("");
    const [resume, setResume]                       = useState(null);


    const { register, formState: { errors }, handleSubmit, control } = useForm();
    const onSubmit = (data)  => {

        try {
            setLoading(true);
            const formData = new FormData();
            formData.append("resgiterAs", 1);


                languageArr.map((v,i) => {
                    formData.append(`lang[${i}]`, v.value);
                });

                genreArr.map((v,i) => {

                    formData.append(`gener[${i}]`, v.value);
                });

                instrumentsArr.map((v,i) => {

                    formData.append(`instrument[${i}]`, v.value);
                });

                formData.append("firstName", data.firstName);
                formData.append("lastName", data.lastName);
                formData.append("phone", data.tutorPhone);
                formData.append("age", document.getElementsByName("teacherAge")[0].value);
                formData.append("email", data.tutoremail);
                formData.append("password", data.tutorpassword);
                formData.append("formCountry", formCountry);
                formData.append("formState", formState);
                formData.append("formCity", document.getElementsByName("formCity")[0].value);
                formData.append("dob", dob);
                formData.append("referredBy", referredBy);
                formData.append("schools", schools);
                formData.append("degree", degree);
                formData.append("classRoomSettings", classRoomSettings);
                formData.append("resume", resume);
                formData.append("teacherAge", teacherAge);
                formData.append("timezone", localStorage.getItem('timezones'));


            //console.log(Object.fromEntries(formData));

            axios({
                method: 'post',
                url: process.env.REACT_APP_API_URL+"auth/register",
                data: formData,
            }).then(function(res) {

                if(res.status === 200){
                    setLoading(false);
                    //localStorage.setItem('authToken', res.data.token);
                    if(res.data.data.user_type === 2){
                        toast(t('lang.common.verifyYourEmail'));
                        //setSuccessMsg('Thank you for registration. Admin will approve your account.');
                        setTimeout(() => {
                            navigate('/');
                        }, 3000);
                    }else if(res.data.data.user_type === 3){
                        toast(t('lang.common.verifyYourEmail'));
                        //window.location.replace(process.env.REACT_APP_LOGIN_URL+'?user='+res.data.data.email+'&tok='+res.data.token);
                        setTimeout(() => {
                            navigate('/');
                        }, 3000);
                    }
                }else if(res.status === 404){
                    setLoading(false);
                    setMessage(res.data.data);
                }

            }).catch(function (error){
                setLoading(false);
                setMessage(error.response.data.data);
                //console.log(error.response.data.data);
            });
        } catch (error) {
            setLoading(false);
            console.error('error',error);
        }
        //console.log(data);
    };

    let countries   = [];
    let states      = [];
    let cities      = [];
    // let languages   = [];
    let genres      = [];
    let instruments = [];


    function getStates(val){
        var getStates = '';

        setTimeout(() => {

            getStates = document.getElementsByName("country")[0].value;

            if(getStates !== ''){

                const stateData = { state: getStates}

                try {

                    axios({
                        method: 'post',
                        url: process.env.REACT_APP_API_URL+"states",
                        data: stateData,
                    }).then(function(res) {
                        if(res.status === 200){
                            setStates(res.data.data);
                            setFormCountry(getStates);
                        }else{
                          return console.log("Error !");
                        }

                    }).catch(function (error){
                        console.log(error);
                    });
                } catch (error) {
                //console.error('error');
                }
            }

        }, 1000);

    }

    const resetForm =() => {
        var clickeRadio = document.querySelector('input[name="register"]:checked').value;

        if(clickeRadio === 1){
            setActive(1);
            setResgiterAs(1);
        }else{
            setActive(0);
            setResgiterAs(0);
        }
    }

    function getCities(){
        var getCity = '';
        setTimeout(() => {

            getCity = document.getElementsByName("state")[0].value;

            if(getCity !== ''){

                const cityData = { city: getCity,}

                try {

                    axios({
                        method: 'post',
                        url: process.env.REACT_APP_API_URL+"city",
                        data: cityData,
                    }).then(function(res) {
                        if(res.status === 200){
                            setCity(res.data.data);
                            setFormState(getCity);
                        }else{
                          return console.log("Error !");
                        }

                    }).catch(function (error){
                        console.log(error);
                    });
                } catch (error) {
                    //console.error('error');
                }
            }

        }, 1000);
    }

    const onFileChange = event => {
        // Update the state
        setResume(event.target.files[0]);
    };

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('language'));

        var today = new Date()
        var dd = today.getDate() -4;
        //dd.setDate(today.getDate() + 1);
        var mm = today.getMonth()-4; //January is 0!
        var yyyy = today.getFullYear()-8;
        if(dd<10){
                dd='0'+dd
            }
            if(mm<10){
                mm='0'+mm
            }

        today = yyyy+'-'+mm+'-'+dd;
        setTimeout(() => {
            document.getElementById("db").setAttribute("max", today);
        }, 1000);



        if(localStorage.getItem('registerType') === 'student'){
            setActive(0);
            setResgiterAs(0);
        }else{
            setActive(1);
            setResgiterAs(1);
            //document.getElementById("teacherForm").reset();
        }
        //Get Logo

        if(settings.length <= 0){
          try {
            axios.get(process.env.REACT_APP_API_URL+"settings")
              .then(async (res) => {
                 if(res.status === 200){
                    setSettings(res.data.data);
                }else{
                  return console.log("Error !");
                }

              }).catch(error =>
                console.log("Error !")
              );
          } catch (error) {
            //console.error('error');
          }
        }

        //Get Country

        if(country.length <= 0){
            try {
              axios.get(process.env.REACT_APP_API_URL+"country")
                .then(async (res) => {
                   if(res.status === 200){
                    setCountry(res.data.data);
                  }else{
                    return console.log("Error !");
                  }

                }).catch(error =>
                  console.log("Error !")
                );
            } catch (error) {
              //console.error('error');
            }
        }

        //Get Languages
        if(language.length <= 0){
            try {
              axios.get(process.env.REACT_APP_API_URL+"get-list-data/language")
                .then(async (res) => {
                   if(res.status === 200){
                    const data = [];
                    res.data.data.map((element) => {
                        if(languages == 'ar'){
                            data.push({value: element.id , label: element.language_ar});
                        }else{
                            data.push({value: element.id , label: element.language});
                        }

                    });
                    setLanguage(data);
                  }else{
                    return console.log("Error !");
                  }

                }).catch(error =>
                  console.log("Error !")
                );
            } catch (error) {
              //console.error('error');
            }
        }

        //Get Genre
        if(genre.length <= 0){
            try {
              axios.get(process.env.REACT_APP_API_URL+"get-list-data/genre")
                .then(async (res) => {
                   if(res.status === 200){
                    const data = [];
                    res.data.data.map((element) => {
                        if(languages == 'ar'){
                            data.push({value: element.id , label: element.name_ar});
                        }else{
                            data.push({value: element.id , label: element.name});
                        }
                        //data.push({value: element.id , label: element.name});
                    });
                    setGenre(data);
                  }else{
                    return console.log("Error !");
                  }

                }).catch(error =>
                  console.log("Error !")
                );
            } catch (error) {
              //console.error('error');
            }
        }

        //Get Instrument
        if(genre.length <= 0){
            try {
              axios.get(process.env.REACT_APP_API_URL+"get-list-data/instrument")
                .then(async (res) => {
                   if(res.status === 200){
                    const data = [];
                    res.data.data.map((element) => {
                        if(languages == 'ar'){
                            data.push({value: element.id , label: element.name_ar});
                        }else{
                            data.push({value: element.id , label: element.name});
                        }
                        //data.push({value: element.id , label: element.name});
                    });
                    setInstrument(data);
                  }else{
                    return console.log("Error !");
                  }

                }).catch(error =>
                  console.log("Error !")
                );
            } catch (error) {
              //console.error('error');
            }
        }

        /* const input = document.querySelector("#Phone");
        intlTelInput(input, {
            // any initialisation options go here
        }); */

      },[]);

      if(country.length >0){
        country.map((v,i) => {
            countries.push({value: v.id, label: v.name,});
        })
      }

      if(state.length >0){
        state.map((v,i) => {
            states.push({value: v.id, label: v.name,});
        })
      }

      if(city.length >0){
        city.map((v,i) => {
            cities.push({value: v.id, label: v.city_name,});
        })
      }

    //   if(language.length >0){
    //     //console.log('lang = ', language);
    //     /* language.map((v,i) => {
    //         languages.push({value: v.id, label: v.language,});
    //     }) */
    //   }
      if(genre.length >0){
        genre.map((v,i) => {
            genres.push({value: v.id, label: v.name,});
        })
      }

      if(instrument.length >0){
        instrument.map((v,i) => {
            instruments.push({value: v.id, label: v.name,});
        })
      }
      const  handleChange = async (e) => {
        setLanguageArr(e);
      }

    const  handleGenreChange = async (e) => {
        setGenreArr(e);
    }

    const  handleInstruChange = async (e) => {
        setInstrumentsArr(e);
    }

     const handleFields = async (e) => {
        //console.log("e.target.name", e.target.name);
        if (e.target.name === "tutorPhone") {
            setTutorphone(e.target.value);
        }else if (e.target.name === "tutoremail") {
            setTutorEmail(e.target.value);
        }else if (e.target.name === "tutorpassword") {
            setTutorPassword(e.target.value);
        }else if (e.target.name === "dob") {
            setDob(e.target.value);
        }else if (e.target.name === "referredBy") {
            setReferredBy(e.target.value);
        }else if (e.target.name === "schools") {
            setSchools(e.target.value);
        }else if (e.target.name === "degree") {
            setDegree(e.target.value);
        }else if (e.target.name === "class_room_settings") {
            setClassRoomSettings(e.target.value);
        }else if (e.target.name === "teacherAge") {
            setTeacherAge(e.target.value);
        }

     }


    return (
        <>
        {
            loading === true ? (<Loader />) : ''
        }
            <section className='Login d-flex justify-content-end' style={{
  backgroundImage: "url(" + process.env.REACT_APP_UPLOAD_URL+'sitesettings/'+settings.login_banner + ")",
  'minHeight': '100vh',
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
}}>
                <div className='regWrap'>
                    <div className="logo">
                        <Link to="/">
                        <img src={process.env.REACT_APP_UPLOAD_URL+'sitesettings/'+settings.logo} alt="" />
                        </Link>
                    </div>
                    <h2>{t('lang.common.registerMsg')}</h2>
                    <h6>{t('lang.common.regisretSubMsg')}</h6>


                        {/* <div className="success">{successMsg}</div> */}
                        <ToastContainer autoClose={2000} />
                        <div className="message text-center errors"><ul>{(errorMsg ?
                            (
                                errorMsg.map((v,i) => {
                                    return(<><li>{v}</li></>)
                                })
                            )

                            : null)}</ul></div>
                        {
                             <div className='regWrapInner forTutor' id='registerTutor'>
                             <form className="needs-validation" id="teacherForm" onSubmit={handleSubmit(onSubmit)}>
                                 <input type="hidden"  value={resgiterAs} name="resgiterAs" />
                             <div className="row g-3 g-2 g-md-3 g-lg-4">
                                 <div className="col-md-4 form-floating">
                                     <input type="text" name="firstName" className="form-control" id="fn"  placeholder="First name" {...register("firstName", { required: true, maxLength: 30 })} onChange={ e => { handleFields(e) }} />
                                     <label htmlFor="fn">{t('lang.common.firstName')}*</label>
                                     {errors?.firstName?.type === "required" && <p className='errors'>{t('lang.validation.firstName')}</p>}
                                 </div>
                                 <div className="col-md-4 form-floating">
                                     <input type="text" name="lastName" className="form-control" id="ln" placeholder="Last name"  {...register("lastName", { required: true, maxLength: 30 })} onChange={ e => { handleFields(e) }} />
                                     <label htmlFor="ln">{t('lang.common.lastName')}*</label>
                                     {errors?.lastName?.type === "required" && <p className='errors'>{t('lang.validation.lastName')}</p>}
                                 </div>
                                 <div className="col-md-4 form-floating mb-3">
                                     <input type="number" name="teacherAge" className="form-control" value={teacherAge} id="floatingInput" placeholder="30" {...register("teacherAge", { required: true, maxLength:2  })} onChange={ e => { handleFields(e) }} />
                                     <label htmlFor="floatingInput">{t('lang.common.age')}*</label>

                                     {errors?.teacherAge?.type === "required" && <p className='errors'>{t('lang.validation.age')}</p>}
                                     {errors?.teacherAge?.type === "maxLength" && <p className='errors'>{t('lang.validation.maximum2')}</p>}
                                 </div>
                                 <div className="col-md-6 form-floating piArea">

                                <Controller
                                     control={control}
                                     name="tutorPhone"
                                     rules={{ required: true }}
                                     render={({ field: { ref, ...field } }) => (
                                     <PhoneInput
                                         {...field}
                                         inputExtraProps={{
                                         ref,
                                         required: true,
                                         autoFocus: true
                                         }}
                                         country={"us"}
                                         //onlyCountries={["in"]}
                                         countryCodeEditable={false}
                                         specialLabel={"Player Mobile Number"}
                                     />
                                     )}
                                />

                                 {/*  <input type="text" name="phone" className="form-control" value={phone} id="Phone" placeholder='Phone' {...register("phone", { required: true, maxLength: 20 })} onChange={ e => { handleFields(e) }} /> */}

                                 {errors?.tutorPhone?.type === "required" && <p className='errors'>{t('lang.validation.phone')}</p>}
                                 {errors?.tutorPhone?.type === "maxLength" && (
                                     <p className='errors'>{t('lang.validation.phone20')}</p>
                                 )}

                                 </div>
                                 <div className="col-md-6 form-floating">
                                     <input type="email" className="form-control" id="ea" value={tutorEmail} placeholder="name@example.com" name="tutoremail" {...register("tutoremail", { required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/  })} onChange={ e => { handleFields(e) }}  />
                                     <label htmlFor="ea">{t('lang.common.emailAddress')}*</label>
                                     {errors?.tutoremail?.type === "required" && <p className='errors'>{t('lang.validation.email')}</p>}
                                     {errors?.tutoremail?.type === "pattern" && <p className='errors'>{t('lang.validation.emailPattern')}</p>}
                                 </div>

                                 <div className="col-md-6 form-floating">
                                     <input type="password" className="form-control" value={tutorPassword} id="pw" placeholder="Password" name="tutorpassword"  {...register("tutorpassword", { required: true, minLength: 8, pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/ })} onChange={ e => { handleFields(e) }} />
                                     <label htmlFor="pw">{t('lang.common.password')}*</label>
                                     {errors?.tutorpassword?.type === "required" && <p className='errors'>{t('lang.validation.passwordRequired')}</p>}
                                     {errors?.tutorpassword?.type === "minLength" && (
                                         <p className='errors'>{t('lang.validation.phone8')}</p>
                                     )}
                                     {errors?.tutorpassword?.type === "pattern" && <p className='errors'>{t('lang.validation.phoneRegex')}</p>}
                                 </div>
                                 <div className="col-md-6">
                                     <label htmlFor="country">{t('lang.common.country')}*</label>
                                     <Select name="country" options={countries} onChange={ getStates } />
                                 </div>
                                 <div className="col-md-6">
                                     <label htmlFor="state">{t('lang.common.state')}*</label>
                                     <Select name="state" options={states} onChange={ getCities } />
                                 </div>
                                 <div className="col-md-6">
                                     <label htmlFor="formCity">{t('lang.common.townCity')}*</label>
                                     <Select name="formCity" id="formCity" options={cities}  />
                                 </div>
                                 <div className="col-md-6 form-floating">
                                     <input type="date" name='dob' className="form-control" id="db" placeholder="(MM/DD/YYYY)" {...register("dob", { required: true })} onChange={ e => { handleFields(e) }} />
                                     <label htmlFor="db">{t('lang.common.dob')}*</label>
                                     {errors?.dob?.type === "required" && <p className='errors'>{t('lang.validation.dateOfBirth')}</p>}
                                 </div>
                                 <div className="col-md-6 form-floating">
                                     <input type="text" name='referredBy' className="form-control"  id="rb" placeholder={t('lang.common.referredBy')} onChange={ e => { handleFields(e) }} />
                                     <label htmlFor="rb">{t('lang.common.referredBy')}</label>
                                     <div className="valid-feedback">

                                     </div>
                                 </div>
                                 <div className="col-12 form-floating radioWrap">
                                     <ul className='d-flex flex-wrap'>
                                         <li className='radioTitle'>{t('lang.common.class12')}*</li>
                                         <li className='form-check abc-radio'>
                                             <input className="form-check-input" type="radio" name="schools" id="schools1" value="1"  {...register("schools", { required: true })} onChange={ e => { handleFields(e) }} />
                                             <label className="form-check-label" htmlFor="schools1">Yes</label>
                                         </li>
                                         <li className='form-check abc-radio'>
                                             <input className="form-check-input" type="radio" name="schools" id="schools2" value="0"  {...register("schools", { required: true })} onChange={ e => { handleFields(e) }} />
                                             <label className="form-check-label" htmlFor="schools2">No</label>
                                         </li>
                                     {errors?.schools?.type === "required" && <p className='errors'>{t('lang.common.class12')}</p>}
                                     </ul>
                                 </div>
                                 <div className="col-12 form-floating radioWrap">
                                     <ul className='d-flex flex-wrap'>
                                     <li className='radioTitle'>{t('lang.common.4yearDegree')}*</li>
                                     <li className='form-check abc-radio'>
                                         <input className="form-check-input" type="radio" name="degree" id="degree1" value="1"  {...register("degree", { required: true })} onChange={ e => { handleFields(e) }} />
                                         <label className="form-check-label" htmlFor="degree1">Yes</label>
                                     </li>
                                     <li className='form-check abc-radio'>
                                         <input className="form-check-input" type="radio" name="degree" id="degree2" value="0"  {...register("degree", { required: true })} onChange={ e => { handleFields(e) }} />
                                         <label className="form-check-label" htmlFor="degree2">No</label>
                                     </li>
                                     </ul>
                                     {errors?.degree?.type === "required" && <p className='errors'>{t('lang.common.4yearDegree')}</p>}
                                 </div>
                                 <div className="col-12 form-floating radioWrap">
                                     <ul className='d-flex flex-wrap'>
                                     <li className='radioTitle'>{t('lang.common.classRoomSettings')}*</li>
                                     <li className='form-check abc-radio'>
                                         <input className="form-check-input" type="radio" name="class_room_settings" id="class_room_settings1" value="1"  {...register("class_room_settings", { required: true })} onChange={ e => { handleFields(e) }} />
                                         <label className="form-check-label" htmlFor="class_room_settings1">Yes</label>
                                     </li>
                                     <li className='form-check abc-radio'>
                                         <input className="form-check-input" type="radio" name="class_room_settings" id="class_room_settings2" value="0" {...register("class_room_settings", { required: true })} onChange={ e => { handleFields(e) }} />
                                         <label className="form-check-label" htmlFor="class_room_settings2">No</label>
                                     </li>
                                     </ul>
                                     {errors?.class_room_settings?.type === "required" && <p className='errors'>{t('lang.common.classRoomSettings')}</p>}
                                 </div>

                                 <div className="col-12">

                                     <label htmlFor="selectLanguage">{t('lang.common.selectLanguage')}</label>
                                     <Select
                                         name="language"
                                         isMulti={true}
                                         onChange={handleChange}
                                         options={language}
                                     />

                                 </div>

                                 <div className="col-12">
                                     <label htmlFor="selectStyles">{t('lang.common.genresStyles')}</label>
                                     <Select
                                         name="genre"
                                         isMulti={true}
                                         onChange={handleGenreChange}
                                         options={genre}
                                     />

                                     {/* <Select name="genre" isMulti options={genres}  classNamePrefix="Select Genres" onChange={ e => { handleFields(e) }} /> */}

                                 </div>

                                 <div className="col-12">
                                     <label htmlFor="whatWnstruments">{t('lang.common.whatInstruments')}</label>
                                     <Select
                                         name="instruments"
                                         isMulti={true}
                                         onChange={handleInstruChange}
                                         options={instrument}
                                     />

                                     {/* <Select name="instruments" isMulti options={instruments}  classNamePrefix="Select Instruments" onChange={ e => { handleFields(e) }} /> */}

                                 </div>

                                 <div className="col-md-12">
                                     <div>
                                         <label htmlFor="formFile" className="form-label"><strong>{t('lang.common.uploadingYourResume')}</strong></label>
                                         <input className="form-control uploadFile" type="file" id="formFile" {...register("resume", { required: false })} onChange={onFileChange} />
                                         {errors?.resume?.type === "required" && <p className='errors'>{t('lang.validation.resume')}</p>}
                                     </div>
                                 </div>

                                 <div className="col-12">
                                     <div className="form-check abc-checkbox abc-checkbox-info">

                                         <input className="form-check-input" type="checkbox" id="singleCheckbox1" value="1" aria-label="Single checkbox One" {...register("termsconditions", { required: true})} />
                                         <label className="form-check-label" htmlFor="singleCheckbox1">
                                             <Link to="/pages/terms-and-conditions" target="_blank">{t('lang.common.agree')}</Link>
                                         </label>
                                         {errors?.termsconditions?.type === "required" && <p className='errors'>{t('lang.validation.agreeMsg')}</p>}
                                     </div>

                                 </div>
                                 <div className="col-12 text-center">
                                     <button className="logInBtn width-auto" type="submit">{t('lang.common.registerBtn')}</button>
                                 </div>
                             </div>
                             </form>
                         </div>
                        }
                </div>
            </section>
        </>
    )
}

export default TeacherRegistration