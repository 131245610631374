import React, { useEffect, useState } from 'react'
import Header from '../includes/Header'
import Footer from '../includes/Footer'
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from 'axios';
import { Loader } from './Loader';
import ReactJkMusicPlayer from 'react-jinke-music-player';
import "react-jinke-music-player/assets/index.css";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const AlbumDetails = () => {

    const [t, i18n] = useTranslation('common');
    const params = useParams();

    const options = {
        loop: true,
        margin: 40,
        nav: true,
        dots: false,
        responsive: {
            0: {
                items: 2,
            },
            540: {
                items: 3,
            },
            768: {
                items: 4,
                margin: 10,
            },
            1000: {
                items: 5,
                margin: 20,
            },
            1135: {
                items: 6,
            },
        },
    };

    const [track, setTracks]                                = useState([]);
    const [album, setAlbum]                                 = useState([]);
    const [loading, setLoading]                             = useState(false);
    const [audioList,setAudioList]                          = useState([]);
    const [audioInstance,setAudioInstance]                  = useState(null);
    const [spotifyPlaylist, setSpotifyPlaylist]             = useState([]);
    const clientId                                          = '3cb75e19b41244598fe3226ded37ffdc';
    const clientSecret                                      = 'b3e9167eaf9a45348bcbc055f5772a58';
    const [albumTitle, setAlbumTitle]                       = useState('');
    const [albumFirstTrackBanner, setAlbumFirstTrackBanner] = useState('');
    const [totalItem, setTotalItem]                         = useState(0);
    const [otherSingers, setOtherSingers]                   = useState([]);
   // var audioInstance = null;


    /* const updateAudioList = ()=>{
        const newAudioList = [...audioList];
        
        newAudioList.splice(2,0,{name:'Paradise',duration: 10,singer:'Coldplay',musicSrc:'https://res.cloudinary.com/dvwayyj5d/video/upload/v1617727909/Coldplay_-_Paradise_Official_Video_px5u9e.mp3'})
        setAudioList(newAudioList); 
    } */

    function millisToMinutesAndSeconds(millis) {
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
    }

    useEffect(() => {
       
        i18next.changeLanguage(localStorage.getItem('language'));
        window.scrollTo(0, 0);
        setLoading(false);

        var products = localStorage.getItem('products');
        if(products !== '' && products !==null){
            setTotalItem(JSON.parse(localStorage.getItem('products')).length);
        }

        //Spotify
        try {
            const headers = {
                'Content-Type' : 'application/x-www-form-urlencoded',
                'Authorization' : 'Basic ' + btoa(clientId + ':' + clientSecret)
              }

            axios({
                method: 'post',
                headers: headers,
                url: "https://accounts.spotify.com/api/token",
                data: 'grant_type=client_credentials', 
            }).then(function(res) {
                
                if(res.data.access_token !== ''){
                    //Other Singers
                    axios.get(`https://api.spotify.com/v1/users/jamjhjfnovm19m39knlhekgnp/playlists?limit=30`, {headers: { 'Authorization' : 'Bearer ' + res.data.access_token}})  
                    .then(async (res) => {
                        setOtherSingers(res.data.items);
                    }).catch(error => 
                        console.log("Error !")
                    ); 

                    //End Other Singers

                    //Fetch playlist data
                    var access_token = res.data.access_token;
                    axios.get(`https://api.spotify.com/v1/playlists/${params.slug}`, {headers: { 'Authorization' : 'Bearer ' + access_token}})
                    .then(async (res) => {
                        
                        if(res.status === 200){
                            var tracks = res.data.tracks.items;
                         
                            //setSpotifyPlaylist(res.data.tracks.items);
                            setTracks(res.data.tracks.items);
                            setAlbumTitle(res.data.name);
                          
                            setAlbumFirstTrackBanner(tracks[0].track.album.images[0].url);

                            var texts = document.getElementById("songTitle"); 
                            texts.textContent = tracks[0].track.name;
                           
                            let arr= [];
                            let ii =1;
       
                            tracks.map((val,index)=>{
                                var artistNames = '';
                            
                                val.track.artists.map((v,i) => {
                                    artistNames += v.name+', ';
                                });
                                artistNames = artistNames.replace(/,\s*$/, "");
                                var obj={};
                                var previewTrack = '';

                                if(val.track.preview_url !== null){
                                    previewTrack = val.track.preview_url;
                                }
                                
                                obj['musicSrc']  = previewTrack;
                                obj['name']      = val.track.name;
                                obj['duration']  = millisToMinutesAndSeconds(val.track.duration_ms);
                                obj['singer']    = artistNames;
                                obj['cover']     = val.track.album.images[0].url;
                                if(val.track.preview_url !== null){
                                    // arr.push(obj);     
                                    arr[ii] = obj
                                    ii++;
                                }
                            });

                            setTimeout(() => {
                                setAudioList(arr);
                              }, 1000)
                            
                  
                            setTimeout(() => {
                                setLoading(false);
                            }, 1000);

                        }else{
                            return console.log("Error !");
                        }
                        
                    }).catch(error => 
                        console.log("Error !")
                    );


                }
                
            }).catch(function (error){
                console.log(error);
            });



        } catch (error) {
            console.error('error');
        }
        //Spotify End

        /* try {
            axios.get(process.env.REACT_APP_API_URL+`albums/${params.slug}`)
              .then(async (res) => {
                  
                 if(res.status === 200){
                    setTracks(res.data.data.album.tracks);
                    setAlbum(res.data.data.album);

                    let tracks = res.data.data.album.tracks;

                    var texts = document.getElementById("songTitle"); 
                    texts.textContent = res.data.data.album.tracks[0].title;
                    
                    let arr= [];
                    
                    tracks.map((val,i)=>{
                        var obj={};
                        obj['musicSrc']  = process.env.REACT_APP_UPLOAD_URL+'album/track/sample/'+val.sample_audio;
                        obj['name']      = val.title;
                        obj['duration']  = val.sample_duration;
                        obj['singer']    = res.data.data.album.album_author;
                        obj['cover']     = process.env.REACT_APP_UPLOAD_URL+'album/banner/'+res.data.data.album.photo;
                        arr.push(obj);
                    });
                    setAudioList(arr);

                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                    
                }else{
                  return console.log("Error !");
                }
                
              }).catch(error => 
                console.log("Error !")
              );
          } catch (error) {
            console.error('error');
          } */

    }, [params.slug]);

    return (
        <>
        {
            loading === true ? (<Loader />) : ''
        }
            <Header itemCount={totalItem} />
            <ReactJkMusicPlayer showMediaSession={true} showDownload={false} /* showPlay={false} */ autoPlay={false} className='playerWrap' mode='full' theme='dark'
                getAudioInstance={(instance) => {
                    setAudioInstance(instance)
                  }}
                audioLists={audioList}
                /* quietUpdate
                clearPriorAudioLists */  
                getContainer={() => document.body}
            />
            <section className='playlist d-flex flex-wrap'>
                <div className='playlistLeft'>
                    <div className='playlistLeftInner'>
                        <h5 id='nowPlaying'></h5>
                        <h3 id='songTitle'></h3>
                        <div className='pliImg'>
                            <img src={albumFirstTrackBanner} alt="" />
                        </div>
                        <div className='pliBtm d-flex w-100 align-items-center'>
                            <div className='pliBtmLeft'>
                                <span><img src={albumFirstTrackBanner} alt="" /></span>
                            </div>
                            <div className='pliBtmMid'>
                                <span>{albumTitle}</span>
                            </div>
                            <div className='pliBtmRight'>
                                <Link to="#" onClick={() => {audioInstance.playByIndex(1); var text = document.getElementById("nowPlaying"); text.textContent = "Now Playing"; } }><FontAwesomeIcon icon={faPlay} /></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='playlistRight'>
                    <div className='playListTop'>
                        <ul className='d-flex'>
                            <li className=''>{t('lang.common.track')}</li>
                            <li className='text-center'>{t('lang.common.artists')}</li>
                            <li className='text-center'>{t('lang.common.duration')}</li>
                            <li className='text-center'>{t('lang.common.action')}</li>
                        </ul>
                    </div>
                    <div className='playListBtm'>
                        {
                            track.map((val,indx) => {
                                var artistNames = '';

                                val.track.artists.map((v,i) => {
                                    artistNames += v.name+', ';
                                });
                                artistNames = artistNames.replace(/,\s*$/, "");
                                return(
                                    <>
                                    {
                                        (val.track.preview_url !== null) ?
                                        <ul className='d-flex align-items-center w-100'>
                                        <li className={indx}><span><img src={val.track.album.images[0].url} alt="" /></span>  {val.track.name}</li>
                                        <li className='text-center'>{artistNames}</li>
                                        <li className='text-center'>{millisToMinutesAndSeconds(val.track.duration_ms)}</li>
                                        <li className='text-center'>
                                            {
                                                val.track.preview_url !== null ? <button  onClick={() => { audioInstance.playByIndex(indx); var text = document.getElementById("nowPlaying"); text.textContent = "Now Playing"; var texts = document.getElementById("songTitle"); 
                                                texts.textContent = val.track.name; }} className='playNowTableBtn'>{t('lang.common.playNow')} <FontAwesomeIcon icon={faPlay} /></button>:''
                                            }
                                            </li>
                                        </ul> : ''
                                    }
                                    
                                </>)
                            })
                        }
                    </div>
                </div>
            </section>
            {/* <section className='similarArtist'>
                <div className='container-fluid ph-40'>
                    <div className='row'>
                        <div className='col-12'>
                            <h3>Similar Artist</h3>
                        </div>
                        <div className='col-12'>
                            <OwlCarousel className='owl-theme similarArtistSlider' {...options}>
                                {
                                    otherSingers.map((val,indx) => {
                                        return(
                                        
                                            albumTitle !== val.name ? <div className='item'>
                                            <Link to={`/album/${val.id}`} className='similarArtistInner'>
                                                <img src={val.images[0].url} alt={val.name} />
                                            </Link>
                                        </div> : ''
                                       
                                        )
                                    })
                                }
                                
                            </OwlCarousel>
                        </div>

                    </div>
                </div>
            </section> */}
             <iframe style={{borderRadius: 12}} src={`https://open.spotify.com/embed/playlist/${params.slug}?utm_source=generator`} width="100%" height={380} frameBorder={0} allowFullScreen allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy" />

            <Footer />
        </>
    )
}

export default AlbumDetails