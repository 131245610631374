import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from './Loader';
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const Login = () => {
    const { register, formState: { errors }, handleSubmit } = useForm();
    let navigate = useNavigate();
    const [settings, setSettings]      = useState([]);
    const [password, setPassword]      = useState("");
    const [email, setEmail]            = useState("");
    const [errorMsg, setMessage]       = useState([]);
    const [error404Msg, set404Message] = useState([]);
    const [loading, setLoading]        = useState(false);
    const [t, i18n] = useTranslation('common');

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('language'));
        if(settings.length <= 0){
          try {
            axios.get(process.env.REACT_APP_API_URL+"settings")
              .then(async (res) => {
                 if(res.status === 200){
                    setSettings(res.data.data);
                }else{
                  return console.log("Error !");
                }

              }).catch(error =>
                console.log("Error !")
              );
          } catch (error) {
            //console.error('error');
          }
        }

      },[]);

      const onSubmit = (data)  => {
        try {

            setLoading(true);

            const formData = new FormData();
            formData.append("email", data.email);
            formData.append("password", data.password);

            //console.log(Object.fromEntries(formData));

            axios({
                method: 'post',
                url: process.env.REACT_APP_API_URL+"auth/login",
                data: formData,
            }).then(function(res) {

                if(res.status === 200){
                    setLoading(false);
                    localStorage.setItem('authToken', res.data.token);
                    localStorage.setItem('loginUser', res.data.user);
                    localStorage.setItem('loginUserEmail', res.data.data.email);
                    localStorage.setItem('loginUserName', res.data.data.name);
                    localStorage.setItem('loginUserRole', res.data.data.user_type);
                    if(res.data.data.user_type === '2'){
                        localStorage.setItem('registerType', 'tutor');
                    }else if(res.data.data.user_type === '3'){
                        localStorage.setItem('registerType', 'student');
                    }

                    window.location.replace(process.env.REACT_APP_LOGIN_URL+'?user='+res.data.data.email+'&tok='+res.data.token);

                }else if(res.data.data.status === 202){
                    setLoading(false);
                    //set404Message(res.data.data);
                    set404Message(t('lang.common.accountReview'));
                }

            }).catch(function (error){

                setLoading(false);

                if(error.response.data.status == 7){
                    setMessage(t('lang.common.wrongDetails'));
                }else if(error.response.data.status == 5){
                    setMessage(t('lang.common.accountReview'));
                }else if(error.response.data.status == 6){
                    setMessage(t('lang.common.pleaseVerifyYourEmail'));
                }else if(error.response.data.status == 8){
                    setMessage(t('lang.common.invalidUser'));
                }else if(error.response.data.status == 9){
                    setMessage(t('lang.common.userNotFound'));
                }else{
                    setMessage(error.response.data.data);
                }


                //setMessage(error.response.data.data);
                //setMessage(t('lang.common.accountReview'));
            });
        } catch (error) {
            setLoading(false);
            //console.error('error');
        }
        //console.log(data);
    };


    /* const handleFields = async (e) => {
        if (e.target.name === "email") {
            setEmail(e.target.value);
        }else if (e.target.name === "password") {
            setPassword(e.target.value);
        }
     } */

    return (
        <>
        {
            loading === true ? (<Loader />) : ''
        }
            <section className='Login' style={{
  backgroundImage: "url(" + process.env.REACT_APP_UPLOAD_URL+'sitesettings/'+settings.login_banner + ")",
  'min-height': '100vh',
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
}}>
                <div className='loginWrap d-flex justify-content-end'>
                    <div className='LoginInner d-flex align-items-center'>
                        <div className='loginForm'>
                            <div className="logo">
                                <Link to="/">
                                    <img src={process.env.REACT_APP_UPLOAD_URL+'sitesettings/'+settings.logo} alt="" />
                                </Link>
                            </div>
                            <div className='loginFormInner'>
                            <div className="logins message errors"><ul><li>{errorMsg}</li></ul></div>
                            {/* {error404Msg} */}
                                <h2>{t('lang.common.soLetsLogIn')}</h2>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-floating mb-3">
                                        <input type="email" name="email" className="form-control" id="floatingInput" placeholder="name@example.com" {...register("email", { required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/  })}  autoComplete="off" />
                                        <label htmlFor="floatingInput">{t('lang.common.emailAddress')}</label>
                                        {errors?.email?.type === "required" && <p className='errors'>{t('lang.validation.enterEmail')}</p>}
                                        {errors?.email?.type === "pattern" && <p className='errors'>{t('lang.validation.emailPattern')}</p>}
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="password" name="password"  className="form-control" id="floatingPassword" placeholder="Password" {...register("password", { required: true, minLength: 6 })}  autoComplete="off" />
                                        <label htmlFor="floatingPassword">{t('lang.common.password')}</label>
                                        {errors?.password?.type === "required" && <p className='errors'>{t('lang.validation.passwordRequired')}</p>}
                                        {errors?.password?.type === "minLength" && (
                                            <p className='errors'>{t('lang.validation.minimumLength')}</p>
                                        )}
                                    </div>
                                    <div className="form-group d-flex justify-content-end mb-3">
                                        <Link to="/forgot-password">{t('lang.common.forgotPassword')}</Link>
                                    </div>
                                    <div className="form-group mb-3">
                                          <button type="submit" className="logInBtn">{t('lang.common.logIn')}</button>
                                    </div>
                                    <div className="form-group mb-3 text-center">
                                          <p>{t('lang.common.dontAccount')} <br /><Link to="/register-teacher">{t('lang.common.signUpAsTeacher')}</Link><br /><Link to="/register-student">{t('lang.common.signUpAsStudent')}</Link></p>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Login