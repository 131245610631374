import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import { Loader } from '../pages/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import { faUser, faPencilAlt} from '@fortawesome/free-brands-svg-icons'
import { faUser, faEdit, faSearch,faUsers,faGraduationCap } from '@fortawesome/free-solid-svg-icons'
//   import { faFacebook } from '@fortawesome/fontawesome-svg-core'
import i18next from "i18next";
import {useTranslation} from "react-i18next";

let languages = localStorage.getItem('language');
function Header(props) {
  const [animate, setAnimate] = useState(false);

  const [t, i18n] = useTranslation('common');

  function handleClick() {
    setAnimate(prevCheck => !prevCheck);
  }
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [search, setSearch] = useState('');
  const [searchError, setSearchError] = useState('');

  const [categoryList, setCategoryList] = useState([]);
  const [settings, setSettings] = useState([]);
  const [item, setitem] = useState(0);
  // const [cartItem, setCartItems] = useState(0);
  const [loading, setLoading]  = useState(false);

  const [changeTotalItems, setChangetotalItems] = useState(1);

  const handleToggle = () => {
    setIsOpen({ isOpen: !this.state.isOpen });
  }
  const handleToggleSearch = () => {
    setIsOpenSearch(!isOpenSearch);
  }

  const setRegistrationType = (event) => {
    localStorage.setItem('registerType', event.currentTarget.dataset.type);
  }

  const resizeHeaderOnScroll = () => {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop,
      shrinkOn = 100,
      headerEl = document.getElementById("mainHeader");

    if (distanceY > shrinkOn) {
      headerEl.classList.add("sticky");
    } else {
      headerEl.classList.remove("sticky");
    }
  }

  let authToken    = localStorage.getItem('authToken');
  let registerType = localStorage.getItem('registerType');
  let userEmail    = localStorage.getItem('loginUserEmail');
  let userName     = localStorage.getItem('loginUserName');
  let loginUser    = localStorage.getItem('loginUser');

  let firstLetter = '';
  if(userName != null){
    firstLetter = userName.charAt(0);
  }

  let navigate = useNavigate();

  const location = useLocation();
  //destructuring pathname from location
  const { pathname } = location;
  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  let getProducts = [];

  useEffect(() => {
    window.addEventListener("scroll", resizeHeaderOnScroll);
    localStorage.setItem("timezones", Intl.DateTimeFormat().resolvedOptions().timeZone);



    /* try {

      axios.post(process.env.REACT_APP_API_URL + "change-currency?currency="+1)
            .then(async (res) => {
            if (res.status === 200) {
                localStorage.setItem('currencyRate', res.data.price);
            } else {
                return console.log("Error !");
            }
            }).catch(error =>
            console.log("Error !")
            );
    } catch (error) {
        console.error('error', error);
    } */

    if(localStorage.getItem('language') == null){
      localStorage.setItem('language', 'en');
    }

    if(localStorage.getItem('currency') == null){
      localStorage.setItem('currency', 1);
    }

    if(localStorage.getItem('currencyCode') == null){
      localStorage.setItem('currencyCode', 'USD');
    }

    let currency = localStorage.getItem('currency');

    if(currency != ''){
      try {

        axios.post(process.env.REACT_APP_API_URL + "change-currency?currency="+currency)
              .then(async (res) => {
              if (res.status === 200) {
                  localStorage.setItem('currencyRate', res.data.price);
              } else {
                  return console.log("Error !");
              }
              }).catch(error =>
              console.log("Error !")
              );
      } catch (error) {
          console.error('error', error);
      }
    }

    if (categoryList.length <= 0) {
      try {
        axios.get(process.env.REACT_APP_API_URL + "courses-category")
          .then(async (res) => {
            if (res.status === 200) {
              setCategoryList(res.data.data);
            } else {
              return console.log("Error !");
            }

          }).catch(error =>
            console.log("Error !")
          );
      } catch (error) {
        console.error('error');
      }
    }

    if (localStorage.getItem('products') != '' && localStorage.getItem('products') != null) {
      getProducts = JSON.parse(localStorage.getItem('products'));
    }


    if (props.itemCount) {
      setitem(getProducts.length);
      //console.log("getProducts.length1", getProducts.length);
    } else {
      //setitem(getProducts ? getProducts.length : 0);

      setitem(getProducts ? getProducts.length : 0);
      //props.itemCount = getProducts ? getProducts.length : 0;
      //console.log("getProducts.length2", getProducts);
    }
    /* let getProducts = JSON.parse(localStorage.getItem('products'));

  setCartItems(getProducts ? getProducts.length : 0);
  console.log('getProducts',getProducts); */



    //Get Logo

    if (settings.length <= 0) {
      try {
        axios.get(process.env.REACT_APP_API_URL + "settings")
          .then(async (res) => {
            if (res.status === 200) {
              setSettings(res.data.data);
            } else {
              return console.log("Error !");
            }

          }).catch(error =>
            console.log("Error !")
          );
      } catch (error) {
        //console.error('error');
      }
    }

    if (authToken != null) {

      try {
        axios.post(process.env.REACT_APP_API_URL + "auth/is-logged-in?token=" + authToken)
          .then(async (res) => {
            if (res.status === 200) {
              if (res.data.data == 0) {
                localStorage.removeItem('authToken');
                localStorage.removeItem('loginUser');
                localStorage.removeItem('loginUserRole');
                localStorage.removeItem('products');
                setChangetotalItems(0);

              }
            } else {
              return console.log("Error !");
            }

          }).catch(error =>
            console.log("Error !")
          );
      } catch (error) {
        //console.error('error');
      }
    }
  }, []);

  //Currency Convert

  const changeCurrency =(e) => {

    localStorage.setItem('currency', e.target.value);

    //if(e.target.value == 2){

    if(e.target.value == 2){
      localStorage.setItem('currencyCode', 'GBP');
    }else{
      localStorage.setItem('currencyCode', 'USD');
    }

      try {

        axios.post(process.env.REACT_APP_API_URL + "change-currency?currency="+e.target.value)
              .then(async (res) => {
              if (res.status === 200) {
                  localStorage.setItem('currencyRate', res.data.price);
              } else {
                  return console.log("Error !");
              }
              }).catch(error =>
              console.log("Error !")
              );
      } catch (error) {
      //console.error('error');
      }

    //}

    setTimeout(() => {
      window.location.reload();
    }, 2000);

  }

  //Change Language
  const changeLanguage = (e) => {
    setLoading(true);
    i18next.changeLanguage(e.target.value);
    localStorage.setItem('language', e.target.value);

    setTimeout(() => {
      window.location.reload();
    }, 1000);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }


  useEffect(() => {
    // alert(props.itemCount);
    i18next.changeLanguage(localStorage.getItem('language'));
  }, [props.itemCount]);


  const searchResult = event => {
    event.preventDefault();

    if(search == ''){
      setSearchError(t('lang.validation.searchValue'));
    }else{
      //console.log("search =", search.length);

      if(search.length >= 3){
        navigate({
          pathname: '/search',
          search: '?search='+search,
        });
      }

      //navigate("/search?search="+search);
    }

    // 👇️ value of input field
    console.log('handleClick 👉️', search);
  };

  const handleChange = event => {
    setSearch(event.target.value);
    setSearchError('');

  };

  return (
    <>
      {
        loading === true ? (<Loader />) : ''
      }
      <header id='mainHeader'>
        <div className="topHeader">
          <div className="container-fluid ph-40">
            <div className="row justify-content-between align-items-center">
              <div className="col-auto d-flex flex-wrap align-items-center">
                <div className="logo">
                  <Link to="/">
                    <img src={process.env.REACT_APP_UPLOAD_URL + 'sitesettings/' + settings.logo} alt="" />
                  </Link>
                </div>
                <div className="topLinks forDesktop">
                  <ul className='d-flex'>
                    <li><Link to="/music-stream" className={splitLocation[1] === "music-stream" ? "active" : ""}>{t('lang.menu.menu_1')}</Link></li>
                    <li><Link to="/courses" className={splitLocation[1] === "courses" ? "active" : ""}>{t('lang.menu.menu_2')}</Link></li>
                    <li><Link to="/blog" className={splitLocation[1] === "blog" ? "active" : ""}>{t('lang.menu.menu_3')}</Link></li>
                    <li><Link to="/tutor" className={splitLocation[1] === "tutor" ? "active" : ""}>{t('lang.menu.menu_4')}</Link></li>
                    <li><Link to="/store" className={splitLocation[1] === "store" ? "active" : ""}>{t('lang.menu.menu_5')}</Link>
                      <ul>
                        <li><Link to="/store/instruments" className={splitLocation[2] === "instruments" ? "active" : ""}>{t('lang.menu.menu_6')}</Link></li>
                        <li><Link to="/store/accessories" className={splitLocation[2] === "accessories" ? "active" : ""}>{t('lang.menu.menu_7')}</Link></li>
                      </ul>
                    </li>
                    {/* <li><Link to="#">Currency</Link>
                      <ul>
                        <li><button className='btn' onClick={(e) => changeCurrency({ 'type': 1 })}>$</button></li>
                        <li><button className='btn' onClick={(e) => changeCurrency({ 'type': 2 })}>£</button></li>
                      </ul>
                    </li> */}

                    {/* <li><Link to="/albums" className={splitLocation[1] === "albums" ? "active" : ""}>Albums</Link></li> */}
                    {
                      authToken == null ? <li><Link to="/register-teacher" onClick={setRegistrationType} data-type="tutor">{t('lang.menu.menu_8')}</Link></li> : ''
                    }

                  </ul>
                </div>
              </div>
              <div className="col-auto d-flex flex-wrap">
                <div className='signUpLang'>
                  <ul className='d-flex align-items-center'>

                    <li>
                      <span className='hSrcBtn' onClick={handleToggleSearch}><FontAwesomeIcon icon={faSearch} /></span>
                    </li>
                    {
                      (authToken != null) ? (
                        <li className='mbHide'>
                          {
                            (registerType == 'student') ? <Link to='#' onClick={() => window.location = process.env.REACT_APP_STUDENT_DASHBOARD_URL} className='logInBtn'><FontAwesomeIcon icon={faUser} /> <span>{t('lang.menu.menu_9')}</span></Link> : <Link to='#' onClick={() => window.location = process.env.REACT_APP_TEACHER_DASHBOARD_URL} className='logInBtn'><FontAwesomeIcon icon={faUser} /> <span>{t('lang.menu.menu_9')}</span></Link>
                          }
                          <div className='accoutDd'>
                            <div className='accoutDdTop d-flex align-items-center'>
                              <div className='adtLeft'><span>{firstLetter}</span></div>
                              <div className='adtRight'>
                                <div className="adtRightInner">
                                  <h4>{userName}</h4>
                                  <h6>{userEmail}</h6>
                                </div>
                              </div>
                            </div>
                            <div className='accoutDdList'>
                              <ul>
                                <li>
                                  {
                                    (registerType == 'student') ? <Link to="#" onClick={() => window.location = process.env.REACT_APP_STUDENT_DASHBOARD_URL}>{t('lang.menu.menu_10')}</Link> : <Link to="#" onClick={() => window.location = process.env.REACT_APP_TEACHER_DASHBOARD_URL}>{t('lang.menu.menu_10')}</Link>
                                  }

                                </li>
                                <li>
                                  {
                                    (registerType == 'student') ? <Link to="#" onClick={() => window.location = process.env.REACT_APP_STUDENT_URL+'courses'}>{t('lang.menu.menu_11')}</Link> : <Link to="#" onClick={() => window.location = process.env.REACT_APP_TEACHER_URL+'courses'}>{t('lang.menu.menu_11')}</Link>
                                  }
                                </li>
                                {
                                  (registerType == 'student') ?
                                  <li>
                                    <Link to="#" onClick={() => window.location = process.env.REACT_APP_STUDENT_URL+'products'}>{t('lang.menu.menu_12')}</Link>
                                  </li>
                                  : ''
                                }

                                {
                                  (registerType == 'student') ?
                                  <li>
                                    <Link to="#" onClick={() => window.location = process.env.REACT_APP_STUDENT_URL+'live-lessons'}>{t('lang.menu.menu_13')}</Link>
                                  </li>
                                  : ''
                                }

                                {
                                  (registerType == 'tutor') ?
                                  <li>
                                    <Link to="#" onClick={() => window.location = process.env.REACT_APP_TEACHER_URL+'availability'}>{t('lang.menu.menu_14')}</Link>
                                  </li>
                                  : ''
                                }
                                {
                                  (registerType == 'tutor') ?
                                  <li>
                                    <Link to="#" onClick={() => window.location = process.env.REACT_APP_TEACHER_URL+'rates'}>{t('lang.menu.menu_15')}</Link>
                                  </li>
                                  : ''
                                }
                              </ul>
                            </div>
                            <div className='accoutDdList'>
                              <ul>
                                <li>
                                  {
                                    (registerType == 'student') ? <Link to="#" onClick={() => window.location = process.env.REACT_APP_STUDENT_URL+'password-change'}>{t('lang.menu.menu_16')}</Link> : <Link to="#" onClick={() => window.location = process.env.REACT_APP_TEACHER_URL+'password-change'}>{t('lang.menu.menu_16')}</Link>
                                  }

                                </li>
                                <li>
                                  {
                                    (registerType == 'student') ? <Link to="#" onClick={() => window.location = process.env.REACT_APP_STUDENT_URL+'profile'}>{t('lang.menu.menu_17')}</Link> : <Link to="#" onClick={() => window.location = process.env.REACT_APP_TEACHER_URL+'profile'}>{t('lang.menu.menu_17')}</Link>
                                  }
                                </li>
                                <li>
                                  <Link to="#" onClick={() => window.location = process.env.REACT_APP_API_URL+'logout/'+loginUser}>{t('lang.menu.menu_18')}</Link>
                                </li>
                              </ul>
                            </div>
                            {/* <div className='accoutDdList'>
                              <ul>
                                <li>
                                  <Link to={"/"}>My Learning</Link>
                                </li>
                                <li>
                                  <Link to={"/"}>My Learning</Link>
                                </li>
                                <li>
                                  <Link to={"/"}>My Learning</Link>
                                </li>
                              </ul>
                            </div> */}
                            {/* <div className='accoutDdList'>
                              <ul>
                                <li>
                                  <Link to={"/"}>My Learning</Link>
                                </li>
                                <li>
                                  <Link to={"/"}>My Learning</Link>
                                </li>
                                <li>
                                  <Link to={"/"}>My Learning</Link>
                                </li>
                              </ul>
                            </div> */}
                          </div>
                        </li>) : (
                          <>
                            <li className='mbHide'>
                              <Link to="/login" className='logInBtn'><FontAwesomeIcon icon={faUser} /> <span>{t('lang.menu.menu_19')}</span></Link>
                            </li>
                            <li className='mbHide'>
                              <Link to="#" onClick={setRegistrationType} data-type="student" className='signUpBtn'><FontAwesomeIcon icon={faEdit} /> <span>{t('lang.menu.menu_20')}</span></Link>

                              <ul className='dropDownReg'>
                                <li>
                                  <Link to="/register-teacher">{t('lang.menu.menu_21')}</Link>
                                </li>
                                <li>
                                  <Link to="/register-student">{t('lang.menu.menu_22')}</Link>
                                </li>
                              </ul>
                            </li>
                          </>
                      )
                    }
                    <li className="top-currency">
                        <select value={localStorage.getItem('currency') && localStorage.getItem('currency')} className='selectCurrency form-control' onChange={changeCurrency}>
                            <option value="1">$ USD</option>
                            <option value="2">£ GBP</option>
                        </select>
                    </li>
                    <li className="top-currency">
                        <select value={localStorage.getItem('language') && localStorage.getItem('language')} className='selectlanguage form-control' onChange={changeLanguage}>
                            <option value="en">EN</option>
                            <option value="ar">AR</option>
                        </select>
                    </li>
                    {/* <li className='mbHide'>
                      <Link to="/"><img src="/images/svg/globe.svg" alt="" /></Link>
                    </li> */}
                    <li className='cartIcon mbHide'>
                      <Link to="/cart"><img src="/images/svg/cart.svg" alt="" /><span>{ changeTotalItems == 1 ? props.itemCount : 0}</span></Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="btmHeader">
          <div className="container">
            <div className='row'>
              <div className='col-12'>
                <nav className={(animate ? 'navigation active' : 'navigation')}>
                  <Link to="" onClick={handleClick} className='humbergar'>
                    <span></span>
                    <span></span>
                    <span></span>
                  </Link>
                  <div className='midHeader'>
                    <div className="topLinks">
                      <ul className='d-flex'>
                        <li><Link to="/music-stream" className={splitLocation[1] === "music-stream" ? "active" : ""}>{t('lang.menu.menu_1')}</Link></li>
                        <li><Link to="/courses" className={splitLocation[1] === "courses" ? "active" : ""}>{t('lang.menu.menu_2')}</Link></li>
                        <li><Link to="/blog" className={splitLocation[1] === "blog" ? "active" : ""}>{t('lang.menu.menu_3')}</Link></li>
                        <li><Link to="/blog" className={splitLocation[1] === "tutor" ? "active" : ""}>{t('lang.menu.menu_4')}</Link></li>
                        <li><Link to="/store" className={splitLocation[1] === "store" ? "active" : ""}>{t('lang.menu.menu_5')}</Link>
                          <ul>
                            <li><Link to="/store/instruments" className={splitLocation[2] === "instruments" ? "active" : ""}>{t('lang.menu.menu_6')}</Link></li>
                            <li><Link to="/store/accessories" className={splitLocation[2] === "accessories" ? "active" : ""}>{t('lang.menu.menu_7')}</Link></li>
                          </ul>
                        </li>
                        {/* <li><Link to="/albums" className={splitLocation[1] === "albums" ? "active" : ""}>Albums</Link></li> */}
                        {
                          authToken == null ? <li><Link to="/register-teacher" onClick={setRegistrationType} data-type="tutor">{t('lang.menu.menu_8')}</Link></li> : ''
                        }

                        {/* <li><Link to="#">Currency</Link>
                          <ul>
                            <li><button onClick={(e) => changeCurrency({ 'type': 1 })}>$</button></li>
                            <li><button onClick={(e) => changeCurrency({ 'type': 2 })}>£</button></li>
                          </ul>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                  {
                    splitLocation[1] === "courses" ? <ul className='d-flex w-100 justify-content-between'>
                      {
                        categoryList.map((val, index) => {
                          return (<li key={index}><Link to={`/courses/category/` + val.slug}>{languages == 'ar' ? val.title_ar : val.title}</Link></li>)
                        })
                      }
                    </ul> : ''
                  }

                  <div className={`signUpLang ${splitLocation[1] !== "courses" ? 'mt-5' : ''}`}>
                    <ul className='d-flex align-items-center'>
                      {/* <li>
                        <span className='hSrcBtn' onClick={handleToggleSearch}><FontAwesomeIcon icon={faSearch} /></span>
                      </li> */}
                      {
                        (authToken != null) ? (
                          <li>
                            {
                              (registerType == 'student') ? <Link to='#' onClick={() => window.location = process.env.REACT_APP_STUDENT_DASHBOARD_URL} className='logInBtn'><FontAwesomeIcon icon={faUser} /> <span>{t('lang.menu.menu_9')}</span></Link> : <Link to='#' onClick={() => window.location = process.env.REACT_APP_TEACHER_DASHBOARD_URL} className='logInBtn'><FontAwesomeIcon icon={faUser} /> <span>{t('lang.menu.menu_9')}</span></Link>
                            }

                          </li>) : (<>
                            <li>
                              <Link to="/login" className='logInBtn'><FontAwesomeIcon icon={faUser} /> <span>{t('lang.menu.menu_10')}</span></Link>
                            </li>
                            <li>
                              <Link to="/register-teacher" onClick={setRegistrationType} data-type="teacher" className='signUpBtn'><FontAwesomeIcon icon={faUsers} /> <span>{t('lang.menu.menu_20')}</span></Link>
                            </li>
                            <li>
                              <Link to="/register-student" onClick={setRegistrationType} data-type="student" className='signUpBtn'><FontAwesomeIcon icon={faGraduationCap} /> <span>{t('lang.menu.menu_20')}</span></Link>
                            </li>
                            </>
                        )
                      }

                      {/* <li>
                        <Link to="/"><img src="/images/svg/globe.svg" alt="" /></Link>

                      </li> */}
                      <li className='cartIcon'>
                        <Link to="/cart"><img src="/images/svg/cart.svg" alt="" /><span>{props.itemCount ? props.itemCount : item}</span></Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>

            </div>
          </div>
        </div>

        {isOpenSearch && <section className='mainSrcArea' id='mainSrcArea'>
          <div className='srcInner'>
            <div className='text-center mb-2 red'>{searchError}</div>
            <div className='banSrc'>
              <input type="text" className="form-control"  onChange={handleChange} name="search" id="search" placeholder={t('lang.menu.menu_23')}  value={search} />
              <button type="button" onClick={searchResult} className='srcIcon'><img src="../images/svg/search.svg" alt="" /></button>
            </div>
          </div>
        </section>}
      </header>
    </>);
}
export default Header;

