import React, { Component } from "react";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './fonts/stylesheet.css';
import './css/style.css';

import Home from './Home';
import Courses from './pages/Courses';
import NewCourses from './pages/NewCourses';
import TrendingCourses from './pages/TrendingCourses';
import CourseDetail from './pages/CourseDetail';
import ProductDetails from './pages/ProductDetails';

import Login from './pages/Login';
import Regisrer from './pages/Regisrer';
import ProdectList from './pages/ProdectList';
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
import Wishlist from './pages/Wishlist';
import TermsConditions from './pages/TermsAndConditions';
import CoursesNew from './pages/CoursesNew';
import Playlist from './pages/Playlist';
import MusicStream    from './pages/MusicStream';
import MusicStreamDetails    from './pages/MusicStreamDetails';
import AlbumDetails from './pages/AlbumDetails';
import Blog from "./pages/Blog";
import { BlogDetails }  from './pages/BlogDetails';
import PageDetails  from "./pages/PageDetails";
import Store from './pages/Store';
import TutorDetails from './pages/TutorDetails';
import Teachers from './pages/Teachers';
import Test from './pages/Test';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CourseCategory from "./pages/CourseCategory";
import StoreList from "./pages/StoreList";
import Thankyou from "./pages/Thankyou";
import Rejected from "./pages/Rejected";
import BookingLesson from './pages/BookingLesson';
import BookingCheckout from './pages/BookingCheckout';
import Search from "./pages/Search";
import MainStore from "./pages/MainStore";
import MainStoreList from "./pages/MainStoreList";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Spotify from "./pages/Spotify";
import ClassRoom from "./pages/ClassRoom";
import Videosdk from "./pages/Videosdk";
import VerifyEmail from "./pages/VerifyEmail";
import TeacherRegistration from "./pages/TeacherRegistration";
import StudentRegistration from "./pages/StudentRegistration";
import PayLater from "./pages/PayLater";
import PayTutor from "./pages/PayTutor";
import BookingLessonPay from "./pages/BookingLessonPay";
import BookingCheckoutPay from "./pages/BookingCheckoutPay";
import NotFoundPage from "./pages/404";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        {/* <Route path="/register" element={<Regisrer />} /> */}
        <Route path="/register-teacher" element={<TeacherRegistration />} />
        <Route path="/register-student" element={<StudentRegistration />} />
        <Route path="/" element={<Home />} />
        <Route path="/courses/category/:slug" element={<CourseCategory />} />
        <Route path="/courses/" element={<Courses />} />
        <Route exact path="/courses/new-courses" element={<NewCourses />} />
        <Route exact path="/courses/trending-courses" element={<TrendingCourses />} />
        <Route path="/courses/:slug" element={<CourseDetail />} />
       
        <Route path="/prodect-list" element={<ProdectList />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/music-stream" element={<MusicStream />} />
        <Route path="/music-stream/:slug" element={<MusicStreamDetails />} />
        <Route path="/playlist" element={<Playlist />} />
        <Route path="/album/:slug" element={<AlbumDetails />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:slug" element={<BlogDetails />} />
        <Route path="/pages/:slug" element={<PageDetails />} />
        <Route exact path="/store" element={<MainStore />} />
        <Route exact path="/store/:slug" element={<Store />} />
        <Route exact path="/store-list" element={<MainStoreList />} />
        <Route exact path="/store-list/:slug" element={<StoreList />} />
        <Route exact path="/products/:slug" element={<ProductDetails />} />
        <Route exact path="/tutor/" element={<Teachers />} />
        <Route exact path="/tutor/:slug" element={<TutorDetails />} />
        <Route exact path="/tutor/:slug/booking-lesson" element={<BookingLesson />} />
        <Route exact path="/tutor/:slug/booking-lesson/:id" element={<BookingLessonPay />} />

        <Route exact path="/tutor/:slug/:id" element={<PayTutor />} />
        <Route exact path="/test" element={<Test />} />
        <Route exact path="/thankyou/:orderid" element={<Thankyou />} />
        <Route exact path="/rejected" element={<Rejected />} />
        <Route path="/booking-checkout" element={<BookingCheckout />} />
        <Route path="/booking-checkout/:id" element={<BookingCheckoutPay />} />

        <Route path="/search" element={<Search />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:email" element={<ResetPassword />} />
        <Route path="/spotify" element={<Spotify />} />
        {/* <Route path="/classroom/:id/:roomid?" element={<ClassRoom />} /> */}
        <Route path="/classroom/:id/" element={<ClassRoom />} />
        <Route path="/classroom/:id/:joinId" element={<ClassRoom />} />
        <Route path="/videosdk" element={<Videosdk />} />
        <Route path="/verify/:slug/:token" element={<VerifyEmail />} />
        <Route path="/pay-later" element={<PayLater />} />
        <Route path="*" element={<NotFoundPage />} />
        
      </Routes>
    </Router>
  );
}

export default App;