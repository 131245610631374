import {React, useEffect} from 'react'
import Header from '../includes/Header'
import Footer from '../includes/Footer'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const Playlist = () => {
    const options = {
        loop: true,
        margin: 40,
        nav: true,
        dots: false,
        responsive: {
            0: {
                items: 2,
            },
            540: {
                items: 3,
            },
            768: {
                items: 4,
                margin: 10,
            },
            1000: {
                items: 5,
                margin: 20,
            },
            1135: {
                items: 6,
            },
        },
    };
    const [t, i18n] = useTranslation('common');

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('language'));

      },[]);

    return (
        <>
            <Header />
            <section className='playlist d-flex flex-wrap'>
                <div className='playlistLeft'>
                    <div className='playlistLeftInner'>
                        <h5>Now Playing</h5>
                        <h3>Lorem Ipsum</h3>
                        <div className='pliImg'>
                            <img src="../images/list/list-1.jpg" alt="" />
                        </div>
                        <div className='pliBtm d-flex w-100 align-items-center'>
                            <div className='pliBtmLeft'>
                                <span><img src="../images/list/thumb-1.jpg" alt="" /></span>
                            </div>
                            <div className='pliBtmMid'>
                                <span>Lorem Ipsum</span>
                            </div>
                            <div className='pliBtmRight'>
                                <Link to="/"><FontAwesomeIcon icon={faPlay} /></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='playlistRight'>
                    <div className='playListTop'>
                        <ul className='d-flex'>
                            <li className=''>Title</li>
                            <li className='text-center'>Author</li>
                            <li className='text-center'>Duration</li>
                            <li className='text-center'>Action</li>
                        </ul>
                    </div>
                    <div className='playListBtm'>
                        <ul className='d-flex align-items-center w-100'>
                            <li className=''><span><img src="../images/list/thumb-1.jpg" alt="" /></span> Lorem ipsum dolor sit amet, con. </li>
                            <li className='text-center'>Lorem ipsum dolor sit</li>
                            <li className='text-center'>04:00</li>
                            <li className='text-center'><Link to='/' className='playNowTableBtn'>Play now <FontAwesomeIcon icon={faPlay} /></Link></li>
                        </ul>
                        <ul className='d-flex align-items-center w-100'>
                            <li className=''><span><img src="../images/list/thumb-1.jpg" alt="" /></span> Lorem ipsum dolor sit amet, con. </li>
                            <li className='text-center'>Lorem ipsum dolor sit</li>
                            <li className='text-center'>04:00</li>
                            <li className='text-center'><Link to='/' className='playNowTableBtn'>Play now <FontAwesomeIcon icon={faPlay} /></Link></li>
                        </ul>
                        <ul className='d-flex align-items-center w-100'>
                            <li className=''><span><img src="../images/list/thumb-1.jpg" alt="" /></span> Lorem ipsum dolor sit amet, con. </li>
                            <li className='text-center'>Lorem ipsum dolor sit</li>
                            <li className='text-center'>04:00</li>
                            <li className='text-center'><Link to='/' className='playNowTableBtn'>Play now <FontAwesomeIcon icon={faPlay} /></Link></li>
                        </ul>
                        <ul className='d-flex align-items-center w-100'>
                            <li className=''><span><img src="../images/list/thumb-1.jpg" alt="" /></span> Lorem ipsum dolor sit amet, con. </li>
                            <li className='text-center'>Lorem ipsum dolor sit</li>
                            <li className='text-center'>04:00</li>
                            <li className='text-center'><Link to='/' className='playNowTableBtn'>Play now <FontAwesomeIcon icon={faPlay} /></Link></li>
                        </ul>
                        <ul className='d-flex align-items-center w-100'>
                            <li className=''><span><img src="../images/list/thumb-1.jpg" alt="" /></span> Lorem ipsum dolor sit amet, con. </li>
                            <li className='text-center'>Lorem ipsum dolor sit</li>
                            <li className='text-center'>04:00</li>
                            <li className='text-center'><Link to='/' className='playNowTableBtn'>Play now <FontAwesomeIcon icon={faPlay} /></Link></li>
                        </ul>
                        <ul className='d-flex align-items-center w-100'>
                            <li className=''><span><img src="../images/list/thumb-1.jpg" alt="" /></span> Lorem ipsum dolor sit amet, con. </li>
                            <li className='text-center'>Lorem ipsum dolor sit</li>
                            <li className='text-center'>04:00</li>
                            <li className='text-center'><Link to='/' className='playNowTableBtn'>Play now <FontAwesomeIcon icon={faPlay} /></Link></li>
                        </ul>
                        <ul className='d-flex align-items-center w-100'>
                            <li className=''><span><img src="../images/list/thumb-1.jpg" alt="" /></span> Lorem ipsum dolor sit amet, con. </li>
                            <li className='text-center'>Lorem ipsum dolor sit</li>
                            <li className='text-center'>04:00</li>
                            <li className='text-center'><Link to='/' className='playNowTableBtn'>Play now <FontAwesomeIcon icon={faPlay} /></Link></li>
                        </ul>
                        <ul className='d-flex align-items-center w-100'>
                            <li className=''><span><img src="../images/list/thumb-1.jpg" alt="" /></span> Lorem ipsum dolor sit amet, con. </li>
                            <li className='text-center'>Lorem ipsum dolor sit</li>
                            <li className='text-center'>04:00</li>
                            <li className='text-center'><Link to='/' className='playNowTableBtn'>Play now <FontAwesomeIcon icon={faPlay} /></Link></li>
                        </ul>
                        <ul className='d-flex align-items-center w-100'>
                            <li className=''><span><img src="../images/list/thumb-1.jpg" alt="" /></span> Lorem ipsum dolor sit amet, con. </li>
                            <li className='text-center'>Lorem ipsum dolor sit</li>
                            <li className='text-center'>04:00</li>
                            <li className='text-center'><Link to='/' className='playNowTableBtn'>Play now <FontAwesomeIcon icon={faPlay} /></Link></li>
                        </ul>
                        <ul className='d-flex align-items-center w-100'>
                            <li className=''><span><img src="../images/list/thumb-1.jpg" alt="" /></span> Lorem ipsum dolor sit amet, con. </li>
                            <li className='text-center'>Lorem ipsum dolor sit</li>
                            <li className='text-center'>04:00</li>
                            <li className='text-center'><Link to='/' className='playNowTableBtn'>Play now <FontAwesomeIcon icon={faPlay} /></Link></li>
                        </ul>
                        <ul className='d-flex align-items-center w-100'>
                            <li className=''><span><img src="../images/list/thumb-1.jpg" alt="" /></span> Lorem ipsum dolor sit amet, con. </li>
                            <li className='text-center'>Lorem ipsum dolor sit</li>
                            <li className='text-center'>04:00</li>
                            <li className='text-center'><Link to='/' className='playNowTableBtn'>Play now <FontAwesomeIcon icon={faPlay} /></Link></li>
                        </ul>
                        <ul className='d-flex align-items-center w-100'>
                            <li className=''><span><img src="../images/list/thumb-1.jpg" alt="" /></span> Lorem ipsum dolor sit amet, con. </li>
                            <li className='text-center'>Lorem ipsum dolor sit</li>
                            <li className='text-center'>04:00</li>
                            <li className='text-center'><Link to='/' className='playNowTableBtn'>Play now <FontAwesomeIcon icon={faPlay} /></Link></li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className='similarArtist'>
                <div className='container-fluid ph-40'>
                    <div className='row g-2 g-md-3 g-lg-4'>
                        <div className='col-12'>
                            <h3>Similar Artist</h3>
                        </div>
                        <div className='col-12'>
                            <OwlCarousel className='owl-theme similarArtistSlider' {...options}>
                                <div className='item'>
                                    <Link to={'/'} className='similarArtistInner'>
                                        <img src="../images/similar-artist/1.jpg" alt="" />
                                    </Link>
                                </div>
                                <div className='item'>
                                    <Link to={'/'} className='similarArtistInner'>
                                        <img src="../images/similar-artist/2.jpg" alt="" />
                                    </Link>
                                </div>
                                <div className='item'>
                                    <Link to={'/'} className='similarArtistInner'>
                                        <img src="../images/similar-artist/3.jpg" alt="" />
                                    </Link>
                                </div>
                                <div className='item'>
                                    <Link to={'/'} className='similarArtistInner'>
                                        <img src="../images/similar-artist/4.jpg" alt="" />
                                    </Link>
                                </div>
                                <div className='item'>
                                    <Link to={'/'} className='similarArtistInner'>
                                        <img src="../images/similar-artist/5.jpg" alt="" />
                                    </Link>
                                </div>
                                <div className='item'>
                                    <Link to={'/'} className='similarArtistInner'>
                                        <img src="../images/similar-artist/6.jpg" alt="" />
                                    </Link>
                                </div>
                                <div className='item'>
                                    <Link to={'/'} className='similarArtistInner'>
                                        <img src="../images/similar-artist/4.jpg" alt="" />
                                    </Link>
                                </div>
                                <div className='item'>
                                    <Link to={'/'} className='similarArtistInner'>
                                        <img src="../images/similar-artist/1.jpg" alt="" />
                                    </Link>
                                </div>
                            </OwlCarousel>
                        </div>

                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Playlist