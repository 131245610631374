import React, { useState, useEffect } from 'react'

import Header from '../includes/Header'
import Footer from '../includes/Footer'
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import SpotifyWebApi from 'spotify-web-api-js';

const Test = () => {

  const clientId = '3cb75e19b41244598fe3226ded37ffdc';
  const clientSecret = 'b3e9167eaf9a45348bcbc055f5772a58';
  const [loading, setLoading]  = useState(false);
  const [totalItem, setTotalItem] = useState(0);
  const [spotifyToken, setSpotifyToken]       = useState('');
  const [spotifyPlaylist, setSpotifyPlaylist] = useState({items:[]});

  //https://github.com/thelinmichael/spotify-web-api-node

  //https://stackoverflow.com/questions/51795449/spotify-api-token-scope-issue#:~:text=To%20get%20the%20token%20with,token%20with%20your%20desired%20scopes.

  //api/token with scope spotify

  //https://gist.github.com/hughrawlinson/1d24595d3648d53440552436dc215d90

  var spotifyApi = new SpotifyWebApi();

  function generateRandomString(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
 charactersLength));
   }
   return result;
}
 
  useEffect(() => {

    window.scrollTo(0, 0);
    setLoading(true);
    
    var products = localStorage.getItem('products');
    if(products !== '' && products !==null){
        setTotalItem(JSON.parse(localStorage.getItem('products')).length);
    }

    var client_id = '3cb75e19b41244598fe3226ded37ffdc';
    var redirect_uri = 'http://localhost:3000/';

    var state = generateRandomString(16);

    localStorage.setItem('stateKey', state);
    var scope = 'user-read-private user-read-email user-read-playback-state';

    var url = 'https://accounts.spotify.com/authorize';
    url += '?response_type=token';
    url += '&client_id=' + encodeURIComponent(client_id);
    url += '&scope=' + encodeURIComponent(scope);
    url += '&redirect_uri=' + encodeURIComponent(redirect_uri);
    url += '&state=' + encodeURIComponent(state);

    console.log("url =", url);
    //Spotify
    //_getToken();

    try {
      

      // axios.get("https://accounts.spotify.com/authorize?response_type=token&client_id=3cb75e19b41244598fe3226ded37ffdc&scope=user-read-private&nbsp;user-read-email&nbsp;user-read-playback-state&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2F&state=9cgK0fWyUYyCfljP")
      /* axios.get(`https://accounts.spotify.com/authorize?`,{
        headers: {
            "Content-type": "application/json",
            "cookies": ""
        },
        params:{
          response_type: 'token',
          client_id: '3cb75e19b41244598fe3226ded37ffdc',
          scope: 'user-read-playback-state',
          redirect_uri: 'http://localhost:3000/',
          state: 'nb7xf'
        }
      })
      .then(async (res) => {
         console.log("res =",res);
        
      }).catch(error => 
        console.log(error.response)
      ); */
   

        const headers = {
            'Content-Type' : 'application/x-www-form-urlencoded',
            'Authorization' : 'Basic ' + btoa(clientId + ':' + clientSecret)
          }
         var data = {
          "grant_type": "client_credentials",
          "scope": "playlist-modify-private user-library-read",
      };
        axios({
            method: 'post',
            headers: headers,
            url: "https://accounts.spotify.com/api/token",
            data: "grant_type=client_credentials", 
        }).then(function(res) {
            
            if(res.data.access_token !== ''){
                //Fetch playlist data
                var access_token = res.data.access_token;
                setSpotifyToken(access_token);
                spotifyApi.setAccessToken(access_token);
                //All playlist
                console.log(res);
                spotifyApi.getUserPlaylists('jamjhjfnovm19m39knlhekgnp').then(
                  function (data) {
                    //console.log('User playlists', data);
                    setSpotifyPlaylist(data);
                  },
                  function (err) {
                    console.error(err);
                  }
                );

                // Get a User's Available Devices
                spotifyApi.getMyDevices()
                  .then(function(data) {
                    let availableDevices = data.body.devices;
                    console.log("availableDevices=",availableDevices);
                  }, function(err) {
                    console.log('Something went wrong!', err);
                  });



                /* axios.get(`https://api.spotify.com/v1/users/jamjhjfnovm19m39knlhekgnp/playlists?limit=30`, {headers: { 'Authorization' : 'Bearer ' + access_token}})  
                .then(async (res) => {
                   //console.log("res dsadfasdfas =",res);
                    setSpotifyPlaylist(res.data.items);
                    
                }).catch(error => 
                    console.log("Error !")
                );  */

                //User Infos
                spotifyApi.getPlaylist('0NSsxD4am1P0wmVBkirMxF')
                .then(function(data) {
                  //console.log('User playlist', data);
                }, function(err) {
                  console.error(err);
                });

            }
            
        }).catch(function (error){
            console.log(error);
        });



    } catch (error) {
        console.error('error', error);
    }
     
    

}, []);
//console.log("spotify = ",spotifyPlaylist);
  return (
    <>
    <Header itemCount={totalItem} />
    <section className='newRealeaseSec'>
            <div className='container-fluid ph-40'>
                <div className='row g-3 g-2 g-md-3 g-lg-4'>
                    <div className='col-12'>
                        <div className='sectionTitle d-flex align-items-center justify-content-between'>
                            <h2>New Release</h2>
                        </div>
                    </div>
      
                {
                    spotifyPlaylist.length !=''  ? spotifyPlaylist.items.map((val,indx) => {
                        
                        return(<div className="col-lg-3 col-md-3 col-sm-6 col-12" key={indx}>
                        <div className='projectInner w-100'>
                            <div className='projectInnerTop w-100'>
                                <Link to={`/album/${val.id}`}>
                                    <img className='w-100' src={val.images[0].url} alt={val.name} />
                                </Link>
                            </div>
                            <div className='projectInnerMid w-100'>
                                <Link to={`/album/${val.id}`}><h4>{val.name}</h4></Link>
                                <p>{val.tracks.total > 1 ? val.tracks.total+' Songs' : val.tracks.total+' Song'}</p>
                            </div>
                        </div>
                    </div>)
                    }) : ''

                }
                </div>
            </div>
          </section>
    <Footer />
    </>
  )
}

export default Test