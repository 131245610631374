import React, { useEffect, useRef, useState, useMemo } from "react";
import ReactPlayer from "react-player";
import Header from '../includes/Header';
import Footer from '../includes/Footer';
import { createMeeting } from "./MeetingAPI";
import { Link, useNavigate } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { Loader } from './Loader';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import axios from 'axios';

import {
  MeetingProvider,
  MeetingConsumer,
  useMeeting,
  useParticipant,
  usePubSub,
} from "@videosdk.live/react-sdk";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faEdit, faSearch, faMicrophone, faMicrophoneLines, faSignOut,  faShare, faVideoCamera, faVideoSlash, faStopCircle,faPaperPlane,faMessage } from '@fortawesome/free-solid-svg-icons'

let userRole = localStorage.getItem('loginUserRole');
localStorage.setItem('chatBox', false);
var authToken1 = "";
// let mikeHandelar = localStorage.getItem('mike');

//https://javascript.plainenglish.io/how-to-pass-props-from-child-to-parent-component-in-react-d90752ff4d01

function onParticipantJoined(participant) {
  console.log(" onParticipantJoined", participant);
}

function JoinScreen({ getMeetingAndToken, participantName  }) {
  const [meetingId, setMeetingId] = useState(null);
  const [participantNames, setParticipantName] = useState(null);
 
  /* const setParticipantName = (e) =>{
    participantName = e.target.value;
    console.log("participantName ", participantName);
  } */
  const onClick = async () => {
    await getMeetingAndToken(meetingId);
  };
  return (
    
      userRole !== null && userRole == 3 ? 
      <> 
      <input type="text" placeholder="Enter Room Id" className="form-control mb-3" onChange={(e) => { setMeetingId(e.target.value); }} />

    <button className="buttonJoin" onClick={onClick}>Validate Room</button> 
    </> 
    :  userRole !== null && userRole == 2 ? <button className="buttonJoin" onClick={onClick}>Create Class</button> : ''
   
  );
}

const chunk = (arr) => {
  const newArr = [];
  while (arr.length) newArr.push(arr.splice(0, 3));
  return newArr;
};

function VideoComponent(props) {
  const webcamRef      = useRef(null);
  const micRef         = useRef(null);
  const screenShareRef = useRef(null);
  const [t, i18n] = useTranslation('common');
  
  const { displayName, webcamStream, micStream, screenShareStream, webcamOn, micOn, screenShareOn, isLocal } = useParticipant(
    props.participantId, props.userV
  );
    

  /* const videoStream = useMemo(() => {
    if (webcamOn && webcamStream || screenShareStream) {
      const mediaStream = new MediaStream();

      if(screenShareOn){
        mediaStream.addTrack(screenShareStream.track);
      }else{
        mediaStream.addTrack(webcamStream.track);
      }
      
      return mediaStream;
    }
  }, [webcamStream, webcamOn]); */


  const videoStream = useMemo(() => {
    if (webcamOn && webcamStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(webcamStream.track);
      return mediaStream;
    }
  }, [webcamStream, webcamOn]);


  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('language'));
    if (webcamRef.current) {
      if (webcamOn) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(webcamStream.track);

        webcamRef.current.srcObject = mediaStream;
        webcamRef.current
          .play()
          .catch((error) =>
            console.error("videoElem.current.play() failed", error)
          );
      } else {
        webcamRef.current.srcObject = null;
      }


    }
  }, [webcamStream, webcamOn]);

  useEffect(() => {
    if (micRef.current) {
      if (micOn && micStream) {
        const mediaStream = new MediaStream();
        //console.log("mediaStream1 =", mediaStream);
        mediaStream.addTrack(micStream.track);

        micRef.current.srcObject = mediaStream;
        micRef.current
          .play()
          .catch((error) =>
            console.error("videoElem.current.play() failed", error)
          );
      } else {
        micRef.current.srcObject = null;
      }
    }
  }, [micStream, micOn]);

  useEffect(() => {
    if (screenShareRef.current) {
      if (screenShareOn) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(screenShareStream.track);

        screenShareRef.current.srcObject = mediaStream;
        screenShareRef.current
          .play()
          .catch((error) =>
            console.error("videoElem.current.play() failed", error)
          );
      } else {
        screenShareRef.current.srcObject = null;
      }
    }
  }, [screenShareStream, screenShareOn]);

  let dName = JSON.parse(displayName);

  return (
    <>
    
      <h4>{dName.name}</h4>
      <audio ref={micRef} autoPlay />
     {/*  {micOn && micRef && } */}
      {/* {
      webcamOn && webcamStream && (

        <ReactPlayer
          //
          playsinline // very very imp prop
          pip={false}
          light={false}
          controls={false}
          muted={false}
          playing={true}
          //
          url={videoStream}
          //
          height={"100%"}
          width={"100%"}
          onError={(err) => {
            console.log(err, "participant video error");
          }}
        />
      )} */}

      {/* {
       webcamOn && webcamStream ? 
        <ReactPlayer
        playsinline // very very imp prop
        pip={false}
        light={false}
        controls={false}
        muted={false}
        playing={true}
        //
        url={videoStream}
        //
        height={"100%"}
        width={"100%"}
        onError={(err) => {
          console.log(err, "participant video error");
        }}
      /> : null
        
      }

      {screenShareOn ? (
        <div>
          <h2>Screen Shared</h2>
          
          <video  width={"100%"} url={screenShareRef} autoPlay />
        </div>
      ) : null} */}

{/* {
      webcamOn && webcamStream && (

        <ReactPlayer
          //
          playsinline // very very imp prop
          pip={false}
          light={false}
          controls={false}
          muted={false}
          playing={true}
          //
          url={videoStream}
          //
          height={"100%"}
          width={"100%"}
          onError={(err) => {
            console.log(err, "participant video error");
          }}
        />
      )}
      {screenShareOn ? (
        <div>
          <h2>Screen Shared</h2>
          <video  width={"100%"} ref={screenShareRef} autoPlay />
        </div>
      ) : null} */}

{
    webcamOn && webcamStream && !screenShareOn && (

          <ReactPlayer
            //
            playsinline // very very imp prop
            pip={false}
            light={false}
            controls={false}
            muted={false}
            playing={true}
            //
            url={videoStream}
            //
            height={"100%"}
            width={"100%"}
            onError={(err) => {
              console.log(err, "participant video error");
            }}
          />
        )
  }

  {
  screenShareOn ? (
          <>
            <video  width={"100%"} ref={screenShareRef} autoPlay />
          </>
        ) : null
  }
      
 
    </>
  );
}

var topic = "CHAT";

function Container(props) {
  const [joined, setJoined] = useState(false);
  // destructure publish method from usePubSub hook
  const { publish, messages } = usePubSub("CHAT");
  const { join, leave, toggleMic, toggleWebcam, toggleScreenShare } = useMeeting();
  const { participants } = useMeeting();
  const [joinMessage, setJoinMessage] = useState('');
  const [loading, setLoading]         = useState(false);
  const [classType, setClassType] = useState('');
  const mMeeting = useMeeting({ onMeetingJoined });
  const [toggleWebcamShow, setToggleWebcamShow] = useState(true);
  const [togglMikeShow, setTogglMikeShow] = useState(true);

  const { RAISEHAND } = usePubSub("RAISE_HAND");

  const [cameraStatus, setCameraStatus] = useState(0);

  const localParticipant = mMeeting.localParticipant;

  const participants1 = mMeeting.participants;

  const [endClassEvent, setEndClassEvent] = useState(true);
  let navigate = useNavigate();
  
  const joinMeeting = () => {

    //Send Mail to users

    if(userRole !== null && userRole == 2){
        alert("oks");
    }else{
        alert("no");
    }
    
    setJoined(true);
    join();
    setLoading(true);

    //console.log(join);

  };
  
  function onMeetingJoined() {
    setLoading(false);
  }

  const toggleMics = async() =>{

    if(togglMikeShow == true){
      mMeeting?.muteMic();
      setTogglMikeShow(false);
    }else{
      mMeeting?.unmuteMic();
      setTogglMikeShow(true);
    }

  }

  const webcamStatus = async () => {
    // Enable Webcam in Meeting

    if(cameraStatus == 1){
      setCameraStatus(0);
    }else{
      setCameraStatus(1);
    }

    mMeeting?.toggleWebcam();
  
    /* // Disable Webcam in Meeting
    mMeeting?.disableWebcam();
  
    // Change Webcam in Meeting
    const webcams = await mMeeting?.getWebcams(); // returns all webcams
  
    const { deviceId, label } = webcams[0];
  
    mMeeting?.changeWebcam(deviceId); */
  };


  function toggleScreenShares(){
    
    mMeeting.toggleScreenShare();
    

  }

  useEffect(() => {
    
    if(userRole !== null && userRole == 3){
      setJoinMessage("Join Class");
      setClassType("students");
    }else{
      setJoinMessage("Start Class");
      setClassType("teacher");
    }
  }, [])


  const openChat = () => {
    if(localStorage.getItem('chatBox') == 'false'){
      localStorage.setItem('chatBox', true);
    }else{
      localStorage.setItem('chatBox', false);
    }

    props.func(localStorage.getItem('chatBox'));
  }

  // publish message
  const sendMessage = (message) => {
    publish(message, { persist: true });
  };


  const sendMessageToAll = (message) => {
   
    message = document.getElementById("txtChat").value;
    publish(message, { persist: true });

    toast(`Message ${document.getElementById("txtChat").value} from ${messages[0].senderName}`);
    
    document.getElementById('txtChat').value = "";

  }

  //props.func('My name is Dean Winchester & this is my brother Sammie');

  const endClass = async() => {
    
    confirmAlert({
      customUI: ({ onClose }) => {
          return (
              <div className="custom-confirmation-box">
                  <h1>Are you sure?</h1>
                  <p>You want to close this class?</p>
                  <button onClick={onClose} className="no-btn">No</button>
                  <button
                      className="yes-btn"
                      onClick={async () => {
                          mMeeting.end();
                          await setEndClassEvent(false);
                          //setEndClassEvent(true);
                          navigate('/');
                          onClose();
                      }}
                  >
                      Yes
                  </button>
              </div>
          );
      }
    }); 
  }
  
 
  const arrLength = Array.from(participants).length;
  //console.log('asdasd',arrLength);
 
  //console.log("participants : ", participants);
  return (
    <>
        {
            loading === true ? (<Loader />) : ''
        }
        <ToastContainer position="bottom-left" autoClose={2000} />
      <div className="liveVideo">
        
        {
          userRole !== null && userRole == 2 ? <h5 className="text-center">Class Room Id: {props.meetingId}</h5> : ''
        }
        
        
        {joined ? (
          <div className="individual-users d-flex flex-wrap align-items-start">

            {
                [...participants.values()].map((val,i) => {
                   
                    let participant = JSON.parse(val.displayName);

                    if(participant.role == '1'){
                      return(<div className="col-8 teacher"><div className={`bigVideo ${val.id}`}><VideoComponent participantId={val.id} /></div></div>)
                    }

                })
            }

            {/* <div className="col-7">
              
              {
                
                [...participants.keys()].map((participantId, i) => (
                    
                    i == (arrLength-1) ? <div className={`bigVideo ${participantId}`}><VideoComponent participantId={participantId} /></div> : ''
                  
                ))
              }
              
            </div> */}
            
            
              <div className="studentWrap-1 col-4">

                {
                  [...participants.values()].map((val,i) => {
                    //console.log(val);
                    let participant = JSON.parse(val.displayName);

                    if(participant.role == '2'){
                      return(<div className={`students ${val.id}`}><VideoComponent participantId={val.id} /></div>)
                    }

                  })
                }


                {/* {[...participants.keys()].map((participantId, i) => (
                  i != (arrLength-1) ? <div className={`students ${participantId}`}><VideoComponent participantId={participantId} setUserV={userV} /></div> : ''
                  
                ))} */}
              </div>

              <div className="studentWrap-2">

              <div className="msg-wrappage">
                <div className="masgTop d-flex align-items-center justify-content-between">
                  <h3>Participants <span>({arrLength})</span></h3>
                  <span className="closeMsg" onClick={openChat}>X</span>
                </div>
                <div className="masg-order"  id="chatArea">
                </div>
                <div className="box-detailsWrap">
                  <div className="msg-wrap-main">
                    <div className="msg-wrap">
                      <div className="msg-history  msg-history-scrollbar allMassage">
                        {
                          messages.length > 0 ? 
                          messages.map((v,i) => {
                            let participantName = JSON.parse(v.senderName);
                            console.log("v.senderName", participantName.name);
                            return(<div className="outgoing-msg d-flex justify-content-end mb-4">
                            <div className="sent-msg">
                              <p>{v.message}</p>
                              <span className="time-date">{participantName.name} | {moment.utc(v.timestamp).format("llll")}</span>
                            </div>
                            <div className="user-img">
                              <img src="http://localhost/zeryab/public/common/images/user.png" alt="" />
                            </div>
                          </div>)
                          })
                          
                          : ''
                        }
                        
                        
                      </div>
                    </div>
                  </div>
                  <div className="type-msg-wrap">
                    <div className="input-msg-write">
                      <textarea type="text" className="write-msg form-control msgArea" id="txtChat" placeholder="Type a message" defaultValue={""} />
                      <button className="msg-send-btn sendMessage" onClick={sendMessageToAll} type="button"><FontAwesomeIcon icon={faPaperPlane} /></button>
                    </div>
                  </div>
                </div>
              </div>


              </div>

              {/* <div className="wrapper">
                {chunk([...participants.keys()]).map((k) => (
                  <div className="box" key={k} style={{ display: "flex" }}>
                    {k.map((l) => (
                      <VideoComponent key={l} participantId={l} />
                    ))}
                  </div>
                ))}
              </div> */}
              <>
                
              </>
              {/* <Controls /> */}
              <div className="col-12"> 
                <div className="buttonWrap">
                  <button className="buttonV" onClick={leave} data-tip="Leave" data-place="bottom"><FontAwesomeIcon icon={faSignOut} /></button>
                  <button className={`buttonV ${!togglMikeShow ? 'danger' : ''}` } onClick={toggleMics} data-tip="Leave" data-place="bottom"> { togglMikeShow ? <FontAwesomeIcon icon={faMicrophone} /> : <FontAwesomeIcon icon={faMicrophoneLines} /> }</button>
                  <button className={`buttonV ${ cameraStatus == 1 ? 'danger' : '' }` } onClick={webcamStatus} data-tip="Turn Off Webcam" data-place="bottom"> { cameraStatus == 0 ? <FontAwesomeIcon icon={faVideoCamera} /> : <FontAwesomeIcon icon={faVideoSlash} /> }</button>
                  <button className={`buttonV`} onClick={toggleScreenShares} data-tip="Share Screen" data-place="bottom"><FontAwesomeIcon icon={faShare} /> </button>
                  <button className={`buttonV ${ localStorage.getItem('chatBox') == 'true' ? 'active' : '' } `} onClick={openChat} data-tip="Open Chat" data-place="bottom"><FontAwesomeIcon icon={faMessage} /></button>


                    {userRole !== null && userRole == 2 ? <button className="buttonV" onClick={endClass} data-tip="End Class" data-place="bottom"><FontAwesomeIcon icon={faStopCircle} /></button> : ''}
                </div>
              </div>
          </div>
        ) : (
          <div className="text-center"><button className="buttonV" onClick={joinMeeting}>{joinMessage}</button></div>
        )}
      </div>
    </>
  );
}



const Videosdk = (props) => {
  const videoPlayerRef = useRef();
  let navigate = useNavigate();
  const [meetingId, setMeetingId] = useState(null);

  
  //const [micOn, setMicOn] = useState(true);
  const [isMeetingStarted, setMeetingStarted] = useState(false);
  const [isMeetingLeft, setIsMeetingLeft] = useState(false);
  const [raisedHandsParticipants, setRaisedHandsParticipants] = useState([]);
  const [totalItem, setTotalItem]   = useState(0);
  const [joinScreenMessage, setJoinScreenMessage] = useState('');
  const [ chatBox, setChatBox] = useState('');
  const [uName, setUname] = useState('{name:a,role:1}')
  const [mikeStatus, setMikeStatus] = useState(false);

  const [changeClass, setChangeClass] = useState('');

  //const singleParticipants = useParticipant({dName});

  const { displayName } = useParticipant(props.participantId);
  
  function onStreamEnabled(stream) {
    // This event will be triggered whenever a participant's video, audio or screen share stream is enabled.
    console.log(" onStreamEnabled", stream);
  }

  useEffect(() => {
    var products = localStorage.getItem('products');
    if(products !== '' && products !==null){
        setTotalItem(JSON.parse(localStorage.getItem('products')).length);
    }

    if(userRole == null){
      navigate('/');
    }

    if(userRole == 2){
      setUname('{"name":"Teacher","role":"1"}');
      setMikeStatus(true);
    }else{
      setUname('{"name":"Student","role":"2"}');
      setMikeStatus(false);
    }
    
    /* if(userRole == 3){
      setJoinScreenMessage("Join Screen");
      setMicOn(false);
    }else{
      setJoinScreenMessage();
      setMicOn(true);
    } */


    //const videoSrcObject = new MediaStream([videoTrack]);

  },[])


  const openChat = (data) => {
    //console.log(data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)

    setChatBox(data);
  }


  const getMeetingAndToken = async (id) => {


    /* try {
      axios.get(`http://localhost/tokens/gettoken.php`)
          .then(async (res) => {
              console.log(res);
            
          }).catch(error => 
            console.log("Error !", error)
          );
    } catch (error) {
        console.error('catch',error);
    }
    return false; */

    await fetch("https://videosdk.zeryabmusicacademy.com/gettoken.php")
    //await fetch("http://localhost/tokens/gettoken.php")
      .then((data) => {
        return data.json();
      })
      .then(async (post) => {
        authToken1 = post.token;
      });

    const meetingId =
      id == null ? await createMeeting({ token: authToken1 }) : id;
      setMeetingId(meetingId);

  };

   
    return (
        <>
        <Header itemCount={totalItem} />
        <section className={`videoSection ${ chatBox == 'true' ? "pr-30p" : ''}`}>
            <div className="container-fluid">
              <div className="row">
                    
                    {
                      meetingId ? (
                        
                        <div className="col-12">
                            <MeetingProvider
                              config={{
                                meetingId,
                                micEnabled: mikeStatus,
                                webcamEnabled: true,
                                name: uName,
                                multiStream: true,
                                mode: "CONFERENCE",
                              }}
                              token={authToken1}
                            >
                            <MeetingConsumer>
                              {() => <Container meetingId={meetingId} func={openChat} />}
                            </MeetingConsumer>
                          </MeetingProvider>
                        </div>
                       
                      ) : (
                        <>
                          <div className="col-9">
                            {/* <video
                              autoPlay
                              playsInline
                              muted
                              ref={videoPlayerRef}
                              controls={true}
                              className={"w-100 flip"}
                            /> */}
                            <div className="lson-checkout-list lclMd">
                            <div className="lson-checkout-wrap d-flex">
                              <div className="lson-checkout-lft">
                                <div className="lson-checkout-img">
                                  <img src="http://localhost/zeryab/public/uploads/teacher/photos/1662446262_teacher-4.webp" alt="Barak Barak" />
                                    </div>
                                    </div>
                                    <div className="lson-checkout-rgt">
                                      <div className="lson-checkout-text">
                                          <h4>4 lessons African/ Banjo a month with Barak Barak</h4>
                                          <p>4 lessons a month</p>
                                          <p><span>$40</span></p>
                                          <p>Date: Monday, Oct 31, 2022</p>
                                          <p>Timing: 07:00 AM-11:00 AM</p>
                                        </div>
                                      </div>
                                    </div>
                            </div>
                          </div>
                          {/* <ReactPlayer
          //
                            playsinline // very very imp prop
                            pip={false}
                            light={false}
                            controls={true}
                            muted={true}
                            playing={true}
                            //
                            url={videoPlayerRef}
                            //
                            height={"100%"}
                            width={"100%"}
                            onError={(err) => {
                              console.log(err, "participant video error");
                            }}
                          /> */}
                          </>
                      )
                    }
                   {
                    meetingId == null ? <div className="col-3 d-flex align-items-center">
                    <div className="JoinDiv">
                        <h5>{joinScreenMessage}</h5>
                        <JoinScreen  getMeetingAndToken={getMeetingAndToken} />
                      </div>
                    </div> : ''
                   }
                    
              </div>
          </div>
        </section>   
        <Footer />
        </>
    ); 
}

export default Videosdk