export const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiIyMDMwZWVkYS1hZTc2LTQ3N2QtYWFhNy00ZWI4MTY1MzdhMjAiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTY2ODQwNDQyMCwiZXhwIjoxNjY5MDA5MjIwfQ.rfy_RuXQnq8Vn8Uwp8KVCKULgIrA7TCGiBYy5c2ndRw";

// API call to create meeting
export const createMeeting = async ({ token }) => {
  //console.log("token",token);
  const res = await fetch(`https://api.videosdk.live/v1/meetings`, {
    method: "POST",
    headers: {
      authorization: `${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ region: "sg001", teacher: "one" }),
  });

  const { meetingId } = await res.json();
  
  return meetingId;
};