import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from './Loader';
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const ResetPassword = () => {
    const [t, i18n] = useTranslation('common');

    const formSchema = Yup.object().shape({
        password: Yup.string()
          .required(t('lang.validation.passwordRequired'))
          .min(6, t('lang.validation.phoneRegex')),
          confirm_password: Yup.string()
          .required(t('lang.validation.passwordRequired'))
          .oneOf([Yup.ref('password')], t('lang.validation.passwordNotMatched')),
      })

      const formOptions = { resolver: yupResolver(formSchema) }

    const { register, formState: { errors }, handleSubmit } = useForm(formOptions);
    let navigate                       = useNavigate();
    const params                       = useParams();
    const [settings, setSettings]      = useState([]);
    const [password, setPassword]      = useState("");
    const [newPassword, setNewPassword]      = useState("");
    const [email, setEmail]            = useState("");
    const [code, setCode]              = useState("");
    const [errorMsg, setMessage]       = useState([]);
    const [errorErrorMsg, setErrorMessage] = useState([]);
    const [error404Msg, set404Message] = useState([]);
    const [loading, setLoading]        = useState(false);


    //console.log("params", params);
    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('language'));

        if(settings.length <= 0){
          try {
            axios.get(process.env.REACT_APP_API_URL+"settings")
              .then(async (res) => {
                 if(res.status === 200){
                    setSettings(res.data.data);
                }else{
                  return console.log("Error !");
                }

              }).catch(error =>
                console.log("Error !")
              );
          } catch (error) {
            //console.error('error');
          }
        }

      },[]);

    const onSubmit = (data)  => {
    try {

        setLoading(true);

        const formData = new FormData();
        formData.append("code", code);
        formData.append("email", params?.email);
        formData.append("password", password);

        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL+"password/code/check",
            data: formData,
        }).then(function(res) {

            setErrorMessage('');
            if(res.status === 200){
                if(res.data.status == 0){
                    set404Message(t('lang.common.codeInvalid'));
                    /* setTimeout(() => {
                        navigate('/login');
                    }, 2000); */

                }else if(res.data.status == 2){
                    set404Message(t('lang.common.codeExpired'));
                    /* setTimeout(() => {
                        navigate('/login');
                    }, 2000); */
                }else if(res.data.status == 1){
                    setMessage(t('lang.common.passwordReset'));
                    setTimeout(() => {
                        navigate('/login');
                    }, 8000);
                }
                setLoading(false);

            }else{
                setLoading(false);
                setMessage("");
            }

        }).catch(function (error){

            setLoading(false);
            setErrorMessage(error.response.data.message);
        });
    } catch (error) {
        setLoading(false);
        //console.error('error');
    }
    //console.log(data);
    };

    const handleFields = async (e) => {
        if (e.target.name === "code") {
            setCode(e.target.value);
        }else if (e.target.name === "email") {
            setEmail(e.target.value);
        }else if (e.target.name === "password") {
            setPassword(e.target.value);
        }else if (e.target.name === "confirm_password") {
            setNewPassword(e.target.value);
        }
    }

    return (
        <>
        {
            loading === true ? (<Loader />) : ''
        }
            <section className='Login' style={{
  backgroundImage: "url(" + process.env.REACT_APP_UPLOAD_URL+'sitesettings/'+settings.login_banner + ")",
  'min-height': '100vh',
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
}}>
                <div className='loginWrap d-flex justify-content-end'>
                    <div className='LoginInner d-flex align-items-center'>
                        <div className='loginForm'>
                            <div className="logo">
                                <Link to="/">
                                    <img src={process.env.REACT_APP_UPLOAD_URL+'sitesettings/'+settings.logo} alt="" />
                                </Link>
                            </div>
                            <div className='loginFormInner'>
                            <div className="logins message text-success"><ul><li>{errorMsg}</li></ul></div>
                            <div className="logins message text-danger"><ul><li>{error404Msg}</li></ul></div>

                            {/* {error404Msg} */}
                                <h3>{t('lang.common.resetPassword')}</h3>
                                <form onSubmit={handleSubmit(onSubmit)}>

                                    <div className="form-floating mb-3">
                                        <input type="text" name="code" className="form-control" value={code} id="floatingInput" placeholder="Code" {...register("code", { required: true  })} onChange={ e => { handleFields(e) }} autoComplete="off" />
                                        <label htmlFor="floatingInput">{t('lang.common.resetCode')}</label>
                                        {errors?.code?.type === "required" && <p className='errors'>{t('lang.common.codeRequired')}</p>}
                                    </div>
                                    <input type="hidden" name="email" className="form-control" value={params?.email} id="floatingInputEmail"  />

                                    <div className="form-floating mb-3">
                                        <input type="password" name="password" value={password} className="form-control" id="floatingPassword1" placeholder={t('lang.validation.newPassword')} {...register("password", { required: true, minLength: 6 })} onChange={ e => { handleFields(e) }} autoComplete="off" />
                                        <label htmlFor="floatingPassword1">{t('lang.validation.newPassword')}</label>

                                        <p className='errors'>{errors.password?.message}</p>

                                    </div>

                                    <div className="form-floating mb-3">
                                        <input type="password" name="confirm_password" value={newPassword} className="form-control" id="floatingPassword" placeholder={t('lang.validation.newConfirmPassword')} {...register("confirm_password", { required: true, minLength: 6 })} onChange={ e => { handleFields(e) }} autoComplete="off" />
                                        <label htmlFor="floatingPassword">{t('lang.validation.newConfirmPassword')}</label>

                                        <p className='errors'>{errors.confirm_password?.message}</p>

                                    </div>

                                    <div className="form-group mb-3">
                                          <button type="submit" className="logInBtn">{t('lang.common.resetPassword')}</button>
                                    </div>
                                    <div className="form-group mb-3 text-center">
                                          <p>{t('lang.common.dontAccount')} <Link to="/register-teacher">{t('lang.common.signupNow')}</Link></p>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default ResetPassword