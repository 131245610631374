import React, { useEffect, useState } from 'react'
import Header from '../includes/Header'
import Footer from '../includes/Footer'

import { Link } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';
import OwlCarouselRtl from "react-owl-carousel-rtl";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from 'axios';
import SpotifyWebApi from 'spotify-web-api-js';
import { Loader } from './Loader';
import {useTranslation} from "react-i18next";
import i18next from "i18next";

//https://www.npmjs.com/package/spotify-web-api-js

let language = localStorage.getItem('language');
const MusicStream = () => {

    const [newRelease,   setNewRelease]       = useState([]);
    const [trendingAlbums, setTrendingAlbums] = useState([]);
    const [musicBanner, setMusicBanner]       = useState([]);
    const [loading, setLoading]               = useState(false);

    const [spotifyToken, setSpotifyToken]       = useState('');
    const [t, i18n] = useTranslation('common');

    //const [spotifyPlaylist, setSpotifyPlaylist] = useState([]);
    const [spotifyPlaylist, setSpotifyPlaylist] = useState({items:[]});

    const [totalItem, setTotalItem] = useState(0);

    const clientId = '3cb75e19b41244598fe3226ded37ffdc';
    const clientSecret = 'b3e9167eaf9a45348bcbc055f5772a58';
    
    
    /* const _getToken = async () => {

        const result = await fetch('https://accounts.spotify.com/api/token', {
            method: 'POST',
            headers: {
                'Content-Type' : 'application/x-www-form-urlencoded',
                'Authorization' : 'Basic ' + btoa(clientId + ':' + clientSecret)
            },
            body: 'grant_type=client_credentials'
        });
        
        const data = await result.json();
        
        setSpotifyToken(data.access_token);

        _getPlaylistByGenre(data.access_token);

        return data.access_token;
    } */

    /* const _getPlaylistByGenre = async (token) => {

        const limit = 10;
        
        const result = await fetch(`https://api.spotify.com/v1/playlists/3t7VcXQKa7Ai5CpzViRfNp`, {
            method: 'GET',
            headers: { 'Authorization' : 'Bearer ' + token}
        });

        const data = await result.json();
        setSpotifyPlaylist(data);
        console.log("data", spotifyPlaylist);
        return data.tracks.items;
        
    } */
    var spotifyApi = new SpotifyWebApi();

    useEffect(() => {

        window.scrollTo(0, 0);
        setLoading(true);
        i18next.changeLanguage(localStorage.getItem('language'));

        var products = localStorage.getItem('products');
        if(products !== '' && products !==null){
            setTotalItem(JSON.parse(localStorage.getItem('products')).length);
        }
        //setTotalItem(JSON.parse(localStorage.getItem('products')).length);
        
        try {
            axios.get(process.env.REACT_APP_API_URL+"albums")
              .then(async (res) => {
                  
                 if(res.status === 200){
                    setNewRelease(res.data.data.newRealease);
                    setTrendingAlbums(res.data.data.trendingAlbums);
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                }else{
                  return console.log("Error !");
                }
                
              }).catch(error => 
                console.log("Error !")
              );
        } catch (error) {
            console.error('error');
        }

        //Music Banners
        try {
            axios.get(process.env.REACT_APP_API_URL+"music-banners")
              .then(async (res) => {
                  
                 if(res.status === 200){
                    setMusicBanner(res.data.data.banners);
                }else{
                  return console.log("Error !");
                }
                
              }).catch(error => 
                console.log("Error !")
              );
        } catch (error) {
            console.error('error');
        }
        

        //Spotify
        //_getToken();

        try {
            const headers = {
                'Content-Type' : 'application/x-www-form-urlencoded',
                'Authorization' : 'Basic ' + btoa(clientId + ':' + clientSecret)
              }

            axios({
                method: 'post',
                headers: headers,
                url: "https://accounts.spotify.com/api/token",
                data: 'grant_type=client_credentials', 
            }).then(function(res) {
                
                if(res.data.access_token !== ''){
                    //Fetch playlist data
                    var access_token = res.data.access_token;
                    setSpotifyToken(access_token);
                    
                    spotifyApi.setAccessToken(access_token);

                    //All playlist
                    spotifyApi.getUserPlaylists('jamjhjfnovm19m39knlhekgnp').then(
                        function (data) {
                          //console.log('User playlists', data);
                          setSpotifyPlaylist(data);
                        },
                        function (err) {
                          console.error(err);
                        }
                    );

                    /* axios.get(`https://api.spotify.com/v1/users/jamjhjfnovm19m39knlhekgnp/playlists?limit=30`, {headers: { 'Authorization' : 'Bearer ' + access_token}})  
                    .then(async (res) => {
                        //console.log("res dsadfasdfas =",res);
                        setSpotifyPlaylist(res.data.items);
                        
                    }).catch(error => 
                        console.log("Error !")
                    );  */
                }
                
            }).catch(function (error){
                console.log(error);
            });



        } catch (error) {
            console.error('error');
        }
         

    }, []);
    const options =  {
        loop: true,
        margin: 10,
        nav: true,
        dots:false,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            }
        },
    };
  
    //console.log("spotifyPlaylist =",spotifyPlaylist);
  return (
    <>
        {
            loading === true ? (<Loader />) : ''
        }
        <Header itemCount={totalItem} />
          <section>
              {
                musicBanner.length > 0 &&
              
                language == 'ar' ? 
                
                <OwlCarouselRtl className='owl-theme newCourseBanSlider' rtlClass={'owl-rtl'} loop margin={10} nav dots={false} items={2} rtl={true}>
              {
                 musicBanner.map((val,indx) => {
                     return(<>
                         <div className='item' key={indx}>
                             <div className='newCourseBanInner'>
                                 <img src={process.env.REACT_APP_UPLOAD_URL+`music/banner/${val.banner_image}`} alt={val.banner_image} />
                             </div>
                         </div>
                     </>)
                 })
              }
               
           </OwlCarouselRtl>

              : 
              <OwlCarousel className='owl-theme newCourseBanSlider' loop margin={10} nav dots={false} items={2}>
                 {
                    musicBanner.map((val,indx) => {
                        return(<>
                            <div className='item' key={indx}>
                                <div className='newCourseBanInner'>
                                    <img src={process.env.REACT_APP_UPLOAD_URL+`music/banner/${val.banner_image}`} alt={val.banner_image} />
                                </div>
                            </div>
                        </>)
                    })
                 }
                  
              </OwlCarousel>
              }
          </section>

            
          <section className='newRealeaseSec'>
            <div className='container-fluid ph-40'>
                <div className='row g-3 g-2 g-md-3 g-lg-4'>
                    <div className='col-12'>
                        <div className='sectionTitle d-flex align-items-center justify-content-between'>
                            <h2>{t('lang.common.newRelease')}</h2>
                            {/* <Link to="/music-stream/new-release" className='viewAll'>View All</Link> */}
                        </div>
                    </div>
                {/* {
                    spotifyPlaylist.length !== '' ? spotifyPlaylist.map((val,indx) => {
                        return(<div className="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div className='projectInner w-100'>
                            <div className='projectInnerTop w-100'>
                                <img className='w-100' src={val.track.album.images[0].url} alt={val.track.name} />
                            </div>
                            <div className='projectInnerMid w-100'>
                                <Link to={`/album/${val.track.id}`}><h4>{val.track.name}</h4></Link>
                                
                            </div>
                        </div>
                    </div>)
                    }) : ''

                } */}
                {
                    spotifyPlaylist.length !== '' ? spotifyPlaylist.items.map((val,indx) => {
                        return(<div className="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div className='projectInner w-100'>
                            <div className='projectInnerTop-new w-100'>
                                <Link to={`/album/${val.id}`}>
                                    <img className='w-100' src={val.images[0].url} alt={val.name} />
                                </Link>
                            </div>
                            <div className='projectInnerMid w-100'>
                                <Link to={`/album/${val.id}`}><h4>{val.name}</h4></Link>
                                <p>{val.tracks.total > 1 ? val.tracks.total+' Songs' : val.tracks.total+' Song'}</p>
                            </div>
                        </div>
                    </div>)
                    }) : ''

                }
                    


                    {/* {
                        newRelease.map((value,index) => {
                        return (<>
                            <div className='col-xl-2 col-lg-2 col-md-3 col-sm-4 col-12' key={value.id}>
                                <Link to={`/album/${value.slug}`} className='newRealeaseBox'>
                                    <div className='newRealeaseBoxTop'>
                                        <img src={process.env.REACT_APP_UPLOAD_URL+`album/banner/${value.photo}`} alt={value.title} />
                                    </div>
                                    <div className='newRealeaseBoxBtm'>
                                        <h4>{value.title}</h4>
                                        <p>{value.description}</p>
                                    </div>
                                </Link>
                            </div>
                        </>)     
                        })
                    } */}
                </div>
            </div>
          </section>

          {/* <section className='newRealeaseSec trandingMusic'>
            <div className='container-fluid ph-40'>
                <div className='row g-3 g-2 g-md-3 g-lg-4'>
                    <div className='col-12'>
                        <div className='sectionTitle d-flex align-items-center justify-content-between'>
                            <h2>Trending MUSIC</h2>
                            <Link to="/music-stream/trending-music" className='viewAll'>View All</Link>
                        </div>
                    </div>
                    {
                        trendingAlbums.map((value,index) => {
                        return (<>
                            <div className='col-xl-2 col-lg-2 col-md-3 col-sm-4 col-12' key={index}>
                                <Link to={`/album/${value.slug}`} className='newRealeaseBox'>
                                    <div className='newRealeaseBoxTop'>
                                        <img src={process.env.REACT_APP_UPLOAD_URL+`album/banner/${value.photo}`} alt={value.title} />
                                    </div>
                                    <div className='newRealeaseBoxBtm'>
                                        <h4>{value.title}</h4>
                                        <p>{value.description}</p>
                                    </div>
                                </Link>
                            </div>
                        </>)     
                        })
                    }
                
                </div>
            </div>
          </section> */}

          {/* <section className='newRealeaseSec'>
            <div className='container-fluid ph-40'>
                <div className='row g-3 g-2 g-md-3 g-lg-4'>
                    <div className='col-12'>
                        <div className='sectionTitle d-flex align-items-center justify-content-between'>
                            <h2>New Release</h2>
                            <Link to="/music-stream/new-release" className='viewAll'>View All</Link>
                        </div>
                    </div>
                    {
                        newRelease.map((value,index) => {
                        return (<>
                            <div className='col-xl-2 col-lg-2 col-md-3 col-sm-4 col-12' key={value.id}>
                                <Link to={`/album/${value.slug}`} className='newRealeaseBox'>
                                    <div className='newRealeaseBoxTop'>
                                        <img src={process.env.REACT_APP_UPLOAD_URL+`album/banner/${value.photo}`} alt={value.title} />
                                    </div>
                                    <div className='newRealeaseBoxBtm'>
                                        <h4>{value.title}</h4>
                                        <p>{value.description}</p>
                                    </div>
                                </Link>
                            </div>
                        </>)     
                        })
                    }
                 
                </div>
            </div>
          </section> */}

        <Footer />
    </>
  )
}

export default MusicStream