import React, { Component, useEffect, useState } from 'react'
import logo from "../logo.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faFacebook , faTwitter, faInstagramSquare} from '@fortawesome/free-brands-svg-icons'
import { faEnvelope , faPhone} from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import i18next from "i18next";
import {useTranslation} from "react-i18next";

const Footer =() => {

    const [settings, setSettings] = useState('');
    const [t, i18n] = useTranslation('common');

    useEffect(() => {
   
        if(settings.length <= 0){
          try {
            axios.get(process.env.REACT_APP_API_URL+"settings")
              .then(async (res) => {
                 if(res.status === 200){
                    setSettings(res.data.data);
                    
                }else{
                  return console.log("Error !");
                }
                
              }).catch(error => 
                console.log("Error !")
              );
          } catch (error) {
            //console.error('error');
          }
        }

      }, []);

    return(
        <>
        <footer id='footerHeight'>
                <div className='footerTop'>
                    <div className='container-fluid ph-40'>
                        <div className='row'>
                            <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                <div className='footerBox'>
                                    <img src={process.env.REACT_APP_UPLOAD_URL+'sitesettings/'+settings.logo} alt="" />
                                    <ul className='d-flex align-items-center'>
                                        <li>{t('lang.menu.menu_24')}</li>
                                        <li className='faIcon'><Link to='#' onClick={() => window.open(settings.facebook) }><FontAwesomeIcon icon={faFacebook} /></Link></li>
                                        <li className='faIcon'><Link to='#' onClick={() => window.open(settings.twitter) }><FontAwesomeIcon icon={faTwitter} /></Link></li>
                                        <li className='faIcon'><Link to='#' onClick={() => window.open(settings.instagram) }><FontAwesomeIcon icon={faInstagramSquare} /></Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-4 col-sm-6 col-6'>
                                <div className='fBox'>
                                    <h5>{t('lang.menu.menu_25')}</h5>
                                    <ul>
                                        <li><Link to="/pages/privacy-policy">{t('lang.menu.menu_26')}</Link></li>
                                        <li><Link to="/pages/terms-and-conditions">{t('lang.menu.menu_27')}</Link></li>
                                        <li><Link to="/pages/about-us">{t('lang.menu.menu_28')}</Link></li>
                                        <li><Link to="/tutor">{t('lang.menu.menu_29')}</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-4 col-sm-6 col-6'>
                                <div className='fBox'>
                                    <h5>{t('lang.menu.menu_30')}</h5>
                                    <ul>
                                        <li><Link to="/pages/disclaimer">{t('lang.menu.menu_31')}</Link></li>
                                        <li><Link to="/pages/faq">{t('lang.menu.menu_32')}</Link></li>
                                 
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-2 col-md-12 col-sm-6 col-12'>
                                <div className='fBox fontSize14'>
                                    <h5>{t('lang.menu.menu_33')}</h5>
                                    <div className='fBoxInner'>
                                        <h6>{settings.site_title}</h6>
                                        <p>{settings.address}</p>
                                    </div>
                                    <div className='fBoxInner'>
                                        <ul>
                                            <li><FontAwesomeIcon icon={faPhone} /> {settings.support_phone}</li>
                                            <li><FontAwesomeIcon icon={faEnvelope} /> <Link to='#' onClick={() => window.location = `mailto:`+settings.contact_email}>{settings.contact_email}</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footerBtm'>
                    <div className='container-fluid ph-40'>
                        <div className='row'>
                            <div className='col-12'>
                                <p>{settings.copyright}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;