import React, { Component, useState, useEffect  } from 'react'
import Header from '../includes/Header'
import Footer from '../includes/Footer'
import { Link, useParams, Navigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faStar, faCirclePlay } from '@fortawesome/free-solid-svg-icons'
import InputNumber from 'rc-input-number';
import axios from 'axios';
import parse from 'html-react-parser';
import 'rc-input-number/assets/index.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import ModalVideo from 'react-modal-video'
import 'react-modal-video/css/modal-video.css';
import { faYoutube, faVimeoSquare } from '@fortawesome/free-brands-svg-icons';

import SliderImage from 'react-zoom-slider';
import ReviewForm from './ReviewForm';

import { withTranslation, WithTranslation } from 'react-i18next';

const data = [
    {
        image: '../images/zoomer/1.jpg',
        //text: 'img1'
    },
    {
        image: '../images/zoomer/2.jpg',
        //text: 'img2'
    },
    {
        image: '../images/zoomer/3.jpg',
        //text: 'img3'
    },
    {
        image: '../images/zoomer/4.jpg',
        //text: 'img1'
    },
    {
        image: '../images/zoomer/5.jpg',
        //text: 'img2'
    },
    {
        image: '../images/zoomer/6.jpg',
        //text: 'img3'
    },
];
// var gallery = [{}];
function withParams(Comp) {
    // eslint-disable-next-line func-names
    return function (props) {
      // eslint-disable-next-line react/jsx-filename-extension
      return <Comp {...props} params={useParams()} />;
    };
}


  var totalStars = '';
  let languages = localStorage.getItem('language');
class ProductDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
          product:[],
          reviews: [],
          purchased: [],
          loading: false,
          discountPercent: 0,
          isOpen: false,
          isOpenMain: false,
          gallery:[{}],
          totalShopItem : 0,
          discountedPrice: 0,
          introVideoUrl: '',
          videoChannel: 'custom',
          reviewCount:'',
          totalItem : 0,
          redirect : false,
          ratingAvg:5,
          totalStars: '',
          currencySymbol: '$',
          courePrice: 0,
          coureDiscountPrice: 0,
          courseDiscountPercent: 0,
          reviewGiven: 0,
        }

        this.openModal = this.openModal.bind(this);
        this.openModalTop = this.openModalTop.bind(this);
    }

    openModal(e) {
        this.setState({ isOpen: true })
    }

    openModalTop() {
        this.setState({ isOpenMain: true })
    }

    YouTubeGetID(url){
        if(url !== undefined && url !== null){
            url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
            return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
        }else{
            return '';
        }
    }

    handleAddToCart = (id,type,uniqueid,total) =>{
        let alreadyAdded = '';

        if(localStorage.getItem('language') == 'ar'){
            alreadyAdded = 'تمت إضافة عربة الانتقال بالفعل';
        }else{
            alreadyAdded = 'Already added goto cart';
        }
        let getProducts = [];
        let selectedProduct = localStorage.getItem('products');
        let check = 1;
        let data = {
          id:id,
          type:type,
          uniqueid:uniqueid,
          total:total
        };
        //console.log(localStorage.getItem('products'));

        if(selectedProduct != '' && selectedProduct != null){
            selectedProduct = JSON.parse(selectedProduct);
            selectedProduct.map((v,i) => {
                if(v.uniqueid == uniqueid && v.type == type){
                    toast.success(alreadyAdded, {
                        position: toast.POSITION.TOP_RIGHT
                    });

                    check = 0;

                }
            })
        }

        if(check == 0){
            return false;
        }

        if(localStorage.getItem('products') !== '' && localStorage.getItem('products')!==null){
            getProducts = JSON.parse(localStorage.getItem('products'));

            getProducts.push(data);
            localStorage.setItem('products', JSON.stringify(getProducts));

            this.setState({ totalItem  : JSON.parse(localStorage.getItem('products')).length});

        }else{

          localStorage.setItem('products', JSON.stringify([data]));
          this.setState({ totalItem  : JSON.parse(localStorage.getItem('products')).length});
        }
    }

    onSubmit =(data) =>{
        console.log(data);
    }

    buyNow =(e) => {
        let data = {
            id:e.id,
            item:e.item
        };

        const getStoreProduct = JSON.parse(localStorage.getItem('storeProduct'));

        if(localStorage.getItem('storeProduct') != null){

            if(getStoreProduct.length > 0){
                getStoreProduct.map((v,i) => {
                    if(v.id === data.id && data.item == 'shop'){
                        //console.log("data=",data.id);
                        getStoreProduct.splice(i, 1);
                    }
                });
                //getStoreProduct.push(data);
                //localStorage.setItem('storeProduct', JSON.stringify(getStoreProduct));
                localStorage.setItem('storeProduct', JSON.stringify(data));

                this.setState({ totalShopItem  : JSON.parse(localStorage.getItem('storeProduct')).length});
            }else{
                localStorage.setItem('storeProduct', JSON.stringify([data]));
                this.setState({ totalShopItem  : JSON.parse(localStorage.getItem('storeProduct')).length});
            }

        }else{
            localStorage.setItem('storeProduct', JSON.stringify([data]));
            this.setState({ totalShopItem  : JSON.parse(localStorage.getItem('storeProduct')).length});
        }
        //this.props.history.push('/checkout');
        this.state.redirect = true;
        //console.log(getStoreProduct);

    }

      async componentDidMount() {
        window.scrollTo(0, 0);
        const slug = this.props.params.slug;
        var products = localStorage.getItem('products');
        if(products !== '' && products !==null){
            this.setState({ totalItem  : JSON.parse(localStorage.getItem('products')).length});
        }

        if(localStorage.getItem('currency') == 2){
            this.setState({ currencySymbol: '£' });
        }

        if(slug !== ""){

          let loginUser    = localStorage.getItem('loginUser');

          try {
            await this.setState({loading:true});

            axios.get(process.env.REACT_APP_API_URL+`product/${slug}?user=${loginUser}`)
              .then(async (res) => {
                 if(res.status === 200){

                    this.setState({ product: res.data.data.product });
                    this.setState({ reviews: res.data.data.reviews });
                    this.setState({ purchased: res.data.data.purchased });
                    this.setState({ reviewCount: res.data.data.reviewCount });
                    this.setState({ ratingAvg: res.data.data.ratingAvg });
                    this.setState({ reviewGiven: res.data.data.alreadyGiveReview });

                    let introVideo = ''; let channels = ''; let channerId = '';
                    if(res.data.data.product.video_type === '1'){
                        introVideo = res.data.data.product.video; //process.env.REACT_APP_UPLOAD_URL+`/courses/introvideo/${res.data.data.course.introductory_video}`;
                        channels = 'vimeo';
                        channerId = res.data.data.product.video;
                    }else if(res.data.data.product.video_type === '2'){
                        introVideo = res.data.data.product.video_url;
                        channels = 'youtube';
                        channerId = this.YouTubeGetID(res.data.data.product.video_url);
                    }else{
                        introVideo = '';
                        channels = 'custom';
                        channerId = '';
                    }


                    this.setState({ introVideoUrl: introVideo });
                    this.setState({ setChannel: channels });
                    this.setState({ channerIds: channerId });


                    // for (let index = 0; index <= res.data.data.ratingAvg; index++) {
                    //     totalStars += '<FontAwesomeIcon icon={faStar} />';

                    // }

                    this.setState({ loading: false });

                    if(res.data.data.product.gallery.length>0){
                        this.state.gallery = [];
                        res.data.data.product.gallery.map((val) => {
                            this.state.gallery.push({image: val, text: ''})
                        })
                    }

                    //Discount price formula = (price - discount) * 100 / price
                    const percent = (res.data.data.product.price - res.data.data.product.discount_price ) * 100 / res.data.data.product.price; ///res.data.data.product.price;
                    this.setState({ discountPercent : percent.toFixed(2) });

                    const discountPrice = res.data.data.product.price - (res.data.data.product.price * res.data.data.product.discount_price / 100);

                    this.setState({ discountedPrice : discountPrice });

                    let price = res.data.data.product.price;
                    let dPrice = res.data.data.product.discount_price;

                    if(localStorage.getItem('currency') == 2){
                        price = price * Number(localStorage.getItem('currencyRate'));
                        //dPrice = dPrice * Number(localStorage.getItem('currencyRate'));
                    }else{
                        price = price;
                        //dPrice = dPrice;
                    }

                    let discountedPrice = price - (price * dPrice / 100);

                    this.setState({ courseDiscountPercent : dPrice });
                    this.setState({ courePrice : price });
                    this.setState({ coureDiscountPrice : discountedPrice });


                }else{
                  return console.log("Error !");
                }

              }).catch(error =>
                console.log("Error !", error)
              );
          } catch (error) {
              //console.error('error');
          }
        }
      }

    render() {
        let { t, i18n } = this.props;
        return (
            <>
            <ToastContainer autoClose={2000} />
                {this.state.redirect && <Navigate to="/checkout" />}
                <Header itemCount={this.state.totalItem} />
                <section className='zoomerArea'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-5 col-md-5 col-sm-12 col-12'>
                                <div className='zoomerWrap'>
                                    <SliderImage
                                        autoplay={true}
                                        data={this.state.gallery}
                                        width="100%"
                                        showDescription={false}
                                        direction="right"
                                    />
                                </div>
                                <div className='videoBtnWrapper'>
                                {
                                    this.state.product.video != null ?
                                    <>
                                        {
                                        this.state.product.video_type == '1' ?

                                            <React.Fragment>
                                                    <ModalVideo videoId={this.state.channerIds} channel={this.state.setChannel} url={this.state.introVideoUrl} isOpen={this.state.isOpenMain} onClose={() => this.setState({ isOpenMain: false })} />

                                                    <FontAwesomeIcon icon={faCirclePlay} size="lg" onClick={this.openModalTop} />
                                            </React.Fragment>

                                          : ''
                                        }

                                    </>

                                    : (this.state.product.video_type == '2' && this.state.product.video_url != null) ?




                                        <React.Fragment>
                                            <ModalVideo videoId={this.YouTubeGetID(this.state.channerIds)} channel="youtube" url={this.state.introVideoUrl} isOpen={this.state.isOpenMain} onClose={() => this.setState({ isOpenMain: false })} />

                                                <FontAwesomeIcon icon={faCirclePlay} size="lg" onClick={this.openModalTop} />

                                        </React.Fragment>


                                     : ''
                                }
                                </div>
                            </div>
                            <div className='col-lg-7 col-md-7 col-sm-12 col-12'>
                                <div className='producDetailRight'>
                                    <div className='pdrTop w-100'>
                                        <h2>{ languages == 'ar' ? this.state.product.product_name_ar : this.state.product.product_name}</h2>
                                        <ul className='d-flex align-items-center'>

                                        <li>
                                            {
                                                this.state.ratingAvg != '' &&
                                                (() => {
                                                    let td = [];
                                                    for (let i = 1; i <= this.state.ratingAvg; i++) {
                                                      td.push(<FontAwesomeIcon icon={faStar} />);
                                                    }
                                                    return td;
                                                  })()
                                            }

                                        </li>

                                            {
                                                this.state.reviewCount != '' ? <li className='ratingBar'> {this.state.reviewCount} {t('lang.common.ratings')}</li> : ''
                                            }

                                            {/* <li>60 answered questions</li> */}
                                        </ul>
                                    </div>
                                    <div className='totalUpdate w-100'>
                                        <div className='prOffer w-100'>
                                            <span className="currentPrice">{ this.state.courePrice != 0 ? this.state.currencySymbol+''+Math.round(this.state.coureDiscountPrice) : t('lang.common.free') }</span> { this.state.courePrice != 0 && this.state.courseDiscountPercent !=0 ? <span className='line-through'>{this.state.currencySymbol}{Math.round(this.state.courePrice)}</span> : '' } { this.state.courePrice != 0 && this.state.courseDiscountPercent !=0 ? <span className="percentOff">{this.state.courseDiscountPercent}% {t('lang.common.off')}</span> : '' }
                                        </div>
                                        <div className='inputArea d-flex align-items-center w-100'>
                                            <p className='ml-4'>{t('lang.common.quantity')}</p>
                                            <InputNumber defaultValue={1}  min={1} max={10} width={100} />
                                        </div>
                                    </div>
                                    <div className='inputArea'>
                                        <h5>{t('lang.common.aboutThisItem')}</h5>
                                        { languages == 'ar' ?  parse(`${this.state.product.product_description_ar}`) : parse(`${this.state.product.product_description}`) }

                                    </div>
                                    <div className='addBuy'>
                                        <ul className='d-flex'>
                                            <li>
                                            <button type='button' onClick={ (e) => {this.handleAddToCart(this.state.product.id,'product', 'product'+this.state.product.id, 1)}} className='addCartBtn'>{t('lang.common.add_to_cart')}</button>

                                            {/* <li><button className='buyNowShop' onClick={(e)=> this.buyNow({"item": "shop", "id": this.state.product.id})}>Buy Now</button></li> */}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {
                    this.state.reviews != '' ? <section className='courseDtlsSection'>
                    <div className='container'>
                        <div className='row g-2 g-md-3 g-lg-4'>
                            <div className='col-12'>
                                <div className='reviews'>
                                    <h3>{t('lang.common.reviews')}</h3>
                                    {
                                        this.state.reviews.map((val, indx) =>{
                                            let text = '';
                                            let letter = '';
                                            let userName = '';
                                            var reviewHtml = '';


                                            if(val.review_by != null){
                                                text = val.review_by.name;
                                                letter = text.charAt(0);
                                                userName = val.review_by.name;

                                                reviewHtml+=`<div className='reviewBox d-flex' key={${indx}}>
                                                <div className='reviewBoxIcon'>
                                                    <span>${letter}</span>
                                                </div>
                                                <div className='reviewBoxDesc'>
                                                    <div className='d-flex align-items-center starArea w-100'>
                                                        <span className='rtng'>${userName}</span>
                                                        <ul className='d-flex'>
                                                            <li><FontAwesomeIcon icon={faStar} /></li>
                                                            <li><FontAwesomeIcon icon={faStar} /></li>
                                                            <li><FontAwesomeIcon icon={faStar} /></li>
                                                            <li><FontAwesomeIcon icon={faStar} /></li>
                                                            <li><FontAwesomeIcon icon={faStar} /></li>
                                                        </ul>
                                                    </div>
                                                    <div className='dateMonth w-100'>${moment(val.created_at,"YYYYMMDD").fromNow()}</div>
                                                    <div className='w-100 reviewBoxDescDtls'>
                                                        <p>${val.review}</p>
                                                    </div>
                                                </div>
                                            </div>`
                                            }

                                            return(parse(reviewHtml))
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </section> : ''
                }

                {
                    this.state.purchased != null && this.state.purchased != '' ?
                    this.state.reviewGiven != 1 ?
                        <section className='ReviewRating'>
                            <div className='container'>
                                <div className="news_reply_form full">
                                    <h3>{t('lang.common.rateThisProduct')}</h3>
                                    <ReviewForm type="2" item={this.state.product.id} />
                                </div>
                            </div>
                        </section> : ''
                     : ''
                }

                <Footer />
            </>
        )
    }
}

export default withParams(withTranslation()(ProductDetails));