import React, { useEffect, useState } from 'react'
import Header from '../includes/Header';
import Footer from '../includes/Footer';
import axios from 'axios';
import parse from 'html-react-parser';
import { Link, useParams } from "react-router-dom";
import { Loader } from './Loader';
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const VerifyEmail = () => {
  const params = useParams();

  const [loading, setLoading]      = useState(false);
  const [totalItem, setTotalItem]  = useState(0);
  const [message, setMessage]      = useState('');  
  const [apiStatus, setApiStatus]  = useState(0);
  const [t, i18n] = useTranslation('common');

  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('language'));    
    window.scrollTo(0,0);
    setLoading(true);
    var products = localStorage.getItem('products');
    if(products !== '' && products !==null){
        setTotalItem(JSON.parse(localStorage.getItem('products')).length);
    }
    
        try {

            axios({
                method: 'post',
                url: process.env.REACT_APP_API_URL+"verify",
                data: {slug: params.slug, token:params.token}, 
            }).then(function(res) {
                if(res.status === 200){
                    setLoading(false);
                    setMessage(res.data.data);
                    setApiStatus(res.data.status);
 
                }else if(res.data.data.status === 404){
                    setLoading(false);
                    setMessage(res.data.data);
                    setApiStatus(res.data.status);
                }
                
            }).catch(function (error){
                setLoading(false);
                
            });

        } catch (error) {
            //console.error('error');
        }
}, [params.slug]);


  return (
    <>
    {
        loading === true ? (<Loader />) : ''
    }
    <Header itemCount={totalItem} />
    <section className=" thankYouSection">
            <div className="container">
                <div className="text-center text-style-design thank-you">
                    <h1>{ apiStatus == 1 ? 'Thank You!' : '' }</h1>
                    <p><strong>{message}</strong></p>
                     { apiStatus == 1 ? <Link to="/login" className='continueBtn'>{t('lang.common.continueLogin')}</Link> : '' }  
            </div>
        </div>
    </section>
    <Footer />
    </>
  )
}

export default VerifyEmail