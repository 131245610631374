import React, { useEffect, useState } from 'react'
import Header from '../includes/Header';
import Footer from '../includes/Footer';
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import parse from 'html-react-parser';
import { Loader } from './Loader';
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const Search = (props) => {
  
  const [totalItem, setTotalItem]       = useState(0);
  const location                        = useLocation();
  let navigate                          = useNavigate();
  const search                          = useLocation().search;
  const searchQuery                     = new URLSearchParams(search).get('search');
  const [searchResult, setSearchResult] = useState([]);  
  const [loading, setLoading]           = useState(false);
  const [t, i18n]                       = useTranslation('common');

  useEffect(() =>{

    i18next.changeLanguage(localStorage.getItem('language'));

    var products = localStorage.getItem('products');
    if(products !== '' && products !==null){
        setTotalItem(JSON.parse(localStorage.getItem('products')).length);
    }

    try {
        axios.get(process.env.REACT_APP_API_URL+`search/${searchQuery}`,{
          headers: {
            'Content-Type':'application/json',
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods':'POST,PATCH,OPTIONS,GET' 
          }
         })
          .then(async (res) => {
              if(res.status === 200){
                setSearchResult(res.data.data.search);
                setLoading(false);
            }else{
              return console.log("Error !");
            }
            
          }).catch(error => 
            console.log("Error !", error)
          );
    } catch (error) {
        console.error('error ',error);
    }

  },[searchQuery])

  //console.log(typeof (searchResult))
  return (
    <>
        {
            loading === true ? (<Loader />) : ''
        }
        <Header itemCount={totalItem} />
        <section>
          <div className='container-fluid ph-40'>
            <div className='row'>
            <div className="col-12">
              <div className="sectionTitle mt-4">
                  <h2 className="p-0">Search Result For:{searchQuery}</h2>
              </div>
            </div>
            </div>
          </div>
        </section>
        <section className='storeSection'>
                <div className='container-fluid ph-40'>
                    <div className='row g-3' dangerouslySetInnerHTML={{__html: searchResult}}></div> 
                </div>
        </section>
        <Footer />
    </>

  )
}

export default Search